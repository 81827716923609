import React from 'react';
import { isNil } from 'ramda';
import { createDiv, createElement } from '@nonlux/react-common';


const OptionsContainer = createDiv('OptionsContainer', {
  marginTop: '15px'
});

const OptionsItem = createDiv('OptionsItem', {
  fontSize: '17px',
  marginBottom: '5px'
});

const OptionsItemVal = createElement('span', 'OptionsItemVal', ({ green }) => ({
  fontWeight: 700,
  color: green && 'green'
}));

const OptionsItemName = createElement('span', 'OptionsItemName', {
  transition: '0.25s'
});

const OptionsForm = ({ options, type }) => (
  <OptionsContainer>
    <OptionsItem>
      <OptionsItemVal>
        Количество:&nbsp;
      </OptionsItemVal>
      <OptionsItemName>
        Неограниченно
      </OptionsItemName>
    </OptionsItem>
    <OptionsItem>
      <OptionsItemVal>
        Одновремено:&nbsp;
      </OptionsItemVal>
      <OptionsItemName>
        {!isNil(options.maxSyncCount) ? `до ${options.maxSyncCount} профилей` : 'Неограниченно'}
      </OptionsItemName>
    </OptionsItem>
    {(type === 'basic') && (
      <OptionsItem>
        <OptionsItemVal green>
          БЕСПЛАТНО:&nbsp;
        </OptionsItemVal>
        <OptionsItemName>
          При наличии активной VDS
        </OptionsItemName>
      </OptionsItem>
    )}

  </OptionsContainer>
);
export default OptionsForm;

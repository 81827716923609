import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  loader__box: {
    height: '55px',
  },
  parent: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    overflow: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.3);',
    zIndex: 10,
  },
  block: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

import React from 'react';
import { css } from 'aphrodite';
import { styles } from './styles';

const loader = require('./gif/loading.gif');

export default function Loader() {
  return (
    <div className={css(styles.parent)}>
      <div className={css(styles.block)}>
        <img alt="loader" className={css(styles.loader__box)} src={loader} />
      </div>
    </div>
  );
}

import React, { Fragment } from 'react';
import Plx from 'react-plx';
import { css } from 'aphrodite/no-important';
import { styles } from './styles';

const x1d2 = require('./img/1x2d.png');
const f1g2 = require('./img/f1g2.png');

const Parallax1x2d = [
  {
    start: 0,
    duration: 350,
    properties: [
      {
        startValue: 190,
        endValue: 400,
        property: 'translateX',
      },
    ],
  },
];
const ParallaxF1g2 = [
  {
    start: 0,
    duration: 350,
    properties: [
      {
        startValue: -170,
        endValue: -500,
        property: 'translateX',
      },
    ],
  },
];

export default function Parallax({ layoutType }) {
  const parallax = (layoutType === 'lg' || layoutType === 'xl') && (
    <Fragment>
      <Plx parallaxData={ParallaxF1g2}>
        <img alt="f1g2" className={css(styles.parallax__f1g2)} src={f1g2} />
      </Plx>
      <Plx parallaxData={Parallax1x2d}>
        <img alt="x1d2" className={css(styles.parallax__x1d2)} src={x1d2} />
      </Plx>
    </Fragment>
  );
  return parallax;
}

import { createElement } from '@nonlux/react-common';
import { mapProps } from 'recompose';

export default mapProps((props) => {
  const {
    values,
    name,
    value,
    defaultChecked,
    onChange,
    ...rest
  } = props;
  let checked = false;
  if (values && values[name] == value) {
    checked = true;
  }
  const onChangeNext = onChange || (() => null);
  return {
    type: 'radio',
    name,
    value,
    checked,
    onChange: onChangeNext,
    ...rest,
  };
})(createElement('input', 'Radio'));

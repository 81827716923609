import React from 'react';
import {
  Col,
  Row,
} from 'reactstrap';

export default ({ children, className }) => (
  <Row className={className}>
    <Col>
      {children}
    </Col>
  </Row>
);

export const COLORS = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  DARK: '#20232a',
  BASE_DARK: '#292d34',
  BLUE: '#61dafb',
  BLUE_LIGHT: '#56c7e4',
  BLUE_DARK: '#55b6cf',
  DARK_BLUE: '#b8cadf',
  WHITE_BLUE: '#f6f9fc',
  DARK_GRAY: '#aaaaab',
  LIGHT_GRAY: '#aaaaab',
  GRAY: '#6d6d6d',
  WHITE_GRAY: '#d3d3d3',
  RED_LIGHT: '#f3d9dc',
};

let SIZES = {
  FULL: 15,
  CLEAR: 0,
  BORDER: 1
};

SIZES.HALF = SIZES.FULL / 2;
SIZES.FIXEDHALF = SIZES.HALF + 5;

SIZES = Object.entries(SIZES).reduce((acc, [key, value]) => ({
  [key]: `${value}px`,
  ...acc
}), {});

const border = () => `${SIZES.BORDER} solid ${COLORS.BORDERS}`;
const getMedia = (media, layoutType) => {
  const layoutMedia = {
    xs: `${media[0]}px`,
    sm: `${media[1]}px`,
    md: `${media[2]}px`,
    lg: `${media[3]}px`,
    xl: `${media[4]}px`
  };
  return layoutMedia[layoutType];
};
const getMediaAcc = (mediaAcc, layoutType) => {
  const layoutMediaAcc = {
    xs: `${mediaAcc[0]}`,
    sm: `${mediaAcc[1]}`,
    md: `${mediaAcc[2]}`,
    lg: `${mediaAcc[3]}`,
    xl: `${mediaAcc[4]}`
  };
  return layoutMediaAcc[layoutType];
};
const getSizeButton = layoutType => (['xs', 'sm'].includes(layoutType) ? 'md' : 'lg');

const STYLES = {
  COLORS,
  SIZES,
  border,
  getSizeButton,
  getMedia,
  getMediaAcc
};

export default STYLES;

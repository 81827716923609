import React from 'react';
import { withModalDialogData } from '../../../reducer/modalDialog/selectors';
import ModalDialogConfirm from './ModalDialogConfirm';
import { numToStr, numToStrTypes } from '../../../reducer/helpers';

const ConfirmBuyProfileTariff = ({ modalDialogData }) => {
  const {
    currentSubName, newSubName, price, isSurcharge, surcharge, syncsPrice, buySyncCount
  } = modalDialogData;

  const getModalText = () => {
    let modalTitle = '';
    let modalText = '';

    if (currentSubName === newSubName) {
      modalTitle = 'Продление тарифа';
      modalText = `Вы продлеваете тариф ${currentSubName} на 30 дней, со счёта будет списано: ${surcharge}€`;
    } else if (isSurcharge) {
      if (buySyncCount && syncsPrice) {
        modalTitle = 'Обновление тарифа';
        modalText = `Вы обновляете тариф ${currentSubName.toUpperCase()} на ${newSubName.toUpperCase()} за ${surcharge}€ и приобретаете ${buySyncCount} синхронизации(ий) за ${syncsPrice}€, итого со счёта будет списано: ${surcharge + syncsPrice}€`;
      } else {
        modalTitle = 'Обновление тарифа';
        modalText = `Вы обновляете тариф ${currentSubName.toUpperCase()} на ${newSubName.toUpperCase()}, со счёта будет списано: ${surcharge}€`;
      }
    } else if (buySyncCount && syncsPrice) {
      modalTitle = 'Приобретение тарифа';
      modalText = `Вы приобретаете тариф ${newSubName.toUpperCase()} на 30 дней за ${price}€ и ${buySyncCount} ${numToStr(buySyncCount, numToStrTypes.syncs)} за ${syncsPrice}€, итого со счёта будет списано: ${price + syncsPrice}€`;
    } else {
      modalTitle = 'Приобретение тарифа';
      modalText = `Вы приобретаете тариф ${newSubName.toUpperCase()} на 30 дней, со счёта будет списано: ${price}€`;
    }
    return { modalTitle, modalText };
  };

  const modalData = getModalText();

  return (
    <ModalDialogConfirm
      title={modalData.modalTitle}
      text={modalData.modalText}
    />
  );
};

export default withModalDialogData(ConfirmBuyProfileTariff);

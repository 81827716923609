import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Parallax,
  Presentation,
  BorderContainer,
} from 'components';
import { useTranslation } from 'react-i18next';
import {
  affiliatePresentationText,
} from './data.js';

import PartnerCabinet from './PartnerCabinet/PartnerCabinet.js';
import Case from './Case/Case.js';
import WhyArb from './WhyArb/WhyArb.js';

function AffiliatePage({ layoutType }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Presentation
        layoutType={layoutType}
        presentation={affiliatePresentationText}
      />
      <Parallax layoutType={layoutType} />
      <PartnerCabinet title={t('affiliate.partner_cabinet.title')} />
      <BorderContainer />
      <WhyArb title={t('affiliate.advantage.title')} />
      <BorderContainer />
      <Case title={t('affiliate.cases.title')} />
    </Fragment>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType
}))(AffiliatePage);

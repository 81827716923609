export const AudioScraper = new class {
  constructor() {
    this.AudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
  }

  isAudioSupported() {
    return !!this.AudioContext;
  }

  scrap() {
    return new Promise((resolve, reject) => {
      const result = { buffer: [] };

      if (!this.AudioContext == null) {
        resolve(result);
      }

      let context = new this.AudioContext(1, 44100, 44100);

      const oscillator = context.createOscillator();
      oscillator.type = 'triangle';
      oscillator.frequency.setValueAtTime(10000, context.currentTime);

      const compressor = context.createDynamicsCompressor();
      [
        ['threshold', -50],
        ['knee', 40],
        ['ratio', 12],
        ['reduction', -20],
        ['attack', 0],
        ['release', 0.25]
      ].forEach(([option, value]) => {
        const canSetOption = compressor[option] !== undefined && typeof compressor[option].setValueAtTime === 'function';
        if (canSetOption) {
          compressor[option].setValueAtTime(value, context.currentTime);
        }
      });

      oscillator.connect(compressor);
      compressor.connect(context.destination);
      oscillator.start(0);
      context.startRendering();

      const audioTimeoutId = setTimeout(() => {
        context.oncomplete = function () {
        };

        context = null;
        resolve(result);
      }, 1000);

      context.oncomplete = function (event) {
        try {
          clearTimeout(audioTimeoutId);

          const data = event.renderedBuffer.getChannelData(0);
          result.buffer = Array.from(data);

          oscillator.disconnect();
          compressor.disconnect();
        } catch (error) {
          reject(error);
        }

        resolve(result);
      };
    });
  }
}();

import { createApiEpic } from '@nonlux/utils/redux';
import { getQueryParams } from '@nonlux/utils';
import TYPES from './types';
import 'rxjs/add/operator/ignoreElements';

const sendVerifyEmailEpic = createApiEpic({
  type: TYPES.SEND_VERIFY_EMAIL,
  urlMap: '/api/user/sendVerifyEmail',
  method: 'post'
});

const resendVerifyEmailEpic = createApiEpic({
  type: TYPES.RESEND_VERIFY_EMAIL,
  urlMap: '/api/user/resend_verify',
  method: 'post',
  mapPayload: action => ({
    ...action.payload,
    ...getQueryParams(window.location.search)
  })
});

const verifyEmailEpic = createApiEpic({
  type: TYPES.VERIFY_EMAIL,
  urlMap: '/api/user/verifyEmail',
  method: 'post',
  mapPayload: action => (getQueryParams(window.location.search))
});

const sendResetPasswordDataEpic = createApiEpic({
  type: TYPES.RESET_PASSWORD,
  urlMap: '/api/user/resetPassword',
  method: 'post',
  mapPayload: action => ({
    ...action.payload,
    ...getQueryParams(window.location.search)
  })
});

const forgotPasswordEpic = createApiEpic({
  type: TYPES.FORGOT_PASSWORD,
  urlMap: '/api/user/forgotPassword',
  method: 'post'
});

const goRecoverEmail = action$ => action$.ofType(TYPES.GO_RECOVER_EMAIL).do(() => window.location.href = '/recover_email').ignoreElements();
const goVerifyEmail = action$ => action$.ofType(TYPES.GO_VERIFY_EMAIL).do(() => window.location.href = '/verify_email').ignoreElements();
const goNotificationSent = action$ => action$.ofType(TYPES.NOTIFICATION_SENT).do(() => window.location.href = '/notification_sent').ignoreElements();
const goWelcome = action$ => action$.ofType(TYPES.GO_WELCOME).do(() => window.location.href = '/welcome').ignoreElements();

export default [
  sendVerifyEmailEpic,
  verifyEmailEpic,
  sendResetPasswordDataEpic,
  forgotPasswordEpic,
  resendVerifyEmailEpic,
  goRecoverEmail,
  goVerifyEmail,
  goNotificationSent,
  goWelcome
];

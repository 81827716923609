import TYPES from './types';

export const initialState = {
  layoutType: 'lg',
};

export default function reducer(state = initialState, action) {
  const width = document.body.clientWidth;
  let widthType = 'lg';
  if (width > 0) {
    widthType = 'xs';
  }
  if (width > 576) {
    widthType = 'sm';
  }
  if (width > 768) {
    widthType = 'md';
  }
  if (width > 992) {
    widthType = 'lg';
  }
  if (width > 1200) {
    widthType = 'xl';
  }
  switch (action.type) {
    case TYPES.MEDIA:
      return {
        ...state,
        layoutType: action.payload,
      };
    default:
      return {
        ...state,
        layoutType: widthType,
      };
  }
}

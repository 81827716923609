import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { reducer as toastrReducer } from 'react-redux-toastr';
import windowResize from './windowResize';
import sign from './sign';
import verifyRecover from './verifyRecover';

import resizyEpics from './windowResize/epic';
import singEpics from './sign/epic';
import verifyRecoverEpics from './verifyRecover/epic';


export const rootReducer = combineReducers({
  verifyRecover,
  windowResize,
  sign,
  toastr: toastrReducer
});

export const rootEpic = combineEpics(
  ...verifyRecoverEpics,
  ...resizyEpics,
  ...singEpics,
);

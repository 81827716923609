import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Parallax,
  Presentation,
  ImgRow,
  BorderContainer,
  CallRegistration, ContainerContent
} from 'components';
import { useTranslation } from 'react-i18next';
import {
  indexPresentationText, services, imgRowText, videoInfoContent, indexCallRegistrationText
} from './data.js';
import Services from './Services/Services.js';
import AboutCompany from './AboutCompany/AboutCompany.js';
import VideoInfo from './VideoInfo/VideoInfo.js';
import AffiliateList from './AffiliateList/AffiliateList.js';
import TextGallery from './TextGallery/TextGallery.js';

import bk from './img/bk.png';
import { Box, Channel } from '../../components';

import Telegram from './icon/telegram.svg';
import VKontakte from './icon/vkontakte.svg';
import Youtube from './icon/youtube.svg';

function IndexPage({ layoutType }) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Presentation
        layoutType={layoutType}
        presentation={indexPresentationText}
      />
      <Parallax layoutType={layoutType} />
      <Services
        services={services}
        layoutType={layoutType}
      />
      <BorderContainer />
      <TextGallery title={t('main.advantage.title')} />
      <ImgRow
        layoutType={layoutType}
        img={bk}
        info={imgRowText}
      />
      <VideoInfo
        title={t('main.videoInfo.title')}
        titleWidth={650}
        content={videoInfoContent}
        layoutType={layoutType}
      />
      <AffiliateList layoutType={layoutType} />
      <AboutCompany
        title={t('main.aboutCompany.title')}
        layoutType={layoutType}
      />
      <ContainerContent title={t('main.aboutCompany.network')} color="blue">
        <Box
          flex
          container
          justify="center"
          gap={20}
          mt={20}
        >
          <Channel
            icon={Telegram}
            type="Telegram"
            link="https://t.me/arbitrage_bets"
          />
          <Channel
            icon={VKontakte}
            type="VKontakte"
            link="https://vk.com/arbitragebets"
          />
          <Channel
            icon={Youtube}
            type="Youtube"
            link="https://www.youtube.com/channel/UCXLr4eeNbvOBuQPaEWpzSiw"
          />
        </Box>
      </ContainerContent>
      <CallRegistration
        layoutType={layoutType}
        data={indexCallRegistrationText}
      />
    </Fragment>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType
}))(IndexPage);
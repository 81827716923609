import { createSelector, createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { modalConfirm, modalCancel, modalApply } from './actions';

const modalDialogRootSelector = state => state.modalDialog;

export const modalDialogTypeSelector = createSelector(
  [modalDialogRootSelector],
  modalDialog => modalDialog && modalDialog.type
);

export const modalDialogDataSelector = createSelector(
  [modalDialogRootSelector],
  modalDialog => modalDialog && modalDialog.data
);

export const withModalDialogType = connect(
  createStructuredSelector({
    modalDialogType: modalDialogTypeSelector
  })
);

export const withModalDialogData = connect(
  createStructuredSelector({
    modalDialogData: modalDialogDataSelector
  })
);

export const withModalDialogActions = connect(
  null,
  { modalConfirm, modalApply, modalCancel }
);

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Parallax,
  Slider,
  Presentation,
  Support,
  TextInfo,
  ImgRow,
  ContainerImgInfo,
  ToggleInfo
} from 'components';
import { useTranslation } from 'react-i18next';
import {
  uniqueIdentifiers, statisticsText, vdsPresentationText, specifications, imgRowText, telemetryText
} from './data.js';
import Triangle from './Triangle/Triangle.js';
import STYLES from '../../styles/constants.js';
import TariffsVdsPage from '../../../ab-admin-project/pages/TariffsVdsPage/TariffsVdsPage';

const telemety = require('./icon/vds/telemety.png');
const statistics = require('./icon/vds/statistics.png');
const customization = require('./icon/vds/custom.png');
const triangle = require('./icon/vds/triangle.png');

function VdsPage({ layoutType }) {
  localStorage.removeItem('onRegistrationBuyVds');
  localStorage.removeItem('vds');
  localStorage.removeItem('proxy');
  const { t, i18n } = useTranslation();
  const { languages } = i18n;
  const lng = languages[0];
  return (
    <Fragment>
      <Presentation
        layoutType={layoutType}
        presentation={vdsPresentationText}
      />
      <Parallax layoutType={layoutType} />
      <ToggleInfo
        title={t('vds.toggleInfo.title')}
        arr={uniqueIdentifiers}
        linkButton="https://arbitrage-bets.com/tutorial/getting_started_vds#howDoesVdsWork"
        textButton="vds.toggleInfo.button"
        id="identifiers"
      />
      <TextInfo
        title={t('vds.textInfo.title')}
        info={specifications}
        layoutType={layoutType}
      />
      <ImgRow
        blue
        layoutType={layoutType}
        img={customization}
        info={imgRowText}
      />
      <Triangle layoutType={layoutType} img={triangle} />
      <ContainerImgInfo
        title={t('vds.statisticsText.title')}
        text={statisticsText}
        titleWidth={850}
        link="https://arbitrage-bets.com/tutorial/configuration_vds#system_setting"
        img={statistics}
        right
        color="blue"
        layoutType={layoutType}
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 50, xl: 50
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 40, xl: 30
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
      />
      <ContainerImgInfo
        text={telemetryText}
        title={t('vds.telemetryText.title')}
        link="https://arbitrage-bets.com/tutorial/configuration_vds#telemetry"
        img={telemety}
        layoutType={layoutType}
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 50, xl: 50
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 40, xl: 30
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
      />
      <Slider />
      <TariffsVdsPage
        id="vdsTariffs"
        landing
        button="sign"
      />
      <Support />
    </Fragment>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType
}))(VdsPage);

import { Container } from "reactstrap";
import { createDiv, createElement } from "@nonlux/react-common";
import { withCSS } from "@nonlux/react-common/composable";
import STYLES from "./style";
import { COLORS } from "../ProfilesPage/style";

export const H2 = createElement("h2", "H2", {});
export const H2Landing = createElement("h2", "H2", {
  fontSize: "33px",
  color: "rgb(41, 45, 52)",
  fontWeight: "400",
});
export const P = createElement("p", "P", {});
export const TextArea = createElement("textarea", "textarea", {});
export const Input = createElement("input", "input", {});

export const RowContainer = createDiv("Row", () => ({
  padding: "16px 24px",
}));

export const MarginTopContainer = createDiv(
  "MarginTopContainer",
  ({ mt = 10 }) => ({
    marginTop: `${mt}px`,
  })
);

export const Button = createElement("button", "Button", {
  height: "45px",
  lineHeight: "45px",
  width: "250px",
  fontSize: "20px",
  border: "none",
  backgroundColor: STYLES.COLORS.BLUE,
  color: STYLES.COLORS.WHITE,
  outline: "none",
  ":hover": {
    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.05)",
    outline: "none",
  },
  ":active": {
    outline: "none",
  },
  ":focus": {
    outline: "none",
  },
});

export const ButtonContainer = withCSS({
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center",
})(Container);

export const CountControlBtn = withCSS(({ isPlus }) => ({
  width: "50px",
  backgroundColor: "white",
  color: "black",
  border: "1px solid #ccc",
  borderRight: !isPlus ? "none" : "1px solid #ccc",
  borderLeft: isPlus ? "none" : "1px solid #ccc",
  paddingBottom: "1px",
  lineHeight: "15px",
  verticalAlign: "middle",
  fontSize: `${isPlus ? "18" : "20"}px`,
  ":hover": {
    boxShadow: `rgba(0, 0, 0, 0.05) ${isPlus ? "20px 0px 40px" : "0px 5px 20px"}`,
    outline: "none",
  },
}))(Button);

export const CountControlInput = withCSS({
  height: "45px",
  verticalAlign: "middle",
  lineHeight: "45px",
  fontWeight: "bold",
  borderRadius: 0,
  borderRight: "none",
  borderLeft: "none",
  textAlign: "center",
  ":focus": {
    borderColor: "#ccc",
  },
})(Input);

export const TariffsContainer = createDiv(
  "TariffsContainer",
  ({ landing = false }) => ({
    display: "flex",
    height: landing ? null : "calc(100vh - 166px)",
    flexDirection: "column",
    justifyContent: "center",
  })
);

export const TariffsTooltipBlock = createDiv(
  "TariffsTooltipBlock",
  ({ isDisplay }) => ({
    display: isDisplay ? "block" : "none",
    position: "absolute",
    padding: "15px",
    zIndex: 100,
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
    backgroundColor: COLORS.WHITE,
    color: COLORS.BLACK,
    marginLeft: "15px",
  })
);

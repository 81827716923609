import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerContent } from 'components';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import STYLES from '../../../styles/constants.js';


const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 30px;
`;

const Box = styled.div`
  ${({ mt }) => mt && css`margin-top: ${mt}px;`};
`;

const H3 = styled.h3`
  color: ${STYLES.COLORS.DARK_GRAY};
  font-weight: 300;
  font-size: ${({ layoutType }) => (['xl', 'lg'].includes(layoutType) ? 28 : 24)}px;
`;

const P = styled.p`
  font-size: ${({ layoutType }) => (['xl'].includes(layoutType) ? 18 : 16)}px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Strong = styled.span`
  font-weight: bold;
`;

const Title = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(H3);

const Text = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(P);

const Link = styled.a`
  cursor: pointer;
  color: #1a1a1a;
  background-color: rgba(187,239,253,0.3);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  &:hover {
    color: #1a1a1a;
    text-decoration: none;
    background-color: #bbeffd;
    border-bottom-color: #1a1a1a;
  }
`;

const LinkHash = styled(HashLink)`
  cursor: pointer;
  color: #1a1a1a;
  background-color: rgba(187,239,253,0.3);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  &:hover {
    color: #1a1a1a;
    text-decoration: none;
    background-color: #bbeffd;
    border-bottom-color: #1a1a1a;
  }
`;

export default function TextGallery({ title }) {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  return (
    <ContainerContent title={title} titleMarginBottom={40}>
      <Grid>
        <div>
          <Title>
            {t('main.advantage.technologies.title')}
          </Title>
          <Box mt={20}>
            {['cn'].includes(language) ? (
              <Text>
                {t('main.advantage.technologies.content.text')}
              </Text>
            ) : (
              <Text>
                {t('main.advantage.technologies.content.text')}
                &nbsp;
                <Strong>
                  {t('main.advantage.technologies.content.strong')}
                </Strong>
                .&nbsp;
                {t('main.advantage.technologies.content.text2')}
              </Text>
            )}
          </Box>
        </div>
        <div>
          <Title>
            {t('main.advantage.service.title')}
          </Title>
          <Box mt={20}>
            {['cn'].includes(language) ? (
              <Text>
                {t('main.advantage.service.content.text')}
              </Text>
            ) : (
              <Text>
                {t('main.advantage.service.content.text1')}
                &nbsp;
                <Strong>
                  {t('main.advantage.service.content.strong1')}
                </Strong>
                &nbsp;
                {t('main.advantage.service.content.text2')}
                &nbsp;
                <Strong>
                  {t('main.advantage.service.content.strong2')}
                </Strong>
                .
              </Text>
            )}
          </Box>
        </div>
        <div>
          <Title>
            {t('main.advantage.simplicity.title')}
          </Title>
          <Box mt={20}>
            {['cn'].includes(language) ? (
              <Text>
                {t('main.advantage.simplicity.content.text')}
              </Text>
            ) : (
              <Text>
                {t('main.advantage.simplicity.content.text1')}
                &nbsp;
                <Strong>
                  {t('main.advantage.simplicity.content.strong1')}
                </Strong>
                &nbsp;
                {t('main.advantage.simplicity.content.text2')}
              </Text>
            )}
          </Box>
        </div>

        <div>
          <Title>
            {t('main.advantage.speed.Скорость работы')}
          </Title>
          <Box mt={20}>
            {['cn'].includes(language) ? (
              <Text>
                {t('main.advantage.speed.content.text')}
              </Text>
            ) : (
              <Text>
                {t('main.advantage.speed.content.Увеличьте скорость работы')}
                &nbsp;
                <Strong>
                  {t('main.advantage.speed.content.Время - деньги')}
                </Strong>
                {t('main.advantage.speed.content.мы как никто это понимаем')}
                &nbsp;
                <LinkHash to="/bet_storm">
                  {t('main.advantage.speed.content.профилях BetStorm')}
                </LinkHash>
                &nbsp;
                {t('main.advantage.speed.content.занимает до 1 минуты')}
                &nbsp;
                <LinkHash to="/vds">
                  {t('main.advantage.speed.content.удаленных серверах VDS')}
                </LinkHash>
                &nbsp;
                {t('main.advantage.speed.content.до 30 минут')}
                &nbsp;
                <LinkHash to="/proxy">
                  {t('main.advantage.speed.content.мобильных прокси')}
                </LinkHash>
                &nbsp;
                {t('main.advantage.speed.content.достигает 30 Mbps')}
                &nbsp;
                <Link href="https://arbitrage-bets.com/tutorial/configuration_proxy#change_ip">
                  {t('main.advantage.speed.content.IP адреса')}
                </Link>
                &nbsp;
                {t('main.advantage.speed.content.и')}
                &nbsp;
                <Link href="https://arbitrage-bets.com/tutorial/configuration_proxy#posf">
                  {t('main.advantage.speed.content.pOf')}
                </Link>
                &nbsp;
                {t('main.advantage.speed.content.происходит мгновенно и без обрыва соединения')}
              </Text>
            )}
          </Box>
        </div>
        <div>
          <Title>
            {t('main.advantage.support.Поддержка')}
          </Title>
          <Box mt={20}>
            {['cn'].includes(language) ? (
              <Text>
                {t('main.advantage.support.content.text')}
              </Text>
            ) : (
              <Text>
                {t('main.advantage.support.content.Решайте задачи не только быстро но и эффективно У наших продуктов')}
                &nbsp;
                <Link href="https://arbitrage-bets.com/tutorial/getting_started_vds">
                  {t('main.advantage.support.content.самая подробная база знаний')}
                </Link>
                &nbsp;
                {t('main.advantage.support.content.во всем сегменте аналогичных решений а уровень технической поддержки в чатах очень высокий')}
                &nbsp;
                <Link href="https://t.me/arb_support">
                  {t('main.advantage.support.content.Сотрудники с большим опытом')}
                </Link>
                &nbsp;
                {t('main.advantage.support.content.ведут живой диалог без шаблонов вникают в проблемы')}
                &nbsp;
                <Strong>
                  {t('main.advantage.support.content.дают ценные советы')}
                </Strong>
                &nbsp;
                {t('main.advantage.support.content.и всегда на стороне пользователя')}
              </Text>
            )}
          </Box>
        </div>
        <div>
          <Title>
            {t('main.advantage.command.Команда')}
          </Title>
          <Box mt={20}>
            {['cn'].includes(language) ? (
              <Text>
                {t('main.advantage.command.content.text')}
              </Text>
            ) : (
              <Text>
                {t('main.advantage.command.content.Работайте с профессионалами')}
                &nbsp;
                <Strong>
                  {t('main.advantage.command.content.более 8 лет в гэмблинг сфере')}
                </Strong>
                {t('main.advantage.command.content.а команда программистов')}
                &nbsp;
                <Strong>
                  {t('main.advantage.command.content.более 12 лет в онлайн-сервисах слежения')}
                </Strong>
                &nbsp;
                {t('main.advantage.command.content.и системах идентификации')}
              </Text>
            )}
          </Box>
        </div>

        <div>
          <Title>
            {t('main.advantage.control.Удобное управление')}
          </Title>
          <Box mt={20}>
            {['cn'].includes(language) ? (
              <Text>
                {t('main.advantage.control.content.text')}
              </Text>
            ) : (
              <Text>
                {t('main.advantage.control.content.Управляйте услугами и подписками в')}
                &nbsp;
                <LinkHash to="/sign_up">
                  {t('main.advantage.control.content.личном кабинете')}
                </LinkHash>
                {t('main.advantage.control.content.Мы продумали эффективные системы контроля')}
              </Text>
            )}
          </Box>
        </div>
        <div>
          <Title>
            {t('main.advantage.price.Лучшая цена')}
          </Title>
          <Box mt={20}>
            {['cn'].includes(language) ? (
              <Text>
                {t('main.advantage.price.content.text')}
              </Text>
            ) : (
              <Text>
                {t('main.advantage.price.content.Не переплачивайте')}
                &nbsp;
                <Strong>
                  {t('main.advantage.price.content.Дорого НЕ всегда значит хорошо')}
                </Strong>
                {t('main.advantage.price.content.Снижая цену')}
              </Text>
            )}
          </Box>
        </div>
        <div>
          <Title>
            {t('main.advantage.guarantees.Гарантии')}
          </Title>
          <Box mt={20}>
            {['cn'].includes(language) ? (
              <Text>
                {t('main.advantage.guarantees.content.text')}
              </Text>
            ) : (
              <Text>
                {t('main.advantage.guarantees.content.Будьте спокойны за инвестированные в работу средства')}
                &nbsp;
                <Strong>
                  {t('main.advantage.guarantees.content.Мы вернем деньги если наши инструменты не решат проблему')}
                </Strong>
                {t('main.advantage.guarantees.content.не подойдут под задачи')}
              </Text>
            )}
          </Box>
        </div>
      </Grid>
    </ContainerContent>
  );
}
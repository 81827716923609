export const bassicTariff = {
  name: 'basic',
  price: 29,
  options: {
    maxSyncCount: 5,
    defaultSyncCount: 1
  }
};

export const standardTariff = {
  name: 'standard',
  price: 49,
  options: {
    maxSyncCount: 25,
    defaultSyncCount: 2
  }
};

export const advancedTariff = {
  name: 'advanced',
  price: 59,
  options: {
    maxSyncCount: null,
    defaultSyncCount: 3
  }
};

export const DEFAULT_PROFILE_TARIFF_NAME = standardTariff.name;

export const allTariffs = [bassicTariff, standardTariff, advancedTariff];

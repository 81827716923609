import React, { Fragment } from 'react';
import { withCSS, toggleLayout } from '@nonlux/react-common/composable';
import { Col, Row } from 'reactstrap';
import {
  RowContent,
  TextContainer,
  H3,
  LinkPrimaryDark,
  LinkSecondaryDark,
} from 'components';
import { useTranslation } from 'react-i18next';
import STYLES from '../../styles/constants.js';

export default function CallRegistration(props) {
  return (
    <RowContainer className="justify-content-center">
      <ToggleLayout {...props} />
    </RowContainer>
  );
}

const LinksContainer = ({ layoutType, data }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <LinkSecondaryDark
        to={data.left.link.href}
        layoutType={layoutType}
        size={STYLES.getSizeButton(layoutType)}
        mr={10}
      >
        {t(data.left.name)}
      </LinkSecondaryDark>
      <LinkPrimaryDark
        to={data.right.link.href}
        layoutType={layoutType}
        size={STYLES.getSizeButton(layoutType)}
      >
        {t(data.right.name)}
      </LinkPrimaryDark>
    </Fragment>
  );
};

const RowContainer = withCSS({
  paddingTop: '35px',
  paddingBottom: '33px',
  backgroundColor: STYLES.COLORS.BASE_DARK,
})(Row);

const Text = withCSS(({ layoutType }) => ({
  fontSize: STYLES.getMedia([18, 22, 22, 26, 28], layoutType),
  fontWeight: 300,
  color: STYLES.COLORS.WHITE,
  padding: 0,
  margin: 0,
}))(H3);

const ToggleLayout = (props) => {
  const { layoutType } = props;
  const [isXs] = toggleLayout({ getLayout: ['xs', 'sm', 'md'] });
  if (isXs(layoutType)) return <LayoutXs {...props} />;
  return <LayoutLg {...props} />;
};

const LayoutLg = ({ layoutType, data }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <TextContainer mr={40} mt={6}>
        <Text layoutType={layoutType}>
          {t(data.text)}
        </Text>
      </TextContainer>
      <LinksContainer layoutType={layoutType} data={data} />
    </Fragment>
  );
};

const LayoutXs = ({ layoutType, data }) => {
  const { t } = useTranslation();
  return (
    <Col>
      <Row className="justify-content-center">
        <Text layoutType={layoutType}>
          {t(data.text)}
        </Text>
      </Row>
      <RowContent className="justify-content-center" mt={20}>
        <LinksContainer layoutType={layoutType} data={data} />
      </RowContent>
    </Col>
  );
};

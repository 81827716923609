import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Presentation,
  ContainerImgInfo,
  Parallax,
  Slider,
  Support,
  Advantage,
  ToggleInfo,
  TariffsForm,
  BorderContainer
} from 'components';
import ExtensionInfo from './ExtensionInfo/ExtensionInfo.js';
import Synchronization from './Synchronization/Synchronization.js';
import {
  proxyPresentationText,
  naturalParametersTechnology,
  mtuText,
  uniqueIdentifiers,
  advantage,
  smartGeneration,
  kernelProtection,
  extensionIdentificationProtection,
  extensionInfo,
  synchronizationInfo,
  synchWhyIsItNecessary,
  synchHowItWorks
} from './data.js';
import STYLES from '../../styles/constants.js';

const logoBetStorm = require('./img/logo.png');

const aiParamGeneration = require('./img/ai_param_generation.png');
const coreProtection = require('./img/core_protection.png');

const profile0 = require('./img/profile2.png');
const profile1 = require('./img/profile1.png');
const profile2 = require('./img/profile0.png');

const bet365 = require('./img/bet365.png');
const wh = require('./img/wh.png');

const profiles = [
  {
    img: {
      src: `${profile0}`,
      width: 550
    },
    position: {
      ml: 0,
      mt: 0
    }
  },
  {
    img: {
      src: `${profile1}`,
      width: 400
    },
    position: {
      ml: 250,
      mt: 180
    }
  },
  {
    img: {
      src: `${profile2}`,
      width: 300
    },
    position: {
      ml: 180,
      mt: 420
    }
  }
];

const canvas_correct = require('./img/canvas_correct.png');
const canvas_err = require('./img/canvas_err.png');

const canvas = [
  {
    img: {
      src: `${canvas_correct}`,
      width: 450
    },
    position: {
      ml: 0,
      mt: 0
    }
  },
  {
    img: {
      src: `${canvas_err}`,
      width: 350
    },
    position: {
      ml: 150,
      mt: 330
    }
  }
];

const id_expansion_0 = require('./img/id_expansion_0.png');
const id_expansion_1 = require('./img/id_expansion_1.png');

const id_expansion = [
  {
    img: {
      src: `${id_expansion_0}`,
      width: 550
    },
    position: {
      ml: 0,
      mt: 0
    }
  },
  {
    img: {
      src: `${id_expansion_1}`,
      width: 400
    },
    position: {
      ml: 250,
      mt: 220
    }
  }
];

/* const getOffset = (props, language, layoutType) => {
  if (typeof (props) === 'object') return props[language][layoutType];
  return props;
}; */

function BetStormPage({ layoutType }) {
  const { t, i18n } = useTranslation();
  const { languages } = i18n;
  const lng = languages[0];

  return (
    <Fragment>
      <Presentation
        layoutType={layoutType}
        presentation={proxyPresentationText}
      />
      <Parallax layoutType={layoutType} />
      <ToggleInfo
        title={t('bet_storm.toggleInfo.title')}
        arr={uniqueIdentifiers}
        linkButton="https://arbitrage-bets.com/tutorial/getting_started_bet_storm"
        textButton="bet_storm.toggleInfo.button"
        id="browser_identifiers"
        page="betStorm"
      />
      <BorderContainer mt={15} />
      <Synchronization
        content={synchronizationInfo}
        layoutType={layoutType}
      />
      <ContainerImgInfo
        text={synchWhyIsItNecessary}
        layoutType={layoutType}
        right
        img={wh}
        imgAboveTextXsLayout
        pt={10}
        pb={{
          xs: 0, sm: 0, md: 0, lg: 60, xl: 60
        }}
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 20, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mtTextBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 20, xl: 40
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 20, xl: 40
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 50, xl: 60
          }
        }, lng, layoutType)}
      />
      <ContainerImgInfo
        text={synchHowItWorks}
        link="https://arbitrage-bets.com/tutorial/sync_profiles"
        linkType="blank"
        layoutType={layoutType}
        img={bet365} // нужно убрать на  xs sm md
        imgAboveTextXsLayout
        hideImg={['lg', 'xl'].includes(layoutType)}
        pt={{
          xs: 0, sm: 0, md: 0, lg: 40, xl: 40
        }}
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 20, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mtTextBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 7
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 5, xl: 15
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 30, xl: 50
          }
        }, lng, layoutType)}
      />
      <ExtensionInfo
        content={extensionInfo}
        img={logoBetStorm}
        layoutType={layoutType}
      />
      <Advantage
        title={t('bet_storm.advantage.title')} // ВСЕ, ЧТО НУЖНО ДЛЯ ПРОДУКТИВНОЙ РАБОТЫ
        content={advantage}
        linkButton="https://arbitrage-bets.com/tutorial/instruction_bet_storm"
      />
      <ContainerImgInfo
        title={t('bet_storm.proxyAbb.title')} // ТЕХНОЛОГИФ НАТУРАЛЬНЫХ ПАРАМЕТРОВ
        text={naturalParametersTechnology}
        layoutType={layoutType}
        color="blue"
        right
        imgArr={profiles}
        mtImgBox={STYLES.getOffset(40)}
        mbImgBox={STYLES.getOffset(40)}
        mlImgBox={STYLES.getOffset(20)}
        link={[
          // харкод ссылки, пофиксиить перед релизом
          {
            href: 'fingerprint2.html',
            text: `${t('bet_storm.proxyAbb.posfText.button')}`,
            linkType: 'blank'
          }]}
      />
      <ContainerImgInfo
        text={mtuText}
        layoutType={layoutType}
        imgArr={canvas}
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: -20, sm: -20, md: -20, lg: 0, xl: 0
          },
          en: {
            xs: -20, sm: -20, md: -20, lg: 60, xl: 30
          },
          cn: {
            xs: -20, sm: -20, md: -20, lg: 15, xl: 30
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mlImgBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: -40, xl: 0
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: -40, xl: 0
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: -40, xl: 0
          }
        }, lng, layoutType)}
        mtTextBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 40, xl: 40
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 140, xl: 100
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 220, xl: 200
          }
        }, lng, layoutType)}
      />
      <ContainerImgInfo
        title={t('bet_storm.smartGeneration.title')} // ГЕНЕРАЦИЯ ПАРАМЕТРОВ НА ОСНОВЕ ИСКУССТВЕННОГО ИНТЕЛЛЕКТА
        titleWidth={650}
        text={smartGeneration}
        img={aiParamGeneration}
        right
        color="blue"
        layoutType={layoutType}
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 80, xl: 40
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 50, xl: 30
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mtTextBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 10, xl: 20
          }
        }, lng, layoutType)}
      />
      <ContainerImgInfo
        title={t('bet_storm.kernelProtection.title')}
        text={kernelProtection}
        img={coreProtection}
        layoutType={layoutType}
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 30, xl: 30
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 30, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mtTextBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 10, xl: 30
          }
        }, lng, layoutType)}
      />
      <ContainerImgInfo
        title={t('bet_storm.extensionIdentificationProtection.title')}
        text={extensionIdentificationProtection}
        layoutType={layoutType}
        color="blue"
        right
        imgArr={id_expansion}
        marginImg={20}
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 40, xl: 40
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 10, xl: 20
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mtTextBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 60, xl: 30
          }
        }, lng, layoutType)}
      />
      <Slider />
      <TariffsForm type="bs" layoutType={layoutType} />
      <Support />
    </Fragment>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType
}))(BetStormPage);

import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { proxyCountries } from '@nonlux/ab-prices';
import STYLES from '../style';
import Dropdown from './Dropdown/Dropdown';
import PriceForm from '../../../components/PriceForm/PriceForm';
import Counter from '../../../components/Counter/Counter';
import Toggle from './Toggle/Toggle';
import Tooltip from '../../../components/Tooltip/Tooltip';
import {
  optionList, sortMobileFunction, sortCountryFunction, sortCityFunction
} from '../constants';

import { getOperatorTooltip } from '../tooltips';

const TariffsFormContainer = styled(Container)``;

const TariffsFormCard = styled.div`
  border: 1px solid ${STYLES.COLORS.WHITE_GRAY};
  min-width: min-content;
`;

const TariffsName = styled.strong`
    color: ${props => STYLES.COLORS[`${props.color.toUpperCase()}`]};
    text-transform: uppercase ;
`;

const CardHead = styled.div`
    padding: 30px 40px 30px;
`;

const CardBody = styled.div`
    padding: 0 40px 30px;
`;

const CardBottom = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 40px 20px;
    background-color: #f5f5f5;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: ${({ columns = 3 }) => `repeat(${columns}, 1fr);`}
    grid-gap: 30px 20px;
`;

const GridItem = styled.div`
    grid-column: auto;
    ${({ column }) => column
  && css`
        grid-column-start: ${column.start};
        grid-column-end: ${column.end};
       `};
`;

const FieldHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
`;

const FieldTitle = styled.div`
    text-align: left;
    color: #67718a;
    font-size: 13px;
    line-height: 1.53846;
    font-weight: 500;
`;

const FieldContent = styled.div``;

const channelsHint = [
  // {
  //   title: 'Общий',
  //   text: ' - прокси канал с авто обновляемым IP адресом по предустановленному интервалу,
  //   каждые 2 или 10 минут. Подходит для различных технических работ, парсинга и тд.'
  // },
  {
    title: 'Приватный',
    text: ' - прокси канал, с обновляемым IP адресом по запросу и/или по API и/или по любому нужному интервалу. Подходит для работы в любых сервисах с высокими требованиями к качеству сетевого уровня. '
  },
  {
    title: 'Мультиприватный',
    text: ' - прокси канал, состоящий из цепочки приватных каналов прокси. Подходит для работы в любых сервисах не только с высокими требованиями к качеству сетевого уровня, но и волатильности внешнего IP адреса. '
  },
  {
    ul: [
      'Отказоустойчивость: канал не перестанет работать даже в случае аварии на вышке оператора, а переключится на другую без обрыва соединения;',
      'Мгновенная смена IP адреса без обрыва соединения: по API или кнопке в личном кабинете;',
      'Высокая волатильность IP адреса: вероятность повторения адреса существенно ниже чем на приватных каналах, так как ротация происходит в разных подсетях оператора;',
      'ROOT доступ к авторизации: авторизация по IP адресу, возможность менять логины и пароли на произвольные;',
      'OpenVPN поддерживает несколько пользователей одновременно без реконектов;'
    ]
  }
];

const connectionType = [
  {
    title: 'IP:PORT',
    text: ' - классический способ подключить прокси через любой софт (например Proxifier или BetStorm). Не зависимо от типа канала доступно два протокола - HTTP и SOCKS5.'
  },
  {
    title: 'OpenVPN',
    text: ' - способ подключить прокси через VPN, используется для подключения прокси на MacOS, телефонах, роутерах и других маршрутизаторах (например Mikrotik). Кроме этого наша технология OpenVPN позволяет нативно изменить WebRTC.'
  }
];

const networkFingerprint = [
  {
    title: 'Passive Operation System Fingerprinting (POSF)',
    text: ' – это отпечаток операционной системы, получаемый через сеть. Выставите отпечаток сети эквивалентным своей операционной системе.'
  },
  {
    text: 'Основная задача POSF не изменить отпечаток реальной операционной системы, а синхронизировать операционную систему с сетью для имитации прямого подключения без триггеров прокси. '
  },
  {
    text: 'Не все операторы поддерживают эту технологию, в Великобритании POSF строго фиксируются оператором связи независимо от операционной системы пользователя.'
  }
];

const configTooltipNetworkFingerprint = {
  body: {
    top: 'calc(100% - 150px)',
    right: '25px',
    left: 'auto',
    bottom: 'auto'
  },
  before: {
    top: '137px',
    right: '-6px'
  }
};

const authorizationType = [
  {
    title: 'PASS',
    text: ' - классический способ авторизации по логину и паролю. На момент подключения прокси, у канала будет автоматически сгенерированный пароль и логин, которые нельзя изменить.'
  },
  {
    title: 'ROOT',
    text: ' - продвинутый способ авторизации. Позволяет переключаться между авторизацией PASS (по логину и паролю) и авторизацией по IP адресу и расширяет возможности типа авторизации PASS (можно менять логин/пароль на произвольные).'
  },
  {
    text: 'Авторизация по IP адресу позволяет привязывать к прокси неограниченное количество устройств, а также это единственный способ подключить мобильные прокси напрямую в профили BetStorm через SOCKS5 протокол.'
  }
];

const configTooltipAuthorizationType = {
  body: {
    top: 'calc(100% - 140px)',
    right: '25px',
    left: 'auto',
    bottom: 'auto'
  },
  before: {
    top: '128px',
    right: '-6px'
  }
};

const numberOfProxy = [
  {
    title: 'Прокси канал',
    text: ' - это программно-аппаратный комплекс с выбранной выше конфигурацией, который имитирует домашний интернет обычного пользователя, без триггеров прокси.'
  },
  {
    text: 'На каждом канале предоставляется доступ ко всему пулу IP адресов выбранного оператора мобильной связи (5000 - 30 000 IP адресов). У одного прокси канала один внешний IP адрес на выходе. '
  },
  {
    text: 'Например, если требуется три внешних IP адреса одновременно, нужно подключить три канала прокси, '
      + 'но если требуется тысяча разных IP адресов последовательно, достаточно одного канала прокси, так как можно бесплатно поменять '
      + 'внешний IP адрес, когда это потребуется.'
  }
];

const configTooltipNumberOfProxy = {
  body: {
    top: 'auto',
    right: 'auto',
    left: '25px',
    bottom: 'calc(100% - 36px)'
  },
  before: {
    left: '-6px',
    bottom: '25px'
  }
};

const isSaveFocusOnInputWhenBlur = e => e.relatedTarget && e.relatedTarget.className.includes('toastr');

const TariffsForm = ({
  selectedTariffName,
  setSelectedTariffName,
  setTypeConnection,
  typeConnection,
  setFingerprint,
  setTypeAuthorization,
  setDaysCount,
  setProxyCount,
  proxyCount,
  setGeo,
  setCity,
  setMobileOperator,
  geo,
  mobileOperator,
  daysCount,
  sum,
  fingerprint,
  typeAuthorization,
  isOpenDropdownCountries,
  isOpenDropdownCities,
  isOpenDropdownOperators,
  setIsOpenDropdownCountries,
  setIsOpenDropdownCities,
  setIsOpenDropdownOperators
}) => {
  const [isOpenChannelsHint, setIsOpenChannelsHint] = useState(false);
  const [isOpenConnectionType, setIsOpenConnectionType] = useState(false);
  const [isOpenNetworkFingerprint, setIsOpenNetworkFingerprint] = useState(false);
  const [isOpenAuthorizationType, setIsOpenAuthorizationType] = useState(false);
  const [isOpenNumberOfProxy, setIsOpenNumberOfProxy] = useState(false);
  const [isOpenOperatorFeatures, setIsOpenOperatorFeatures] = useState(false);
  const [proxyLimits, setProxyLimits] = useState({});

  const tooltip = getOperatorTooltip(mobileOperator, selectedTariffName, geo, daysCount);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/api/proxyLimits');
      setProxyLimits(data);
    })();
  }, []);

  useEffect(() => {
    setSelectedTariffName('advanced');
  }, [geo]);

  const tariffType = selectedTariffName === 'basic' ? 'basic' : 'advanced';
  const countries = tariffType === 'basic' ? ['Великобритания'] : ['Великобритания', 'Украина', 'Россия', 'Польша', 'Испания', 'Германия', 'Ирландия', 'Швеция', 'Италия', 'США', 'Франция', 'Канада', 'Казахстан', 'Нидерланды'];
  const MobileFingerprint = () => (['three', 'vodafon', 'ee'].includes(mobileOperator)
    ? (
      <Toggle
        data={['Linux', '', '', '']}
        altData={['linux', '', '', '']}
        selectedTariffName={selectedTariffName}
        isDefault={0}
        basicParam={0}
        setFunction={setFingerprint}
        value={fingerprint}
        disabled
      />
    )
    : (
      <Toggle
        data={['Windows 10', '', '', '']}
        altData={['win10', '', '', '']}
        selectedTariffName={selectedTariffName}
        isDefault={0}
        basicParam={0}
        setFunction={setFingerprint}
        value={fingerprint}
        disabled
      />
    ));

  const countryAvailable = proxyCountries
    .filter(country => country !== 'mx')
    .map(country => !(proxyLimits.country || []).includes(country));
  const cityAvailable = optionList.standard.cityList[`${geo}Alt`].map(city => !(proxyLimits.city || []).includes(city));
  const operatorAvailable = optionList[selectedTariffName].mobileOperator[`${geo}Alt`].map(op => !(proxyLimits.operator || []).includes(`${geo}_${op}`));

  const countryOptions = sortCountryFunction(countries, countryAvailable);
  const countryAltOptions = sortCountryFunction(proxyCountries, countryAvailable);
  const sortedAvailableCountry = [...countryAvailable].sort((a, b) => b - a);

  const cityOptions = [...optionList.standard.cityList[geo]]
    .sort(sortCityFunction(cityAvailable, geo));
  const cityAltOptions = [...optionList.standard.cityList[`${geo}Alt`]].sort(sortCityFunction(cityAvailable, `${geo}Alt`));
  const sortedAvailableCity = [...cityAvailable].sort((a, b) => b - a);

  const operatorOptions = [...optionList[selectedTariffName].mobileOperator[geo]]
    .sort(sortMobileFunction(operatorAvailable, geo, selectedTariffName));
  const operatorAltOptions = [...optionList[selectedTariffName].mobileOperator[`${geo}Alt`]].sort(sortMobileFunction(operatorAvailable, `${geo}Alt`, selectedTariffName));
  const sortedAvailableOperator = [...operatorAvailable].sort((a, b) => b - a);

  const tariffs = {
    standard: 'Приватный',
    advanced: 'Мультиприватный'
  };
  if ((proxyLimits.tariff || []).includes(`${geo}_${selectedTariffName}`)) {
    setSelectedTariffName(Object.keys(tariffs)
      .find(tariff => !(proxyLimits.tariff || []).includes(`${geo}_${tariff}`)) || 'basic');
  }
  if ((proxyLimits.duration || []).includes(`${geo}_${daysCount}`)) {
    setDaysCount([1, 14, 30].find(duration => !(proxyLimits.duration || []).includes(`${geo}_${duration}`)) || 30);
  }
  if ((proxyLimits.operator || []).includes(`${geo}_${mobileOperator}_${daysCount}`)) {
    setDaysCount([1, 14, 30].find(duration => !(proxyLimits.operator || []).includes(`${geo}_${mobileOperator}_${duration}`)) || 30);
  }

  return (
    <TariffsFormContainer>
      <TariffsFormCard>
        <CardHead>
          <TariffsName color={selectedTariffName}>
            {selectedTariffName}
          </TariffsName>
        </CardHead>

        <CardBody>
          <GridContainer>
            <GridItem column={{
              start: 1,
              end: 3
            }}
            >
              <FieldHead>
                <FieldTitle>
                  Тип канала
                </FieldTitle>
                <Tooltip
                  hint={channelsHint}
                  isOpen={isOpenChannelsHint}
                  setIsOpen={setIsOpenChannelsHint}
                  width="700px"
                />
              </FieldHead>
              <FieldContent>
                <Toggle
                  data={Object.values(tariffs)}
                  altData={Object.keys(tariffs)}
                  available={Object.keys(tariffs)
                    .map(tariff => !(proxyLimits.tariff || []).includes(`${geo}_${tariff}`))}
                  isDefault={1}
                  setFunction={setSelectedTariffName}
                  selectedTariffName={selectedTariffName}
                  setGeo={setGeo}
                  controlled
                  value={selectedTariffName}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Тип подключения
                </FieldTitle>
                <Tooltip
                  hint={connectionType}
                  isOpen={isOpenConnectionType}
                  setIsOpen={setIsOpenConnectionType}
                />
              </FieldHead>
              <FieldContent>
                <Toggle
                  data={['OpenVPN', 'IP:PORT']}
                  altData={['1', '0']}
                  isDefault={1}
                  basicParam={1}
                  // disabled={selectedTariffName === 'advanced'}
                  setFunction={setTypeConnection}
                  selectedTariffName={selectedTariffName}
                  value={typeConnection}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Страна
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <Dropdown
                  options={countryOptions}
                  altData={countryAltOptions}
                  available={sortedAvailableCountry}
                  isOpenDropdown={isOpenDropdownCountries}
                  setIsOpenDropdown={setIsOpenDropdownCountries}
                  setFunction={setGeo}
                  rebootOnDefault={tariffType === 'basic'}
                  geo={geo}
                  controlled
                  value={countryAltOptions.find(country => !(proxyLimits.country || [])
                    .includes(country))}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Город
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <Dropdown
                  options={cityOptions}
                  altData={cityAltOptions}
                  available={sortedAvailableCity}
                  isOpenDropdown={isOpenDropdownCities}
                  setIsOpenDropdown={setIsOpenDropdownCities}
                  setFunction={setCity}
                  geo={geo}
                  value={cityAltOptions.find(c => !(proxyLimits.city || []).includes(c))}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Оператор
                </FieldTitle>
                {tooltip !== '' ? (
                  <Tooltip
                    hint={tooltip}
                    isOpen={isOpenOperatorFeatures}
                    setIsOpen={setIsOpenOperatorFeatures}
                  />
                ) : null}
              </FieldHead>
              <FieldContent>
                <Dropdown
                  options={operatorOptions}
                  altData={operatorAltOptions}
                  available={sortedAvailableOperator}
                  isOpenDropdown={isOpenDropdownOperators}
                  setIsOpenDropdown={setIsOpenDropdownOperators}
                  setFunction={setMobileOperator}
                  geo={geo}
                  tariff={selectedTariffName}
                  value={operatorAltOptions.find(op => !(proxyLimits.operator || []).includes(`${geo}_${op}`))}
                />
              </FieldContent>
            </GridItem>

            <GridItem column={{
              start: 1,
              end: 3
            }}
            >
              <FieldHead>
                <FieldTitle>
                  Отпечаток сети
                </FieldTitle>
                <Tooltip
                  hint={networkFingerprint}
                  isOpen={isOpenNetworkFingerprint}
                  setIsOpen={setIsOpenNetworkFingerprint}
                  config={configTooltipNetworkFingerprint}
                />
              </FieldHead>
              <FieldContent>
                {
                  geo === 'uk'
                    ? <MobileFingerprint />
                    : (
                      <Toggle
                        data={tariffType === 'basic' ? ['Android', '', '', ''] : ['Windows 10', 'MacOS', 'Windows 7', 'Android']}
                        altData={tariffType === 'basic' ? ['android', '', '', ''] : ['win10', 'macos', 'win7', 'android']}
                        selectedTariffName={selectedTariffName}
                        isDefault={0}
                        basicParam={0}
                        setFunction={setFingerprint}
                        value={fingerprint}
                      />
                    )
                }
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Тип авторизации
                </FieldTitle>
                <Tooltip
                  hint={authorizationType}
                  isOpen={isOpenAuthorizationType}
                  setIsOpen={setIsOpenAuthorizationType}
                  config={configTooltipAuthorizationType}
                />
              </FieldHead>
              <FieldContent>
                <Toggle
                  data={['PASS', 'ROOT']}
                  altData={['pass', 'switch']}
                  selectedTariffName={selectedTariffName}
                  isDefault={selectedTariffName === 'advanced' ? 1 : 0}
                  basicParam={selectedTariffName === 'advanced' ? 1 : 0}
                  disabled
                  setFunction={setTypeAuthorization}
                  value={typeAuthorization}
                />
              </FieldContent>
            </GridItem>

          </GridContainer>
        </CardBody>

        <CardBottom>
          <GridContainer>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Кол-во прокси с этой конфигурацией
                </FieldTitle>
                <Tooltip
                  hint={numberOfProxy}
                  isOpen={isOpenNumberOfProxy}
                  setIsOpen={setIsOpenNumberOfProxy}
                  config={configTooltipNumberOfProxy}
                />
              </FieldHead>
              <FieldContent>
                <Counter
                  setFunction={setProxyCount}
                  count={proxyCount}
                  isSaveFocusOnInputWhenBlur={isSaveFocusOnInputWhenBlur}
                  max={100}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Тарифный план
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <Toggle
                  data={['1 день', '14 дней', '30 дней']}
                  altData={[1, 14, 30]}
                  isDefault={2}
                  available={[1, 14, 30].map(duration => !(proxyLimits.duration || []).includes(`${geo}_${duration}`)
                    && !(proxyLimits.operator || []).includes(`${geo}_${mobileOperator}_${duration}`))}
                  basicParam={tariffType === 'basic' ? 2 : [1, 14, 30].indexOf(daysCount)}
                  secondary
                  selectedTariffName={selectedTariffName}
                  setFunction={setDaysCount}
                  value={daysCount}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Цена
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <PriceForm sum={sum} days={daysCount} unitsCount={proxyCount} discount={selectedTariffName === 'advanced'} />
              </FieldContent>
            </GridItem>
          </GridContainer>
        </CardBottom>
      </TariffsFormCard>
    </TariffsFormContainer>
  );
};

export default TariffsForm;

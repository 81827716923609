import React from 'react';
import {
  Col, Row, Button, Container, Card, CardBody, Form, Label,
} from 'reactstrap';
import { withFormikConnected } from '@nonlux/react-common/formik';
import { useTranslation } from 'react-i18next';
import { resetPasswordAction } from 'reducer/verifyRecover/actions';
import { selectResetPasswordErrors } from 'reducer/verifyRecover/selectors';
import { css } from 'aphrodite';
import { Input } from 'components';
import { styles } from './styles';

export default function ForgotPasswordPage() {
  const { t } = useTranslation();
  return (
    <Row className={css(styles.recover_form__box)}>
      <Container className={css(styles.recover_form__container)}>
        <h2 className={css(styles.recover_form__font_title)}>
          {t('recoverPassword.title')}
        </h2>
        <Row className="mt-4">
          <Col>
            <Card>
              <CardBody>
                <RecoverPasswordFormConnected />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Row>
  );
}

const RecoverPasswordForm = (props) => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;
  const { t } = useTranslation();
  const errors = {
    ...props.serverErrors,
    ...props.errors,
  };
  const serverErrorRender = (
    <div className={css(styles.input__feedback)}>
      {errors.resetPasswordError}
    </div>
  );

  const correctRender = (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="password" className="mt-3">
        {t('recoverPassword.label')}
      </Label>
      <hr className={css(styles.recover_form__line)} />
      <small className="text-muted">
        {t('recoverPassword.password.label')}
      </small>
      <Row>
        <Col>
          <Input
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            className={errors.password && touched.password ? 'text-input error' : 'text-input'}
            placeholder={t('recoverPassword.password.placeholder.pass')}
          />
          {touched.password && errors.password
          && (
          <div className={css(styles.input__feedback)}>
            {t(errors.password)}
          </div>
          )}
        </Col>
        <Col>
          <Input
            type="password"
            name="passwordConfirm"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirm}
            className={errors.passwordConfirm && touched.passwordConfirm ? 'text-input error' : 'text-input'}
            placeholder={t('recoverPassword.password.placeholder.confirmPass')}
          />
          {touched.passwordConfirm && errors.passwordConfirm
          && (
          <div className={css(styles.input__feedback)}>
            {t(errors.passwordConfirm)}
          </div>
          )}
        </Col>
      </Row>
      <Row className={css(styles.recover_form__box_btn)}>
        <Col xs="auto">
          <Button className={css(styles.btn__primary)}>
            {t('recoverPassword.button')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
  return errors.resetPasswordError === undefined ? correctRender : serverErrorRender;
};

const errorsMessages = {
  resetPasswordError: 'recoverPassword.errorsMessages.resetPasswordError',
};

const RecoverPasswordFormConnected = withFormikConnected({
  mapPropsToValues: () => ({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    passwordConfirm: '',
    checkbox: false,
    checkboxData: false,
  }),
  validate: (values) => {
    const errors = {};
    if (!values.password || values.password.length < 8) {
      errors.password = 'recoverPassword.errorsMessages.password';
    }
    if (!values.passwordConfirm || !(values.passwordConfirm === values.password)) {
      errors.passwordConfirm = 'recoverPassword.errorsMessages.passwordConfirm';
    }
    return errors;
  },
  handleSubmit(values, { props: { dispatch } }) {
    dispatch(resetPasswordAction(values));
  },
}, state => ({
  serverErrors: selectResetPasswordErrors(state).reduce((acc, next) => {
    if (Array.isArray(next)) {
      const [key, msgKey] = next;
      return {
        ...acc,
        [key]: errorsMessages[msgKey],
      };
    }
    return {
      ...acc,
      [next]: errorsMessages[next],
    };
  }, {}),
}))(RecoverPasswordForm);

import React, { Fragment } from 'react';
import { createDiv } from '@nonlux/react-common';
import {
  Col, Container,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { withCSS, toggleLayout } from '@nonlux/react-common/composable';
import {
  ContainerContent,
  RowContent,
  Img,
  P, H3,
  ButtonContainer,
  LinkTargetPrimaryLight,
} from 'components';
import STYLES from '../../../styles/constants.js';

const schemeProxy = require('./img/schemeProxy.png');

export default function ProxyScheme(props) {
  const { title, layoutType } = props;
  const getClassTitleWidth = () => {
    if (['xs'].includes(layoutType)) return 350;
    if (['sm'].includes(layoutType)) return 460;
    return '100%';
  };
  const { t } = useTranslation();
  return (
    <ContainerContent title={title} titleWidth={getClassTitleWidth()} id="scheme_proxy">
      <Container>
        <ToggleLayout {...props} />
        <ButtonContainer mt={40}>
          <LinkTargetPrimaryLight
            to="https://arbitrage-bets.com/tutorial/getting_started_proxy"
            target="_self"
            layoutType={layoutType}
            fontSize={16}
          >
            {t('proxy.proxyScheme.button')}
          </LinkTargetPrimaryLight>
        </ButtonContainer>
      </Container>
    </ContainerContent>
  );
}

const ImgContainer = createDiv('ImgContainer', ({ layoutType }) => ({
  padding: STYLES.getMediaAcc([0, 0, 0, '0 100px 0', '0 170px 0'], layoutType),
}));

const TextContainer = withCSS(({ layoutType }) => ({
  padding: STYLES.getMediaAcc([0, 0, 0, '0 100px 0', '0 170px 0'], layoutType),
}))(RowContent);

const ToggleLayout = (props) => {
  const { layoutType } = props;
  const [isXs] = toggleLayout({ getLayout: ['xs'] });
  if (isXs(layoutType)) return <LayoutXs {...props} />;
  return <LayoutSm {...props} />;
};

const LayoutXs = () => {
  const { t } = useTranslation();
  return (
    <RowContent>
      <Col className="text-center" xs={12}>
        <H3>{t('proxy.proxyScheme.connection.title')}</H3>
        <P>{t('proxy.proxyScheme.connection.text')}</P>
      </Col>
      <Col className="text-center" xs={12}>
        <H3>{t('proxy.proxyScheme.customization.title')}</H3>
        <P>{t('proxy.proxyScheme.customization.text')}</P>
      </Col>
      <Col className="text-center" xs={12}>
        <H3>{t('proxy.proxyScheme.work.title')}</H3>
        <P>{t('proxy.proxyScheme.work.text')}</P>
      </Col>
    </RowContent>
  );
};

const LayoutSm = ({ layoutType }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <RowContent mt={50} className="justify-content-center">
        <ImgContainer layoutType={layoutType}>
          <Img src={schemeProxy} />
        </ImgContainer>
      </RowContent>
      <TextContainer layoutType={layoutType} mt={20}>
        <Col className="text-center">
          <P>
            {t('proxy.proxyScheme.connection.text')}
          </P>
        </Col>
        <Col className="text-center">
          <P>
            {t('proxy.proxyScheme.customization.text')}
          </P>
        </Col>
        <Col className="text-center">
          <P>
            {t('proxy.proxyScheme.work.text')}
          </P>
        </Col>
      </TextContainer>
    </Fragment>
  );
};
import React, { Fragment } from 'react';
import { withCSS } from '@nonlux/react-common/composable';
import { createDiv } from '@nonlux/react-common';
import { connect } from 'react-redux';
import {
  Col, Row, Container
} from 'reactstrap';
import {
  ContainerContent,
  RowContent,
  ButtonContainer,
  H3, P,
  LinkTargetPrimaryLight
} from 'components';
import { useAccordion } from '@nonlux/react-common/hooks';
import { useTranslation } from 'react-i18next';
import STYLES from '../../styles/constants.js';


export default function ToggleInfo({
  layoutType, arr, title, toggleId, linkButton, id, page, textButton, titleWidth = 650, pt
}) {
  const { t } = useTranslation();
  return (
    <ContainerContent title={title} titleWidth={titleWidth} id={id} pt={pt}>
      <ToggleList arr={arr} toggleId={toggleId} page={page} layoutType={layoutType} />
      <ButtonContainer mt={40}>
        <LinkTargetPrimaryLight
          to={linkButton}
          target="_self"
          layoutType={layoutType}
        >
          {t(textButton)}
        </LinkTargetPrimaryLight>
      </ButtonContainer>
    </ContainerContent>
  );
}

const NavLinkPure = createDiv('NavLink', ({ layoutType, className }) => ({
  borderBottom: `2px solid ${STYLES.COLORS.LIGHT_GRAY}`,
  display: 'inline-block',
  cursor: 'pointer',
  paddingLeft: '15px',
  paddingRight: '15px',
  userSelect: 'none',
  color: STYLES.COLORS.DARK_GRAY,
  fontWeight: 300,
  fontSize: STYLES.getMedia([24, 24, 24, 28, 28], layoutType),
  zIndex: 2,
  ':hover': {
    color: STYLES.COLORS.BLUE
  },
  ...className
}));

const NavLink = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(NavLinkPure);

const NavMenu = withCSS(() => ({
  marginTop: '40px'
}))(Row);

const Border = createDiv('Border', {
  borderBottom: `2px solid ${STYLES.COLORS.LIGHT_GRAY}`,
  position: 'relative',
  top: '-2px',
  zIndex: 1
});

function Article({ article, isOpen = false, page, layoutType }) {
  const { t } = useTranslation();
  const fullSizeVideo = ['xs', 'sm'];
  return isOpen && (
    <Fragment>
      <RowContent className="justify-content-center">
        <H3 className="text-center">
          {t(article.title)}
        </H3>
        <P className="text-center">
          {t(article.text)}
        </P>
      </RowContent>
      {page === 'betStorm' && (
        <RowContent className="justify-content-center">
          <video
            width={fullSizeVideo.includes(layoutType) ? '100%' : '80%'}
            height="100%"
            poster={article.media.poster}
            autoPlay
            loop
            muted
            playsInline
            style={{ boxShadow: article.id === 'network' || '0px 5px 20px rgba(0, 0, 0, 0.2)', objectFit: 'fill' }}
          >
            <source src={article.media.av1} type="video/mp4" />
            <source src={article.media.h264} type="video/mp4" />
          </video>
        </RowContent>
      )}
      <RowTestList row={article.row} />
    </Fragment>
  );
}

const RowTestList = ({ row }) => row.map(item => (
  <RowContent key={item.id}>
    <ColTextList col={item.col} />
  </RowContent>
));

const ColTextList = ({ col }) => col.map((item) => {
  const { t } = useTranslation();
  return (
    <Col xs={6} lg={3} key={item.id}>
      <H3>
        {t(item.title)}
      </H3>
      <P>
        {t(item.text)}
      </P>
    </Col>
  );
});

function ToggleList({
  arr, toggleId = false, page, layoutType
}) {
  const [openId, toggleOpenId] = useAccordion(toggleId || 'hardware');
  return (
    <Row>
      <Container>
        <NavMenu className="justify-content-center">
          <NavLinksList arr={arr} toggleOpenId={toggleOpenId} openId={openId} />
        </NavMenu>
        <Border />
        <ArticlesList arr={arr} openId={openId} page={page} layoutType={layoutType} />
      </Container>
    </Row>
  );
}

const ArticlesList = ({
  arr, openId, page, layoutType
}) => arr.map(article => <Article article={article} isOpen={article.id === openId} page={page} layoutType={layoutType} />);

export function NavLinksList({ arr, toggleOpenId, openId }) {
  return arr.map((article) => {
    const getActiveClass = () => (article.id === openId) && { borderColor: STYLES.COLORS.BLUE, color: STYLES.COLORS.BLUE };
    const { t } = useTranslation();
    return (
      <NavLink onClick={toggleOpenId(article.id)} className={getActiveClass()}>
        {t(article.name)}
      </NavLink>
    );
  });
}

export function TariffsNavLinksList({ arr, toggleOpenId, openId }) {
  return arr.map((article) => {
    const getActiveClass = () => (article.id === openId) && { borderColor: STYLES.COLORS.BLUE, color: STYLES.COLORS.BLUE };
    const { t } = useTranslation();
    return (
      <NavLink onClick={() => toggleOpenId(article.id)} className={getActiveClass()}>
        {t(article.name)}
      </NavLink>
    );
  });
}

import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { toastr } from 'react-redux-toastr';

const Toggle = ({
  data,
  isDefault = 0,
  secondary = false,
  setFunction,
  selectedTariffName,
  altData,
  basicParam,
  setGeo = null,
  controlled,
  disabled,
  available,
  value
}) => (
  <ToggleContainer secondary={secondary}>
    <ToggleList
      data={data}
      isDefault={isDefault}
      setFunction={setFunction}
      selectedTariffName={selectedTariffName}
      altData={altData}
      basicParam={basicParam}
      setGeo={setGeo}
      controlled={controlled}
      disabled={disabled}
      available={available}
      value={value}
    />
  </ToggleContainer>
);
export default Toggle;

const ToggleContainer = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
    background: ${({ secondary }) => (secondary ? '#fff' : '#f5f5f5')};
    padding: 5px;
`;

const ToggleItem = styled.div`
    background: #f5f5f5;
    color: #626273;
    display: block;
    flex: 1 1 auto;
    position: relative;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    font-size: 17px;
    line-height: 20px;
    -webkit-user-select: none;
    user-select: none;
    padding: 10px 20px;
    ${({ iaActive }) => iaActive
    && css`
          background: #fff;
          color: #000;
          font-weight: 500;
      `};
    ${({ available }) => available === false
    && css`
          color: #000;
      `};
`;

function handleClick(props) {
  const {
    setIaActive, index, setFunction, selectedTariffName, altData, controlled
  } = props;
  if (selectedTariffName === 'basic' && controlled) {
    setFunction(altData[index]);
    setIaActive(index);
    return;
  } if (selectedTariffName === 'basic') {
    return;
  }
  setFunction(altData[index]);
  setIaActive(index);
}

const ToggleList = ({
  data,
  isDefault = 0,
  setFunction,
  selectedTariffName,
  altData,
  basicParam,
  controlled,
  disabled,
  available,
  value
}) => {
  const [iaActive, setIaActive] = React.useState(isDefault);
  useEffect(() => {
    if (!controlled) {
      setIaActive(basicParam);
      setFunction(altData[basicParam]);
    }
    if (disabled) {
      setIaActive(basicParam);
      setFunction(altData[basicParam]);
    }
    if (controlled) setIaActive(altData.indexOf(selectedTariffName));
  }, [selectedTariffName, disabled]);
  useEffect(() => {
    setIaActive(altData.indexOf(value) === -1 ? 0 : altData.indexOf(value));
  }, [value]);
  return data.map((item, index) => (
    <ToggleItem
      key={`${item + index}`}
      iaActive={iaActive === index}
      available={(available || [])[index]}
      onClick={() => {
        if (disabled) return null;
        if ((available || [])[index] === false) return toastr.error('Нет в наличии, закажите в поддержке изготовление');
        return handleClick({
          item, setIaActive, index, setFunction, selectedTariffName, altData, controlled
        });
      }}
    >
      <span>{item}</span>
    </ToggleItem>
  ));
};

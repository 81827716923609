import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { compose } from "redux";
import { createDiv } from "@nonlux/react-common";
import { calcProxy } from "@nonlux/ab-prices";
import {
  H2,
  H2Landing,
  RowContainer,
  MarginTopContainer,
  ButtonContainer,
  Button,
  TariffsContainer,
} from "./styleElements";
import { withBuyProxyTariffAction } from "../../reducer/profileTariff/selectors";
import ModalRoot from "../ProfilesPage/Modals/ModalRoot";
import TariffsForm from "./TariffsForm/TariffsForm";

const mastercard = require("./icon/mastercard_128.png");
const qiwi = require("./icon/qiwi_128.png");
const visa = require("./icon/visa_128.png");
const eth = require("./icon/eth_128.png");

const PaymentContainer = createDiv("PaymentMethods", {
  display: "flex",
  flexWrap: "nowrap",
  justifyContent: "center",
  gridGap: "50px",
  marginBottom: "60px",
});

const PaymentMethods = () => (
  <PaymentContainer>
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={visa} alt="logo" style={{ height: "35px" }} />
    </div>
    <div>
      <img src={mastercard} alt="logo" style={{ height: "40px" }} />
    </div>
    <div>
      <img src={qiwi} alt="logo" style={{ height: "40px" }} />
    </div>
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        gridGap: "10px",
        alignItems: "center",
      }}
    >
      <img src={eth} alt="logo" style={{ height: "40px" }} />
      <div style={{ color: "#738496", userSelect: "none" }}>Ethereum</div>
    </div>
  </PaymentContainer>
);

const TariffsProxyPage = ({ landing, button, buyProxyTariffAction, id }) => {
  const [isOpenDropdownCountries, setIsOpenDropdownCountries] = useState(false);
  const [isOpenDropdownCities, setIsOpenDropdownCities] = useState(false);
  const [isOpenDropdownOperators, setIsOpenDropdownOperators] = useState(false);

  const [selectedTariffName, setSelectedTariffName] = useState("advanced");
  const [typeConnection, setTypeConnection] = useState("0");
  const [geo, setGeo] = useState("uk");
  const [city, setCity] = useState("London");
  const [mobileOperator, setMobileOperator] = useState("Three");
  const [fingerprint, setFingerprint] = useState("win10");
  const [typeAuthorization, setTypeAuthorization] = useState("switch");
  const [proxyCount, setProxyCount] = useState(1);
  const [daysCount, setDaysCount] = useState(30);
  const [sum, setSum] = useState(49);

  useEffect(() => {
    if (localStorage.getItem("onRegistrationBuyProxy")) {
      const {
        proxyType,
        vpn,
        geo,
        city,
        operator,
        fingerprint,
        authorization,
        proxyCount,
        daysCount,
        sum,
      } = JSON.parse(localStorage.getItem("proxy"));
      const tariffName =
        proxyType === "1"
          ? "basic"
          : proxyType === "0"
            ? "standard"
            : "advanced";
      setSelectedTariffName(tariffName);
      setTypeConnection(vpn);
      setGeo(geo);
      setCity(city);
      setMobileOperator(operator);
      setFingerprint(fingerprint);
      setTypeAuthorization(authorization);
      setProxyCount(proxyCount);
      setDaysCount(daysCount);
      setSum(sum);
      localStorage.removeItem("onRegistrationBuyProxy");
      localStorage.removeItem("proxy");
    }
  }, []);

  useEffect(() => {
    selectedTariffName === "basic" ? setDaysCount(30) : null;
    let proxyType = "0";
    if (selectedTariffName === "basic") proxyType = "1";
    if (selectedTariffName === "advanced") proxyType = "2";

    setSum(
      calcProxy({
        proxyType,
        vpn: typeConnection,
        authorization: typeAuthorization,
        geo,
        daysCount,
        operator: mobileOperator,
        proxyCount,
      }) * proxyCount
    );
  }, [
    geo,
    proxyCount,
    daysCount,
    typeAuthorization,
    typeConnection,
    selectedTariffName,
    mobileOperator,
  ]);

  const afterConfirmBuyProxy = () => {
    setSelectedTariffName("advanced");
    setTypeConnection("1");
    setGeo("uk");
    setCity("London");
    setMobileOperator("Three");
    setFingerprint("win10");
    setTypeAuthorization("switch");
    setProxyCount(1);
    setDaysCount(30);
    setSum(49);
  };

  const onBuyClick = (sign = false) => {
    const proxyType =
      selectedTariffName === "basic"
        ? "1"
        : selectedTariffName === "standard"
          ? "0"
          : "2";
    const proxy = {
      ipChangeTime: "0",
      buildCount: null,
      authorization: typeAuthorization,
      vpn: typeConnection,
      geo,
      city,
      operator: mobileOperator,
      proxyType,
      fingerprint,
      proxyCount,
      daysCount,
      sum,
    };
    if (sign) {
      localStorage.setItem("proxy", JSON.stringify(proxy));
      localStorage.setItem("onRegistrationBuyProxy", JSON.stringify(true));
      return;
    }

    buyProxyTariffAction(proxy, afterConfirmBuyProxy);
  };
  return (
    <RowContainer
      id={id}
      landing={landing}
      onClick={() => {
        if (isOpenDropdownCountries) setIsOpenDropdownCountries(false);
        if (isOpenDropdownCities) setIsOpenDropdownCities(false);
        if (isOpenDropdownOperators) setIsOpenDropdownOperators(false);
      }}
    >
      {landing ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <H2Landing>СОЗДАЙТЕ СВОЮ КОНФИГУРАЦИЮ</H2Landing>
        </div>
      ) : (
        <H2>Мобильные прокси</H2>
      )}
      <TariffsContainer landing={landing}>
        <TariffsForm
          selectedTariffName={selectedTariffName}
          setSelectedTariffName={setSelectedTariffName}
          setTypeConnection={setTypeConnection}
          setFingerprint={setFingerprint}
          setTypeAuthorization={setTypeAuthorization}
          typeConnection={typeConnection}
          setDaysCount={setDaysCount}
          setProxyCount={setProxyCount}
          proxyCount={proxyCount}
          setGeo={setGeo}
          setCity={setCity}
          setMobileOperator={setMobileOperator}
          geo={geo}
          mobileOperator={mobileOperator}
          daysCount={daysCount}
          sum={sum}
          fingerprint={fingerprint}
          typeAuthorization={typeAuthorization}
          city={city}
          isOpenDropdownCountries={isOpenDropdownCountries}
          isOpenDropdownCities={isOpenDropdownCities}
          isOpenDropdownOperators={isOpenDropdownOperators}
          setIsOpenDropdownCountries={setIsOpenDropdownCountries}
          setIsOpenDropdownCities={setIsOpenDropdownCities}
          setIsOpenDropdownOperators={setIsOpenDropdownOperators}
        />
        <ButtonContainer>
          {button === "sign" ? (
            <Link to="/sign_up">
              <Button
                onClick={() => {
                  onBuyClick(true);
                }}
              >
                Подключить
              </Button>
            </Link>
          ) : (
            <Button
              onClick={() => {
                onBuyClick(false);
              }}
            >
              Оплатить
            </Button>
          )}
        </ButtonContainer>
        {landing && <PaymentMethods />}
      </TariffsContainer>
      <ModalRoot />
    </RowContainer>
  );
};

export default compose(withBuyProxyTariffAction)(TariffsProxyPage);

export IndexPage from './IndexPage/IndexPage';
export ProxyPage from './ProxyPage/ProxyPage';
export VdsPage from './VdsPage/VdsPage';
export AdvantagesPage from './AdvantagesPage/AdvantagesPage.js';
export LoginPage from './LoginPage/LoginPage';
export RegistrationPage from './RegistrationPage/RegistrationPage';
export ForgotPasswordPage from './ForgotPasswordPage/ForgotPasswordPage';
export RecoverPasswordPage from './RecoverPasswordPage/RecoverPasswordPage';
export TermsPage from './TermsPage/TermsPage';
export SupportPage from './SupportPage/SupportPage';
export NotFoundPage from './NotFoundPage/NotFoundPage';
export RecoverEmailPage from './RecoverEmailPage/RecoverEmailPage';
export VerifyEmailPage from './VerifyEmailPage/VerifyEmailPage';
export ConfirmEmailPage from './ConfirmEmailPage/ConfirmEmailPage';
export WelcomePage from './WelcomePage/WelcomePage';
export NewUserPage from './NewUserPage.js';
export PasswordPage from './PasswordPage/PasswordPage.js';
export BetStormPage from './BetStormPage/BetStormPage.js';
export AffiliatePage from './AffiliatePage/AffiliatePage.js';
export AccountConfirmationPage from './AccountConfirmationPage/AccountConfirmationPage.js';
export UnsubscribePage from './UnsubscribePage/UnsubscribePage.js';
export NotificationSentPage from './NotificationSentPage/NotificationSentPage.js';
export PrivacyPolicyPage from './PrivacyPolicyPage/PrivacyPolicyPage.js';
export UnsubscribeSuccessPage from './UnsubscribeSuccessPage/UnsubscribeSuccessPage';
import React from 'react';
import { withCSS } from '@nonlux/react-common/composable';
import { Row } from 'reactstrap';
import { createDiv } from '@nonlux/react-common';
import {
  LinkPrimaryDark,
  H3,
  P,
} from 'components';
import { useTranslation } from 'react-i18next';
import STYLES from '../../styles/constants.js';

function ImgRowPure({ className, layoutType, info }) {
  const { t } = useTranslation();
  return (
    <Row className={className}>
      <WrapedBox>
        <InfoBox layoutType={layoutType}>
          <WrapedText layoutType={layoutType}>
            <Title layoutType={layoutType}>
              {t(info.title)}
            </Title>
            <P>
              {t(info.text)}
            </P>
            <WrapedLink>
              <LinkPrimaryDark to="/sign_up" layoutType={layoutType} size="md">
                {t(info.button)}
              </LinkPrimaryDark>
            </WrapedLink>
          </WrapedText>
        </InfoBox>
      </WrapedBox>
    </Row>
  );
}

const ImgRow = withCSS(({ layoutType, img, blue = false }) => ({
  minHeight: STYLES.getMedia([630, 630, 630, 670, 740], layoutType),
  backgroundColor: blue ? STYLES.COLORS.WHITE_BLUE : STYLES.COLORS.WHITE,
  backgroundImage: `url(${img})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
}))(ImgRowPure);
export default ImgRow;

const Title = withCSS({
  color: STYLES.COLORS.WHITE,
  marginBottom: '30px',
})(H3);

const WrapedText = createDiv('WrapedText', {
  padding: '31px',
  color: STYLES.COLORS.WHITE,
});

const WrapedLink = createDiv('WrapedLink', {
  marginTop: '30px',
  marginBottom: '10px',
});

const WrapedBox = createDiv('WrapedBox', {
  width: '100%',
  position: 'absolute',
});

const InfoBox = createDiv('InfoBox', ({ layoutType }) => ({
  position: 'relative',
  top: STYLES.getMedia([180, 160, 160, 140, 160], layoutType),
  left: STYLES.getMedia([0, 70, 160, 280, 260], layoutType),
  minHeight: STYLES.getMedia([295, 295, 295, 313, 330], layoutType),
  width: STYLES.getMedia([320, 320, 340, 360, 380], layoutType),
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  clipPath: 'polygon(0 0, 80% 0, 80% 20%, 100% 20%, 100% 100% , 0 100%, 0 0)',
}));

import React from 'react';
import { connect } from 'react-redux';
import { scrollWithOffset } from '@nonlux/utils';
import { Link as LinkPure } from 'react-router-dom';
import { HashLink as HashLinkPure } from 'react-router-hash-link';
import { withCSS, toggleLayout } from '@nonlux/react-common/composable';
import { createDiv } from '@nonlux/react-common';
import { Col, Row, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  P,
  H3,
  Img,
  RowContent,
  LinkTarget as LinkTargetPure,
} from 'components';
import STYLES from '../../styles/constants.js';

import logo from './icon/logo.png';

const ToggleLayout = (props) => {
  const { layoutType } = props;
  const [isXs] = toggleLayout({ getLayout: ['xs'] });
  if (isXs(layoutType)) return <LayoutXs {...props} />;
  return <LayoutSm {...props} />;
};

function Footer(props) {
  const { layoutType } = props;
  return (
    <RowContainer layoutType={layoutType}>
      <Container>
        <ToggleLayout {...props} />
      </Container>
    </RowContainer>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType,
}))(Footer);

const LinkContainer = createDiv('LinkContainer', ({ mt = 6 }) => ({
  marginTop: `${mt}px`,
}));

const ImgContainer = createDiv('ImgContainer', {
  maxWidth: '70px',
  display: 'inline-block',
});

const TextContainer = createDiv('TextContainer', {
  display: 'inline-block',
  marginBottom: '20px',
  paddingLeft: '10px',
});

const Title = withCSS({
  fontSize: '14px',
  fontWeight: '600',
  color: STYLES.COLORS.DARK_GRAY,
})(H3);

const LogoName = withCSS({
  fontSize: '16px',
  fontWeight: '200',
  color: STYLES.COLORS.WHITE,
})(P);

const RowContainer = withCSS(({ layoutType }) => ({
  backgroundColor: STYLES.COLORS.DARK,
  padding: STYLES.getMediaAcc(['60px 50px 150px', '60px 75px 150px', '60px 150px 150px', '60px 150px 150px', '60px 150px 150px'], layoutType),
}))(Row);

const Link = withCSS({
  color: STYLES.COLORS.WHITE,
  textDecoration: 'none',
  ':hover': {
    color: STYLES.COLORS.BLUE,
    textDecoration: 'none',
  },
})(LinkPure);

const LinkTarget = withCSS({
  color: STYLES.COLORS.WHITE,
  textDecoration: 'none',
  ':hover': {
    color: STYLES.COLORS.BLUE,
    textDecoration: 'none',
  },
})(LinkTargetPure);

const HashLink = withCSS({
  color: STYLES.COLORS.WHITE,
  textDecoration: 'none',
  ':hover': {
    color: STYLES.COLORS.BLUE,
    textDecoration: 'none',
  },
})(HashLinkPure);

const ColLayout = withCSS(({ layoutType }) => {
  const getMarginTop = ['xs', 'sm', 'md'].includes(layoutType) ? 40 : 0;
  return ({
    marginTop: `${getMarginTop}px`,
  });
})(Col);

const LayoutXs = ({ layoutType }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col xs={12} layoutType={layoutType}>
        <Row>
          <Col>
            <Title>
              {t('footer.products.title')}
            </Title>
            <LinkContainer>
              <Link to="/vds">
                {t('footer.products.vds')}
              </Link>
            </LinkContainer>
            <LinkContainer>
              <Link to="/proxy">
                {t('footer.products.proxy')}
              </Link>
            </LinkContainer>
            <LinkContainer>
              <Link to="/bet_storm">
                Bet Storm
              </Link>
            </LinkContainer>
          </Col>
          <Col>
            <Title>
              {t('footer.tutorial.title')}
            </Title>
            <LinkContainer>
              <LinkTarget to="https://arbitrage-bets.com/tutorial/getting_started_vds">
                {t('footer.tutorial.tutorial_vds')}
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget to="https://arbitrage-bets.com/tutorial/getting_started_proxy">
                {t('footer.tutorial.tutorial_proxy')}
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget to="https://arbitrage-bets.com/tutorial/getting_started_bet_storm">
                {t('footer.tutorial.tutorial_bet_storm')}
              </LinkTarget>
            </LinkContainer>
          </Col>
        </Row>
        <RowContent>
          <Col>
            <Title>
              {t('footer.company.title')}
            </Title>
            <LinkContainer>
              <HashLink
                to="#about_us"
                scroll={el => scrollWithOffset(el, '50', 'smooth')}
              >
                {t('footer.company.about_us')}
              </HashLink>
            </LinkContainer>
            <LinkContainer>
              <Link to="/support">
                {t('footer.company.contacts')}
              </Link>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget to="https://arbitrage-bets.com/partners/all_best_bets">
                {t('footer.company.partners')}
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <Link to="/affiliate">
                {t('footer.company.affiliate')}
              </Link>
            </LinkContainer>
          </Col>
          <Col>
            <Title>
              {t('footer.channels.title')}
            </Title>
            <LinkContainer>
              <LinkTarget
                to="https://t.me/arbitrage_bets"
                target="blank"
              >
              Telegram
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget
                to="https://vk.com/arbitragebets"
                target="blank"
              >
              VKontakte
              </LinkTarget>
            </LinkContainer>
{/*            <LinkContainer>
              <LinkTarget
                to="https://www.facebook.com/arbitrage.bets.58"
                target="blank"
              >
              Facebook
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget
                to="https://twitter.com/BetsArbitrage"
                target="blank"
              >
              Twitter
              </LinkTarget>
            </LinkContainer>*/}
            <LinkContainer>
              <LinkTarget
                to="https://www.youtube.com/channel/UCXLr4eeNbvOBuQPaEWpzSiw?view_as=subscriber"
                target="blank"
              >
              YouTube
              </LinkTarget>
            </LinkContainer>
          </Col>
        </RowContent>
      </Col>
      <ColLayout xs={12} className="text-center" layoutType={layoutType}>
        <ImgContainer>
          <Img src={logo} />
        </ImgContainer>
        <TextContainer>
          <LogoName>LEON</LogoName>
        </TextContainer>
        <Title>Copyright © 2018-2024 LEON</Title>
      </ColLayout>
    </Row>
  );
};

const LayoutSm = ({ layoutType }) => {
  const { t } = useTranslation();
  return (
    <Row>
      <Col xs={12} lg={4}>
        <ImgContainer>
          <Img src={logo} />
        </ImgContainer>
        <TextContainer>
          <LogoName>LEON</LogoName>
        </TextContainer>
        <Title>Copyright © 2018-2024 LEON</Title>
      </Col>
      <ColLayout xs={12} lg={8} layoutType={layoutType}>
        <Row>
          <Col>
            <Title>
              {t('footer.products.title')}
            </Title>
            <LinkContainer>
              <Link to="/vds">
                {t('footer.products.vds')}
              </Link>
            </LinkContainer>
            <LinkContainer>
              <Link to="/proxy">
                {t('footer.products.proxy')}
              </Link>
            </LinkContainer>
            <LinkContainer>
              <Link to="/bet_storm">
                BetStorm
              </Link>
            </LinkContainer>
          </Col>
          <Col>
            <Title>
              {t('footer.tutorial.title')}
            </Title>
            <LinkContainer>
              <LinkTarget to="https://arbitrage-bets.com/tutorial/getting_started_vds">
                {t('footer.tutorial.tutorial_vds')}
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget to="https://arbitrage-bets.com/tutorial/getting_started_proxy">
                {t('footer.tutorial.tutorial_proxy')}
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget to="https://arbitrage-bets.com/tutorial/getting_started_bet_storm">
                {t('footer.tutorial.tutorial_bet_storm')}
              </LinkTarget>
            </LinkContainer>
          </Col>
        </Row>
        <RowContent>
          <Col>
            <Title>
              {t('footer.company.title')}
            </Title>
            <LinkContainer>
              <HashLink
                to="/#about_us"
                scroll={el => scrollWithOffset(el, '50', 'smooth')}
              >
                {t('footer.company.about_us')}
              </HashLink>
            </LinkContainer>
            <LinkContainer>
              <Link to="/support">
                {t('footer.company.contacts')}
              </Link>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget to="https://arbitrage-bets.com/partners/all_best_bets">
                {t('footer.company.partners')}
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <Link to="/affiliate">
                {t('footer.company.affiliate')}
              </Link>
            </LinkContainer>
          </Col>
          <Col>
            <Title>
              {t('footer.channels.title')}
            </Title>
            <LinkContainer>
              <LinkTarget
                to="https://t.me/arbitrage_bets"
                target="blank"
              >
                Telegram
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget
                to="https://vk.com/arbitragebets"
                target="blank"
              >
                VKontakte
              </LinkTarget>
            </LinkContainer>
{/*            <LinkContainer>
              <LinkTarget
                to="https://www.facebook.com/arbitrage.bets.58"
                target="blank"
              >
                Facebook
              </LinkTarget>
            </LinkContainer>
            <LinkContainer>
              <LinkTarget
                to="https://twitter.com/BetsArbitrage"
                target="blank"
              >
                Twitter
              </LinkTarget>
            </LinkContainer>*/}
            <LinkContainer>
              <LinkTarget
                to="https://www.youtube.com/channel/UCXLr4eeNbvOBuQPaEWpzSiw?view_as=subscriber"
                target="blank"
              >
                YouTube
              </LinkTarget>
            </LinkContainer>
          </Col>
        </RowContent>
      </ColLayout>
    </Row>
  )
};

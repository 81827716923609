import React from 'react';
import { withModalDialogData } from '../../../reducer/modalDialog/selectors';
import ModalDialogConfirm from './ModalDialogConfirm';

const ConfirmBuyDevices = ({ modalDialogData }) => (
  <ModalDialogConfirm
    title="Покупка устройств"
    text={`Количество: ${modalDialogData.devicesCount}, итоговая сумма: ${modalDialogData.price}€`}
  />
);

export default withModalDialogData(ConfirmBuyDevices);

import React from 'react';
import { withModalDialogData } from '../../../reducer/modalDialog/selectors';
import ModalDialogConfirm from './ModalDialogConfirm';

const ConfirmTestProfileTariff = () => {
  const getModalText = () => ({ modalTitle: 'Тестирование тарифа', modalText: 'Подключить бесплатный тест STANDARD на 24 часа' });

  const modalData = getModalText();

  return (
    <ModalDialogConfirm
      title={modalData.modalTitle}
      text={modalData.modalText}
    />
  );
};

export default withModalDialogData(ConfirmTestProfileTariff);

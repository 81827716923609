import React, { Fragment } from 'react';
import Plx from 'react-plx';
import { useTranslation } from 'react-i18next';
import { scrollWithOffset } from '@nonlux/utils';
import { withCSS, toggleLayout } from '@nonlux/react-common/composable';
import { Col, Row } from 'reactstrap';
import { createElement, createDiv } from '@nonlux/react-common';
import {
  LinkPrimaryDark,
  HashLinkOutline,
  LinkSecondaryDark,
  LinkPrimaryDarkTarget,
  H1,
} from 'components';
import STYLES from '../../styles/constants.js';
import {HashLinkPrimaryDark} from "../Links/Links";

const plxCoords = [
  {
    start: 0,
    end: 250,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
    ],
  },
];

export default function Presentation({ layoutType, presentation }) {
  const { t } = useTranslation();
  return (
    <PresentationContainer layoutType={layoutType}>
      <PresentationTextContainer>
        <Plx parallaxData={plxCoords}>
          <H1>
            {t(presentation.title)}
          </H1>
          <PresentationP layoutType={layoutType}>
            {t(presentation.text)}
          </PresentationP>
          <LinksContainer
            layoutType={layoutType}
            button={presentation.button}
          />
        </Plx>
      </PresentationTextContainer>
    </PresentationContainer>
  );
}

const PresentationContainer = withCSS(({ layoutType }) => ({
  marginTop: '55px',
  minHeight: STYLES.getMedia([350, 375, 400, 525, 560], layoutType),
  backgroundColor: STYLES.COLORS.BASE_DARK,
  padding: STYLES.getMediaAcc(['75px 75px 100px', '75px 50px 100px', '90px 80px', '130px 130px', '150px 150px'], layoutType),
}))(Row);

const PresentationTextContainer = createDiv('PresentationTextContainer', {
  maxWidth: '950px',
  marginRight: 'auto',
  marginLeft: 'auto',
  textAlign: 'center',
});

const PresentationP = createElement('p', 'PresentationP', ({ layoutType }) => ({
  fontSize: STYLES.getMedia([16, 22, 22, 28, 28], layoutType),
  fontWeight: 200,
  color: STYLES.COLORS.WHITE,
  marginTop: '15px',
  lineHeight: 1.3,
}));


const ToggleLayoutLinkContainer = (props) => {
  const { layoutType } = props;
  const [isXs] = toggleLayout({ getLayout: ['xs', 'sm'] });
  if (isXs(layoutType)) return <LayoutXsLinkContainer {...props} />;
  return <LayoutMdLinkContainer {...props} />;
};

function LinksContainer(props) {
  return (
    <LinksRow>
      <ToggleLayoutLinkContainer {...props} />
    </LinksRow>
  );
}

const ToggleLink = ({ button, layoutType }) => {
  const { t } = useTranslation();
  const { offset = false, behavior = false, href } = button.left.link;
  const mr = ['xs', 'sm'].includes(layoutType) ? 0 : 10;
  if (button.left.type === 'secondary') {
    return (
      <LinkSecondaryDark
        to={href}
        layoutType={layoutType}
        size={STYLES.getSizeButton(layoutType)}
        mr={mr}
      >
        {t(button.left.name)}
      </LinkSecondaryDark>
    );
  }
  return (
    <HashLinkOutline
      to={href}
      scroll={el => scrollWithOffset(el, offset, behavior)}
      mr={10}
      layoutType={layoutType}
      size={STYLES.getSizeButton(layoutType)}
    >
      &#9654;&nbsp;
      {t(button.left.name)}
    </HashLinkOutline>
  );
};

const LayoutXsLinkContainer = ({ layoutType, button }) => {
  const { t } = useTranslation();
  const { offset = false, behavior = false, href } = button.right.link;

  return (
    <Col>
      <Row className="justify-content-center">
        <ToggleLink layoutType={layoutType} button={button} />
      </Row>
      <Row className="justify-content-center mt-3">
        {button.right.type === 'target' ? (
          <LinkPrimaryDarkTarget
            to={button.right.link.href}
            layoutType={layoutType}
            size={STYLES.getSizeButton(layoutType)}
          >
            {t(button.right.name)}
          </LinkPrimaryDarkTarget>
        ) : (
          <HashLinkPrimaryDark
            to={href}
            scroll={el => scrollWithOffset(el, offset, behavior)}
            layoutType={layoutType}
            size={STYLES.getSizeButton(layoutType)}
          >
            {t(button.right.name)}
          </HashLinkPrimaryDark>
        )}
      </Row>
    </Col>
  );
};

const LayoutMdLinkContainer = ({ layoutType, button }) => {
  const { t } = useTranslation();
  const { offset = false, behavior = false, href } = button.right.link;

  return (
    <Fragment>
      <ToggleLink layoutType={layoutType} button={button} />
      {button.right.type === 'target' ? (
        <LinkPrimaryDarkTarget
          to={href}
          layoutType={layoutType}
          size={STYLES.getSizeButton(layoutType)}
        >
          {t(button.right.name)}
        </LinkPrimaryDarkTarget>
      ) : (
        <HashLinkPrimaryDark
          to={button.right.link.href}
          scroll={el => scrollWithOffset(el, offset, behavior)}
          layoutType={layoutType}
          size={STYLES.getSizeButton(layoutType)}
        >
          {t(button.right.name)}
        </HashLinkPrimaryDark>
      )}
    </Fragment>
  )

};

const LinksRow = createDiv('LinksRow', {
  whiteSpace: 'nowrap',
  marginTop: '40px',
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexBasis: 'auto',
  flexShrink: 1,
});

/* eslint camelcase:0 */
import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Border } from 'components';
import { css } from 'aphrodite';
import { styles } from './styles';
import { Box, Channel } from '../../components';

import Telegram from '../IndexPage/icon/telegram.svg';
import VKontakte from '../IndexPage/icon/vkontakte.svg';
import Youtube from '../IndexPage/icon/youtube.svg';

import telegram from './icon/telegram__icon.png';
import partners from './icon/partners.png';
import email from './icon/email.png';

export default function SupportPage() {
  const { t } = useTranslation();
  return (
    <Row className={css(styles.help__box)}>
      <Container className={css(styles.help__container)}>
        <Row>
          <Col className="text-center">
            <h2 className={css(styles.help__font_label)}>
              {t('support.title')}
            </h2>
            <Container className={css(styles.help__text_box)}>
              <p className="mt-4">
                {t('support.info')}
              </p>
            </Container>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <img alt="email" className={css(styles.help__box_img)} src={email} />
            <p className="mb-1 mt-3">
              {t('support.contacts.email.title')}
            </p>
            <p>
              {t('support.contacts.email.contact')}
            </p>
          </Col>
          <Col className="text-center">
            <img alt="telegram" className={css(styles.help__box_img)} src={telegram} />
            <p className="mb-1 mt-3">
              {t('support.contacts.telegram.title')}
            </p>
            <p className="mb-4">
              <a href="https://t.me/arb_support">{t('support.contacts.telegram.contact')}</a>
            </p>
          </Col>
          <Col className="text-center">
            <img alt="partners" className={css(styles.help__box_img)} src={partners} />
            <p className="mb-1 mt-3">
              {t('support.contacts.cooperation.title')}
            </p>
            <p>
              {t('support.contacts.cooperation.contact')}
            </p>
          </Col>
        </Row>
        <Border />
        <Box
          flex
          container
          justify="center"
          mt={40}
        >
          <h3>
            {t('support.socialNetworks')}
          </h3>
        </Box>
        <Box
          flex
          container
          justify="center"
          gap={20}
          mt={20}
        >
          <Channel
            icon={Telegram}
            type="Telegram"
            link="https://t.me/arbitrage_bets"
          />
          <Channel
            icon={VKontakte}
            type="VKontakte"
            link="https://vk.com/arbitragebets"
          />
          <Channel
            icon={Youtube}
            type="Youtube"
            link="https://www.youtube.com/channel/UCXLr4eeNbvOBuQPaEWpzSiw"
          />
        </Box>
      </Container>
    </Row>
  );
}

function Imgs(props) {
  const { href, box_img, img } = props;
  return (
    <a target="_blank" rel="noreferrer noopener" href={href}>
      <img alt="img" className={box_img} src={img} />
    </a>
  );
}
Imgs.defaultProps = {
  href: '',
  box_img: '',
  img: ''
};

function ImgsList(props) {
  const { imgs, box_img } = props;
  return imgs.map(item => (
    <Imgs
      key={`${item.title}`}
      box_img={box_img}
      {...item}
    />
  ));
}
ImgsList.defaultProps = {
  imgs: '',
  box_img: ''
};

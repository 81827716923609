import TYPES from './types';

export const buyProfileTariffAction = (tariffType, syncCount, userSyncCount, syncsPrices) => ({
  type: TYPES.BUY_PROFILE_TARIFF,
  payload: {
    tariffType, syncCount, userSyncCount, syncsPrices
  }
});
buyProfileTariffAction.type = TYPES.BUY_PROFILE_TARIFF;

export const testProfileTariffAction = () => ({
  type: TYPES.TEST_PROFILE_TARIFF,
  payload: {}
});
testProfileTariffAction.type = TYPES.TEST_PROFILE_TARIFF;

export const buySyncsAction = (
  syncCount, userSyncCount, tariffName, afterConfirmBuySync, syncsPrices
) => ({
  type: TYPES.BUY_SYNCS,
  payload: {
    syncCount, userSyncCount, tariffName, afterConfirmBuySync, syncsPrices
  }
});
buySyncsAction.type = TYPES.BUY_SYNCS;

export const buyDevicesAction = (devicesCount, tariffName, afterConfirmBuyDevices) => ({
  type: TYPES.BUY_DEVICES,
  payload: { devicesCount, tariffName, afterConfirmBuyDevices }
});
buyDevicesAction.type = TYPES.BUY_DEVICES;

export const setSubscriptionAutoRenewalAction = autoRenewal => ({
  type: TYPES.SET_SUBSCRIPTION_AUTO_RENEWAL,
  payload: { autoRenewal }
});

export const setProxyAutoRenewalAction = (autoRenewal, _id) => ({
  type: TYPES.SET_PROXY_AUTO_RENEWAL,
  payload: { autoRenewal, _id }
});
setProxyAutoRenewalAction.type = TYPES.SET_PROXY_AUTO_RENEWAL;

export const setVdsAutoRenewalAction = (autoRenewal, _id) => ({
  type: TYPES.SET_VDS_AUTO_RENEWAL,
  payload: { autoRenewal, _id }
});
setVdsAutoRenewalAction.type = TYPES.SET_VDS_AUTO_RENEWAL;

export const buyProxyTariffAction = (proxy, afterConfirmBuyProxy) => ({
  type: TYPES.BUY_PROXY_TARIFF,
  payload: { proxy, afterConfirmBuyProxy }
});
buyProxyTariffAction.type = TYPES.BUY_PROXY_TARIFF;

export const buyVdsTariffAction = (vds, proxy, sum) => ({
  type: TYPES.BUY_VDS_TARIFF,
  payload: { vds, proxy, sum }
});
buyVdsTariffAction.type = TYPES.BUY_VDS_TARIFF;

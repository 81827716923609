import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  recover_form__line: {
    border: 'none',
    backgroundColor: 'black',
    color: 'black',
    height: '2px',
    marginTop: 0,
    marginBottom: '10px',
  },
  recover_form__box: {
    paddingTop: '200px',
    paddingBottom: '160px',
  },
  recover_form__container: {
    maxWidth: '700px',
  },
  btn__primary: {
    border: 'none',
    backgroundColor: '#61dafb',
    color: 'white',
    ':hover': {
      backgroundColor: '#56c7e4',
      color: 'white',
      boxShadow: 'none',
    },
    ':active': {
      backgroundColor: '#56c7e4',
      color: 'white',
      boxShadow: 'none',
    },
    ':focus': {
      boxShadow: 'none',
    },
  },
  recover_form__box_btn: {
    marginTop: '20px',
  },
  forgot_form__box_link: {
    marginTop: '20px',
  },
  forgot_form_link: {
    color: '#61dafb',
  },
  input__feedback: {
    color: 'red',
    marginTop: '10px',
    fontSize: '15px',
    fontWeight: 500,
  },
  recover_form__font_title: {
    fontWeight: 300,
  },
});

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Services, ServicesInfo } from 'components';
import { css } from 'aphrodite/no-important';
import { styles } from './styles';

const logo = require('./icon/logo.png');

const channelLinks = [
  { href: 'https://t.me/arbitrage_bets', title: 'Telegram' },
  { href: 'https://vk.com/id504944813', title: 'Vkontakte' },
  { href: 'https://www.facebook.com/arbitrage.bets.58', title: 'Facebook' },
  { href: 'https://twitter.com/BetsArbitrage', title: 'Twitter' },
  { href: 'https://www.youtube.com/channel/UCXLr4eeNbvOBuQPaEWpzSiw?view_as=subscriber', title: 'YouTube' },
];

const vdsLinks = [
  { href: '/vds', title: 'Быстрый старт' },
  { href: '/tutorial/connect_vds', title: 'Руководство' },
];

const proxyLinks = [
  { href: '/proxy', title: 'Быстрый старт' },
  { href: '/tutorial/connect_proxy', title: 'Руководство' },
];

const tutorialLinks = [
  { href: '/connect_vds', title: 'Быстрый старт' },
  { href: '/tutorial', title: 'Руководство' },
];

const servicesInfo = { title: 'LEON', text: 'Copyright © 2018 LEON Inc.' };

function Footer({ layoutType }) {
  const layoutLg = (layoutType === 'lg' || layoutType === 'xl') && <LayoutLg />;
  const layoutMd = (layoutType === 'sm' || layoutType === 'md') && <LayoutMd />;
  const layoutXs = (layoutType === 'xs') && <LayoutXs />;
  return (
    <Fragment>
      {layoutLg}
      {layoutMd}
      {layoutXs}
    </Fragment>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType,
}))(Footer);

function LayoutLg() {
  return (
    <Row className={css(styles.footer__box_container_fluid)}>
      <Col>
        <Row>
          <ServicesInfo
            img_logo={css(styles.footer__img_logo)}
            font_brand={css(styles.footer__font_brand)}
            font_text_brand={css(styles.footer__font_text_brand)}
            info={servicesInfo}
            logo={logo}
          />
          <Services
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            links={vdsLinks}
            title="VDS"
          />
          <Services
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            links={proxyLinks}
            title="PROXY"
          />
        </Row>
        <Row className="mt-3">
          <Services
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            links={tutorialLinks}
            title="TUTORIAL"
            size="4"
            offset="4"
          />
          <Services
            flag="target"
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            styles={styles}
            links={channelLinks}
            title="CHANNELS"
            size="4"
          />
        </Row>
      </Col>
    </Row>
  );
}

function LayoutMd() {
  return (
    <Row className={css(styles.footer__box_container_fluid)}>
      <Col>
        <Row>
          <ServicesInfo
            img_logo={css(styles.footer__img_logo)}
            font_brand={css(styles.footer__font_brand)}
            font_text_brand={css(styles.footer__font_text_brand)}
            info={servicesInfo}
            logo={logo}
          />
        </Row>
        <Row className={css(styles.footer__layout_margin)}>
          <Services
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            links={vdsLinks}
            title="VDS"
          />
          <Services
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            links={proxyLinks}
            title="PROXY"
          />
        </Row>
        <Row className={css(styles.footer__layout_margin)}>
          <Services
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            links={tutorialLinks}
            title="TUTORIAL"
          />
          <Services
            flag="target"
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            styles={styles}
            links={channelLinks}
            title="CHANNELS"
          />
        </Row>
      </Col>
    </Row>
  );
}

function LayoutXs() {
  return (
    <Row className={css(styles.footer__box_container_fluid)}>
      <Col>
        <Row>
          <Services
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            links={vdsLinks}
            title="VDS"
          />
          <Services
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            links={proxyLinks}
            title="PROXY"
          />
        </Row>
        <Row className={css(styles.footer__layout_margin)}>
          <Services
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            links={tutorialLinks}
            title="TUTORIAL"
          />
          <Services
            flag="target"
            font_title={css(styles.footer__font_title)}
            box_link={css(styles.footer__box_link)}
            style_link={css(styles.footer__text_link)}
            styles={styles}
            links={channelLinks}
            title="CHANNELS"
          />
        </Row>
        <Row className={css(styles.footer__layout_margin)}>
          <div className={css(styles.footer__servicesInfo_centre)}>
            <ServicesInfo
              img_logo={css(styles.footer__img_logo)}
              font_brand={css(styles.footer__font_brand)}
              font_text_brand={css(styles.footer__font_text_brand)}
              info={servicesInfo}
              logo={logo}
            />
          </div>
        </Row>
      </Col>
    </Row>
  );
}

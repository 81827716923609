/**
 * @modules components
 */
import React from 'react';
import PropTypes from 'prop-types';

import { createElement } from '@nonlux/react-common';

export const HtmlSelect = createElement('select', 'Select');
export const Option = createElement('option', 'Option');

/**
 * компонент Селекс
 *
 * @param {Object} props  - props
 * @property {Array<string>} options - массив для option
 * @returns {ReactElememt}
 */
export default function Select(props) {
  const { options, value, ...rest } = props;
  return (
    <HtmlSelect {...rest}  value={value != undefined && value.toString? value.toString() : value}>
      { opts(options)}
    </HtmlSelect>
  );
}

function opts(options) {
  return options.map((option) => {
    if (typeof option === 'string') {
      return (
        <Option key={option} value={option}>
          {option}
        </Option>
      );
    }
    const {value, title} = option;
    return (
      <Option key={value} value={value.toString? value.toString() : value}>
        {title}
      </Option>
    );
  });
}


Select.defaultProps = {
  options: [],
};

import React from 'react';
import { withCSS, branched, toggleLayout } from '@nonlux/react-common/composable';
import { createElement, createDiv } from '@nonlux/react-common';
import {
  Col, Row, Container,
} from 'reactstrap';
import {
  ContainerContent,
  Img,
  ButtonContainer,
  H3, P,
  LinkTargetPrimaryLight,
} from 'components';
import { useTranslation } from 'react-i18next';
import STYLES from '../../../styles/constants.js';
import {
  hardware, os, browser, noBrowser, telemetry, allParams,
} from '../data.js';

const Triangle = ({ layoutType, img }) => {
  const getMarginTopButton = ['xs', 'sm', 'md'].includes(layoutType) ? 40 : 0;
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  return (
    <ContainerContent
      title={t('vds.triangle.title')}
      titleWidth={700}
    >
      <Row>
        <ToggleLayout layoutType={layoutType} img={img} language={language} />
      </Row>
      <ButtonContainer mt={getMarginTopButton}>
        <LinkTargetPrimaryLight
          to="https://arbitrage-bets.com/tutorial/configuration_vds"
          layoutType={layoutType}
          size="md"
        >
          {t('vds.triangle.button')}
        </LinkTargetPrimaryLight>
      </ButtonContainer>
    </ContainerContent>
  );
};
export default Triangle;

const ToggleLayout = ({ layoutType, ...rest }) => {
  const [isXs] = toggleLayout({ getLayout: ['lg', 'xl'] });
  if (isXs(layoutType)) return <LayoutLg {...rest} layoutType={layoutType} />;
  return <LayoutXs {...rest} layoutType={layoutType} />;
};

const LayoutLg = ({ img, layoutType, language }) => (
  <Container>
    <TextContainer>
      <Telemetry info={telemetry} layoutType={layoutType} />
      <NoBrowser info={noBrowser} layoutType={layoutType} />
      <Browser info={browser} position="left" layoutType={layoutType} language={language} />
      <OS info={os} position="left" layoutType={layoutType} language={language} />
      <Hardware info={hardware} position="left" layoutType={layoutType} language={language} />
    </TextContainer>
    <Img src={img} />
  </Container>
);

const LayoutXs = ({ layoutType }) => allParams.map((item) => {
  const { t } = useTranslation();
  return (
    <Col xs={12} sm={6}>
      <WrapedText>
        <WrapedH3 layoutType={layoutType}>{t(item.title)}</WrapedH3>
        <WrapedP layoutType={layoutType}>{t(item.exp)}</WrapedP>
        <WrapedP layoutType={layoutType}>{t(item.text)}</WrapedP>
      </WrapedText>
    </Col>
  );
});

const WrapedText = createDiv('TextContainer', {
  marginTop: '40px',
});

const WrapedP = withCSS({
  margin: 0,
  padding: 0,
})(P);

const WrapedH3 = withCSS({
  marginTop: '20px',
})(H3);


const TextBox = ({
  className, info, position, layoutType,
}) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Title layoutType={layoutType}>
        {t(info.title)}
      </Title>
      <Text
        layoutType={layoutType}
        position={position}
        pl={15}
        pr={15}
      >
        {t(info.text)}
      </Text>
      <Text
        position={position}
        pr={45}
        pl={45}
        layoutType={layoutType}
      >
        {t(info.line1)}
      </Text>
      <Text
        position={position}
        pr={60}
        pl={60}
        layoutType={layoutType}
      >
        {t(info.line2)}
      </Text>
    </div>
  );
};

const Title = createElement('h3', 'H3', ({ layoutType }) => ({
  color: STYLES.COLORS.DARK_GRAY,
  fontWeight: 300,
  fontSize: STYLES.getMedia([24, 24, 24, 24, 28], layoutType),
  lineHeight: 1.3,
}));

const TextPure = createElement('p', 'Text', ({ pl = 0, pr = 0, layoutType }) => ({
  fontSize: STYLES.getMedia([16, 16, 16, 16, 18], layoutType),
  lineHeight: 0.4,
}));

const TextRight = withCSS(({ pl = 0 }) => ({
  paddingLeft: `${pl}px`,
}))(TextPure);

const TextLeft = withCSS(({ pr = 0 }) => ({
  paddingRight: `${pr}px`,
}))(TextPure);

const Text = branched(({ position }) => position, TextLeft)(TextRight);

const TextContainer = createDiv('TextContainer', {
  width: '100%',
  position: 'absolute',
});

function getCoords(language, coords, layoutType) {
  if (['en'].includes(language)) return coords.en[layoutType];
  if (['cn'].includes(language)) return coords.cn[layoutType];
  return coords.ru[layoutType];
}

const Hardware = withCSS(({ layoutType, language }) => {
  const coords = {
    ru: {
      xl: {
        top: '345px',
        left: '-40px',
      },
      lg: {
        top: '285px',
        left: '-65px',
      },
    },
    en: {
      xl: {
        top: '345px',
        left: '57px',
      },
      lg: {
        top: '285px',
        left: '23px',
      },
    },
    cn: {
      xl: {
        top: '345px',
        left: '85px',
      },
      lg: {
        top: '285px',
        left: '55px',
      },
    },
  };
  return ({
    position: 'absolute',
    textAlign: 'right',
    ...getCoords(language, coords, layoutType),
  });
})(TextBox);

const OS = withCSS(({ layoutType, language }) => {
  const coords = {
    ru: {
      xl: {
        top: '210px',
        left: '31px',
      },
      lg: {
        top: '170px',
        left: '-5px',
      },
    },
    en: {
      xl: {
        top: '210px',
        left: '31px',
      },
      lg: {
        top: '170px',
        left: '-5px',
      },
    },
    cn: {
      xl: {
        top: '210px',
        left: '180px',
      },
      lg: {
        top: '170px',
        left: '130px',
      },
    },
  };
  return ({
    position: 'absolute',
    textAlign: 'right',
    ...getCoords(language, coords, layoutType),
  });
})(TextBox);

const Browser = withCSS(({ layoutType, language }) => {
  const coords = {
    ru: {
      xl: {
        top: '72px',
        left: '-10px',
      },
      lg: {
        top: '54px',
        left: '-45px',
      },
    },
    en: {
      xl: {
        top: '72px',
        left: '-10px',
      },
      lg: {
        top: '54px',
        left: '-45px',
      },
    },
    cn: {
      xl: {
        top: '72px',
        left: '170px',
      },
      lg: {
        top: '54px',
        left: '125px',
      },
    },
  };
  return ({
    position: 'absolute',
    textAlign: 'right',
    ...getCoords(language, coords, layoutType),
  });
})(TextBox);

const NoBrowser = withCSS(({ layoutType }) => {
  const coord = {
    xl: {
      top: '45px',
      left: '650px',
    },
    lg: {
      top: '30px',
      left: '542px',
    },
  };
  return ({
    position: 'absolute',
    textAlign: 'left',
    ...coord[layoutType],
  });
})(TextBox);

const Telemetry = withCSS(({ layoutType }) => {
  const coord = {
    xl: {
      top: '185px',
      left: '730px',
    },
    lg: {
      top: '150px',
      left: '613px',
    },
  };
  return ({
    position: 'absolute',
    textAlign: 'left',
    ...coord[layoutType],
  });
})(TextBox);

import layout from './layout';
import common from './common';
import main from './main';
import proxy from './proxy';
import vds from './vds';
import login from './login';
import registration from './registration';
import forgotPassword from './forgotPassword';
import passwordSign from './passwordSign';
import dialogPages from './dialogPages';
import recoverPassword from './recoverPassword';
import support from './support';
import bet_storm from './bet_storm';
import affiliate from './affiliate';
import confirmationAccount from './confirmationAccount';
import unsubscribe from './unsubscribe';

export default {
  ...layout,
  ...common,
  ...main,
  ...proxy,
  ...vds,
  ...login,
  ...registration,
  ...forgotPassword,
  ...passwordSign,
  ...dialogPages,
  ...recoverPassword,
  ...support,
  ...bet_storm,
  ...affiliate,
  ...confirmationAccount,
  ...unsubscribe
};
import React from 'react';
import { createDiv } from '@nonlux/react-common';

const visa = require('../icon/visa.png');
const mastercard = require('../icon/mastercard.png');
const qiwi = require('../icon/qiwi.png');
const eth = require('../icon/eth.png');

const PaymentContainer = createDiv('PaymentMethods', {
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  gridGap: '50px',
  marginBottom: '60px',
  margin: '40px auto 0',
  userSelect: 'none'
});

export const PaymentSystems = () => (
  <PaymentContainer>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={visa} alt="logo" style={{ height: '35px' }} />
    </div>
    <div>
      <img src={mastercard} alt="logo" style={{ height: '40px' }} />
    </div>
    <div>
      <img src={qiwi} alt="logo" style={{ height: '40px' }} />
    </div>
    <div style={{
      display: 'flex', flexWrap: 'nowrap', gridGap: '10px', alignItems: 'center'
    }}
    >
      <img src={eth} alt="logo" style={{ height: '40px' }} />
      <div style={{ color: '#738496', userSelect: 'none' }}>
        Ethereum
      </div>
    </div>
  </PaymentContainer>
);

import React, { Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import STYLES from '../../styles/constants';

const pug = require('./img/pug.png');

export default function UnsubscribePage(props) {
  const [reason, setReason] = useState(null);

  function parseQuery(queryString) {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const queryData = parseQuery(location.search.split('?')[1] || '');
      await axios.post('/api/broadcasts/unsubscribe', {userId: queryData.id, reason})

      props.history.push('/unsubscribe_success')
    } catch (e) {
      console.error(e);
    }
  }

  const { t } = useTranslation();
  return (
    <Fragment>
      <Hero>
        <Img flex src={pug} alt="Sad pug" />
      </Hero>
      <Container>
        <Box pt={60} pb={60}>
          <Title>
            {t('unsubscribe.title')}
          </Title>
        </Box>
      </Container>
      <Container>
        <Box>
          <form onSubmit={handleSubmit}>
            <Radio>
              <Input type="radio" id="answer_1" name="unsubOpt" onChange={() => setReason('Слишком много писем')} />
              <Label htmlFor="answer_1">
                {t('unsubscribe.answers.Слишком много писем')}
              </Label>
            </Radio>
            <Radio>
              <Input type="radio" id="answer_2" name="unsubOpt" onChange={() => setReason('Неуместные материалы')} />
              <Label htmlFor="answer_2">
                {t('unsubscribe.answers.Неуместные материалы')}
              </Label>
            </Radio>
            <Radio>
              <Input type="radio" id="answer_3" name="unsubOpt" onChange={() => setReason('Не интересно')} />
              <Label htmlFor="answer_3">
                {t('unsubscribe.answers.Не интересно')}
              </Label>
            </Radio>
            <Radio>
              <Input type="radio" id="answer_4" name="unsubOpt" onChange={() => setReason('Не хочу получать информацию')} />
              <Label htmlFor="answer_4">
                {t('unsubscribe.answers.Не хочу получать информацию')}
              </Label>
            </Radio>
            <Radio>
              <Input type="radio" id="answer_5" name="unsubOpt" value="OTHER" onChange={() => setReason('Другое')} />
              <Label htmlFor="answer_5">
                {t('unsubscribe.answers.Другое')}
              </Label>
            </Radio>
            <TextArea name="textarea" rows="3" disabled={!(reason || '').includes('Другое')}
                      onKeyUp={e => setReason(`Другое${e.target.value ? `: ${e.target.value}` : ''}`)} />
            <Box pt={20} pb={40}>
              <Button>
                {t('unsubscribe.action')}
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
    </Fragment>
  );
}


const Hero = styled.div`
  display: flex;
  margin-top: 55px;
  justify-content: center;
  padding-top: 40px;
`;

const Container = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: center;
  background-color: #20232a;
  margin-left: -15px;
  margin-right: -15px;
`;

const Box = styled.div`
  flex-grow: 1;
  max-width: 940px;
  ${({ pt }) => pt && css`padding-top: ${pt}px;`};
  ${({ pb }) => pb && css`padding-bottom: ${pb}px;`};
`;

const Img = styled.img`
  ${({ flex }) => flex && css`max-width: 100%;`};
`;

const H2 = styled.h2`
  font-weight: 300;
  color: #fff;
  font-size: ${({ layoutType }) => {
    if (['xl', 'lg'].includes(layoutType)) return 28;
    return 24;
  }}px;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(H2);

const Radio = styled.div`
  display:flex;
  align-items: center;
`;

const Input = styled.input`
  width: 0;
  height: 0;
  pointer-events: none;
  position: absolute;
  opacity: 0;
  outline: none;
`;

const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  outline: none;
  padding: 15px;
`;

const Label = styled.label`
  ${Input} + & {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    line-height: 50px;
    color: #fff;
    user-select: none;
  }
  ${Input} + &::before {
    content: "";
    display: inline-block;
    height: 30px;
    width: 30px;
    background: #fff;
    border-radius: 50px;
    margin-right: 16px;
  }
  ${Input}:checked + &::after {
    content: "";
    display: block;
    height: 18px;
    width: 18px;
    background: #20232a;
    border: none;
    position: absolute;
    top: 16px;
    left: 6px;
    border-radius: 50px;
 
  }
`;

const Button = styled.button`
  padding: 10px 25px;
  background-color: ${STYLES.COLORS.BLUE};
  color: ${STYLES.COLORS.BLACK};
  border: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${STYLES.COLORS.WHITE};
    color: ${STYLES.COLORS.BLACK};
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { toastr } from 'react-redux-toastr';
import STYLES from '../../style';

const Dropdown = ({
  setIsOpenDropdown, isOpenDropdown, options = [], setFunction, altData = [], controlled, geo = 'uk', value, available, tariff
}) => {
  const [currentTitle, setCurrentTitle] = useState(options[0]);
  const [disabled, setDisabled] = React.useState(false);
  useEffect(() => {
    if (!controlled) {
      setCurrentTitle(options[altData.indexOf(value) === -1 ? 0 : altData.indexOf(value)]);
      setFunction(altData[options.indexOf(currentTitle)]);
    }
    if (controlled) {
      altData.includes(geo) && available[altData.indexOf(geo)]
        ? setCurrentTitle(options[altData.indexOf(geo)])
        : setCurrentTitle('—')
    }
  }, [geo, value, tariff]);

  useEffect(() => {
    setFunction(altData[options.indexOf(currentTitle) === -1 ? 0 : options.indexOf(currentTitle)]);
    if (options && options.length <= 1) setDisabled(true);
    else setDisabled(false);
  }, [options, currentTitle]);

  return (
    <DropdownContainer onClick={() => (disabled ? null : setIsOpenDropdown(!isOpenDropdown))}>
      <DropdownHead>
        <DropdownCurrent>
          <DropdownCurrentTitle>
            {currentTitle}
          </DropdownCurrentTitle>
        </DropdownCurrent>
        {
                    disabled
                      ? null
                      : (
                        <DropdownIcon>
                          {isOpenDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </DropdownIcon>
                      )
                }
      </DropdownHead>
      {isOpenDropdown && (
        <DropdownOptions options={options}>
          <DropdownOptionList
            options={options}
            setCurrentTitle={setCurrentTitle}
            currentTitle={currentTitle}
            available={available}
          />
        </DropdownOptions>
      )}
    </DropdownContainer>
  );
};
export default Dropdown;


const DropdownContainer = styled.div`
    display: block;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    -webkit-user-select: none;
    user-select: none;
    z-index: 10;
`;

const DropdownHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 15px 13px 20px;
    background: #fff;
    border: 1px solid #e9e9e9;
    cursor: pointer;
    color: #000;
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
`;

const DropdownCurrent = styled.div`
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const DropdownCurrentTitle = styled.div`
    display: inline;
`;

const DropdownIcon = styled.div`
    flex: 0 0 auto;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    position: relative;
    font-size: 15px;
`;

const DropdownOptions = styled.div`
    display: block;
    position: absolute;
    ${({ options }) => options.length > 5
  && css`
    overflow: auto;
    height: 220px;
    `};
    left: 0;
    top: 100%;
    margin-top: 5px;
    width: 100%;
    background: #fff;
    box-shadow: 0 4px 24px rgb(0 0 0 / 5%);
`;

const DropdownOption = styled.button`
    text-align: left;
    padding: 10px 20px;
    background: #fff;
    color: #000;
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
      &:hover {
          outline: none;
      }
      &:focus {
          outline: none;
      }
      &:active {
          outline: none;
      }
`;

const DropdownOptionText = styled.div`
    &:hover {
      ${({ available }) => available
              && css`
        color: ${STYLES.COLORS.BLUE};
      `};
    }
        ${({ isActive }) => isActive
  && css`
        color: ${STYLES.COLORS.BLUE};
      `};
  ${({ available }) => !available
          && css`
        color: ${STYLES.COLORS.RED};
      `};
`;

const DropdownOptionList = ({
  options, isDefault = 0, setCurrentTitle, currentTitle, available
}) => options.map((option, index) => {
  const [isActive, setIsActive] = React.useState(isDefault);
  useEffect(() => {
    setIsActive(options.indexOf(currentTitle));
  }, [currentTitle]);
  return (
    <DropdownOption key={`${option + index}`}>
      <DropdownOptionText
        available={available[index]}
        isActive={isActive === index}
        onClick={available[index] === true ? () => { setCurrentTitle(option); } : () => { toastr.error('Нет в наличии, закажите в поддержке изготовление'); }}
      >
        {option}
      </DropdownOptionText>
    </DropdownOption>
  );
});

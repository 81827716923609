const MODAL_TYPES = {
  REMOVE: 'remove',
  DEACTIVATE_AND_REMOVE_PROFILE: 'deactivate and remove profile',
  BUY_PROFILE_TARIFF: 'Buy profile tariff',
  TEST_PROFILE_TARIFF: 'Test profile tariff',
  BUT_SYNCS: 'Buy syncs',
  BUY_DEVICES: 'Buy devices',
  NOT_ENOUGH_MONEY: 'Not enough money',
  TARIFF_DOWNGRADE_IS_NOT_ALLOWED: 'Tariff downgrade is not allowed',
  MAP_FOR_GPS: 'Map for GPS',
  BUY_PROXY: 'Buy proxy',
  BUY_VDS: 'Buy VDS'
};

export default MODAL_TYPES;

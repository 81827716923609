const crypto = require('crypto')

/*
 * использование анонимной функции вместо контекстной
 */
export function withThis(callback) {
  return function contextContainer(...args) {
    const context = this;
    return callback(context, ...args);
  };
}

/*
 * Первая буква заглавная
 */
export function fstLtr(str) {
  return str.substr(0, 1).toUpperCase() + str.substr(1);
}


export function toInt(x) {
  return parseInt(parseFloat(x).toFixed(0), 10);
}

/*
 * Создание именованной ошибки
 */
export function createStringError(name, defaultMessage = '') {
  const Err = function Err(message) {
    this.name = name;
    if (message) {
      this.message = message;
    } else {
      this.message = defaultMessage;
    }
    const nextError = new Error(this.message);
    nextError.name = this.name;
    this.stack = nextError.stack;
  };
  Err.prototype = Object.create(Error.prototype);
  return Err;
}

/*
 * abstractMethod - создает абстрактную функцию  для класса
 */
export const AbstractMethodError = createStringError('AbstractMethodError');
export function abstractMethod(obj, method, isAsync = true) {
  const msg = `Method ${method} not implemented`;
  if (isAsync) {
    /* eslint-disable-next-line no-param-reassign */
    obj.prototype[method] = async () => {
      const err = new AbstractMethodError(msg);
      throw err;
    };
  } else {
    /* eslint-disable-next-line no-param-reassign */
    obj.prototype[method] = () => {
      const err = new AbstractMethodError(msg);
      throw err;
    };
  }
}

/*
 * простой хеш из строки
 */
export function hashCode(str) {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    /* eslint-disable-next-line no-bitwise */
    hash = ((hash << 5) - hash) + char;
    /* eslint-disable-next-line no-bitwise */
    hash &= hash;
  }
  return hash;
}

/*
 * парсер параметров из queryString
 */
export function getQueryParams(search) {
  const res = {};
  const nextSearch = search.substr(1);
  nextSearch.split('&').forEach(item => {
    const [key, value] = item.split('=');
    res[key] = value;
  });
  return res;
}

/*
 * создает url  с queryString  из объекта
 */
export function resolveUrlString(url, resolvedParams) {
  const paramStr = Object.entries(resolvedParams).map(item => item.join('=')).join('&');
  return `${url}?${paramStr}`;
}

export function msToSec(time) {
  return time / 1000;
}

export function msToMin(time) {
  return msToSec(time / 60);
}

export function msToHour(time) {
  return msToMin(time / 60);
}

export function createTimer() {
  const startTime = Date.now();
  return () => {
    const res = Date.now() - startTime;
    return msToSec(res);
  };
}


/*
 * добавляейт элементы в  html и прописывает им атрибуты
 */
// добавить проверку на массив и реализовать функционал перебора
// объекта (если один атрбут то не массив закидывать а объект)

export async function addElementsInHtml(elm, atr, scr) {
  const e = document.createElement(elm);
  atr.map((item) => {
    for (const prop in item) {
      if ({}.hasOwnProperty.call(item, prop)) {
        e.setAttribute(`${prop}`, item[prop]);
      }
      if (scr) {
        e.innerHTML = scr;
      }
    }
  });
  await document.body.appendChild(e);
}


/*
 * задает координаты якорным ссылкам
 */
export const scrollWithOffset = (el, offset, behavior) => {
  const elementPosition = el.offsetTop - offset;
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior,
  });
};

/*
 * Hashing algorithm sha256
 */

export const sha256 = (password, salt) => {
  const hash = crypto.createHmac('sha256', salt);
  hash.update(password);
  const value = hash.digest('hex');
  return value;
};

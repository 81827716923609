import React from 'react';
import { Container } from 'reactstrap';
import { createDiv } from '@nonlux/react-common';
import STYLES from '../../styles/constants.js';

export const Border = createDiv('Border', ({ mt = 0 }) => ({
  borderBottom: `1px solid ${STYLES.COLORS.WHITE_GRAY}`,
  marginTop: `${mt}px`
}));

export const BorderContainer = ({ mt }) => (
  <Container>
    <Border mt={mt} />
  </Container>
);
import React, { Fragment } from 'react';

import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  Form,
  Label as BaseLabel,
  FormGroup
} from 'reactstrap';
import { ButtonPrimaryLight, CheckBox } from '@nonlux/ab-ui';
import { Input } from 'components';
import { Link, withRouter } from 'react-router-dom';
import { withFormikConnected } from '@nonlux/react-common/formik';
import { css } from 'aphrodite';
import { withCSS } from '@nonlux/react-common/composable';
import { emailRegex, passwordRegex } from '@nonlux/utils/regexp';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { selectRegErrors } from 'reducer/sign/selectors';
import { regDataSendAction, clearRegErrorsAction } from 'reducer/sign/actions';
import fp from '@fingerprintjs/fingerprintjs';
import { styles } from './styles';

import icon from './icon/select-arrow.svg';
import { options } from './options';


const Label = withCSS({
  userSelect: 'none',
  cursor: 'pointer'
}, true)(BaseLabel);

const Select = styled.select`
  -webkit-appearance: none;
  /* for FF */
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
  
  height: calc(1.5em + 0.75rem + 2px);
  width: 100%;
  padding: 0.375rem 0.75rem;
  
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: url('${icon}') right 0.75rem center no-repeat #FFFFFF;
  
  line-height: 1.5;
  color: #495057;
  
  cursor: pointer;
  
  &:focus {
    outline: none;
  }
`;

export default function RegistrationPage() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Row className={css(styles.registration_form__text_box)}>
        <Col className="text-center">
          <h1 className={css(styles.registration_form__label)}>
            {t('registration.title')}
          </h1>
          <h2 className={css(styles.registration_form__title)}>
            {t('registration.label')}
          </h2>
        </Col>
      </Row>
      <Row className={css(styles.registration_form__box)}>
        <Container className={css(styles.registration_form__container)}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <RegistrationFormConnected />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="text-center">
              <p className={css(styles.registration_form__text)}>
                {t('registration.loginPage.text')}
                &nbsp;
                <Link className={css(styles.registration_form__link)} to="/sign_in">
                  {t('registration.loginPage.link')}
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </Row>
    </Fragment>
  );
}

const RegistrationForm = (props) => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    location
  } = props;
  const { t } = useTranslation();
  const errors = {
    ...props.serverErrors,
    ...props.errors
  };
  const { pathname } = location;

  return (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="firstName">
        {t('registration.personalData.title')}
      </Label>
      <hr className={css(styles.registration_form__line)} />
      <Row>
        <Col>
          <Input
            style={{
              boxShadow: 'none',
              border: '1px solid #ced4da'
            }}
            type="text"
            name="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            className={errors.firstName && touched.firstName ? 'text-input error' : 'text-input'}
            placeholder={t('registration.personalData.placeholder.name')}
          />
          {touched.firstName && errors.firstName
          && (
          <div className={css(styles.input__feedback)}>
            {t(errors.firstName)}
          </div>
          )}
        </Col>
        <Col>
          <Input
            style={{
              boxShadow: 'none',
              border: '1px solid #ced4da'
            }}
            type="text"
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            className={errors.lastName && touched.lastName ? 'text-input error' : 'text-input'}
            placeholder={t('registration.personalData.placeholder.lastName')}
          />
          {touched.lastName && errors.lastName
          && (
          <div className={css(styles.input__feedback)}>
            {t(errors.lastName)}
          </div>
          )}
        </Col>
      </Row>

      <Label htmlFor="email" className="mt-3">
        {t('registration.email.title')}
      </Label>
      <hr className={css(styles.registration_form__line)} />
      <Row>
        <Col>
          <small className="text-muted">
            {t('registration.email.label')}
          </small>
          <Input
            style={{
              boxShadow: 'none',
              border: '1px solid #ced4da'
            }}
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            className={errors.email && touched.email ? 'text-input error' : 'text-input'}
            placeholder={t('registration.email.placeholder.email')}
            autoComplete="email"
          />
          {touched.email && (errors.email || errors.emailDubl)
          && (
          <div className={css(styles.input__feedback)}>
            {t(errors.email || errors.emailDubl)}
          </div>
          )}
        </Col>
      </Row>

      <Label htmlFor="password" className="mt-3">
        {t('registration.password.title')}
      </Label>
      <hr className={css(styles.registration_form__line)} />
      <small className="text-muted">
        {t('registration.password.label')}
      </small>
      <Row>
        <Col>
          <Input
            style={{
              boxShadow: 'none',
              border: '1px solid #ced4da'
            }}
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            className={errors.password && touched.password ? 'text-input error' : 'text-input'}
            placeholder={t('registration.password.placeholder.pass')}
            autoComplete="new-password"
          />
          {touched.password && errors.password
          && (
          <div className={css(styles.input__feedback)}>
            {t(errors.password)}
          </div>
          )}
        </Col>
        <Col>
          <Input
            style={{
              boxShadow: 'none',
              border: '1px solid #ced4da'
            }}
            type="password"
            name="passwordConfirm"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirm}
            className={errors.passwordConfirm && touched.passwordConfirm ? 'text-input error' : 'text-input'}
            placeholder={t('registration.password.placeholder.confirmPass')}
            autoComplete="new-password"
          />
          {touched.passwordConfirm && errors.passwordConfirm
          && (
          <div className={css(styles.input__feedback)}>
            {t(errors.passwordConfirm)}
          </div>
          )}
        </Col>
      </Row>

{/*      <Label htmlFor="country" className="mt-3">
        {t('Страна')}
      </Label>
      <hr className={css(styles.registration_form__line)} />
      <Row>
        <Col>
          <Select
            name="country"
            id="country"
            value={values.country}
            onChange={handleChange}
          >
            {options.map(option => (
              <option
                key={`${option.value}`}
                value={option.value}
              >
                {option.content}
              </option>
            ))}
          </Select>
        </Col>
      </Row>*/}

      {['/affiliate/sign_up'].includes(pathname) && (
        <Fragment>
          <Input type="hidden" name="isPartner" id="isPartnerInput" value={1} />
          <Label htmlFor="url" className="mt-3">
            {t('registration.url.title')}
          </Label>
          <hr className={css(styles.registration_form__line)} />
          <Row>
            <Col>
              <small className="text-muted">
                {t('registration.url.label')}
              </small>
              <Input
                style={{
                  boxShadow: 'none',
                  border: '1px solid #ced4da'
                }}
                type="url"
                name="url"
                pattern="https://.*"
                size="30"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                className={errors.url && touched.url ? 'text-input error' : 'text-input'}
                placeholder="https://example.com"
                autoComplete="url"
              />
              {touched.url && errors.url
              && (
                <div className={css(styles.input__feedback)}>
                  {t(errors.url)}
                </div>
              )}
            </Col>
          </Row>

          <Label htmlFor="transmittalLetter" className="mt-3">
            {t('registration.transmittalLetter.title')}
          </Label>
          <hr className={css(styles.registration_form__line)} />
          <Row>
            <Col>
              <small className="text-muted">
                {t('registration.transmittalLetter.label')}
              </small>
              <ChatInputText
                tabIndex={0}
                contentEditable
                role="textbox"
                aria-multiline
                id="transmittalLetter"
                type="text"
                name="transmittalLetter"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.transmittalLetter}
                className={errors.transmittalLetter && touched.transmittalLetter ? 'text-input error' : 'text-input'}
              />
              {touched.transmittalLetter && errors.transmittalLetter
              && (
                <div className={css(styles.input__feedback)}>
                  {t(errors.transmittalLetter)}
                </div>
              )}
            </Col>
          </Row>
        </Fragment>
      )}

      <FormGroup check className="mt-3">
        <CheckBox
          type="checkbox"
          name="allowPersonal"
          id="allowPersonal"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.allowPersonal}
        />
        <Label className="form-check-label" htmlFor="allowPersonal">
          {t('registration.userConsent.saveData')}
        </Label>
        {touched.allowPersonal && errors.allowPersonal
          && (
          <div className={css(styles.input__feedback)}>
            {t(errors.allowPersonal)}
          </div>
          )}
      </FormGroup>
      <FormGroup check className="pb-3">
        <CheckBox
          type="checkbox"
          name="allowTerms"
          id="allowTerms"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.allowTerms}
        />
        <Label className="form-check-label" htmlFor="allowTerms">
          {t('registration.userConsent.termsUser.text')}
          &nbsp;
          <Link to="/terms" className={css(styles.registration_form__link)}>
            {t('registration.userConsent.termsUser.link')}
          </Link>
          {touched.allowTerms && errors.allowTerms
          && (
          <div className={css(styles.input__feedback)}>
            {t(errors.allowTerms)}
          </div>
          )}
        </Label>
        {errors.ipOrFp && (
        <div className={css(styles.input__feedback)}>
          {t(errors.ipOrFp)}
        </div>
        )}
      </FormGroup>

      {['/affiliate/sign_up'].includes(pathname) ? (
        <ButtonPrimaryLight size="lg" block className="rounded-0">
          {t('Отправить заявку')}
        </ButtonPrimaryLight>
      ) : (
        <ButtonPrimaryLight size="lg" block className="rounded-0">
          {t('registration.buttonReg')}
        </ButtonPrimaryLight>
      )}
    </Form>
  );
};

const ChatInputText = styled.textarea`
  padding: 8px;
  :focus {
    outline: 0 solid transparent;
  }
  overflow-y: scroll;
  min-height: 100px;
  max-height: 400px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%
`;

const errorsMessages = {
  email: 'registration.errorsMessages.email',
  password: 'registration.errorsMessages.password',
  passwordConfirm: 'registration.errorsMessages.passwordConfirm',
  firstName: 'registration.errorsMessages.firstName',
  lastName: 'registration.errorsMessages.lastName',
  allowPersonal: 'registration.errorsMessages.allowPersonal',
  allowTerms: 'registration.errorsMessages.allowTerms',
  emailDubl: 'registration.errorsMessages.emailDubl',
  url: 'registration.errorsMessages.url',
  transmittalLetter: 'registration.errorsMessages.transmittalLetter',
  ipOrFp: 'registration.errorsMessages.ipOrFp',
  country: 'registration.errorsMessages.country'
};

const RegistrationFormConnected = withFormikConnected({
  mapPropsToValues: () => ({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    passwordConfirm: '',
    allowTerms: false,
    allowPersonal: false,
    url: '',
    transmittalLetter: '',
    ipOrFp: '',
    country: ''

    /*
    email: 'ererwj@wdssn.rt',
    password: '111111qA',
    firstName: 'dsdfsdf',
    lastName: 'dfsdfdf',
    passwordConfirm: '111111qA',
    allowTerms: true,
    allowPersonal: true,
    */
  }),
  validate: (values, { dispatch }) => {
    console.log('validate', values)
    dispatch(clearRegErrorsAction());
    const errors = {};
    const isPartnerInput = document.getElementById('isPartnerInput');
    // eslint-disable-next-line no-param-reassign
    values.isPartner = isPartnerInput ? isPartnerInput.value : '';

    if (!values.email || !emailRegex.test(values.email)) {
      errors.email = errorsMessages.email;
    }
    if (!values.password || values.password.length < 8
      || !passwordRegex.test(values.password)
    ) {
      errors.password = errorsMessages.password;
    }
    if (!values.passwordConfirm
        || (values.passwordConfirm !== values.password)
    ) {
      errors.passwordConfirm = errorsMessages.passwordConfirm;
    }
    if (!values.firstName) {
      errors.firstName = errorsMessages.firstName;
    }
    if (!values.lastName) {
      errors.lastName = errorsMessages.lastName;
    }
    if (!values.allowPersonal) {
      errors.allowPersonal = errorsMessages.allowPersonal;
    }
    if (!values.allowTerms) {
      errors.allowTerms = errorsMessages.allowTerms;
    }
    if (values.isPartner) {
      if (!values.url) {
        errors.url = errorsMessages.url;
      }
      if (!values.transmittalLetter) {
        errors.transmittalLetter = errorsMessages.transmittalLetter;
      }
    }
    // if(!values.country) {
    //   errors.country = errorsMessages.country;
    // }
    console.log(errors)

    return errors;
  },
  async handleSubmit(values, { props: { dispatch } }) {
    console.log('handleSubmit', values)
    const onRegistrationBuy = localStorage.getItem('onRegistrationBuyVds') ? 'vds' : localStorage.getItem('onRegistrationBuyProxy') ? 'proxy' : null;
    const components = await fp.getPromise({ fonts: { extendedJsFonts: true } });
    const fpHash = fp.x64hash128(components.map(component => component.value).join(''), 31);
    dispatch(regDataSendAction({ ...values, fp: fpHash, onRegistrationBuy }));
  }
}, state => ({
  serverErrors: selectRegErrors(state).reduce((acc, next) => {
    if (Array.isArray(next)) {
      const [key, msgKey] = next;
      return {
        ...acc,
        [key]: errorsMessages[msgKey]
      };
    }
    return {
      ...acc,
      [next]: errorsMessages[next]
    };
  }, {})
}))(withRouter(RegistrationForm));

import React, { Fragment } from 'react';

import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  Form,
  Label as BaseLabel,
  FormGroup,
} from 'reactstrap';
import { ButtonPrimaryLight, CheckBox } from '@nonlux/ab-ui';
import { Input } from 'components';
import { Link } from 'react-router-dom';
import { withFormikConnected } from '@nonlux/react-common/formik';
import { css } from 'aphrodite';
import { withCSS } from '@nonlux/react-common/composable';
import { emailRegex, passwordRegex } from '@nonlux/utils/regexp';


import { selectRegErrors } from 'reducer/sign/selectors';
import { applyRegDataSendAction, clearRegErrorsAction } from 'reducer/sign/actions';
import fp from '@fingerprintjs/fingerprintjs';
import { styles } from './RegistrationPage/styles';

const Label = withCSS({
  userSelect: 'none',
  cursor: 'pointer',
}, true)(BaseLabel);

export default function RegistrationPage() {
  return (
    <Fragment>
      <Row className={css(styles.registration_form__text_box)}>
        <Col className="text-center">
          <h1 className={css(styles.registration_form__label)}>
            Подтвеждение регистрации
          </h1>
          <h2 className={css(styles.registration_form__title)}>
            Это займет меньше минуты.
          </h2>
        </Col>
      </Row>
      <Row className={css(styles.registration_form__box)}>
        <Container className={css(styles.registration_form__container)}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <RegistrationFormConnected />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Row>
    </Fragment>
  );
}

const RegistrationForm = (props) => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;
  const errors = {
    ...props.serverErrors,
    ...props.errors,
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Label htmlFor="firstName">
        Персональные данные
      </Label>
      <hr className={css(styles.registration_form__line)} />
      <Row>
        <Col>
          <Input
            type="text"
            name="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            className={errors.firstName && touched.firstName ? 'text-input error' : 'text-input'}
            placeholder="Имя"
          />
          {touched.firstName && errors.firstName
          && (
          <div className={css(styles.input__feedback)}>
            {errors.firstName}
          </div>
          )}
        </Col>
        <Col>
          <Input
            type="text"
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            className={errors.lastName && touched.lastName ? 'text-input error' : 'text-input'}
            placeholder="Фамилия"
          />
          {touched.lastName && errors.lastName
          && (
          <div className={css(styles.input__feedback)}>
            {errors.lastName}
          </div>
          )}
        </Col>
      </Row>

      <Label htmlFor="email" className="mt-3">
        Адрес электронной почты
      </Label>
      <hr className={css(styles.registration_form__line)} />
      <Row>
        <Col>
          <small className="text-muted">
            Используемый для входа в свой аккаунт.
          </small>
          <Input
            type="email"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            className={errors.email && touched.email ? 'text-input error' : 'text-input'}
            placeholder="Адрес электронной почты"
            autoComplete="email"
          />
          {touched.email && errors.email
          && (
          <div className={css(styles.input__feedback)}>
            {errors.email}
          </div>
          )}
        </Col>
      </Row>

      <Label htmlFor="password" className="mt-3">
        Пароль
      </Label>
      <hr className={css(styles.registration_form__line)} />
      <small className="text-muted">
        Должен содержать минимум 8 символов включая хотя бы 1 цифру и 1 заглавную латинскую букву.
      </small>
      <Row>
        <Col>
          <Input
            type="password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            className={errors.password && touched.password ? 'text-input error' : 'text-input'}
            placeholder="Пароль"
            autoComplete="new-password"
          />
          {touched.password && errors.password
          && (
          <div className={css(styles.input__feedback)}>
            {errors.password}
          </div>
          )}
        </Col>
        <Col>
          <Input
            type="password"
            name="passwordConfirm"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.passwordConfirm}
            className={errors.passwordConfirm && touched.passwordConfirm ? 'text-input error' : 'text-input'}
            placeholder="Подтвердить пароль"
            autoComplete="new-password"
          />
          {touched.passwordConfirm && errors.passwordConfirm
          && (
          <div className={css(styles.input__feedback)}>
            {errors.passwordConfirm}
          </div>
          )}
        </Col>
      </Row>

      <FormGroup check className="mt-3">
        <CheckBox
          type="checkbox"
          name="allowPersonal"
          id="allowPersonal"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.allowPersonal}
        />
        <Label className="form-check-label" htmlFor="allowPersonal">
          Я даю свое согласие на сбор, обработку и хранение моих персональных данных
        </Label>
        {touched.allowPersonal && errors.allowPersonal
          && (
          <div className={css(styles.input__feedback)}>
            {errors.allowPersonal}
          </div>
          )}
      </FormGroup>
      <FormGroup check className="pb-3">
        <CheckBox
          type="checkbox"
          name="allowTerms"
          id="allowTerms"
          onChange={handleChange}
          onBlur={handleBlur}
          checked={values.allowTerms}
        />
        <Label className="form-check-label" htmlFor="allowTerms">
          Я прочитал(-а) и соглашаюсь с&nbsp;
          <Link to="/terms" className={css(styles.registration_form__link)}>
            Пользовательское соглашение.
          </Link>
          {touched.allowTerms && errors.allowTerms
          && (
          <div className={css(styles.input__feedback)}>
            {errors.allowTerms}
          </div>
          )}
        </Label>
      </FormGroup>
      <ButtonPrimaryLight size="lg" block>
        Зарегистрировать аккаунт
      </ButtonPrimaryLight>
    </Form>
  );
};

const errorsMessages = {
  email: 'Укажите действительный адрес электронной почты.',
  password: 'Пароль должен содержать минимум 8 символов включая хотя бы 1 цифру и 1 заглавную латинскую букву.',
  passwordConfirm: 'Введенные пароли не совпадают.',
  firstName: 'Необходимо ввести имя.',
  lastName: 'Необходимо ввести фамилию.',
  allowPersonal: 'Необходимо подтвердить.',
  allowTerms: 'Необходимо подтвердить.',
  emailDubl: 'Адрес уже занят.',
};

const RegistrationFormConnected = withFormikConnected({
  mapPropsToValues: () => ({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    passwordConfirm: '',
    allowTerms: false,
    allowPersonal: false,

    /*
    email: 'ererwj@wdssn.rt',
    password: '111111qA',
    firstName: 'dsdfsdf',
    lastName: 'dfsdfdf',
    passwordConfirm: '111111qA',
    allowTerms: true,
    allowPersonal: true,
    */
  }),
  validate: (values, { dispatch }) => {
    dispatch(clearRegErrorsAction());
    const errors = {};
    if (!values.email || !emailRegex.test(values.email)) {
      errors.email = errorsMessages.email;
    }
    if (!values.password || values.password.length < 8
      || !passwordRegex.test(values.password)
    ) {
      errors.password = errorsMessages.password;
    }
    if (!values.passwordConfirm
        || (values.passwordConfirm !== values.password)
    ) {
      errors.passwordConfirm = errorsMessages.passwordConfirm;
    }
    if (!values.firstName) {
      errors.firstName = errorsMessages.firstName;
    }
    if (!values.lastName) {
      errors.lastName = errorsMessages.lastName;
    }
    if (!values.allowPersonal) {
      errors.allowPersonal = errorsMessages.allowPersonal;
    }
    if (!values.allowTerms) {
      errors.allowTerms = errorsMessages.allowTerms;
    }
    return errors;
  },
  async handleSubmit(values, { props: { dispatch } }) {
    const components = await fp.getPromise({ fonts: { extendedJsFonts: true } });
    const fpHash = fp.x64hash128(components.map(component => component.value).join(''), 31);
    dispatch(applyRegDataSendAction({ ...values, fp: fpHash }));
  },
}, state => ({
  serverErrors: selectRegErrors(state).reduce((acc, next) => {
    if (Array.isArray(next)) {
      const [key, msgKey] = next;
      return {
        ...acc,
        [key]: errorsMessages[msgKey],
      };
    }
    return {
      ...acc,
      [next]: errorsMessages[next],
    };
  }, {}),
}))(RegistrationForm);

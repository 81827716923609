import { Button } from 'reactstrap';
import { withCSS } from '@nonlux/react-common/composable';

export const ButtonPrimaryDark = withCSS({
  borderColor: '#61dafb',
  backgroundColor: '#61dafb',
  color: 'black',
  boxShadow: 'none',
  ':hover': {
    borderColor: 'white',
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none',
  },
  ':active': {
    backgroundColor: '#56c7e4',
    color: 'white',
    boxShadow: 'none',
  },
  ':focus': {
    boxShadow: 'none',
  },
}, true)(Button);

export const ButtonSecondaryDark = withCSS({
  borderColor: '#fff',
  backgroundColor: '#fff',
  color: 'black',
  boxShadow: 'none',
  ':hover': {
    borderColor: '#fff',
    backgroundColor: '#fff',
    color: 'black',
    boxShadow: 'none',
  },
  ':active': {
    borderColor: '#fff',
    backgroundColor: '#fff',
    color: 'black',
    boxShadow: 'none',
  },
  ':focus': {
    borderColor: '#fff',
    backgroundColor: '#fff',
    color: 'black',
    boxShadow: 'none',
  },
}, true)(Button);

export const ButtonOutlineDark = withCSS({
  borderColor: 'white',
  color: 'white',
  ':hover': {
    borderColor: 'white',
    color: 'white',
    backgroundColor: 'rgba(192, 192, 192, 0.1)',
    transition: '0.5s',
  },
}, true)(Button);

export const ButtonPrimaryLight = withCSS({
  border: 'none',
  backgroundColor: '#61dafb',
  color: 'white',
  ':hover': {
    backgroundColor: '#56c7e4',
    color: 'white',
    boxShadow: 'none',
  },
  ':active': {
    backgroundColor: '#61dafb !important',
    color: 'white',
    boxShadow: 'none',
  },
  ':focus': {
    backgroundColor: '#61dafb !important',
    color: 'white',
    boxShadow: 'none',
  },
}, true)(Button);

export const ButtonSecondaryLight = withCSS({
  backgroundColor: '#fff',
  borderColor: 'rgba(192, 192, 192, 0.8)',
  color: '#6d6d6d',
  boxShadow: 'none',
  ':hover': {
    boxShadow: 'none',
    borderColor: 'rgba(192, 192, 192, 0.8)',
    backgroundColor: 'rgba(192, 192, 192, 0.4)',
    transition: '0.5s',
    color: '#6d6d6d',
    ':not(:hover)': {
      boxShadow: 'none',
      transition: '0.5s',
    },
  },
  ':active': {
    boxShadow: 'none',
  },
  ':focus': {
    boxShadow: 'none',
  },
}, true)(Button);

import { StyleSheet } from 'aphrodite/no-important';


export const styles = StyleSheet.create({
  terms__box: {
    '@media (min-width: 0)': {
      marginTop: '55px',
      height: '180px',
      backgroundColor: '#292d34',
      paddingTop: '5px',
    },
    '@media (min-width: 576px)': {
      paddingTop: '10px',
      height: '210px',
    },
    '@media (min-width: 992px)': {
      paddingTop: '60px',
      clipPath: 'polygon(0 0, 100% 0, 100% 70%, 13% 70%, 13% 100%, 0 100% , 0 0)',
      height: '300px',
    },
  },
  terms__font_title: {
    '@media (min-width: 0)': {
      fontSize: '12px',
      fontWeight: '400',
      textTransform: 'uppercase',
      color: '#fff',
    },
    '@media (min-width: 576px)': {
      fontSize: '14px',
    },
  },
  terms__font_text: {
    '@media (min-width: 0)': {
      fontSize: '24px',
      fontWeight: '200',
      color: '#fff',
    },
    '@media (min-width: 576px)': {
      fontSize: '35px',
    },
    '@media (min-width: 768px)': {
      fontSize: '42px',
    },
  },
});

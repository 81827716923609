import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Container as BaseContainer
} from 'reactstrap';
import { Parallax } from 'components';
import { createElement } from '@nonlux/react-common';
import { withCSS } from '@nonlux/react-common/composable';
import { css } from 'aphrodite/no-important';
import Plx from 'react-plx';
import {
  termsData,
  parallaxData,
} from './fixtures';
import { styles } from './styles';

const Strong = createElement('strong', 'Strong', {
  fontWeight: '700'
});

const Title = createElement('h3', 'Title', {
  '@media (min-width: 0)': {
    color: '#61dafb',
    marginTop: '15px',
    fontSize: '22px',
    fontWeight: '300'
  },
  '@media (min-width: 576px)': {
    fontSize: '30px'
  }
});

const Container = withCSS({
  '@media (min-width: 0)': {
    paddingBottom: '50px',
    paddingTop: '50px'
  },
  '@media (min-width: 992px)': {
    paddingTop: 0,
    paddingLeft: '100px',
    paddingRight: '70px'
  },
  '@media (min-width: 1200px)': {
    paddingLeft: '115px',
    paddingRight: '85px'
  }
}, true)(BaseContainer);

function PrivacyPolicyPage({ layoutType }) {
  return (
    <Fragment>
      <Hero
        termsData={termsData}
        parallaxData={parallaxData}
        box={css(styles.terms__box)}
        fontTitle={css(styles.terms__font_title)}
        fontText={css(styles.terms__font_text)}
      />
      <Parallax layoutType={layoutType} />
      <Info />
    </Fragment>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType
}))(PrivacyPolicyPage);

function Hero(props) {
  const {
    box,
    fontTitle,
    fontText
  } = props;
  return (
    <Row className={box}>
      <Container>
        <Plx parallaxData={parallaxData}>
          <Col>
            <Row>
              <Col>
                <h1 className={fontTitle}>
                  {termsData.title}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className={fontText}>
                  {termsData.text}
                </h2>
              </Col>
            </Row>
          </Col>
        </Plx>
      </Container>
    </Row>
  );
}

function Info() {
  return (
    <Row>
      <Container>
        <Col>
          <p>
            <Strong>
              Январь 2022 г.
            </Strong>
          </p>
          <p>
            Эта Политика Конфиденциальности определяет то,
            каким образом ARBITRAGE BETS собирает, обрабатывает, использует, хранит и раскрывает информацию,
            собранную у пользователей (в каждом случае "Пользователь") сайта www.arbitrage-bets.com (далее “Сервис”).
            Эта политика Конфиденциальности распространяется на Сервис и все продукты и услуги, предлагаемые ARBITRAGE BETS.
          </p>
          <Title>
            ПЕРСОНАЛЬНАЯ ИДЕНТИФИКАЦИОННАЯ ИНФОРМАЦИЯ
          </Title>
          <p>
            В соответствии с требованиями действующего законодательства, Пользователь, вступая в любые взаимоотношения с Сервисом выражает свое согласие: на обработку Сервисом и его Администрацией своих Персональных данных, указанных Пользователем в момент регистрации; на осуществление Сервисом и его Администрацией действий (операций) со своими Персональными данными, включая сбор, систематизацию, накопление, хранение, обновление, изменение, использование, обезличивание, блокирование, уничтожение и трансграничную передачу, в том числе, с использованием средств автоматизации; на обработку Сервисом и его Администрацией своих Персональных данных посредством внесения их в электронную базу данных, включения в списки (реестры) и отчетные формы.
          </p>
          <p>
            Обработка Персональных данных осуществляется Сервисом и его Администрацией с учетом соблюдения требований действующего законодательства с применением следующих основных способов (но, не ограничиваясь ими): хранение, запись на электронные носители и их хранение, составление перечней, маркировка. Пользователи всегда могут отказаться предоставить персональную информацию, помимо тех случаев, когда это может ограничить их от вовлечения в деятельность, связанную с Сервисом. Мы не собираем у Пользователей информацию об оплате. Регистрационная информация (данные для доступа на сервис) хранится на сервере.
          </p>
          <Title>
            СOOKIES-ФАЙЛЫ ВЕБ-БРАУЗЕРА
          </Title>
          <p>
            Наш Сервис может использовать "cookies" для повышения качества работы Пользователя. Используя наш Сервис и его сервисы Пользователь соглашается с тем, что Администрация в автоматическом режиме может проводить сбор и обработку cookies-файлов. Cookies - это небольшие файлы, которые дают возможность сайтам или системам сервис провайдера распознать Ваш браузер,чтобы отличить уникальных пользователей и понять как они используют сайт. Собранные данные являются анонимными и не содержат никакой личной информации.
          </p>
          <p>
            Мы используем cookies-файлы для понимания и сохранения Ваших предпочтений относительно будущих визитов и сбора сводных данных о трафике сайта и его взаимодействии для предоставления Вам более качественных услуг и инструментов работы в будущем. Мы можем связаться с поставщиками услуг, являющимися третьими сторонами, для оказания помощи в лучшем понимании посетителей нашего сайта. Сервис провайдеры не имеют права использовать информацию, собранную от нашего имени, за исключением тех случаев, когда нам понадобится помощь в ведении и совершенствовании нашего дела.
          </p>
          <Title>
            ИСПОЛЬЗОВАНИЕ СОБРАННОЙ ИНФОРМАЦИИ
          </Title>
          <p>
            Сервис ARBITRAGE BETS собирает и использует персональную информацию Пользователей для следующих целей: для персонализации Пользователя в Сервисе; для улучшения обслуживания клиентов; Ваша информация помогает нам более эффективно отвечать на Ваши запросы и потребности; для периодической персонализированной-рассылки электронных писем; электронные адреса, которые предоставляют Пользователи, используются исключительно для ответа на их запросы, и/или другие заявки либо вопросы.
          </p>
          <p>
            Если Пользователь соглашается на отправку ему электронных писем, он(-а) может получать сообщения о новостях компании, обновлениях, информацию, связанную с товарами и услугами и т.д (исключительно персональные письма). Если Пользователь изъявит желание отписаться от рассылки такого рода сообщений в будущем, у нас предоставляется детальная инструкция по отписке внизу каждого сообщения, или же Пользователь может обратиться к нам за помощью через специальную форму обращений на Сервисе.
          </p>
          <Title>
            ЗАЩИТА ИНФОРМАЦИИ
          </Title>
          <p>
            Мы внедрили надлежащую систему по сбору, хранению и обработке данных, а также применяем меры безопасности для предотвращения несанкционированного доступа, изменения, разглашения или уничтожения Вашей персональной информации: имя пользователя, пароля, информации о транзакциях и данных, хранящихся на нашем Сервисе.
          </p>
          <Title>
            ПРЕДОСТАВЛЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ
          </Title>
          <p>
            Мы не имеем права продать, обменять или сдать во временное пользование Вашу личную информацию другим лицам. Мы имеем право предоставлять сводную демографическую информацию, не связанную с персональной идентификационной информацией о посетителях и пользователях с нашими бизнес партнерами, доверенными партнерами и рекламодателями в целях, изложенных выше. Администрация сервиса обязуется не раскрывать эти данные, за исключением случаев, когда такое раскрытие происходит в установленном законодательством порядке.
          </p>
          <Title>
            СТОРОННИЕ ВЕБ-САЙТЫ
          </Title>
          <p>
            Пользователи могут столкнуться с рекламой или другим контентом на нашем Сервисе, который содержит ссылки на сайты наших партнеров, поставщиков, рекламодателей, спонсоров, лицензиаров или других лиц. Мы не контролируем контент или ссылки, которые есть на этих сайтах, и не несем ответственности за практики, применяемые на этих сайтах. Кроме того, эти сайты и сервисы, включая их содержимое и ссылки, могут постоянно меняться. Эти сайты и сервисы могут иметь свою политику приватности и обслуживания пользователей. Просмотр и взаимодействие на любом другом сайте, включая сайты на которых есть ссылка на наш Сервис, подлежит регулированию политикой и условиями этого Сервиса.
          </p>
          <Title>
            ИЗМЕНЕНИЯ В УВЕДОМЛЕНИИ О КОНФИДЕНЦИАЛЬНОСТИ
          </Title>
          <p>
            Сервис на свое усмотрение может обновлять эту политику и пользовательское соглашение в любое время, размещая новую версию на нашем сайте. Новая версия данной политики вступает в силу с момента ее публикации. Вы обязуетесь периодически проверять данную страницу, чтобы убедиться, что Вы согласны с любыми изменениями.
          </p>
          <Title>
            СОГЛАСИЕ С УСЛОВИЯМИ
          </Title>
          <p>
            Используя этот Сервис, Вы подтверждаете согласие с политикой и условиями. В случае, если Вы не соглашаетесь с данной политикой, советуем Вам не пользоваться нашим Сервисом. Если Вы продолжаете использовать этот Сервис после публикации изменений в этой политике, Вы принимаете и соглашаетесь со всеми изменениями.
          </p>
          <Title>
            СЛУЖБА ПОДДЕРЖКИ
          </Title>
          <p>
            Если у Вас возникли вопросы, касающиеся Политики Конфиденциальности, ее практики или Вашего отношения к этому сайту, обращайтесь в Службу по работе с клиентами. Наша Служба по работе с клиентами работает с 10:00 до 19:00 по мск пн-пт. Все контактные данные Вы можете найти на сайте в разделе Контакты.
          </p>
        </Col>
      </Container>
    </Row>
  );
}
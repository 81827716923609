import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  footer__box_container_fluid: {
    '@media (min-width: 0) and (max-width: 576px)': {
      backgroundColor: '#20232a',
      height: '525px',
      paddingLeft: '50px',
      paddingTop: '55px',
    },
    '@media (min-width: 576px)': {
      backgroundColor: '#20232a',
      height: '550px',
      paddingLeft: '100px',
      paddingTop: '65px',
    },
    '@media (min-width: 768px)': {
      paddingLeft: '140px',
      paddingRight: '140px',
    },
    '@media (min-width: 992px)': {
      height: '500px',
    },
  },
  footer__img_logo: {
    height: '55px',
    float: 'left',
  },
  footer__font_brand: {
    fontSize: '16px',
    fontWeight: '200',
    color: '#fff',
    marginTop: '15px',
    paddingLeft: '80px',
  },
  footer__font_text_brand: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#939394',
    marginTop: '20px',
  },
  footer__font_title: {
    fontSize: '14px',
    fontWeight: '600',
    color: '#939394',
  },
  footer__box_link: {
    marginTop: '5px',
  },
  footer__text_link: {
    color: '#fff',
    textDecoration: 'none',
    ':hover': {
      color: '#61dafb',
      textDecoration: 'none',
    },
  },
  footer__layout_margin: {
    marginTop: '40px',
  },
  footer__servicesInfo_centre: {
    width: '250px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
});

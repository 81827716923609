import React from 'react';
import { createDiv } from '@nonlux/react-common';
import { Row } from 'reactstrap';

export const ButtonContainer = ({ mt, mb, children }) => (
  <Row className="justify-content-center">
    <ButtonPosition mt={mt} mb={mb}>
      {children}
    </ButtonPosition>
  </Row>
);

export const ButtonPosition = createDiv('ButtonPosition', ({ mt = 0, mb = 0, center = false }) => {
  const mid = center && {
    marginRight: 'auto',
    marginLeft: 'auto',
  };
  return ({
    marginTop: `${mt}px`,
    marginBottom: `${mb}px`,
    ...mid,
  });
});

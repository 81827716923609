import React from 'react';
import { createDiv, createElement } from '@nonlux/react-common';


const PriceContainer = createDiv('PriceContainer', {
  position: 'relative',
  marginBottom: '5px',
  height: '75px',
  fontSize: '32px',
  color: 'black',
  transition: '0.25s'
});

const PriceVal = createElement('span', 'PriceVal', {
  fontWeight: '500'
});

const PriceCurrency = createElement('span', 'PriceCurrency', {
  fontFamily: 'Helvetica Neue, sans-serif',
  fontWeight: '500',
  marginRight: '5px',
  marginLeft: '5px'
});

const PricePeriod = createElement('span', 'PricePeriod', {
  fontWeight: '500',
  fontSize: '17px'
});

const PriceDay = createDiv('PriceDay', {
  fontSize: '15px'
});

const PriceDayVal = createElement('span', 'PriceDayVal');
const PriceDayCurrency = createElement('span', 'PriceDayCurrency');
const PriceDayPeriod = createElement('span', 'PriceDayPeriod');

function calcPricePeriodDay(daysCount, nextPrice) {
  const count = parseInt(daysCount, 10);
  return Math.round(nextPrice * 1000 / count) / 1000;
}

const PriceForm = ({ price }) => (
  <PriceContainer>
    <PriceVal>
      {price}
    </PriceVal>
    <PriceCurrency>
      €&nbsp;
    </PriceCurrency>
    <PricePeriod>
      / месяц
    </PricePeriod>
    <PriceDay>
      <PriceDayVal>
        {calcPricePeriodDay(30, price)}
      </PriceDayVal>
      <PriceDayCurrency>
        €&nbsp;
      </PriceDayCurrency>
      <PriceDayPeriod>
        / день
      </PriceDayPeriod>
    </PriceDay>
  </PriceContainer>
);
export default PriceForm;

const DEVICE_ID_LENGTH = 64;

export const WebRtcScraper = new class {
  constructor(props) {
    this.devicesWithId = 0;
    this.groupIdsCache = {};
  }

  _deviceIdAlias(id) {
    if (id.length !== DEVICE_ID_LENGTH) {
      return id;
    }

    this.devicesWithId++;

    return `$${this.devicesWithId}`;
  }

  _groupIdAlias(id) {
    let alias = this.groupIdsCache[id];
    if (!alias) {
      const num = Object.keys(this.groupIdsCache).length + 1;

      this.groupIdsCache[id] = alias = `$${num}`;
    }

    return alias;
  }

  _prepareDevices(devices) {
    return devices.map(d => ({
      deviceId: this._deviceIdAlias(d.deviceId),
      groupId: this._groupIdAlias(d.groupId),
      kind: d.kind,
      label: d.label
    }));
  }

  async scrap() {
    this.devicesWithId = 0;
    this.groupIdsCache = {};

    const devices = await navigator.mediaDevices.enumerateDevices();

    return {
      devices: this._prepareDevices(devices)
    };
  }
};

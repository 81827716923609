export const HTTP_METHODS = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete'
};

export const API_V1 = '/api/v1';

export const MSG_LOG_IN = 'LOG_IN';
export const MSG_DEACTIVATE_PROFILE = 'DEACTIVATE_PROFILE';

export const CHECK_PROXY = 'CHECK_PROXY';
export const CHECK_PROXY_RESULT = 'CHECK_PROXY_RESULT';

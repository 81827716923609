export const affiliatePresentationText = {
  title: 'affiliate.presentation.title',
  text: 'affiliate.presentation.text',
  button: {
    left: {
      type: 'secondary',
      name: 'affiliate.presentation.right',
      link: {
        href: '/sign_in'
      }
    },
    right: {
      name: 'affiliate.presentation.left',
      link: {
        href: '/affiliate/sign_up'
      }
    }
  }
};

export const advantage = [
  {
    col: [
      {
        title: 'affiliate.advantage.equalPartnership.title',
        text: 'affiliate.advantage.equalPartnership.text'
      },
      {
        title: 'affiliate.advantage.lifetimePayments.title',
        text: 'affiliate.advantage.lifetimePayments.text'
      },
      {
        title: 'affiliate.advantage.conversion.title',
        text: 'affiliate.advantage.conversion.text'
      },

    ]
  },
  {
    col: [
      {
        title: 'affiliate.advantage.reseller.title',
        text: 'affiliate.advantage.reseller.text'
      },
      {
        title: 'affiliate.advantage.marketingTools.title',
        text: 'affiliate.advantage.marketingTools.text'
      },
      {
        title: 'affiliate.advantage.withdrawalMoney.title',
        text: 'affiliate.advantage.withdrawalMoney.text'
      }
    ]
  },
];

export const table = [
  {
    col: [
      {
        title: 'affiliate.payouts.fundedSystem.title',
        text: 'affiliate.payouts.fundedSystem.text'
      },
      {
        title: 'affiliate.payouts.fireproofPercentage.title',
        text: 'affiliate.payouts.fireproofPercentage.text'
      }
    ]
  }
];

export const affiliateCallRegistrationText = {
  text: 'affiliate.callRegistration.text',
  left: {
    name: 'affiliate.callRegistration.right',
    link: {
      href: '/sign_in',
    },
  },
  right: {
    name: 'affiliate.callRegistration.left',
    link: {
      href: '/sign_up',
    }
  }
};


export const referralProgram = [
  {
    h3: 'affiliate.partnershipOptions.referralProgram.title',
  },
  {
    p: 'affiliate.partnershipOptions.referralProgram.item1',
  },
  {
    p: 'affiliate.partnershipOptions.referralProgram.item2',
  },
  {
    p: 'affiliate.partnershipOptions.referralProgram.item3',
  }
];

export const integrationProgram = [
  {
    h3: 'affiliate.partnershipOptions.integrationProgram.title',
  },
  {
    p: 'affiliate.partnershipOptions.integrationProgram.item1',
  },
  {
    p: 'affiliate.partnershipOptions.integrationProgram.item2',
  },
  {
    p: 'affiliate.partnershipOptions.integrationProgram.item1',
  }
];

export const whiteLabelProgram = [
  {
    h3: 'affiliate.partnershipOptions.whiteLabel.title',
  },
  {
    p: 'affiliate.partnershipOptions.whiteLabel.item1',
  },
  {
    p: 'affiliate.partnershipOptions.whiteLabel.item2',
  },
  {
    p: 'affiliate.partnershipOptions.whiteLabel.item3',
  }
];


export const whoCanBecomePartner = [
  {
    col: [
      {
        title: 'affiliate.whoCanBecomePartner.equalPartnership.title',
        text: 'affiliate.whoCanBecomePartner.equalPartnership.text'
      },
      {
        title: 'affiliate.whoCanBecomePartner.lifetimePayments.title',
        text: 'affiliate.whoCanBecomePartner.lifetimePayments.text'
      },
      {
        title: 'affiliate.whoCanBecomePartner.conversion.title',
        text: 'affiliate.whoCanBecomePartner.conversion.text'
      },

    ]
  },
  {
    col: [
      {
        title: 'affiliate.advantage.reseller.title',
        text: 'affiliate.advantage.reseller.text'
      },
      {
        title: 'affiliate.advantage.marketingTools.title',
        text: 'affiliate.advantage.marketingTools.text'
      },
      {
        title: 'affiliate.advantage.withdrawalMoney.title',
        text: 'affiliate.advantage.withdrawalMoney.text'
      }
    ]
  },
];
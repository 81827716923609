import React, { useState, useEffect, Fragment } from 'react';
import { Container } from 'reactstrap';
import styled, { css } from 'styled-components';
import axios from 'axios';
import { proxyCountries } from '@nonlux/ab-prices';
import STYLES from '../style';
import Dropdown from '../../TariffsProxyPage/TariffsForm/Dropdown/Dropdown';
import PriceForm from '../../../components/PriceForm/PriceForm';
import Counter from '../../../components/Counter/Counter';
import Toggle from '../../TariffsProxyPage/TariffsForm/Toggle/Toggle';
import Tooltip from '../../../components/Tooltip/Tooltip';
import {
  optionList, sortCityFunction, sortCountryFunction, sortMobileFunction
} from '../../TariffsProxyPage/constants';

const isSaveFocusOnInputWhenBlur = e => e.relatedTarget && e.relatedTarget.className.includes('toastr');

const TariffsFormContainer = styled(Container)``;

const TariffsFormCard = styled.div`
  border: 1px solid ${STYLES.COLORS.WHITE_GRAY};
  min-width: min-content;
`;

const TariffsName = styled.strong`
    color: ${props => STYLES.COLORS[`${props.color}`]};
    text-transform: uppercase ;
`;

const CardHead = styled.div`
    padding: 30px 40px 30px;
`;

const CardBody = styled.div`
    padding: 0 40px 30px;
`;

const CardBottom = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 40px 20px;
    background-color: #f5f5f5;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(${({ columns = 3 }) => columns && columns}, 1fr);
    grid-gap: 30px 20px;
`;

const GridItem = styled.div`
    grid-column: auto;
    ${({ column }) => column
      && css`
        grid-column-start: ${column.start};
        grid-column-end: ${column.end};
       `};
`;

const FieldHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3px;
`;

const FieldTitle = styled.div`
    text-align: left;
    color: #67718a;
    font-size: 13px;
    line-height: 1.53846;
    font-weight: 500;
`;

const FieldContent = styled.div``;

const CardConfig = styled.div`
    display: flex;
    grid-gap: 20px;
`;

const CardLabel = styled.div`
    margin-bottom: 8px;
    background: ${props => (props.color ? '#fff' : '#f5f5f5')};
    color: ${props => (props.color ? '#fff' : '#000')};
    text-align: center;
    font-size: 17px;
    line-height: 20px;
    font-weight: 500;
    box-sizing: border-box;
    padding: 15px;
    flex-grow: 1;
    user-select: none;
    -webkit-user-select: none;
    max-height: max-content;
`;

const Collapse = ({ children, isMobileProxiesSelect }) => isMobileProxiesSelect && (
  <Fragment>
    {children}
  </Fragment>
);

const proxy = [
  {
    text: 'Серверные IP адреса не пригодны для работы в рамках специфики арбитражных стратегий. На выходе нужен домашний IP адрес, который смогут обеспечить только мобильные, либо резидентские прокси.'
  },
  {
    text: 'На каждом VDS инженерами нашего сервиса настраивается маршрутизация с учетом телеметрии Windows 10, чтобы предотвратить любые утечки в обход прокси. strong В связи с этим без прокси на VDS интернета нет. /strong '
  },
  {
    text: 'Рекомендуем сразу подключить на VDS наши мобильные прокси, либо использовать свои прокси, если уверены в их качестве или у нас нет требуемых локаций'
  }
];

const systemAssembly = [
  {
    title: 'Сборка',
    text: ' – операция , в процессе которой полностью обновляются уникальные параметры отработанного VDS. Специалистами технического отдела удаляется отработанный сервер, на его месте создается совершенно новый, с уникальным фингерпринтом и передается пользователю.'
  },
  {
    text: 'В комплекте с каждым VDS идет одна сборка бесплатно, Чем больше пакет сборок, тем дешевле стоимость одной сборки. Сборки не сгорают и переносятся на следующий месяц если не были использованы.'
  }
];

const connectionType = [
  {
    title: 'IP:PORT',
    text: ' - классический способ подключить прокси через любой софт. На VDS прокси подключается через SOCKS5 протокол в Proxifier. Такой способ позволяет настроить гибкую маршрутизацию (например каждый сервис под своим IP адресом)'
  },
  {
    title: 'OpenVPN',
    text: ' - способ подключить прокси через VPN. На системе создается новый сетевой адаптер. Local IP адрес отдается от системы из домашней подсети 192.168.xx.x , что гарантирует нативный WebRTC.'
  },
  {
    text: 'Для каждого способа подключения весь трафик на VDS будет проходить строго через прокси. Если прокси перестанет работать, интернет будет автоматически заблокирован для избежания утечки реального IP адреса.'
  }
];

const configTooltipConnectionType = {
  body: {
    top: 'calc(100% - 150px)',
    right: 'auto',
    left: '25px',
    bottom: 'auto'
  },
  before: {
    top: '138px',
    left: '-6px'
  }
};

const operatorFeatures = [
  {
    text: ''
  }
];

const numberOfServers = [
  {
    title: 'VDS',
    text: ' – это выделенный сервер для работы, имитирующий обычный домашний компьютер пользователя. Каждый VDS является уникальным по фингерпринту на уровнях железа, операционной системы и браузера.'
  },
  {
    text: 'Для каждого VDS резервируется мощность физического сервера с выбранной выше конфигурацией. Можно одновременно запускать и работать с такого количества серверов , которое будет заказано.'
  }
];

const configTooltipNumberOfServers = {
  body: {
    top: 'auto',
    right: 'auto',
    left: '25px',
    bottom: 'calc(100% - 36px)'
  },
  before: {
    left: '-6px',
    bottom: '25px'
  }
};

const TariffsForm = ({
  vcpu,
  setVcpu,
  ram,
  setRam,
  space,
  setSpace,
  vdsCount,
  setVdsCount,
  geo,
  setGeo,
  setCity,
  setMobileOperator,
  setBuildCount,
  buildCount,
  setDaysCount,
  setTypeConnection,
  typeConnection = '1',
  isMobileProxiesSelect = false,
  setIsMobileProxiesSelect,
  daysCount,
  proxyType,
  setProxyType,
  sum,
  vdsType,
  mobileOperator,
  isOpenDropdownCountries,
  isOpenDropdownCities,
  isOpenDropdownOperators,
  setIsOpenDropdownCountries,
  setIsOpenDropdownCities,
  setIsOpenDropdownOperators

}) => {
  const [isOpenProxy, setIsOpenProxy] = useState(false);
  const [isOpenSystemAssembly, setIsOpenSystemAssembly] = useState(false);
  const [isOpenConnectionTypeVds, setIsOpenConnectionTypeVds] = useState(false);
  const [isOpenNumberOfServers, setIsOpenNumberOfServers] = useState(false);
  const [isOpenOperatorFeatures, setIsOpenOperatorFeatures] = useState(false);
  const [isVisibleOpeatorTooltip, setIsVisibleOperatorTooltip] = useState(false);
  const [proxyLimits, setProxyLimits] = useState({});

  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/api/proxyLimits');
      setProxyLimits(data);
    })();
  }, []);

  useEffect(() => {
    if (!isMobileProxiesSelect) return;
    if ((proxyLimits.tariff || []).includes(`${geo}_standard`)) setProxyType('advanced');
    else if (proxyType !== 'standard') setProxyType('standard');
    if ((proxyLimits.duration || []).includes(`${geo}_${daysCount}`)) {
      setDaysCount([1, 14, 30].find(duration => !(proxyLimits.duration || []).includes(`${geo}_${duration}`)) || 30);
    }
  }, [isMobileProxiesSelect, proxyType, daysCount, geo]);

  const countryAvailable = isMobileProxiesSelect
    ? proxyCountries.map(country => !(proxyLimits.country || []).includes(country))
    : [true, true, true, true, true, true, true, true, true];
  const cityAvailable = optionList.standard.cityList[`${geo}Alt`].map(city => !(proxyLimits.city || []).includes(city));
  const operatorAvailable = optionList[proxyType].mobileOperator[`${geo}Alt`].map(op => !(proxyLimits.operator || []).includes(`${geo}_${op}`));

  const countryOptions = isMobileProxiesSelect ? sortCountryFunction([...optionList.standard.geo], countryAvailable) : ['Великобритания', 'Украина', 'Россия', 'Испания', 'Ирландия', 'Швеция', 'США', 'Нидерланды'];
  const countryAltOptions = isMobileProxiesSelect
    ? sortCountryFunction(proxyCountries, countryAvailable)
    : ['uk', 'ua', 'ru', 'es', 'ie', 'se', 'us', 'nl'];
  const sortedAvailableCountry = [...countryAvailable].sort((a, b) => b - a);

  const cityOptions = [...optionList.standard.cityList[geo]]
    .sort(sortCityFunction(cityAvailable, geo));
  const cityAltOptions = [...optionList.standard.cityList[`${geo}Alt`]].sort(sortCityFunction(cityAvailable, `${geo}Alt`));
  const sortedAvailableCity = [...cityAvailable].sort((a, b) => b - a);

  const operatorOptions = [...optionList[proxyType].mobileOperator[geo]]
    .sort(sortMobileFunction(operatorAvailable, geo));
  const operatorAltOptions = [...optionList[proxyType].mobileOperator[`${geo}Alt`]].sort(sortMobileFunction(operatorAvailable, `${geo}Alt`));
  const sortedAvailableOperator = [...operatorAvailable].sort((a, b) => b - a);


  useEffect(() => {
    if (mobileOperator === 'o2' && proxyType !== 'advanced' && !['uk', 'de'].includes(geo)) {
      operatorFeatures[0].text = 'В пакете 80 GB трафика с возможностью пролонгации. ';
      setIsVisibleOperatorTooltip(true);
      return;
    }
    if (mobileOperator === 'vodafon' && geo !== 'ie') {
      operatorFeatures[0].text = geo !== 'uk'
        ? 'Превышение 3,5 GB в сутки снижает скорость до 0,8 Мбит/с. Лимит обновляется каждые 24 часа.'
        : 'В пуле 1000 IP адресов';
      setIsVisibleOperatorTooltip(true);
      return;
    }
    if (['de'].includes(geo)) {
      operatorFeatures[0].text = 'В пакете 30 GB трафика с возможностью пролонгации';
      setIsVisibleOperatorTooltip(true);
      return;
    }
    setIsVisibleOperatorTooltip(false);
    operatorFeatures[0].text = '';
  }, [mobileOperator]);

  return (
    <TariffsFormContainer>
      <TariffsFormCard>
        <CardHead>
          <TariffsName color={vdsType === '0' ? 'BASIC' : vdsType === '1' ? 'STANDARD' : 'ADVANCED'}>
            {vdsType === '0' ? 'BASIC' : vdsType === '1' ? 'STANDARD' : 'ADVANCED'}
          </TariffsName>
        </CardHead>

        <CardBody>
          <GridContainer columns={6}>
            <GridItem>
              <FieldHead>
                <FieldTitle>
                  vCPU, шт
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <Counter
                  count={vcpu}
                  setFunction={setVcpu}
                  step={2}
                  min={2}
                  max={16}
                  progressiveStep={[12, 4]}
                  isSaveFocusOnInputWhenBlur={isSaveFocusOnInputWhenBlur}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  RAM, ГБ
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <Counter
                  count={ram}
                  setFunction={setRam}
                  step={2}
                  min={4}
                  max={16}
                  progressiveStep={[8, 8]}
                  isSaveFocusOnInputWhenBlur={isSaveFocusOnInputWhenBlur}
                />
              </FieldContent>
            </GridItem>

            <GridItem column={{ start: 3, end: 5 }}>
              <FieldHead>
                <FieldTitle>
                  Прокси
                </FieldTitle>
                <Tooltip hint={proxy} isOpen={isOpenProxy} setIsOpen={setIsOpenProxy} />
              </FieldHead>
              <FieldContent>
                <Toggle
                  data={['Мобильные', 'Другие']}
                  altData={[true, false]}
                  setFunction={setIsMobileProxiesSelect}
                  isDefault={1}
                  value={isMobileProxiesSelect}
                  basicParam={1}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Жесткий диск, ГБ
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <Counter
                  count={space}
                  setFunction={setSpace}
                  min={65}
                  max={500}
                  step={5}
                  isSaveFocusOnInputWhenBlur={isSaveFocusOnInputWhenBlur}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Кол-во сборок
                </FieldTitle>
                <Tooltip
                  hint={systemAssembly}
                  isOpen={isOpenSystemAssembly}
                  setIsOpen={setIsOpenSystemAssembly}
                />
              </FieldHead>
              <FieldContent>
                <Counter
                  count={buildCount}
                  setFunction={(val) => {
                    if (val >= vdsCount) setBuildCount(val);
                  }}
                  step={1}
                  min={1}
                  isSaveFocusOnInputWhenBlur={isSaveFocusOnInputWhenBlur}
                  max={500}
                />
              </FieldContent>
            </GridItem>

            <GridItem column={{ start: 1, end: 3 }}>
              <FieldHead>
                <FieldTitle>
                  Страна
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <Dropdown
                  options={countryOptions}
                  altData={countryAltOptions}
                  available={sortedAvailableCountry}
                  isOpenDropdown={isOpenDropdownCountries}
                  setIsOpenDropdown={setIsOpenDropdownCountries}
                  setFunction={setGeo}
                  geo={sortedAvailableCountry[countryAltOptions.indexOf(geo)]
                    ? geo
                    : countryAltOptions[0]}
                  controlled
                  value={countryAltOptions
                    .find(country => !(proxyLimits.country || [])
                      .includes(country))}
                />
              </FieldContent>
            </GridItem>


            <Collapse isMobileProxiesSelect={isMobileProxiesSelect}>
              <GridItem column={{ start: 3, end: 5 }}>
                <FieldHead>
                  <FieldTitle>
                    Город
                  </FieldTitle>
                </FieldHead>
                <FieldContent>
                  <Dropdown
                    options={cityOptions}
                    altData={cityAltOptions}
                    available={isMobileProxiesSelect
                      ? sortedAvailableCity
                      : []}
                    isOpenDropdown={isOpenDropdownCities}
                    setIsOpenDropdown={setIsOpenDropdownCities}
                    setFunction={setCity}
                    geo={countryAvailable[countryAltOptions.indexOf(geo)]
                      ? geo
                      : countryAltOptions[0]}
                    value={cityAltOptions.find(c => !(proxyLimits.city || []).includes(c))}
                  />
                </FieldContent>
              </GridItem>

              <GridItem column={{ start: 5, end: 7 }}>
                <FieldHead>
                  <FieldTitle>
                    Оператор
                  </FieldTitle>
                  {isVisibleOpeatorTooltip ? (
                    <Tooltip
                      hint={operatorFeatures}
                      isOpen={isOpenOperatorFeatures}
                      setIsOpen={setIsOpenOperatorFeatures}
                    />
                  ) : null }
                </FieldHead>
                <FieldContent>
                  <Dropdown
                    options={operatorOptions}
                    altData={operatorAltOptions}
                    available={isMobileProxiesSelect
                      ? sortedAvailableOperator
                      : []}
                    isOpenDropdown={isOpenDropdownOperators}
                    setIsOpenDropdown={setIsOpenDropdownOperators}
                    setFunction={setMobileOperator}
                    geo={countryAvailable[countryAltOptions.indexOf(geo)]
                      ? geo
                      : countryAltOptions[0]}
                    value={operatorAltOptions.find(op => !(proxyLimits.operator || []).includes(`${geo}_${op}`))}
                  />
                </FieldContent>
              </GridItem>

              <GridItem column={{ start: 1, end: 3 }}>
                <FieldHead>
                  <FieldTitle>
                    Тип подключения прокси
                  </FieldTitle>
                  <Tooltip
                    hint={connectionType}
                    isOpen={isOpenConnectionTypeVds}
                    setIsOpen={setIsOpenConnectionTypeVds}
                    config={configTooltipConnectionType}
                  />
                </FieldHead>
                <FieldContent>
                  <Toggle
                    data={['OpenVPN', 'IP:PORT']}
                    altData={['1', '0']}
                    isDefault={1}
                    setFunction={setTypeConnection}
                    value={typeConnection}
                    basicParam={0}
                  />
                </FieldContent>
              </GridItem>
            </Collapse>


            <GridItem column={{ start: 3, end: 7 }}>
              <FieldHead>
                <FieldTitle>
                  Параметры данной конфигурации
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <CardConfig>
                  <CardLabel>
                    Windows 10
                  </CardLabel>
                  <CardLabel>
                    vGPU NVIDIA
                  </CardLabel>
                  <CardLabel>
                    ANTI DETECT
                  </CardLabel>
                  <CardLabel color={daysCount <= 14}>
                    BET STORM
                  </CardLabel>
                </CardConfig>
              </FieldContent>
            </GridItem>
          </GridContainer>
        </CardBody>

        <CardBottom>
          <GridContainer>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Кол-во серверов с этой конфигурацией
                </FieldTitle>
                <Tooltip
                  hint={numberOfServers}
                  isOpen={isOpenNumberOfServers}
                  setIsOpen={setIsOpenNumberOfServers}
                  config={configTooltipNumberOfServers}
                />
              </FieldHead>
              <FieldContent>
                <Counter
                  setFunction={(val) => {
                    setVdsCount(val);
                    setBuildCount(buildCount - vdsCount + val);
                  }}
                  count={vdsCount}
                  min={1}
                  isSaveFocusOnInputWhenBlur={isSaveFocusOnInputWhenBlur}
                  max={100}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Тарифный план
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <Toggle
                  data={['1 день', '14 дней', '30 дней']}
                  altData={[1, 14, 30]}
                  isDefault={2}
                  secondary
                  available={[1, 14, 30].map(duration => !isMobileProxiesSelect || !(proxyLimits.duration || []).includes(`${geo}_${duration}`))}
                  setFunction={setDaysCount}
                  value={daysCount}
                  basicParam={2}
                />
              </FieldContent>
            </GridItem>

            <GridItem>
              <FieldHead>
                <FieldTitle>
                  Цена
                </FieldTitle>
              </FieldHead>
              <FieldContent>
                <PriceForm sum={sum} days={daysCount} unitsCount={vdsCount} />
              </FieldContent>
            </GridItem>
          </GridContainer>
        </CardBottom>
      </TariffsFormCard>
    </TariffsFormContainer>
  );
};

export default TariffsForm;

export const PluginsScraper = new class {
  scrap() {
    return Array.from(window.navigator.plugins).map(p => ({
      description: p.description,
      filename: p.filename,
      name: p.name,
      mime: Array.from(p).map(m => ({
        description: m.description,
        suffixes: m.suffixes,
        type: m.type
      }))
    }));
  }
}();

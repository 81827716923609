import React, { Fragment, useEffect } from 'react';
import styled, { css } from 'styled-components';
import NavLinkTarget from '../NavLinkTarget/NavLinkTarget';

const formatTooltipText = (text) => {
  if (!text.includes('strong')) return text;
  const left = text.split('strong');
  const right = text.split('/strong');
  const strong = right.reduce((acc, item) => {
    if (item.includes('strong')) return item.split('strong')[1];
    return acc;
  }, '');
  return (
    <Fragment>
      {left[0]}
      <strong>{strong}</strong>
      {right[1]}
    </Fragment>
  );
};

const Tooltip = ({
  isOpen, setIsOpen, hint, config, width
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = '';
  }, [isOpen]);
  return (
    <FieldTooltip>
      {isOpen && <TooltipOverlay onClick={() => setIsOpen(false)} />}
      <TooltipToggle onClick={() => setIsOpen(true)}>
        ?
      </TooltipToggle>
      {isOpen && (
        <TooltipBody config={config} width={width}>
          <TooltipClose onClick={() => setIsOpen(false)}>
            &#215;
          </TooltipClose>
          <TooltipAnswer onBlur={() => setIsOpen(false)}>
            {(typeof hint === 'string' ? [{ text: hint }] : hint).map((item, index, arr) => (
              <Fragment>
                <Text resetMarginLastTextElement={arr[index] === arr[arr.length - 1]}>
                  {item.title && (
                    <strong>
                      {item.title}
                    </strong>
                  )}
                  {item.ul && (
                    <List>
                      {item.ul.map(listItem => (
                        <li>
                          {listItem}
                        </li>
                      ))}
                    </List>
                  )}
                  {item.text && formatTooltipText(item.text)}
                </Text>
                {item.link && (
                  <NavLinkTarget to={item.link.href}>
                    {item.link.text}
                  </NavLinkTarget>
                )}
              </Fragment>
            ))}
          </TooltipAnswer>
        </TooltipBody>
      )}
    </FieldTooltip>
  );
};
export default Tooltip;

const Text = styled.p`${({ resetMarginLastTextElement }) => resetMarginLastTextElement && css`margin: 0`}`;

const List = styled.ul`
  &:last-child {
    margin-bottom: 0;
  }
`;

const FieldTooltip = styled.div`
    position: relative;
    margin-left: 8px;
`;

const TooltipToggle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #67718a;
    color: #fff;
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    font-weight: 500;
`;

const TooltipBody = styled.div`
    width: ${({ width }) => (width || '600px')};
    position: absolute;
    top: ${({ config }) => (config ? config.body.top : 'calc(100% + 15px)')};
    right: ${({ config }) => (config ? config.body.right : '-10px')};
    bottom: ${({ config }) => (config ? config.body.bottom : 'auto')};
    left: ${({ config }) => (config ? config.body.left : 'auto')};
    font-size: 15px;
    line-height: 1.33333;
    color: #333;
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #f5f5f5;
    border-radius: 2px;
    box-shadow: 0 8px 32px 0 rgb(0 0 0 / 2%);
    z-index: 100;
    transition: .3s ease-out;
    animation: opacity .4s ease-out;
    background-color: #fff;
    &:before {
        background-color: #fff;
        position: absolute;
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-top: 1px solid #f5f5f5;
        border-left: 1px solid #f5f5f5;
        transform: rotate(45deg); 
        top: ${({ config }) => (config ? config.before.top : '-6px')};
        right: ${({ config }) => (config ? config.before.right : '10px')};
        bottom: ${({ config }) => (config ? config.before.bottom : 'auto')};
        left: ${({ config }) => (config ? config.before.left : 'auto')};
      }
`;

const TooltipClose = styled.div`
    display: block;
    position: absolute;
    top: 0;
    right: 1px;
    cursor: pointer;
    color: #737888;
    width: 1em;
    height: 1em;
    font-size: 20px;
`;

const TooltipOverlay = styled.div`
    background: rgba(196,196,196,.4);
    animation: calc-card-total .4s ease-out;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 70;
`;


const TooltipAnswer = styled.div``;

import advanced from './img/advanced.png';
import basic from './img/basic.png';

export const indexPresentationText = {
  title: 'ARBITRAGE BETS',
  text: 'main.presentation.text',
  button: {
    left: {
      type: 'secondary',
      name: 'main.presentation.right',
      link: {
        href: '/sign_in',
      },
    },
    right: {
      name: 'main.presentation.left',
      link: {
        href: '/sign_up',
      },
    },
  },
};

export const uniqueIdentifiers = [
  {
    id: 'quality',
    name: 'main.toggleInfo.quality.name',
    title: 'main.toggleInfo.quality.title',
    text: 'main.toggleInfo.quality.text',
    row: [
      {
        col: [
          {
            title: 'main.toggleInfo.quality.item.accounts.title',
            text: 'main.toggleInfo.quality.item.accounts.text'
          },
          {
            title: 'main.toggleInfo.quality.item.decrease_in_highs.title',
            text: 'main.toggleInfo.quality.item.decrease_in_highs.text'
          },
          {
            title: 'main.toggleInfo.quality.item.verification.title',
            text: 'main.toggleInfo.quality.item.verification.text'
          },
          {
            title: 'main.toggleInfo.quality.item.gain.title',
            text: 'main.toggleInfo.quality.item.gain.text'
          },
        ],
      },
    ],
  },
  {
    id: 'simplicity',
    name: 'main.toggleInfo.simplicity.name',
    title: 'main.toggleInfo.simplicity.title',
    text: 'main.toggleInfo.simplicity.text',
    row: [
      {
        col: [
          {
            title: 'main.toggleInfo.simplicity.item.professionally.title',
            text: 'main.toggleInfo.simplicity.item.professionally.text'
          },
          {
            title: 'main.toggleInfo.simplicity.item.quickly.title',
            text: 'main.toggleInfo.simplicity.item.quickly.text'
          },
          {
            title: 'main.toggleInfo.simplicity.item.comfortable.title',
            text: 'main.toggleInfo.simplicity.item.comfortable.text'
          }
        ],
      },
    ],
  },
  {
    id: 'available',
    name: 'main.toggleInfo.available.name',
    title: 'main.toggleInfo.available.title',
    text: 'main.toggleInfo.available.text',
    row: [
      {
        col: [
          {
            title: 'main.toggleInfo.available.item.price.title',
            text: 'main.toggleInfo.available.item.price.text'
          },
          {
            title: 'main.toggleInfo.available.item.guarantees.title',
            text: 'main.toggleInfo.available.item.guarantees.text'
          },
          {
            title: 'main.toggleInfo.available.item.support.title',
            text: 'main.toggleInfo.available.item.support.text'
          }
        ],
      },
    ],
  },
];

export const indexCallRegistrationText = {
  text: 'main.callRegistration.text',
  left: {
    name: 'main.callRegistration.right',
    link: {
      href: '/sign_in',
    },
  },
  right: {
    name: 'main.callRegistration.left',
    link: {
      href: '/sign_up',
    }
  }
};

export const services = [
  {
    title: 'main.services.betStorm.title',
    text: 'main.services.betStorm.text',
    link: {
      name: 'main.services.betStorm.link.name',
      href: '/bet_storm'
    }
  },
  {
    title: 'main.services.proxy.title',
    text: 'main.services.proxy.text',
    link: {
      name: 'main.services.proxy.link.name',
      href: '/proxy'
    }
  },
  {
    title: 'main.services.vds.title',
    text: 'main.services.vds.text',
    link: {
      name: 'main.services.vds.link.name',
      href: '/vds'
    }
  }
];

export const advantage = [
  {
    col: [
      {
        title: 'main.advantage.technologies.title',
        text: 'main.advantage.technologies.text',
      },
      {
        title: 'main.advantage.service.title',
        text: 'main.advantage.service.text',
      },
      {
        title: 'main.advantage.tests.title',
        text: 'main.advantage.tests.text',
      },
    ],
  },
  {
    col: [
      {
        title: 'main.advantage.parties.title',
        text: 'main.advantage.parties.text',
      },
      {
        title: 'main.advantage.team.title',
        text: 'main.advantage.team.text',
      },
      {
        title: 'main.advantage.support.title',
        text: 'main.advantage.support.text',
      },
    ],
  },
];

export const imgRowText = {
  title: 'main.imgRow.title',
  text: 'main.imgRow.text',
  button: 'main.imgRow.button',
};

export const videoInfoContent = [
  {
    title: 'main.videoInfo.basic.title',
    text: 'main.videoInfo.basic.text',
    img: `${basic}`,
    id: 'wfSkcNuI45A',
  },
  {
    title: 'main.videoInfo.advanced.title',
    text: 'main.videoInfo.advanced.text',
    img: `${advanced}`,
    id: '0rvGzfwBQ_0',
  },
];
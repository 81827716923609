import styled from 'styled-components';
import LinkTarget from '../LinksTarget/LinksTarget';

const NavLinkTarget = styled(LinkTarget)`
    background-color: rgba(187,239,253,0.3);
    border-bottom: 1px solid rgba(0,0,0,0.2);
    color: #1a1a1a;
    cursor: pointer;
    &:hover {
       background-color: #bbeffd;
       color: #1a1a1a;
       border-bottom-color: #1a1a1a;
       text-decoration: none;
    }
`;
export default NavLinkTarget;
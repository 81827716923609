import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Container as BaseContainer
} from 'reactstrap';
import { LinkTarget } from '@nonlux/ab-ui';
import { Parallax, Border } from 'components';
import { createElement } from '@nonlux/react-common';
import { withCSS, branched } from '@nonlux/react-common/composable';
import { css } from 'aphrodite/no-important';
import Plx from 'react-plx';
import {
  termsData,
  parallaxData
} from './fixtures';
import { styles } from './styles';

const H1 = createElement('h1', 'H1', ({ layoutType }) => ({
  fontWeight: '200',
  fontSize: (layoutType === 'lg' || layoutType === 'xl') ? '45px' : '35px',
  color: '#fff'
}));

const H2 = createElement('h2', 'H2', ({ layoutType }) => ({
  color: '#292d34',
  fontWeight: '400',
  fontSize: (layoutType === 'lg' || layoutType === 'xl') ? '33px' : '30px'
}));

const H3 = createElement('h3', 'H3', ({ layoutType }) => ({
  color: 'darkgray',
  fontWeight: '300',
  fontSize: (layoutType === 'lg' || layoutType === 'xl') ? '28px' : '24px',
  margin: '30px 0 20px 0'
}));

const H4 = createElement('h4', 'H4', ({ layoutType }) => ({
  fontSize: (layoutType === 'lg' || layoutType === 'xl') ? '20px' : '20px',
  fontWeight: '400',
  color: '#6d6d6d',
  lineHeight: '1.5',
  marginBottom: '15px'
}));

const P = createElement('p', 'P', ({ layoutType }) => ({
  fontSize: (layoutType === 'lg' || layoutType === 'xl') ? '18px' : '16px'
}));

function WrappedText({ type, children, ...rest }) {
  if (type === 'h1') {
    return (
      <H1 {...rest}>
        {children}
      </H1>
    );
  }
  if (type === 'h2') {
    return (
      <H2 {...rest}>
        {children}
      </H2>
    );
  }
  if (type === 'h3') {
    return (
      <H3 {...rest}>
        {children}
      </H3>
    );
  }
  if (type === 'h4') {
    return (
      <H4 {...rest}>
        {children}
      </H4>
    );
  }
  return (
    <P {...rest}>
      {children}
    </P>
  );
}

const Text = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(WrappedText);

function TextListBr({ arr }) {
  return arr.map((item, index, array) => {
    if (!(item === array[array.length - 1])) {
      return (
        <Text key={index} className="mb-0">
          {item}
        </Text>
      );
    }
    return (
      <Text key={index}>
        {item}
      </Text>
    );
  });
}

function TextList({ arr }) {
  return arr.map((item, index) => {
    if (Array.isArray(item.text)) return <TextListBr arr={item.text} />;
    if (item.link) {
      return (
        <Text>
          <LinkTarget to={item.link} target="_self" key={index}>
            {item.text}
          </LinkTarget>
        </Text>
      );
    }
    return (
      <Text type={item.type} key={index}>
        {item.text}
      </Text>
    );
  });
}

function ColTextList({ arr }) {
  return arr.map((item, index) => (
    <Col key={index}>
      <TextList arr={item.col} />
    </Col>
  ));
}

function RowTextList({ arr }) {
  return arr.map((item, index) => (
    <Row key={index}>
      <ColTextList arr={item.row} />
    </Row>
  ));
}

const Container = withCSS({
  '@media (min-width: 0)': {
    paddingBottom: '50px',
    paddingTop: '50px'
  },
  '@media (min-width: 992px)': {
    paddingTop: 0,
    paddingLeft: '100px',
    paddingRight: '70px'
  },
  '@media (min-width: 1200px)': {
    paddingLeft: '115px',
    paddingRight: '85px'
  }
}, true)(BaseContainer);

const Hr = withCSS({
  marginTop: '30px',
  marginBottom: '60px'
})(Border);

function TermsPage({ layoutType }) {
  return (
    <Fragment>
      <Hero
        termsData={termsData}
        parallaxData={parallaxData}
        box={css(styles.terms__box)}
        fontTitle={css(styles.terms__font_title)}
        fontText={css(styles.terms__font_text)}
      />
      <Parallax layoutType={layoutType} />
      <Info />
    </Fragment>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType
}))(TermsPage);

function Hero(props) {
  const {
    box
  } = props;
  return (
    <Row className={box}>
      <Container>
        <Plx parallaxData={parallaxData}>
          <Col>
            <Row>
              <Col>
                <Text type="h1">
                  ПРЕИМУЩЕСТВА
                </Text>
              </Col>
            </Row>
          </Col>
        </Plx>
      </Container>
    </Row>
  );
}

/*
{
  text: '',
    type: '',
},
*/


const advantages = [
  {
    row: [
      {
        col: [
          {
            text: 'Передовые технологии',
            type: 'h3'
          },
          {
            text: 'Мы используем лучшие разработки и практики , основываясь на анализе современных антифрод систем. Своевременно интегрируем новые технологии. Кроме того, мы сами являемся активными пользователями собственного софта, что в полной мере отображает личную заинтересованность в исключительном качестве продукта.',
            type: 'p'
          }
        ]
      },
      {
        col: [
          {
            text: 'Комплексный сервиc',
            type: 'h3'
          },
          {
            text: 'Единственные на рынке мы предлагаем комплексный сервис. Настроенные для работы VDS с уникальным отпечатком системы для каждого аккаунта на уровне железа, операционной системы и как следствие - браузера. Не блокируемая бекконект технология 4G LTE Proxy с пулом более 200 000 чистых IP адресов обеспечат преимущество на сетевом уровне. Интегрированный сканер арбитражных событий.',
            type: 'p'
          }
        ]
      }
    ]
  },
  {
    row: [
      {
        col: [
          {
            text: 'Тесты системы',
            type: 'h3'
          },
          {
            text: 'Для нас важно, чтобы Вы имели возможность убедиться лично, насколько система соответствует заявленным характеристикам. И такая возможность есть. Никаких котов в мешке. Для проверки сборок, мы предлагаем ряд тестов, которые используют самые требовательные системы для идентификации пользователей.',
            type: 'p'
          }
        ]
      },
      {
        col: [
          {
            text: 'Нет третьих сторон',
            type: 'h3'
          },
          {
            text: 'Мы не используем сторонние ресурсы, а управляем 100% собственных: оборудованием, программным обеспечением и сетью. Именно поэтому, мы можем гарантировать стабильную работу всех систем и конфиденциальность Ваших данных.',
            type: 'p'
          }
        ]
      }
    ]
  },
  {
    row: [
      {
        col: [
          {
            text: 'Команда',
            type: 'h3'
          },
          {
            text: 'Наши специалисты являются профессиональными арбитражниками с опытом более 8 лет в гемблинг сфере, а инженеры обладают стажем более 10 лет в онлайн-сервисах слежения и системах идентификации. Мы знаем, что нужно пользователям и делаем это качественно.',
            type: 'p'
          }
        ]
      },
      {
        col: [
          {
            text: 'Поддержка',
            type: 'h3'
          },
          {
            text: 'Мы помогаем решать сложные вопросы, благодаря годами наработаному опыту в игровой сфере и прямым контакам с ведущими букмкерскими конторами и платежными системами. С готовностью 24/7 окажем поддержку в вопросах арбитража и не бросим Вас в сложной ситуации.',
            type: 'p'
          }
        ]
      }
    ]
  }
];

const vds = [
  {
    text: 'VDS',
    type: 'h2'
  },
  {
    text: 'Техническое превосходство',
    type: 'h3'
  },
  {
    type: 'p',
    text: 'Технические характеристики наших VDS отлично подходят для быстрой и эффективной работы. На одну VDS мы выделяем мощности:'
  },
  {
    type: 'p',
    text: [
      '2 vCORE',
      '4 RAM',
      '65 GB NVMe'
    ]
  },
  {
    text: 'В силу разнообразия стратегий, которые используют наши пользователи, по запросу, мы можем выделять дополнительные мощности под самые высоконагруженные задачи.',
    type: 'p'
  },
  {
    text: 'На серверах используется новое поколение SSD накопителей типа NVMe, которые выдают невероятные показатели скорости. На чтение 465 000 IOPS на NVMe вместо 97 000 IOPS на обычных SSD, на запись 70 000  IOPS на NVMe вместо 35 500 IOPS на SSD. Это полностью исключает влияние пользователей друг на друга, “борьбу за ресурсы” на одном сервере и делает работу комфортной.',
    type: 'p'
  },
  {
    text: 'Все сервера оборудованы дискретной GPU, помимо общего увеличения производительности, это позволяет прокидывать на VDS шейдеры видеокарты сервера и устанавливать кастомные драйвера. В пуле более 100 видеокарт разных производителей с драйверами разных версий. Таким образом параметры, которые должны отдаваться видеокартой, такие как Canvas и WebGL, являются не подменой(спуф), а нативными значениями, формируются системой(драйверами видеокарт).',
    type: 'p'
  },
  {
    text: 'Правильная уникализация параметров',
    type: 'h3'
  },
  {
    text: 'Мы не занимаемся перепродажей обычных датацентровых VDS под специфику,  как делают некоторые сервисы. В каждой сборке под новую связку аккаунтов наши скрипты создают совершенно уникальный пул параметров железа и операционной системы, которые передаются в браузер. Чтобы не быть голословными, пользователям предлагается система тестов, для проверки системы на уникальность перед работой:',
    type: 'p'
  },
  {
    text: 'Тесты железа >',
    link: 'https://arbitrage-bets.com/tutorial/tests_vds#hardware',
    type: 'p'
  },
  {
    text: 'Тесты операционной системы >',
    link: 'https://arbitrage-bets.com/tutorial/tests_vds#operating_system',
    type: 'p'
  },
  {
    text: 'Тесты браузера >',
    link: 'https://arbitrage-bets.com/tutorial/tests_vds#browser',
    type: 'p'
  },
  {
    text: 'На VDS используется сложный софт который меняет параметры железа еще до запуска системы, после запуска мы меняем уникальные параметры реестра (более 200 параметров), которые и задают уникальный отпечаток каждой сборки. ',
    type: 'p'
  },
  {
    text: 'Обычные пользователи не используют модернизацию браузеров. В обычной жизни браузер отдает лишь те реальные параметры, которые формируются системой пользователя. Любые манипуляции с параметрами через API браузера - видны для любого софта, нацеленного раскрыть подмену и создают больше проблем чем пользы. \n',
    type: 'p'
  },
  {
    text: 'На VDS Arbitrage Bets не создается иллюзии подмены параметров за счет JavaScript шума, как это делают браузерные антидетекты или расширения, проблема решается за счет реального изменения всех параметров железа и операционной системы. ',
    type: 'p'
  },
  {
    text: 'Благодаря такому подходу, помимо качества отдаваемых на клиент параметров, нет привязки на браузеры. Вы можете использовать любые официальные браузеры последних версий строго под локацию работы. В том числе Chrome, самый популярный по статистике браузер, с закрытым исходным кодом, который невозможно полноценно с эмулировать, даже скомпилировав исходники движка Chromium.',
    type: 'p'
  },
  {
    text: 'Так же мы не используем серверные операционные системы, на VDS будет Windows 7/10. Переписан брандмауэр, что позволяет контролировать телеметрию Windows 10, исключены любые утечки на сервера Microsoft, NVIDIA и Google в обход VPN или прокси.',
    type: 'p'
  },
  {
    text: 'Изменение параметров в один клик',
    type: 'h3'
  },
  {
    text: 'Мы прекрасно понимаем специфику работы в арбитраже в БК, поэтому ввели такую опцию как Сборки. Задача этой операции изменить все параметры на системе и существенно снизить для Вас затраты на новые конфигурации в рамках оплаченных мощностей сервера.',
    type: 'p'
  },
  {
    text: 'Более подробно ознакомиться с тем, как это работает, можно в Руководстве:',
    type: 'p'
  },
  {
    text: 'Узнать больше >',
    link: 'https://arbitrage-bets.com/tutorial/configuration_vds#change_parameters',
    type: 'p'
  },
  {
    text: 'Полный доступ к системе',
    type: 'h3'
  },
  {
    text: 'На VDS Arbitrage Bets предлагается полноценный доступ ко всей системе. Вы единственный у кого есть доступ. Появляется возможность установить свой пароль, а значит избежать утечки своей схемы в паблик, гарантировать сохранность важных данных от платежных систем и букмекерских контор. На VDS используются образы только с официального сайта Microsoft, мы заботимся о Вашей безопасности.',
    type: 'p'
  },
  {
    text: 'Работайте с VDS как с полноценным ПК, а не только c окном браузера. Храните данные, устанавливайте любой софт для арбитража под десктоп. Есть много хороших решений для мультиставок или ботов, экспериментируйте в поиске своего подхода, используйте весь функционал доступный в современном арбитраже без ограничений.',
    type: 'p'
  },
  {
    text: 'Контроль телеметрии',
    type: 'h3'
  },
  {
    text: 'У операционной системы есть скрытая часть, которая фактически является официальным и легальным «трояном» от корпораций, задача которого собирать всю информацию о системе и о всех действиях пользователя в ней.',
    type: 'p'
  },
  {
    text: 'Важно понимать, что информация отправляется в обход браузеров напрямую от системы, что делает неэффективными любые браузерные решения (браузерные антидетекты и расширения). На VDS Arbitrage Bets телеметрия под полным контролем, подробнее можно ознакомиться в Руководстве:',
    type: 'p'
  },
  {
    text: 'Узнать больше >',
    link: 'https://arbitrage-bets.com/tutorial/configuration_vds#telemetry',
    type: 'p'
  },
  {
    text: 'Настройка системы на основе статистики',
    type: 'h3'
  },
  {
    text: 'Под каждую связку БК необходимо настраивать уникальную систему по параметрам железа, операционной системы и браузера, контролировать телеметрию и внебраузерные отпечатки, использовать качественные решения для сетевого уровня.',
    type: 'p'
  },
  {
    text: 'Но даже если мы подготовили систему по всем параметрам, этого все еще не достаточно для эффективной работы. Необходимо адаптировать систему под локацию, в которой планируем работать. На VDS Arbitrage Bets каждая сборка собирается на основе статистических данных строго под страну - Россия, Украина или Великобритания. Подробнее можно ознакомиться в Руководстве:',
    type: 'p'
  },
  {
    text: 'Узнать больше >',
    link: 'https://arbitrage-bets.com/tutorial/configuration_vds#system_setting',
    type: 'p'
  }
];

const proxy = [
  {
    text: 'PROXY',
    type: 'h2'
  },
  {
    text: 'Мобильные IP адреса',
    type: 'h3'
  },
  {
    text: 'Мы предоставляем Вам доступ в пул мобильных  4G LTE адресов провайдера выбранной страны и региона с пулом более 13 000 не блокируемых IP адресов на провайдера (суммарный пул на все локации более 200 000 IP). Вы сможете не ограничено менять IP из личного кабинета по нажатию кнопки или выгрузить ссылку (API). Доступны все сетевые протоколы - HTTP или SOCKS5. В силу своей специфики они не блокируются в целевых сервисах. ',
    type: 'p'
  },
  {
    text: 'IPv4 адресов В МИРЕ всего 4.5 млрд, все они были распределены между организациями в 2011 году.\n'
    + 'Операторы мобильной связи обладают лишь несколькими тысячами уникальных IPv4 адресов (около 4000-13000 на оператора), при этом абонентов у операторов миллионы. ОСС используют технологию NAT, т.е. под одним внешним адресом выходят сотни тысяч пользователей.\n'
    + 'IP адресов у мобильного оператора всего несколько тысяч, системы , проверяющие IP адрес относятся к таким адресам с высокой степенью доверия, т.к понизив доверие или заблокировав такой адрес под блокировку попадут десятки тысяч невинных пользователей.\n',
    type: 'p'
  },
  {
    text: 'Именно сокрытие в толпе обычных пользователей с помощью мобильного адреса является ключевым преимуществом мобильных прокси.',
    type: 'p'
  },
  {
    text: 'Например у Мегафон по открытой статистике 75 000 000 клиентов на 2015 год, сейчас более 100 млн, соответственно в один момент времени в целевом сервисе может находиться 7 500 человек под одним IP, пул адресов с 2011 года у всех мобильных операторов строго регламентирован и общедоступен, в сервисах следят за этим, потому что заблокировав IP одного арбитражника под блок попадет +-7 500 человек “невиновных” и целевой сервис (букмекерская контора и тд.) будет нести убытки.',
    type: ''
  },
  {
    text: 'Технология бекконект',
    type: 'h3'
  },
  {
    text: 'Backconnect Proxy — это такой тип прокси, доступ к которым производится через специальный сервер-гейтвей (выделенного внешнего IP адреса у прокси-сервера нет). Другими словами, Вы подключаетесь на один IP -  адрес с разными портами, а коннекты транслируются на сами прокси, которые имеют разные IP. Это позволяет Вам с одного борда управления без изменения IP:PORT подключения получать доступ в пул IP адресов провайдера и переключаться между ними неограниченное количество раз.',
    type: 'p'
  },
  {
    text: 'Помимо этого такое соединение совершенно не детектится как прокси или VPN, потому что не Вы подключаетесь к серверу, а сервер к Вам. Для полноты картины на прокси отключен двухсторонний пинг, что делает технологию неуязвимой к детекту. Целевые сервисы видят Вас, как если бы Вы раздавали на свой ПК WiFi с сотового телефона.',
    type: 'p'
  },
  {
    text: 'Пассивный отпечаток операционной системы (POSF)',
    type: 'h3'
  },
  {
    text: 'Различные реализации стэка TCP/IP в операционных системах по умолчанию отличаются. Это позволяет с неплохой степенью достоверности делать вывод о том, какая операционная система сформировала пакет.',
    type: 'p'
  },
  {
    text: 'Метод, который позволяет проанализировать трафик называют пассивным отпечаток операционной системы (POSF). В Руководстве подробно описан алгоритм синхронизации прокси с операционной системой Вашего устройства.',
    type: 'p'
  },
  {
    text: 'Узнать больше >',
    link: 'https://arbitrage-bets.com/tutorial/configuration_proxy#posf',
    type: 'p'
  },
  {
    text: 'Максимальный уровень передачи данных (MTU)',
    type: 'h3'
  },
  {
    text: 'MTU является протоколом канального типа, который определяет максимальное число битов с полезной информацией, помещающихся в один сетевой пакет. Данные пакеты сведений передаются от провайдера к пользователю, то есть от серверов к клиенту. У каждого оператора свой размер пакета передачи данных.',
    type: 'p'
  },
  {
    text: 'При использовании прокси повлиять на этот пакет возможно только в том случае, если у Вас есть доступ к контрольному серверу. На прокси Arbitrage Bets не придется беспокоиться об этом. MTU выставляется персонально под каждого оператора связи, как на реальном устройстве с прямым подключением. \n',
    type: 'p'
  },
  {
    text: 'Узнать больше >',
    link: 'https://arbitrage-bets.com/tutorial/configuration_proxy#mtu',
    type: 'p'
  },
  {
    text: 'Веб коммуникации в реальном времени (WebRTC)',
    type: 'h3'
  },
  {
    text: 'WebRTC (Real Time Communication) - технология, которая позволяет пользователям передавать потоковые аудио и видео данные между мобильными приложениями и браузерами. WebRTC является прямым конкурентом Skype. В обмен на удобство, у технологии есть серьезные уязвимости, она раскрывает реальный локальный и публичный IP пользователя в обходы любых VPN и proxy.',
    type: ''
  },
  {
    text: 'Для того, что бы правильно изменять WebRTC мы разработали связку OpenVPN+PROXY . Программное обеспечение формирует на системе дополнительный сетевой адаптер с локальным IP, уникальным для каждого пользователя, из домашней подсети 192.168.xxx и отдает его на клиент, а также позволяет отдавать Public IP соответствующий внешнему IP прокси. Все как на обычном домашнем ПК, на который раздается интернет с мобильного телефона по WiFi. \n',
    type: ''
  },
  {
    text: 'Такого качества невозможно добиться ни одним из альтернативных способов, Например при пробросе прокси через Proxifier Public IP будет отключен, что косвенно является триггером к использованию прокси. Технология OpenVPN+PROXY “пробивает” любые профессиональные чекеры для анализа трафика, которые используют open source и коммерческие системы, например GetIntelIP или MaxMind',
    type: ''
  },
  {
    text: 'Узнать больше >',
    link: 'https://arbitrage-bets.com/tutorial/configuration_proxy#control_webrtc',
    type: 'p'
  },
  {
    text: 'Разные страны и регионы',
    type: 'h3'
  },
  {
    text: 'Для работы у нас доступны несколько стран - Россия, Украина и Великобритания, с возможностью узкой настройки локации под регион/город, что очень актуально в специфических разновидностях арбитража.\n',
    type: 'p'
  },
  {
    text: 'С российской локации Вы сможете работать в рамках ЦУПИС или с оффшорными и евро БК через зеркала. Украина поможет Вам работать одновременно и с российскими и с оффшорными БК. Великобритания как правило используется для работы с оффшорными и евро БК.',
    type: 'p'
  },
  {
    text: 'Узнать больше >',
    link: 'https://arbitrage-bets.com/tutorial/configuration_vds#system_setting',
    type: 'p'
  }
];

function Info() {
  return (
    <Row>
      <Container>
        <RowTextList arr={advantages} />
        <Hr />
        <TextList arr={vds} />
        <Hr />
        <TextList arr={proxy} />
      </Container>
    </Row>
  );
}

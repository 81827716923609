import React from 'react';
import styled from 'styled-components';

const PriceForm = ({
  sum, days, unitsCount, discount
}) => {
  const pricePerDay = Number.isInteger(sum / days) ? sum / days : Number((sum / days).toFixed(2));
  const PerDayPriceBlock = ({ dateUnit }) => <PriceBlock sum={pricePerDay} displayAmount={4} dateUnit={dateUnit} unitsCount={unitsCount} discount={discount} />;
  return (
    <PriceContainer notranslate>
      {days === 30 && <PriceBlock sum={sum} displayAmount={5} dateUnit="месяц" unitsCount={unitsCount} discount={discount} />}
      {days === 14 && <PriceBlock sum={sum} displayAmount={4} dateUnit="две недели" unitsCount={unitsCount} discount={discount} />}

      {/* Ugly hack for google translate on a website */}
      {days === 30 && <PerDayPriceBlock dateUnit="день" />}
      {days === 14 && <PerDayPriceBlock dateUnit="дeнь" />}
      {days === 1 && <PerDayPriceBlock dateUnit="в день" />}
    </PriceContainer>
  );
};

export default PriceForm;

const PriceBlock = ({
  sum, displayNum, dateUnit, unitsCount, discount = true
}) => (
  <div>
    <Price display={`${sum}`.length > displayNum}>
      <PriceNum>
        {Number.isInteger(sum) ? sum : sum.toFixed(2)}
        &nbsp;€
      </PriceNum>
      <PriceText>
        &nbsp;/
        {' '}
        {dateUnit}
      </PriceText>
    </Price>
    {unitsCount > 5 && discount && (
    <PriceDiscount>
      <DiscountNum>
        -&nbsp;
        {(sum / 9).toFixed(2)}
        &nbsp;€
      </DiscountNum>
      <DiscountText>
        &nbsp;/ скидка
      </DiscountText>
    </PriceDiscount>
    )}
  </div>
);

const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Price = styled.div`
/*  display: ${({ display }) => (display ? 'flex' : 'block')};
  flex-direction: ${({ display }) => (display ? 'column' : '')};*/
`;

const PriceNum = styled.span`
  color: #000;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 700;
`;

const PriceText = styled.span`
  color: #67718a;
  font-size: 17px;
  line-height: 1.41176;
`;

const PriceDiscount = styled.div`
  font-size: 13px;
  line-height: 1.53846;
  font-weight: 500;
`;

const DiscountNum = styled.span`
  color: #000;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 700;
`;

const DiscountText = styled.span`
  color: #67718a;
  font-size: 13px;
  line-height: 1.41176;
`;

import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  ContainerContent
} from 'components';
import {
  Grid, Title, Text, Strong, Img, Box, Link
} from '../common';

import manWorking from '../img/manWorking.svg';
import pilot from '../img/pilot.svg';
import manSystem from '../img/man-system.svg';

export default function PartnerCabinet({ title }) {
  const { t, i18n } = useTranslation();
  return (
    <ContainerContent title={title} titleMarginBottom={40}>
      <Grid>
        <div>
          <Title>
            {t('affiliate.cases.B2C.title')}
          </Title>
          <Text>
            {t('affiliate.cases.B2C.intro')}
          </Text>
          <Text>
            <Strong>
              {t('affiliate.cases.B2C.who_is.title')}
            </Strong>
            &nbsp;
            {t('affiliate.cases.B2C.who_is.text')}
          </Text>
          <Text>
            <Strong>
              {t('affiliate.cases.B2C.conditions.title')}
            </Strong>
            &nbsp;
            {t('affiliate.cases.B2C.conditions.text')}
          </Text>
          <Img flex src={manWorking} alt="B2C" />
        </div>
        <div>
          <Title>
            {t('affiliate.cases.B2B.title')}
          </Title>
          <Text>
            {t('affiliate.cases.B2B.intro')}
          </Text>
          <Text>
            <Strong>
              {t('affiliate.cases.B2B.who_is.title')}
            </Strong>
            &nbsp;
            {t('affiliate.cases.B2B.who_is.text')}
          </Text>
          <Text>
            <Strong>
              {t('affiliate.cases.B2B.conditions.title')}
            </Strong>
            &nbsp;
            {t('affiliate.cases.B2B.conditions.text')}
          </Text>
          <Box mt={30}>
            <Img flex src={pilot} alt="B2B" />
          </Box>
        </div>
        <div>
          <Title>
            {t('affiliate.cases.WL.title')}
          </Title>
          <Text>
            {t('affiliate.cases.WL.intro')}
          </Text>
          <Text>
            <Strong>
              {t('affiliate.cases.WL.who_is.title')}
            </Strong>
            &nbsp;
            {t('affiliate.cases.WL.who_is.text1')}
          </Text>
          <Text>
            {t('affiliate.cases.WL.who_is.text2')}
          </Text>
          <Text>
            {t('affiliate.cases.WL.who_is.text3')}
          </Text>
          <Text>
            <Strong>
              {t('affiliate.cases.WL.conditions.title')}
            </Strong>
            &nbsp;
            {t('affiliate.cases.WL.conditions.text')}
          </Text>
          <Img flex src={manSystem} alt="WHITE LABEL" />
        </div>
      </Grid>
      <Box mt={40} flex justify="center">
        <Link to="/affiliate/sign_up">
          {t('affiliate.cases.action')}
        </Link>
      </Box>
    </ContainerContent>
  );
}

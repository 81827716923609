export const SpeechSynthesisScraper = {
  _voiceToObject(voice) {
    return {
      voiceURI: voice.voiceURI,
      name: voice.name,
      lang: voice.lang,
      localService: voice.localService,
      default: voice.default
    };
  },

  async _getVoices() {
    return new Promise((resolve, reject) => {
      try {
        const speech = window.speechSynthesis;

        function populateVoiceList() {
          resolve(speech.getVoices());
        }

        if (speech.onvoiceschanged) {
          resolve(speech.getVoices());
        } else {
          speech.onvoiceschanged = populateVoiceList;
        }
      } catch (e) {
        resolve(null);
      }
    });
  },

  async scrap() {
    const voices = await this._getVoices();
    return {
      voices: voices ? voices.map(this._voiceToObject) : null
    };
  }
};

import React from 'react';
import { withModalDialogData } from '../../../reducer/modalDialog/selectors';
import ModalDialogConfirm from './ModalDialogConfirm';

const ConfirmBuySyncs = ({ modalDialogData }) => (
  <ModalDialogConfirm
    title="Покупка синхронизаций"
    text={`Количество: ${modalDialogData.syncCount}, итоговая сумма: ${modalDialogData.price}€`}
  />
);

export default withModalDialogData(ConfirmBuySyncs);

import { Container as ContainerPure } from 'reactstrap';
import { withCSS } from '@nonlux/react-common/composable';
import { createElement } from '@nonlux/react-common';
import { Link as LinkPure } from 'react-router-dom';

export const Title = createElement('h2', 'Title', {
  '@media (min-width: 0)': {
    fontSize: '30px',
    fontWeight: '300',
    color: '#61dafb',
  },
  '@media (min-width: 768px)': {
    fontSize: '35px',
  },
  '@media (min-width: 992px)': {
    fontSize: '40px',
  },
  '@media (min-width: 1200px)': {
    fontSize: '45px',
  },
});

export const Intro = createElement('h3', 'Strong', {
  '@media (min-width: 0)': {
    fontSize: '18px',
    fontWeight: '200',
    color: '#939394',
  },
  '@media (min-width: 768px)': {
    fontSize: '22px',
  },
  '@media (min-width: 992px)': {
    fontSize: '24px',
  },
  '@media (min-width: 1200px)': {
    fontSize: '28px',
  },
});

export const Text = createElement('p', 'Text', {
  '@media (min-width: 0)': {
    marginTop: '30px',
    fontSize: '16px',
  },
  '@media (min-width: 1200px)': {
    fontSize: '18px',
  },
});

export const Container = withCSS({
  marginTop: '55px',
  paddingTop: '80px',
  paddingBottom: '80px',
})(ContainerPure);

export const Link = withCSS({
  color: '#61dafb',
  ':hover': {
    color: '#939394',
    textDecoration: 'none',
  },
})(LinkPure);

import React from 'react';
import { ContainerContent, ImgContainer } from '../entities';
import { useTranslation } from 'react-i18next';
import {
  RowContent,
  P,
  ButtonContainer,
  H3,
  LinkTargetPrimaryLight
} from 'components';

export default function ExtensionInfo({ content, img, layoutType }) {
  const { t } = useTranslation();
  return (
    <ContainerContent title={t(content.title)} color="blue">
      <RowContent>
        <P className="text-center">
          {t(content.text)}
        </P>
        <ImgContainer img={img} />
      </RowContent>
{/*      <ButtonContainer mt={40}>
        <LinkTargetPrimaryLight
          to="sdfsd"
          target="_self"
          layoutType={layoutType}
        >
          {t('Быстрый старт')}
        </LinkTargetPrimaryLight>
      </ButtonContainer>*/}
    </ContainerContent>
  );
}
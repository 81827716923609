export const priceVdsSchema = ['gpu', 'daysCount'];

export const pricesVds = {
  tariffs: {
    eur: { 1: 9, 14: 29, 30: 49 },
    usd: { 1: 10, 14: 39, 30: 59 },
  },
  steps: {
    vcpu: [
      {
        min: 2, max: 10, eur: 12, usd: 14, step: 2,
      },
      {
        min: 10, max: 12, eur: 19, usd: 22, step: 2,
      },
      {
        min: 12, max: 16, eur: 54, usd: 64, step: 4,
      },
    ],
    ram: [
      {
        min: 4, max: 8, eur: 5, usd: 5, step: 2,
      },
      {
        min: 8, max: 16, eur: 17, usd: 20, step: 8,
      },
    ],
    space: [
      {
        min: 65, max: 500, eur: 1.5, usd: 1.5, step: 5,
      },
    ],
    buildCount: [
      {
        min: 0, max: 10, eur: 6, usd: 7, step: 1,
      },
      {
        min: 10, max: 20, eur: 5, usd: 6, step: 1,
      },
      {
        min: 20, max: Infinity, eur: 4, usd: 5, step: 1,
      },
    ],
  },
};

export const proxyCountries = ['uk', 'ua', 'ru', 'pl', 'es', 'de', 'ie', 'se', 'it', 'us', 'fr', 'ca', 'kz', 'nl'];
// export const proxyOperators = {
//   ru: ['tele2', 'megafon', 'mts', 'rostelecom', 'yota'],
//   uk: ['three', 'o2'],
//   ua: ['kyivstar', 'vodafon'],
//   es: ['movistar', 'yoigo', 'digi'],
//   se: ['comviq', 'telenor'],
//   ie: ['vodafon', 'three'],
//   it: ['tim'],
//   us: ['t-mobile', 'Verizon', 'Verizon Unlimited'],
//   pl: ['plus', 'orange'],
//   deAlt: ['o2', 'vodafon', 'Vodafone Unlimited', 'O2 Unlimited'],
//   fr: ['sfr'],
//   ca: ['rogers'],
//   kz: ['almatv', 'kazakhtelecom', 'jusanmobile'],
//   nl: ['t-mobile', 'VodafoneZiggo'],
// };

export const priceBuildsSchema = ['buildCount'];

// если выбрали 2 часа то увеличиваем стоиомсть сборки за "вне очереди"
export const pricesBuilds = {
  1: 7,
  7: 45, // экономия 15%, 6.4$ цена одной сборки
  15: 78, // экономия 30%, 5.2$ цена одной сборки
};

export const calcAdditionalPrice = (key, num, withFree = true) => pricesVds.steps[key].filter(s => num >= s.min).reduce((pv, cv) => {
  const max = num > cv.max ? cv.max : num;
  return pv + ((max - cv.min) / cv.step) * cv.eur;
}, 0) + (withFree ? 0 : pricesVds.steps[key][0].eur);

// eslint-disable-next-line no-shadow
export function calcVds({
  daysCount, vcpu = 2, ram = 4, space = 65, vdsCount: count = 1,
}) {
  const basePrice = pricesVds.tariffs.eur[daysCount];

  let additionalPrice = 0;

  const additions = {
    vcpu,
    ram,
    space,
  };

  Object.keys(additions).forEach((key) => {
    additionalPrice += calcAdditionalPrice(key, additions[key]);
  });

  return (basePrice + additionalPrice) * (count > 5 ? 0.9 : 1);
}

/**
 * return [{ buildCount: number, price: number }, ...]
 */
export function calcBuildPriceList({
  buildCount,
  vdsCount = 0,
  skip = 0,
  joinNonZero = false,
}) {
  const buildCountForPayment = buildCount - vdsCount + skip;

  const buildPriceList = pricesVds.steps.buildCount
    .filter(s => buildCountForPayment >= s.min)
    .map((step) => {
      let count = 0;

      if (skip > step.max) {
        count = 0;
      }
      if (skip > step.min && skip < step.max) {
        const skipCount = skip - step.min;
        count = Math.min(buildCountForPayment, step.max) - step.min - skipCount;
      }
      if (skip <= step.min) {
        count = Math.min(buildCountForPayment, step.max) - step.min;
      }

      const price = (count / step.step) * step.eur;

      return { buildCount: count, price };
    })
    .filter(bp => bp.buildCount);

  let res = [...buildPriceList];

  if (joinNonZero) {
    res = [
      buildPriceList.reduce((pv, cv) => ({
        buildCount: pv.buildCount + cv.buildCount,
        price: pv.price + cv.price,
      }), { buildCount: 0, price: 0 }),
    ];
  } else {
    res = [...buildPriceList];
  }

  if (vdsCount > 0) {
    res.push({ buildCount: vdsCount, price: 0 });
  }

  return res.filter(i => i.buildCount);
}

export const priceProxySchema = ['daysCount', 'geo', 'proxyType'];
const baseProxyPrice = {
  usd: {
    1: 10,
    14: 69,
    30: 109,
  },
  eur: {
    1: { 0: 9, 2: 9 },
    14: { 0: 69, 2: 79 },
    30: { 0: 109, 2: 129 },
  },
};
export const pricesProxy = {
  vpn: {
    0: 0,
    1: 5,
  },
  authorization: { switch: { 1: 1.125 } },
  proxyType: {
    ru: {
      usd: {
        1: { 0: 10, 1: 5 },
        14: { 0: 39, 1: 15 },
        30: { 0: 59, 1: 19 },
      },
      eur: {
        1: { 0: 9, 1: 5 },
        14: { 0: 29, 1: 15 },
        30: { 0: 49, 1: 19 },
      },
    },
    uk: {
      sm: { ...baseProxyPrice },
      o2: {
        usd: {
          1: { 0: 10, 1: 5 },
          14: { 0: 39, 1: 15 },
          30: { 0: 59, 1: 19 },
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 29, 2: 59.86 },
          30: { 0: 49, 2: 119.72 },
        },
      },
      three: {
        usd: {
          1: { 0: 10, 1: 5 },
          14: { 0: 39, 1: 15 },
          30: { 0: 59, 1: 19 },
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 29, 2: 59.86 },
          30: { 0: 49, 2: 119.72 },
        },
      },
      ee: { ...baseProxyPrice },
      vo: { ...baseProxyPrice },
    },
    ua: {
      usd: {
        1: 10,
        14: 49,
        30: 79,
      },
      eur: {
        1: { 0: 9, 2: 9 },
        14: { 0: 39, 2: 49 },
        30: { 0: 59, 2: 79 },
      },
    },
    kz: {
      usd: {
        1: 9,
        14: 30,
        30: 59,
      },
      eur: {
        1: { 0: 9, 2: 9 },
        14: { 0: 30, 2: 30 },
        30: { 0: 59, 2: 59 },
      },
    },
    se: {
      usd: {
        1: 10,
        14: 59,
        30: 89,
      },
      eur: {
        1: 9,
        14: 79,
        30: { 0: 99, 2: 129 },
      },
    },
    pl: {
      plus: {
        usd: {
          1: 9,
          14: 29,
          30: 49,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 29, 2: 41.28 },
          30: { 0: 49, 2: 82.57 },
        },
      },
      orange: {
        usd: {
          1: 9,
          14: 29,
          30: 49,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 29, 2: 41.28 },
          30: { 0: 49, 2: 82.57 },
        },
      },
    },
    es: {
      digi: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 59.86 },
          30: { 0: 99, 2: 119.72 },
        },
      },
      movistar: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 59.86 },
          30: { 0: 99, 2: 119.72 },
        },
      },
      yoigo: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 59.86 },
          30: { 0: 99, 2: 119.72 },
        },
      },
    },
    de: {
      o2: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 59.86 },
          30: { 0: 99, 2: 119.72 },
        },
      },
      vodafon: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 71.27 },
          30: { 0: 99, 2: 152.75 },
        },
      },
      'Vodafone Unlimited': {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 69, 2: 9 },
          14: { 0: 69, 2: 115.6 },
          30: { 0: 99, 2: 247.71 },
        },
      },
      'O2 Unlimited': {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 69, 2: 9 },
          14: { 0: 69, 2: 115.6 },
          30: { 0: 99, 2: 247.71 },
        },
      },
    },
    ie: {
      vodafon: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 69, 2: 9 },
          14: { 0: 69, 2: 59.86 },
          30: { 0: 99, 2: 119.72 },
        },
      },
      three: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 69, 2: 9 },
          14: { 0: 69, 2: 59.86 },
          30: { 0: 99, 2: 119.72 },
        },
      },
    },
    it: {
      tim: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 79 },
          30: { 0: 99, 2: 129 },
        },
      },
      vodafon: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 51.61 },
          30: { 0: 99, 2: 103.21 },
        },
      },
      windtre: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 51.61 },
          30: { 0: 99, 2: 103.21 },
        },
      },
    },
    nl: {
      usd: {
        1: 10,
        14: 69,
        30: 109,
      },
      eur: {
        1: { 0: 9, 2: 9 },
        14: { 0: 69, 2: 59.86 },
        30: { 0: 99, 2: 119.72 },
      },
    },
    us: {
      't-mobile': {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 59.86 },
          30: { 0: 99, 2: 119.72 },
        },
      },
      Verizon: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 61.65 },
          30: { 0: 99, 2: 132.11 },
        },
      },
      'Verizon Unlimited': {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 69.36 },
          30: { 0: 99, 2: 148.62 },
        },
      },
    },
    cz: { ...baseProxyPrice },
    fr: {
      sfr: {
        usd: {
          1: 10,
          14: 69,
          30: 109,
        },
        eur: {
          1: { 0: 9, 2: 9 },
          14: { 0: 69, 2: 41.28 },
          30: { 0: 99, 2: 82.57 },
        },
      },
    },
    ca: {
      usd: {
        1: 10,
        14: 69,
        30: 109,
      },
      eur: {
        1: { 0: 9, 2: 9 },
        14: { 0: 69, 2: 55.87 },
        30: { 0: 99, 2: 119.72 },
      },
    },
  },
};

export const pricesVPN = 5;

// eslint-disable-next-line no-shadow
export function calcProxy({
  proxyType, vpn, authorization = 'pass', geo, daysCount, operator, airId, proxyCount: count = 1,
}) {
  try {
  // eslint-disable-next-line no-param-reassign
    if (!operator || operator === '***') operator = airId && airId.operator ? airId.operator.toLowerCase() : 'th';
    // eslint-disable-next-line no-param-reassign
    // if (geo === 'uk' && !Object.keys(pricesProxy.proxyType.uk).includes(operator)) operator = 'th';
    let basePrice;
    let additionalPrice = 0;
    // if (geo === 'uk') {
    //   const prices = pricesProxy.proxyType[geo][operator.slice(0, 2)
    //     .toLowerCase()];
    //   if (!prices) throw new Error('Invalid proxy parameters for price calculation');
    //   basePrice = prices.eur[daysCount];
    // } else
    if (!pricesProxy.proxyType[geo].eur) {
      const prices = pricesProxy.proxyType[geo][operator] || pricesProxy.proxyType[geo][operator.toLowerCase()];
      if (!prices) throw new Error('Invalid proxy parameters for price calculation');
      basePrice = prices.eur[daysCount];
    } else basePrice = pricesProxy.proxyType[geo].eur[daysCount];

    if (!Number.isInteger(basePrice)) basePrice = basePrice[proxyType];

    if (daysCount > 1) {
      additionalPrice = pricesProxy.vpn[vpn];
    }

    let multiplier = 1;
    // if (proxyType === '2' && daysCount > 1) multiplier = 2;
    if (proxyType === '0' && authorization === 'switch' && daysCount > 1) multiplier = 1.125;

    return (basePrice * multiplier + additionalPrice) * (count > 5 && proxyType === '2' ? 0.9 : 1);
  } catch (e) {
    console.error(geo, operator, proxyType, daysCount, e);
  }
}

export const defaultPrices = {
  pricesVds,
  pricesBuilds,
  pricesProxy,
  pricesVPN,
};


export const getProfileLimits = () => ({
  basic: 5,
  standard: 25,
  advanced: 0,
});

export const profileLimits = {};

export const pricesBetStorm = {
  basic: 29,
  standard: 49,
  advanced: 59,
};
export const pricesProfileSync = {
  0: 21,
  10: 17,
  25: 13,
};

export const freeSyncsForTariff = {
  basic: 1,
  standard: 2,
  advanced: 3,
};

/**
 * return [{ count: number, price: number }, ...]
 */
export function calculateSyncsPriceList({
  amount, profileSyncs, tariff, joinNonZero = false,
}) {
  let profileSyncsForPayment = profileSyncs;
  if (tariff) {
    profileSyncsForPayment -= freeSyncsForTariff[tariff.toLowerCase()] || 0;
  }
  const priceList = Object.keys(pricesProfileSync)
    .sort((a, b) => b - a)
    .reduce((pv, cv) => {
      // eslint-disable-next-line no-param-reassign
      cv = Number(cv);
      if (profileSyncsForPayment + amount <= cv || amount <= 0) return pv;
      const amountForCurrPrice = profileSyncsForPayment + amount - Math.max(cv, profileSyncsForPayment);
      // eslint-disable-next-line no-param-reassign
      amount -= amountForCurrPrice;

      pv.push({
        count: amountForCurrPrice,
        price: amountForCurrPrice * pricesProfileSync[cv.toString()],
      });
      return pv;
    }, []).reverse();

  let res;

  if (joinNonZero) {
    res = [
      ...priceList.filter(p => p.price === 0),
      priceList.reduce((pv, cv) => ({
        count: cv.count + pv.count,
        price: pv.price + cv.price,
      }), { count: 0, price: 0 }),
    ];
  } else {
    res = priceList;
  }
  return res.filter(i => i.count);
}

export function calculateSyncsPrice(amount, profileSyncs) {
  return Object.keys(pricesProfileSync)
    .sort((a, b) => b - a)
    .reduce((pv, cv) => {
      // eslint-disable-next-line no-param-reassign
      cv = Number(cv);
      if (profileSyncs + amount <= cv || amount <= 0) return pv + 0;
      const amountForCurrPrice = profileSyncs + amount - Math.max(cv, profileSyncs);
      // eslint-disable-next-line no-param-reassign
      amount -= amountForCurrPrice;

      return pv + amountForCurrPrice * pricesProfileSync[cv.toString()];
    }, 0);
}

export const residentialProxyCostDependencies = {
  // variable cost
  internetProviderCost: 8.38,
  equipmentDeprecation: 0.58,
  maintenanceCost: 0.38,
  // permanent cost
  developmentCost: 15000,
  marketingCost: 5000,
  infrastructureCost: 600,
  clientSupportCost: 500,
  // demand
  expectedDemand: 1000,
  // markup
  unitMarkup: 0.6,
};

export function calculateResidentialProxyPrice() {
  const dep = residentialProxyCostDependencies;
  const variableCost = dep.internetProviderCost + dep.equipmentDeprecation + dep.maintenanceCost;
  const constantCost = dep.developmentCost + dep.marketingCost + dep.infrastructureCost + dep.clientSupportCost;
  const unitCost = variableCost + constantCost / 1000;
  return Math.ceil(unitCost / (1 - dep.unitMarkup) * 100) / 100;
}

/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions: 0 */
import React, { Fragment } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { css } from 'aphrodite/no-important';
import selectOpen from '../../decorators/selectOpen';

function SelectBar(props) {
  const {
    lable,
    layoutType,
    dataFirst,
    dataSecond,
    dataThird,
    handleClickFirst,
    handleClickSecond,
    handleClickThird,
    sliderFirst,
    sliderSecond,
    sliderThird,
    nav,
    navFirst,
    navSecond,
    navThird,
    box,
    fontLable,
    fontNav,
    fontNavActive,
    container,
    imgStyles,
    fontTitle,
    margin,
    img,
    styleImg,
    selectOne,
    selectTwo,
    selectThree,
  } = props;
  const handleData = () => {
    if (sliderFirst) {
      return dataFirst;
    }
    if (sliderSecond) {
      return dataSecond;
    }
    if (sliderThird) {
      return dataThird;
    }
    return dataFirst;
  };
  const stayleSelectBarNav = css(
    nav,
    sliderFirst && navFirst,
    sliderSecond && navSecond,
    sliderThird && navThird,
  );
  const layoutLg = (layoutType === 'md' || layoutType === 'lg' || layoutType === 'xl') && (
    <Row>
      <DataList
        data={handleData()}
        layoutType={layoutType}
        imgStyles={imgStyles}
        fontTitle={fontTitle}
        margin={margin}
      />
    </Row>
  );
  const layoutXs = (layoutType === 'xs' || layoutType === 'sm')
    && (
    <DataList
      data={handleData()}
      layoutType={layoutType}
      imgStyles={imgStyles}
      fontTitle={fontTitle}
      margin={margin}
    />
    );
  const imgInfo = img && (
    <Row className="mt-5">
      <Col className="text-center">
        <img alt="icon" className={styleImg} src={img} />
      </Col>
    </Row>
  );

  return (
    <Row className={box} id="info_vds">
      <Col>
        <Row>
          <Col className="text-center">
            <h2 className={fontLable}>
              {lable}
            </h2>
          </Col>
        </Row>
        {imgInfo}
        <Row className={stayleSelectBarNav}>
          <Col className="text-center">
            <h3
              onClick={handleClickFirst}
              className={css(fontNav, sliderFirst && fontNavActive)}
            >
              {selectOne}
            </h3>
          </Col>
          <Col className="text-center">
            <h3
              onClick={handleClickSecond}
              className={css(fontNav, sliderSecond && fontNavActive)}
            >
              {selectTwo}
            </h3>
          </Col>
          <Col className="text-center">
            <h3
              onClick={handleClickThird}
              className={css(fontNav, sliderThird && fontNavActive)}
            >
              {selectThree}
            </h3>
          </Col>
        </Row>
        <Container className={container}>
          {layoutLg}
          {layoutXs}
        </Container>
      </Col>
    </Row>
  );
}
export default selectOpen(SelectBar);

function DataList({
  data, layoutType, imgStyles, fontTitle, margin,
}) {
  return data.map(item => (
    <Data
      key={`${item.title}`}
      {...item}
      layoutType={layoutType}
      imgStyles={imgStyles}
      fontTitle={fontTitle}
      margin={margin}
    />));
}
function Data(props) {
  const {
    layoutType,
    img,
    text,
    title,
    imgStyles,
    fontTitle,
    margin,
  } = props;
  const dataLg = (layoutType === 'md' || layoutType === 'lg' || layoutType === 'xl') && (
    <Col className="text-center">
      <img alt="icon" className={imgStyles} src={img} />
      <h3 className={css(fontTitle, margin)}>
        {title}
      </h3>
      <p>
        {text}
      </p>
    </Col>
  );
  const dataXs = (layoutType === 'xs' || layoutType === 'sm') && (
    <Row>
      <Col xs="4" className="text-center">
        <img alt="icon" className={imgStyles} src={img} />
      </Col>
      <Col xs="8">
        <h3 className={css(fontTitle)}>
          {title}
        </h3>
        <p>
          {text}
        </p>
      </Col>
    </Row>
  );
  return (
    <Fragment>
      {dataLg}
      {dataXs}
    </Fragment>
  );
}

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { isNil } from 'ramda';
import { toastr } from 'react-redux-toastr';

const onMoreThanAllowableCount = () => toastr.error('Больше чем максимальное значение');

const Counter = ({
  setFunction,
  count,
  step = 1,
  min = 1,
  max = Infinity,
  progressiveStep = [],
  isSaveFocusOnInputWhenBlur,
  borderColor
}) => {
  const [inputRef, setInputRef] = useState(null);
  const onDivRef = div => div && div.children && setInputRef(div.children[0]);

  const handleClick = (oper, step, progressiveStep) => {
    if (oper === 'increment') {
      if (progressiveStep.length) {
        if (count >= max) {
          onMoreThanAllowableCount();
          setFunction(max);
          return;
        } setFunction(count >= progressiveStep[0] ? count + progressiveStep[1] : count + step);
        return;
      }
      if (count >= max) {
        onMoreThanAllowableCount();
        setFunction(max);
        return;
      } setFunction(count + step);
    }
    if (oper === 'decrement') {
      if (progressiveStep.length) {
        setFunction(count <= min ? min : count > progressiveStep[0] ? count - progressiveStep[1] : count - step);
        return;
      }
      setFunction(count <= min ? min : count - step);
    }
  };

  const onInputChange = (e) => {
    const { value } = e.target;
    if (value === '') {
      setFunction('');
    } else if (value && /[!-/:-~А-я]/.test(value)) {
      setFunction(min);
    } else if (!isNil(Number(value))) {
      if (max >= 0 && Number(value) > max) {
        setFunction(max);
        onMoreThanAllowableCount();
        return;
      } if (progressiveStep.length && Number(value) >= progressiveStep[0]) {
        const a = Math.abs(value - progressiveStep[0]);
        const b = Math.abs(value - (progressiveStep[0] + progressiveStep[1]));
        setFunction(a <= b ? progressiveStep[0] : progressiveStep[0] + progressiveStep[1]);
      } else {
        setFunction(Number(value));
      }
    }
  };

  const onInputBlur = (e) => {
    if (typeof count === 'string' || Number(count) < min || isNaN(count)) {
      setFunction(min);
    } else if (isSaveFocusOnInputWhenBlur(e)) {
      setTimeout(() => inputRef.focus(), 300);
    } else {
      setFunction(Math.ceil(Number(count) / step) * step);
    }
  };

  return (
    <CounterContainer>
      <CounterButton
        left
        onClick={() => handleClick('decrement', step, progressiveStep)}
      >
        -
      </CounterButton>
      <div ref={onDivRef}>
        <CounterInput
          type="text"
          value={count}
          onChange={onInputChange}
          onBlur={onInputBlur}
          borderColor={borderColor}
        />
      </div>
      <CounterButton
        right
        onClick={() => handleClick('increment', step, progressiveStep)}
      >
        +
      </CounterButton>
    </CounterContainer>
  );
};


export default Counter;


const CounterContainer = styled.div`
    display: block;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-user-select: none;
    user-select: none;
`;

const CounterButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 40px;
    background: 0 0;
    color: #333;
    font-size: 18px;
    line-height: 30px;
    padding: 10px;
    font-weight: 700;
    border: 0;
    cursor: pointer;
    ${({ left }) => left
  && css`
        left: 0;
      `};
      ${({ right }) => right
  && css`
          right: 0;
  `};
      &:hover {
          outline: none;
      }
      &:focus {
          outline: none;
      }
      &:active {
          outline: none;
      }
`;

const CounterInput = styled.input`
    display: block;
    border-radius: 0;
    min-width: 50px;
    background: #fff;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 50px;
    font-size: 17px;
    line-height: 20px;
    padding: 14px 40px;
    color: #000;
    font-weight: 500;
    border: 1px solid ${({ borderColor }) => (borderColor || '#f5f5f5')};
    text-align: center;
    box-sizing: border-box;
    outline: 0;
      &:focus {
        border-color: #f5f5f5;
       }
`;

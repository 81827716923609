export default {
  REG_ERROR: 'Invalid registration data',
  REG_DATA_SEND: 'Create new user',
  APPLY_REG_DATA_SEND: 'Create parner user',
  CLEAR_REG_ERRORS: 'Clear server side errors for reg form',
  SIGN_IN: 'User sign in',
  SIGN_PASSWORD: 'User sign in password',
  CLEAR_SIGN_IN_ERRORS: 'Clear sign in errors',
  SIGN_IN_ERRORS: 'Sign in errors',
  GO_MAIN: 'Go main',
  GO_SIGN_IN: 'Go sign in',
  GO_PANEL: 'Go panel',
  GO_REFERER: 'Go referer'
};

import React from 'react';
import { compose } from 'redux';
import { ModalWindow } from 'components';
import { withModalDialogActions } from '../../../reducer/modalDialog/selectors';

const ModalDialogConfirm = ({ title, text, modalConfirm, modalCancel, ...props }) => (
  <ModalWindow
    modal
    title={title}
    text={text}
    toggle={modalCancel}
    toggleSuccess={modalConfirm}
    {...props}
  />
);

export default compose(withModalDialogActions)(ModalDialogConfirm);

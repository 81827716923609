import React  from 'react';
import { Col, Row, Container } from 'reactstrap';
import { createDiv } from '@nonlux/react-common';
import { withCSS } from '@nonlux/react-common/composable';
import {
  Img,
  H2,
} from 'components';
import STYLES from '../../styles/constants.js';

const RowColContainer = ({ children, className, id = false }) => (
  <Row className={className} id={id}>
    {/*    <ImgContaierFp0>
      <Img src={fp0} />
    </ImgContaierFp0> */}
    <Col>
      {children}
    </Col>
  </Row>
);

const ContainerContentPure = withCSS(({ color = false }) => ({
  backgroundColor: color ? STYLES.COLORS.WHITE_BLUE : STYLES.COLORS.WHITE,
  paddingTop: '60px',
  paddingBottom: '60px'
}))(RowColContainer);

const TitleContainer = createDiv('TitleContainer', ({ titleWidth }) => ({
  maxWidth: titleWidth ? `${titleWidth}px` : '100%',
  textAlign: 'center'
}));

export const ContainerContent = (props) => {
  const {
    title,
    children,
    color,
    titleWidth,
    id
  } = props;
  const hideTitle = () => (title && (
    <Row className="justify-content-center">
      <TitleContainer titleWidth={titleWidth}>
        <H2>{title}</H2>
      </TitleContainer>
    </Row>
  ));
  return (
    <ContainerContentPure color={color} id={id}>
      {hideTitle()}
      <Container>
        {children}
      </Container>
    </ContainerContentPure>
  );
};

const ImgContainerPure = createDiv('ImgContainer', {
  position: 'relative',
  width: '250px',
  marginRight: 'auto',
  marginLeft: 'auto',
  marginTop: '40px'
});

export function ImgContainer({ img }) {
  return (
    <ImgContainerPure>
      <Img src={img} />
    </ImgContainerPure>
  );
}
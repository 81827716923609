import React from 'react';
import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  Form,
} from 'reactstrap';
import { Input } from 'components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withFormikConnected } from '@nonlux/react-common/formik';
import { forgotPasswordAction } from 'reducer/verifyRecover/actions';
import { css } from 'aphrodite';
import { styles } from './styles';

export default function ForgotPasswordPage() {
  const { t } = useTranslation();
  return (
    <Row className={css(styles.forgot_form__box)}>
      <Container className={css(styles.forgot_form__container)}>
        <h2 className={css(styles.forgot_form__font_title)}>
          {t('forgotPassword.title')}
        </h2>
        <Row className="mt-4">
          <Col>
            <Card>
              <CardBody>
                <ForgotPasswordFormConnected />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className={css(styles.forgot_form__box_btn)}>
          <Col>
            <Link to="/sign_in" className={css(styles.forgot_form_link)}>
              &#60;&nbsp;
              {t('forgotPassword.link')}
            </Link>
          </Col>
        </Row>
      </Container>
    </Row>
  );
}

const ForgotPasswordForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;
  const { t } = useTranslation();
  const styleErrEmail = `${errors.email && touched.email ? 'text-input error' : 'text-input'} mt-1`;
  const messageErrEmail = touched.email && errors.email
    && (
    <div className={css(styles.input__feedback)}>
      {t(errors.email)}
    </div>
    );
  return (
    <Form onSubmit={handleSubmit}>
      <small className="text-muted">
        {t('forgotPassword.email.label')}
      </small>
      <Input
        type="email"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        className={styleErrEmail}
        placeholder={t('forgotPassword.email.placeholder')}
      />
      {messageErrEmail}
      <Row className={css(styles.forgot_form__box_btn)}>
        <Col xs="auto">
          <Button className={`${css(styles.btn__primary)} rounded-0`}>
            {t('forgotPassword.button')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const ForgotPasswordFormConnected = withFormikConnected({
  mapPropsToValues: () => ({ email: '', password: '' }),
  validate: (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'forgotPassword.errorsMessages.email';
    } if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'forgotPassword.errorsMessages.email';
    }
    return errors;
  },
  handleSubmit(values, { props: { dispatch } }) {
    dispatch(forgotPasswordAction(values));
  },
})(ForgotPasswordForm);

import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { withCSS } from '@nonlux/react-common/composable';
import STYLES from '../../styles/constants.js';

const font = {
  sm: [14, 14, 14, 14, 14],
  md: [16, 16, 16, 16, 16],
  lg: [20, 20, 20, 20, 20],
};
const padding = {
  sm: '8px 14px',
  md: '8px 16px',
  lg: '10px 25px',
};

/**
 * параметр target:
 _blank - загружает страницу в новое окно браузера.
 _self - загружает страницу в текущее окно.
 _parent - загружает страницу во фрейм-родитель, если фреймов нет,
 то это значение работает как _self.
 _top - отменяет все фреймы и загружает страницу в полном окне браузера,
 если фреймов нет, то это значение работает как _self.
 */
export const LinkTarget = (props) => {
  const {
    children, className, to, target = 'self',
  } = props;
  return (
    <a className={className} target={`_${target}`} href={to}>
      {children}
    </a>
  );
};

export const LinkPrimaryDarkTarget = withCSS(({ layoutType, size = 'lg' }) => ({
  padding: padding[size],
  backgroundColor: STYLES.COLORS.BLUE,
  color: STYLES.COLORS.BLACK,
  textDecoration: 'none',
  fontSize: STYLES.getMedia(font[size], layoutType),
  cursor: 'pointer',
  ':hover': {
    transition: '0.3s',
    backgroundColor: STYLES.COLORS.WHITE,
    color: STYLES.COLORS.BLACK,
    textDecoration: 'none',
  },
}))(LinkTarget);

export const LinkPrimaryDark = withCSS(({ layoutType, size = 'lg' }) => ({
  padding: padding[size],
  backgroundColor: STYLES.COLORS.BLUE,
  color: STYLES.COLORS.BLACK,
  textDecoration: 'none',
  fontSize: STYLES.getMedia(font[size], layoutType),
  cursor: 'pointer',
  ':hover': {
    transition: '0.3s',
    backgroundColor: STYLES.COLORS.WHITE,
    color: STYLES.COLORS.BLACK,
    textDecoration: 'none',
  },
}))(Link);

export const HashLinkPrimaryDark = withCSS(({ layoutType, size = 'lg' }) => ({
  padding: padding[size],
  backgroundColor: STYLES.COLORS.BLUE,
  color: STYLES.COLORS.BLACK,
  textDecoration: 'none',
  fontSize: STYLES.getMedia(font[size], layoutType),
  cursor: 'pointer',
  ':hover': {
    transition: '0.3s',
    backgroundColor: STYLES.COLORS.WHITE,
    color: STYLES.COLORS.BLACK,
    textDecoration: 'none',
  },
}))(HashLink);

export const HashLinkOutline = withCSS(({ mr = 0, layoutType, size = 'lg' }) => ({
  outline: `1px solid ${STYLES.COLORS.WHITE}`,
  color: STYLES.COLORS.WHITE,
  fontSize: STYLES.getMedia(font[size], layoutType),
  cursor: 'pointer',
  userSelect: 'none',
  marginRight: `${mr}px`,
  padding: '9px 25px',
  textDecoration: 'none',
  ':hover': {
    transition: '0.3s',
    backgroundColor: 'rgba(192, 192, 192, 0.1)',
    color: STYLES.COLORS.WHITE,
    textDecoration: 'none',
  },
}))(HashLink);

export const LinkSecondaryDark = withCSS(({ mr = 0, layoutType, size = 'lg' }) => {
  return ({
    padding: padding[size],
    backgroundColor: STYLES.COLORS.WHITE,
    color: STYLES.COLORS.BLACK,
    textDecoration: 'none',
    fontSize: STYLES.getMedia(font[size], layoutType),
    cursor: 'pointer',
    marginRight: `${mr}px`,
    ':hover': {
      transition: '0.3s',
      color: STYLES.COLORS.BLACK,
      textDecoration: 'none',
    },
  });
})(Link);

export const LinkTargetPrimaryLight = withCSS(({ layoutType, size = 'lg', fontSize }) => ({
  padding: padding[size],
  backgroundColor: STYLES.COLORS.BLUE,
  color: STYLES.COLORS.WHITE,
  textDecoration: 'none',
  fontSize: fontSize ? `${fontSize}px` : STYLES.getMedia(font[size], layoutType),
  cursor: 'pointer',
  ':hover': {
    transition: '0.3s',
    backgroundColor: STYLES.COLORS.BLUE_LIGHT,
    color: STYLES.COLORS.WHITE,
    textDecoration: 'none',
  },
}))(LinkTarget);

export const LinkPrimaryLight = withCSS(({ layoutType, size = 'lg' }) => ({
  padding: padding[size],
  backgroundColor: STYLES.COLORS.BLUE,
  color: STYLES.COLORS.WHITE,
  textDecoration: 'none',
  fontSize: STYLES.getMedia(font[size], layoutType),
  cursor: 'pointer',
  ':hover': {
    transition: '0.3s',
    backgroundColor: STYLES.COLORS.BLUE_LIGHT,
    color: STYLES.COLORS.WHITE,
    textDecoration: 'none',
  },
}))(Link);

export const LinkSecondaryLight = withCSS(({ mr = 0, layoutType, size = 'lg' }) => ({
  outline: `1px solid ${STYLES.COLORS.DARK_GRAY}`,
  color: STYLES.COLORS.GRAY,
  fontSize: STYLES.getMedia(font[size], layoutType),
  cursor: 'pointer',
  userSelect: 'none',
  marginRight: `${mr}px`,
  padding: '9px 25px',
  textDecoration: 'none',
  ':hover': {
    transition: '0.3s',
    backgroundColor: 'rgba(192, 192, 192, 0.4)',
    color: STYLES.COLORS.GRAY,
    textDecoration: 'none',
  },
}))(Link);

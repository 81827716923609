import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Col, Row, ListGroup as ListGroupPure, ListGroupItem as ListGroupItemPure
} from 'reactstrap';
import {
  withCSS, toggleble
} from '@nonlux/react-common/composable';
import { createElement } from '@nonlux/react-common';
import { compose } from 'recompose';
import { NavLink as NavLinkPure, Link as LinkPure, Link as LinkBtnPure } from 'react-router-dom';
import {
  LinkPrimaryDark,
  LinkSecondaryDark
} from 'components';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';
import axios from 'axios';
import toggler from './icon/menu.png';

import lngSprite from './icon/lng_sprite.png';
import { LinkPrimaryDarkTarget } from '../Links/Links';

function ToggleLayout(props) {
  const { layoutType } = props;
  return (['xl'].includes(layoutType)) ? <LayoutLg {...props} /> : <LayoutXs {...props} />;
}

function Header(props) {
  const [isOpen, toggleOpen] = useState(false); // тоглер языкового меню
  const [isState, setState] = useState(false); // тоглер иконки языкового меню
  const [isOpenNav, toggleOpenNav] = useState(false); // тоглер навМеню
  const [authorized, setAuthorized] = useState(false);
  useEffect(() => {
    (async () => {
      const { data } = await axios.get('/api/authenticated');
      setAuthorized(data.result);
    })();
  }, []);
  const addProps = {
    ...props,
    isOpen,
    toggleOpen,
    isState,
    setState,
    isOpenNav,
    toggleOpenNav,
    isAuthorization: authorized
  };
  return (
    <NavBar className="fixed-top">
      <ToggleLayout {...addProps} />
    </NavBar>
  );
}
export default compose(
  connect(state => ({
    layoutType: state.windowResize.layoutType
  }), null, null, { pure: false }),
  toggleble(),
)(Header);

const NavBarPure = createElement('div', 'NavBarPure', 'row');

const NavBar = withCSS({
  backgroundColor: '#20232a',
  height: '55px',
  paddingRight: '10px'
})(NavBarPure);

const Link = withCSS({
  height: '100%',
  display: 'block',
  fontSize: '20px',
  color: '#61dafb',
  cursor: 'pointer',
  padding: '13px 15px 0 15px',
  textDecoration: 'none',
  userSelect: 'none',
  ':hover': {
    color: '#61dafb',
    textDecoration: 'none'
  }
}, true)(LinkPure);

const NavLink = withCSS({
  height: '55px',
  display: 'block',
  color: '#fff',
  cursor: 'pointer',
  padding: '15px',
  fontWeight: 300,
  textDecoration: 'none',
  userSelect: 'none',
  ':hover': {
    color: '#61dafb',
    textDecoration: 'none'
  }
})(NavLinkPure);

const NavLinkTargetPure = ({
  children, className, to, target = '_self'
}) => (
  <a className={className} target={target} href={to}>
    {children}
  </a>
);
const NavLinkTarget = withCSS(({ pl = 15 }) => ({
  height: '55px',
  display: 'block',
  color: '#fff',
  cursor: 'pointer',
  fontWeight: 300,
  lineHeight: '55px',
  paddingLeft: `${pl}px`,
  paddingRight: '15px',
  textDecoration: 'none',
  userSelect: 'none',
  ':hover': {
    color: '#61dafb',
    textDecoration: 'none'
  }
}))(NavLinkTargetPure);

const LinkBtn = withCSS({
  borderColor: '#61dafb',
  backgroundColor: '#61dafb',
  boxShadow: 'none',
  ':hover': {
    borderColor: 'white',
    backgroundColor: 'white',
    color: 'black',
    boxShadow: 'none',
    textDecoration: 'none'
  },
  ':active': {
    backgroundColor: '#56c7e4',
    color: 'white',
    boxShadow: 'none'
  },
  ':focus': {
    boxShadow: 'none'
  },
  color: 'black',
  textDecoration: 'none',
  userSelect: 'none',
  margin: '10px',
  padding: '5px 15px'
})(LinkBtnPure);

const LinkBtnWht = withCSS({
  backgroundColor: 'white',
  borderColor: 'white'
})(LinkBtn);

function getActiveClassLink(layoutType) {
  const activeStyleLink = {
    color: '#61dafb',
    borderBottom: '3px solid #61dafb'
  };
  const activeStyleLinkToggle = {
    color: '#61dafb'
  };
  if (['xl'].includes(layoutType)) return activeStyleLink;
  return activeStyleLinkToggle;
}

function NavBarBrandPure(props) {
  const {
    children, layoutType, to, className, onClick
  } = props;
  function getClass() {
    if (['sm', 'md', 'lg'].includes(layoutType)) return 'mr-auto';
    if (layoutType === 'xs') return `${className} mr-auto`;
    return null;
  }
  return (
    <Col xs="auto" className={getClass()} onClick={onClick}>
      <Link to={to}>
        {children}
      </Link>
    </Col>
  );
}
const NavBarBrand = withCSS({
  '@media (min-width: 0) and (max-width: 575px)': {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})(NavBarBrandPure);

const NavPure = ({ children, row, className }) => {
  if (row) {
    return (
      <Row className={className}>
        <Col>
          {children}
        </Col>
      </Row>
    );
  }
  return (
    <Col>
      <Row>
        {children}
      </Row>
    </Col>
  );
};
const Nav = withCSS({
  position: 'absolute',
  zIndex: '100000',
  marginTop: '55px',
  backgroundColor: '#20232a',
  width: '100%',
  paddingLeft: '15px'
})(NavPure);

const NavItemPure = ({ children, row, className }) => {
  if (row) {
    return (
      <Row className={className}>
        <Col>
          {children}
        </Col>
      </Row>
    );
  }
  return (
    <Col xs="auto" className={className}>
      {children}
    </Col>
  );
};
const NavItem = withCSS({
  color: '#fff',
  paddingTop: '15px'
}, true)(NavItemPure);

function Collapse({ children, isOpen, layoutType }) {
  if (['xl'].includes(layoutType)) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
  if (['xs', 'sm', 'md', 'lg'].includes(layoutType) && isOpen) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
  return <Fragment />;
}

const Toggler = () => <img alt="toggler" style={{ height: '30px' }} src={toggler} />;

function NavbarTogglerPure({ className, toggleOpenNav, isOpenNav }) {
  return (
    <Col xs="auto" className="mr-md-0 mr-sm-auto pr-0">
      <div
        role="button"
        className={className}
        tabIndex={0}
        onClick={() => toggleOpenNav(!isOpenNav)}
        onKeyDown={() => toggleOpenNav(!isOpenNav)}
      >
        <Toggler />
      </div>
    </Col>
  );
}
const NavbarToggler = withCSS({
  marginTop: '12px',
  marginLeft: '15px',
  padding: 0,
  cursor: 'pointer'
})(NavbarTogglerPure);

const changeLanguage = (lng, props) => {
  const {
    isOpen, toggleOpen, toggleOpenNav, i18n
  } = props;
  i18n.changeLanguage(lng);
  toggleOpen(!isOpen);
  toggleOpenNav(false);
};

const LngBoxDropdown = (props) => {
  const { i18n } = useTranslation();
  const addProps = {
    ...props,
    i18n
  };
  const { layoutType } = props;
  return (
    <ListGroup layoutType={layoutType}>
      <ListGroupItem onClick={() => changeLanguage('ru', addProps)}>Русский</ListGroupItem>
      <ListGroupItem onClick={() => changeLanguage('en', addProps)}>English</ListGroupItem>
      <ListGroupItem onClick={() => changeLanguage('cn', addProps)}>中國人</ListGroupItem>
    </ListGroup>
  );
};

const ListGroupItem = withCSS({
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  width: '100%',
  cursor: 'pointer',
  ':hover': {
    color: '#61dafb'
  }
})(ListGroupItemPure);

const ListGroup = withCSS(({ layoutType }) => ({
  position: !['xl'].includes(layoutType) ? 'relative' : 'absolute',
  marginLeft: '15px'
}))(ListGroupPure);


const Lng = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 55px;
`;

const Rollover = styled.div`
  height: 17px;
  line-height: 17px;
  padding-left: 25px;
  margin-right: 15px;
  ${({ layoutType }) => !['xl'].includes(layoutType) && css`margin-left: 15px;`};
  font-weight: 300;
  user-select: none;
  cursor: pointer;
  color: ${({ isOpen }) => (isOpen ? '#61dafb' : '#fff')};
  // rollover
  background-image: url(${lngSprite});
  background-repeat: no-repeat;
  background-position: ${({ isOpen }) => (isOpen ? '0 -17.5px' : 'left top')};;
  background-size: auto 35px;
  &:hover {
    background-position: 0 -17.5px;
    color: #61dafb;
  }
`;

const LanguagesContainer = (props) => {
  const {
    isOpen, toggleOpen, layoutType
  } = props;
  const { t } = useTranslation();

  return (
    <Fragment>
      <Lng>
        <Rollover
          layoutType={layoutType}
          onClick={() => toggleOpen(!isOpen)}
          onBlur={() => toggleOpen(false)} // не работает
          isOpen={isOpen}
        >
          {t('header.languages')}
        </Rollover>
      </Lng>
      {isOpen && <LngBoxDropdown {...props} />}
    </Fragment>
  );
};

function LayoutLg(props) {
  const { layoutType, isAuthorization } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <NavBarBrand to="/">
        ARBITRAGE BETS
      </NavBarBrand>
      <Collapse layoutType={layoutType}>
        <Nav>
          <NavItem className="p-0">
            <NavLink to="/bet_storm" activeStyle={getActiveClassLink(layoutType)}>
              {t('header.bet_storm')}
            </NavLink>
          </NavItem>
          <NavItem className="p-0">
            <NavLink to="/proxy" activeStyle={getActiveClassLink(layoutType)}>
              {t('header.proxy')}
            </NavLink>
          </NavItem>
          <NavItem className="p-0">
            <NavLink to="/vds" activeStyle={getActiveClassLink(layoutType)}>
              {t('header.vds')}
            </NavLink>
          </NavItem>
          <NavItem className="p-0 mr-auto">
            <NavLinkTarget to="/tutorial">
              {t('header.tutorial')}
            </NavLinkTarget>
          </NavItem>
          <NavItem className="p-0">
            <LanguagesContainer {...props} />
          </NavItem>
          {isAuthorization ? (
            <Fragment>
              <NavItem className="p-0">
                <NavLinkTarget to="/panel" activeStyle={getActiveClassLink(layoutType)}>
                  {t('Личный кабинет')}
                </NavLinkTarget>
              </NavItem>
              <NavItem>
                <LinkPrimaryDarkTarget to="/logout/?redirect=sign_in" size="md" layoutType={layoutType}>
                  {t('Выход')}
                </LinkPrimaryDarkTarget>
              </NavItem>
            </Fragment>
          ) : (
            <NavItem>
              <LinkSecondaryDark
                to="/sign_in"
                size="md"
                layoutType={layoutType}
                mr={10}
              >
                {t('header.sign_in')}
              </LinkSecondaryDark>
              <LinkPrimaryDark to="/sign_up" size="md" layoutType={layoutType}>
                {t('header.sign_up')}
              </LinkPrimaryDark>
            </NavItem>
          )
          }
        </Nav>
      </Collapse>
    </Fragment>
  );
}

function LayoutXs(props) {
  const {
    layoutType, isOpenNav, toggleOpenNav, isAuthorization
  } = props;
  const { t } = useTranslation();
  return (
    <Fragment>
      <NavbarToggler
        toggleOpenNav={toggleOpenNav}
        isOpenNav={isOpenNav}
      />
      <NavBarBrand
        to="/"
        layoutType={layoutType}
        onClick={() => toggleOpenNav(false)}
      >
        ARBITRAGE BETS
      </NavBarBrand>
      <Collapse isOpen={isOpenNav} layoutType={layoutType}>
        <Nav row>
          <NavItem className="p-0">
            <LanguagesContainer {...props} />
          </NavItem>
          {isAuthorization && (
            <NavItem className="p-0">
              <NavLinkTarget to="/panel">
                {t('Личный кабинет')}
              </NavLinkTarget>
            </NavItem>
          )}
          <NavItem className="p-0" row>
            <NavLink
              to="/vds"
              activeStyle={getActiveClassLink(layoutType)}
              onClick={() => toggleOpenNav(false)}
            >
              {t('header.vds')}
            </NavLink>
          </NavItem>
          <NavItem className="p-0" row>
            <NavLink
              to="/proxy"
              activeStyle={getActiveClassLink(layoutType)}
              onClick={() => toggleOpenNav(false)}
            >
              {t('header.proxy')}
            </NavLink>
          </NavItem>
          <NavItem className="p-0" row>
            <NavLink
              to="/bet_storm"
              activeStyle={getActiveClassLink(layoutType)}
              onClick={() => toggleOpenNav(false)}
            >
              {t('header.bet_storm')}
            </NavLink>
          </NavItem>
          <NavItem className="p-0 mr-auto" row>
            <NavLinkTarget to="/tutorial">
              {t('header.tutorial')}
            </NavLinkTarget>
          </NavItem>
          {!isAuthorization && (
            <NavItem className="mb-4">
              <LinkPrimaryDark
                to="/sign_up"
                size="sm"
                layoutType={layoutType}
              >
                {t('header.sign_up')}
              </LinkPrimaryDark>
            </NavItem>
          )}

        </Nav>
      </Collapse>
      {isAuthorization ? (
        <NavItem>
          <LinkPrimaryDark to="/sign_up" size="sm" layoutType={layoutType}>
            {t('Выход')}
          </LinkPrimaryDark>
        </NavItem>
      ) : (
        <NavItem>
          <LinkSecondaryDark
            to="/sign_in"
            size="sm"
            layoutType={layoutType}
          >
            {t('header.sign_in')}
          </LinkSecondaryDark>
        </NavItem>
      )}
    </Fragment>
  );
}

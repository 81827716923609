import { BasicScraper } from './basic';
import { WebGlScraper } from './webgl';
import { CanvasScraper } from './canvas';
import { AudioScraper } from './audio';
import { WebRtcScraper } from './webrtc';
import { PluginsScraper } from './plugins';
import { IntlScraper } from './intl';
import { SpeechSynthesisScraper } from './speech';
import { DateScraper } from './date';

const SNAPSHOT_VERSION = '1.3';

export const DataScraper = new class {
  async run() {
    return new Promise(async (resolve, reject) => {
      const result = {
        version: SNAPSHOT_VERSION
      };

      result.basicParams = await BasicScraper.scrap();
      result.params = {};

      if (WebGlScraper.isWebGlSupported()) {
        result.params.webGL = WebGlScraper.scrap();
      }

      if (CanvasScraper.isCanvasSupported()) {
        result.params.canvas = await CanvasScraper.scrap();
      }

      if (AudioScraper.isAudioSupported()) {
        result.params.audio = await AudioScraper.scrap();
      }

      result.params.webRTC = await WebRtcScraper.scrap();
      result.params.intl = await IntlScraper.scrap();
      result.params.date = await DateScraper.scrap();
      result.params.plugins = await PluginsScraper.scrap();
      result.params.speechSynthesis = await SpeechSynthesisScraper.scrap();

      resolve(result);
    });
  }
}();

if (window.top === window.self) {
  (async () => {
    // const systemData = await DataScraper.run();
    // console.log('--------------------------- System Snapshot: ');
    // console.log(systemData);
    // console.log(JSON.stringify(systemData));
  })();
}

import fetch from 'node-fetch';
import { MSG_LOG_IN, MSG_DEACTIVATE_PROFILE, CHECK_PROXY } from './constants';

export const fetchApi = async (url, method, body) => {
  const noJsonResponse = await fetch(url, { method, body: JSON.stringify(body), headers: { 'Content-Type': 'application/json' } });
  const response = await noJsonResponse.json();
  if (response.error === 'Go authenticate') {
    window.location.href = '/sign_in';
  }
  return response;
};

export const sendTokenToExtension = (token) => {
  window.dispatchEvent(
    new CustomEvent(MSG_LOG_IN, {
      detail: { key: token }
    })
  );
};

export const deactivateProfile = () => {
  window.dispatchEvent(
    new CustomEvent(MSG_DEACTIVATE_PROFILE)
  );
};

export const checkProxyViaExtension = (payload) => {
  window.dispatchEvent(
    new CustomEvent(CHECK_PROXY, { detail: payload })
  );
};

export const numToStrTypes = {
  days: 'days',
  syncs: 'syncs'
};
export const numToStrTextForms = {
  days: ['день', 'дня', 'дней'],
  syncs: ['синхронизация', 'синхронизации', 'синхронизаций']
};
export const numToStr = (num, type) => {
  const textForms = numToStrTextForms[type] || numToStrTextForms[numToStrTypes.days];
  const n = Math.abs(num) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) { return textForms[2]; }
  if (n1 > 1 && n1 < 5) { return textForms[1]; }
  if (n1 === 1) { return textForms[0]; }
  return textForms[2];
};

import React from 'react';
import { createElement, createDiv } from '@nonlux/react-common';

export const Img = createElement('Img', 'Img', 'img-fluid');

export const ImgContainer = createDiv('ImgContainer', ({
  mt = 0, mb = 0, ml = 0, mr = 0, pt = 0, pb = 0, pl = 0, pr = 0, center = false, padding = 0, maxWidthImgBox = null
}) => {
  const mid = center ? {
    marginRight: 'auto',
    marginLeft: 'auto'
  } : {
    marginLeft: `${ml}px`,
    marginRight: `${mr}px`
  };
  return ({
    marginTop: `${mt}px`,
    marginBottom: `${mb}px`,
    ...mid,
    paddingTop: `${pt}px`,
    paddingBottom: `${pb}px`,
    paddingLeft: `${pl}px`,
    paddingRight: `${pr}px`,
    padding: `${padding}px`,
    maxWidth: `${maxWidthImgBox}px`
  });
});


// позволит добавлять много картинок и позиционировать любым образом рядом с текстом

const ImgInner = createElement('img', 'ImgInner', ({ width }) => ({
  width: `${width}px`
}));

const ImgBoxInner = createDiv('ImgBoxInner', ({ ml = 0, mt = 0 }) => ({
  position: 'absolute',
  marginLeft: `${ml}px`,
  marginTop: `${mt}px`
}));

const ImgBox = createDiv('ImgBox', ({ mt = 0, ml = 0 }) => ({
  position: 'absolute',
  marginTop: `${mt}px`,
  marginLeft: `${ml}px`
}));

const ImgInnerList = ({ arr }) => arr.map(item => (
  <ImgBoxInner ml={item.position.ml} mt={item.position.mt}>
    <ImgInner src={item.img.src} width={item.img.width} />
  </ImgBoxInner>
));

export const ImgInnerContainer = (props) => {
  const { imgArr, mtImgBox, mlImgBox } = props;

  return (
    <ImgBox
      mt={mtImgBox}
      ml={mlImgBox}
    >
      <ImgInnerList arr={imgArr} />
    </ImgBox>
  );
};

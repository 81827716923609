import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';

import { windowWasResizeAction } from './actions';

const resize$ = Observable.fromEvent(window, 'resize')
  .debounceTime(20)
  .map(() => {
    const width = document.body.clientWidth;
    let widthType = 'md';
    if (width > 0) {
      widthType = 'xs';
    }
    if (width > 576) {
      widthType = 'sm';
    }
    if (width > 768) {
      widthType = 'md';
    }
    if (width > 992) {
      widthType = 'lg';
    }
    if (width > 1200) {
      widthType = 'xl';
    }
    return windowWasResizeAction(widthType);
  });

export function resizyEpic() {
  return resize$;
}

export default [
  resizyEpic
];

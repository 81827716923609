import { connect } from 'react-redux';
import { createElement, createDiv } from '@nonlux/react-common';
import STYLES from '../../styles/constants.js';

const PPure = createElement('p', 'P', ({ layoutType, className }) => ({
  fontSize: STYLES.getMedia([16, 16, 16, 16, 18], layoutType),
  ...className,
}));

const H1Pure = createElement('h1', 'H1', ({ layoutType }) => ({
  fontSize: STYLES.getMedia([25, 35, 35, 45, 45], layoutType),
  color: STYLES.COLORS.BLUE,
}));

const H2Pure = createElement('h2', 'H2', ({ layoutType }) => ({
  fontSize: STYLES.getMedia([30, 30, 30, 33, 33], layoutType),
  fontWeight: 400,
  color: STYLES.COLORS.BASE_DARK,
}));

const H3Pure = createElement('h3', 'H3', ({ layoutType, color }) => ({
  color: color || STYLES.COLORS.DARK_GRAY,
  fontWeight: 300,
  fontSize: STYLES.getMedia([24, 24, 24, 28, 28], layoutType),
}));

export const P = connect(state => ({
  layoutType: state.windowResize.layoutType,
}))(PPure);

export const H1 = connect(state => ({
  layoutType: state.windowResize.layoutType,
}))(H1Pure);

export const H2 = connect(state => ({
  layoutType: state.windowResize.layoutType,
}))(H2Pure);

export const H3 = connect(state => ({
  layoutType: state.windowResize.layoutType,
}))(H3Pure);

export const TextContainer = createDiv('TextContainer', ({ mt = 0, mb = 0, mr = 0, pl = 0, pr = 0 }) => ({
  marginTop: `${mt}px`,
  marginBottom: `${mb}px`,
  marginRight: `${mr}px`,
  paddingLeft: `${pl}px`,
  paddingRight: `${pr}px`,
}));
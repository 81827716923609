import React from 'react';
import {
  Title as BaseTitle, Intro, Text,
} from 'components';
import {
  Col, Row, Button as BaseButton, Container as BaseContainer, Card, CardBody, Form, Label,
} from 'reactstrap';
import { verifyEmailAction } from 'reducer/verifyRecover/actions';
import { withFormikConnected } from '@nonlux/react-common/formik';
import { ButtonPrimaryLight } from '@nonlux/ab-ui';
import { useTranslation } from 'react-i18next';

import { withCSS } from '@nonlux/react-common/composable';

const Container = withCSS({
  paddingBottom: '150px',
  paddingTop: '200px',
  minHeight: '100%',
}, true)(BaseContainer);

const Title = withCSS({
  fontSize: '1.5em',
  paddingBottom: '30px',
})(BaseTitle);

const Button = withCSS({
  backgroundColor: 'rgb(97, 218, 251)',
  color: 'black',
  borderColor: 'rgb(97, 218, 251)',
  ':hover': {
    backgroundColor: 'white',
    color: 'black',
  }
}, true)(BaseButton);
export default function ConfirmEmailPage() {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>
        {t('dialogPages.confirmEmail.title')}
      </Title>
      <ConfirmEmailFormConnected />
    </Container>
  );
}

const ConfirmEmailForm = (props) => {
  const {
    handleSubmit,
  } = props;
  const { t } = useTranslation();
  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <ButtonPrimaryLight className="rounded-0">
            {t('dialogPages.confirmEmail.button')}
          </ButtonPrimaryLight>
        </Col>
      </Row>
    </Form>
  );
};

const ConfirmEmailFormConnected = withFormikConnected({
  handleSubmit({}, { props: { dispatch } }) {
    dispatch(verifyEmailAction());
  },
})(ConfirmEmailForm);

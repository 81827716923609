import React from 'react';
import { createDiv, createElement } from '@nonlux/react-common';
import { withCSS } from '@nonlux/react-common/composable';
import { Link as LinkPure } from 'react-router-dom';
import OptionsForm from '../OptionsForm/OptionsForm.js';
import PriceForm from '../PriceForm/PriceForm.js';
import STYLES from '../../../../styles/constants.js';
import { allTariffs } from '../../../../../constants';

const Label = createDiv('Label', {
  marginTop: '15px'
});

const TariffsName = createElement('strong', 'TariffsName', {
  textTransform: 'uppercase'
});

const Link = withCSS({
  color: STYLES.COLORS.BLACK,
  textDecoration: 'none',
  cursor: 'pointer',
  ':hover': {
    color: STYLES.COLORS.BLACK,
    textDecoration: 'none'
  }
})(LinkPure);

const TariffsFormRow = createDiv('TariffsFormRow', 'row');
const TariffsFormCol = createDiv('TariffsFormCol', 'col');

const TariffsFormItem = createDiv('TariffsFormItem', ({ isSelect }) => ({
  border: `1px solid ${(isSelect === 'standard') ? STYLES.COLORS.BLUE : STYLES.COLORS.WHITE_GRAY}`,
  padding: '40px',
  height: '100%',
  cursor: 'pointer',
  userSelect: 'none',
  ':hover': {
    boxShadow: '0px 5px 20px rgba(0, 0, 0, 0.05)'
  }
}));

export const TariffItem = ({
  tariff,
  onSelectTariff,
  marginTop = 0
}) => (
  <TariffsFormCol border xs={12} sm={12} md={4} className={`mt-${marginTop}`}>
    <Link to="/sign_up">
      <TariffsFormItem
        onClick={() => onSelectTariff(tariff)}
        isSelect={tariff.name}
      >
        <TariffsName>
          {tariff.name}
        </TariffsName>
        <Label>
          Цена
        </Label>
        <PriceForm price={tariff.price} />
        <OptionsForm options={tariff.options} type={tariff.name} />
      </TariffsFormItem>
    </Link>
  </TariffsFormCol>
);

export default function TariffsFormBetStorm({ onSelectTariff }) {
  return (
    <TariffsFormRow>
      {allTariffs && allTariffs.map(tariff => (
        <TariffItem
          tariff={tariff}
          onSelectTariff={onSelectTariff}
        />
      ))}
    </TariffsFormRow>
  );
}

export const proxyPresentationText = {
  title: 'proxy.presentation.title',
  text: 'proxy.presentation.text',
  button: {
    left: {
      name: 'proxy.presentation.right',
      link: {
        href: '/proxy#scheme_proxy',
        offset: '50',
        behavior: 'smooth',
        id: 'scheme_proxy'
      }
    },
    right: {
      name: 'proxy.presentation.left',
      link: {
        href: '/proxy#proxyTariffs',
        offset: '50',
        behavior: 'smooth',
        id: 'proxyTariffs'
      }
    }
  }
};

export const specifications = {
  row: [
    {
      col: [
        {
          title: 'proxy.textInfo.item1.title',
          text: 'proxy.textInfo.item1.text'
        },
        {
          title: 'proxy.textInfo.item2.title',
          text: 'proxy.textInfo.item2.text'
        },
        {
          title: 'proxy.textInfo.item3.title',
          text: 'proxy.textInfo.item3.text'
        }
      ]
    },
    {
      col: [
        {
          title: 'proxy.textInfo.item4.title',
          text: 'proxy.textInfo.item4.text'
        },
        {
          title: 'proxy.textInfo.item5.title',
          text: 'proxy.textInfo.item5.text'
        },
        {
          title: 'proxy.textInfo.item6.title',
          text: 'proxy.textInfo.item6.text'
        }
      ]
    }
  ],
  col: [
    {
      title: 'proxy.textInfo.item1.title',
      text: 'proxy.textInfo.item1.text'
    },
    {
      title: 'proxy.textInfo.item2.title',
      text: 'proxy.textInfo.item2.text'
    },
    {
      title: 'proxy.textInfo.item3.title',
      text: 'proxy.textInfo.item3.text'
    },
    {
      title: 'proxy.textInfo.item4.title',
      text: 'proxy.textInfo.item4.text'
    },
    {
      title: 'proxy.textInfo.item5.title',
      text: 'proxy.textInfo.item5.text'
    },
    {
      title: 'proxy.textInfo.item6.title',
      text: 'proxy.textInfo.item6.text'
    }
  ]
};

export const imgRowText = {
  title: 'proxy.imgRow.title',
  text: 'proxy.imgRow.text',
  button: 'proxy.imgRow.button'
};

export const botnetText = [
  {
    p: 'proxy.botnetText.item1'
  },
  {
    p: 'proxy.botnetText.item2'
  },
  {
    p: 'proxy.botnetText.item3'
  },
  {
    p: 'proxy.botnetText.item4'
  }
];

export const mobilText = [
  {
    p: 'proxy.mobilText.item1'
  },
  {
    p: 'proxy.mobilText.item2'
  },
  {
    p: 'proxy.mobilText.item3'
  },
  {
    p: 'proxy.mobilText.item4'
  }
];

export const posfText = [
  {
    h3: 'proxy.proxyAbb.posfText.title'
  },
  {
    p: 'proxy.proxyAbb.posfText.item1'
  },
  {
    p: 'proxy.proxyAbb.posfText.item2'
  },
  {
    p: 'proxy.proxyAbb.posfText.item3'
  },
  {
    p: 'proxy.proxyAbb.posfText.item4'
  }
];

export const mtuText = [
  {
    h3: 'proxy.proxyAbb.mtuText.title'
  },
  {
    p: 'proxy.proxyAbb.mtuText.item1'
  },
  {
    p: 'proxy.proxyAbb.mtuText.item2'
  },
  {
    p: 'proxy.proxyAbb.mtuText.item3'
  },
  {
    p: 'proxy.proxyAbb.mtuText.item4'
  }
];

export const webRtcText = [
  {
    h3: 'proxy.proxyAbb.webRtcText.title'
  },
  {
    p: 'proxy.proxyAbb.webRtcText.item1'
  },
  {
    p: 'proxy.proxyAbb.webRtcText.item2'
  },
  {
    p: 'proxy.proxyAbb.webRtcText.item3'
  },
  {
    p: 'proxy.proxyAbb.webRtcText.item4'
  }
];

export const becconnectText = [
  {
    h3: 'BACKCONNECT'
  },
  {
    p: 'Backconnect Proxy - это такой тип прокси, доступ к которым производится через специальный Gateway сервер (выделенного внешнего IP адреса у прокси-сервера нет).'
  },
  {
    p: 'Другими словами, Вы подключаетесь на один IP адрес с разными портами, а коннекты транслируются на сами прокси, которые имеют разные IP.'
  },
  {
    p: 'Это позволяет переключаться между IP адресами в рамках всего пула оператора по нажатию кнопки в личном кабинете, по ссылке через API или по установленному интервалу. Доступно более 20 000 IP адресов на одном канале прокси.'
  },
  {
    p: 'Такое соединение не детектится как прокси или VPN, потому что не Вы подключаетесь к серверу, а сервер к Вам. Плюс ко всему на прокси отключен двухсторонний пинг, что делает технологию неуязвимой к детекту.'
  }
];

const WEBGL1_FEATURES = ['VERSION', 'SHADING_LANGUAGE_VERSION', 'VENDOR', 'RENDERER', 'MAX_VERTEX_ATTRIBS', 'MAX_VERTEX_UNIFORM_VECTORS', 'MAX_VERTEX_TEXTURE_IMAGE_UNITS', 'MAX_VARYING_VECTORS', 'ALIASED_LINE_WIDTH_RANGE', 'ALIASED_POINT_SIZE_RANGE', 'MAX_FRAGMENT_UNIFORM_VECTORS', 'MAX_TEXTURE_IMAGE_UNITS', 'RED_BITS', 'GREEN_BITS', 'BLUE_BITS', 'ALPHA_BITS', 'DEPTH_BITS', 'STENCIL_BITS', 'MAX_RENDERBUFFER_SIZE', 'MAX_VIEWPORT_DIMS', 'MAX_TEXTURE_SIZE', 'MAX_CUBE_MAP_TEXTURE_SIZE', 'MAX_COMBINED_TEXTURE_IMAGE_UNITS'];
const WEBGL2_FEATURES = ['MAX_VERTEX_UNIFORM_COMPONENTS', 'MAX_VERTEX_UNIFORM_BLOCKS', 'MAX_VERTEX_OUTPUT_COMPONENTS', 'MAX_VARYING_COMPONENTS', 'MAX_TRANSFORM_FEEDBACK_INTERLEAVED_COMPONENTS', 'MAX_TRANSFORM_FEEDBACK_SEPARATE_ATTRIBS', 'MAX_TRANSFORM_FEEDBACK_SEPARATE_COMPONENTS', 'MAX_FRAGMENT_UNIFORM_COMPONENTS', 'MAX_FRAGMENT_UNIFORM_BLOCKS', 'MAX_FRAGMENT_INPUT_COMPONENTS', 'MIN_PROGRAM_TEXEL_OFFSET', 'MAX_PROGRAM_TEXEL_OFFSET', 'MAX_DRAW_BUFFERS', 'MAX_COLOR_ATTACHMENTS', 'MAX_SAMPLES', 'MAX_3D_TEXTURE_SIZE', 'MAX_ARRAY_TEXTURE_LAYERS', 'MAX_TEXTURE_LOD_BIAS', 'MAX_UNIFORM_BUFFER_BINDINGS', 'MAX_UNIFORM_BLOCK_SIZE', 'UNIFORM_BUFFER_OFFSET_ALIGNMENT', 'MAX_COMBINED_UNIFORM_BLOCKS', 'MAX_COMBINED_VERTEX_UNIFORM_COMPONENTS', 'MAX_COMBINED_FRAGMENT_UNIFORM_COMPONENTS'];

const EXTENSION_FEATURES = {
  'WEBGL_debug_renderer_info': ['UNMASKED_RENDERER_WEBGL', 'UNMASKED_VENDOR_WEBGL'],
  'EXT_texture_filter_anisotropic': ['MAX_TEXTURE_MAX_ANISOTROPY_EXT']
};

const AVAILABLE_SHADERS = ['FRAGMENT_SHADER', 'VERTEX_SHADER'];
const SHADER_PRECISION_FORMATS = ['HIGH_FLOAT', 'MEDIUM_FLOAT', 'LOW_FLOAT', 'HIGH_INT', 'MEDIUM_INT', 'LOW_INT'];

export const WebGlScraper = new class {
  isWebGlSupported() {
    return !!window.WebGLRenderingContext;
  }

  isWebGl2Supported() {
    return !!window.WebGL2RenderingContext && this.context instanceof window.WebGL2RenderingContext;
  }

  getExtensions() {
    return this.context.getSupportedExtensions();
  }

  getUnmaskedVendor() {
    return this.context.getParameter(this.rendererInfo.UNMASKED_RENDERER_WEBGL);
  }

  getUnmaskedRenderer() {
    return this.context.getParameter(this.rendererInfo.UNMASKED_VENDOR_WEBGL);
  }

  getShaderPrecisionFormats() {
    const result = [];

    AVAILABLE_SHADERS.forEach(shader => {
      SHADER_PRECISION_FORMATS.map(name => {
        const precision = this.context.getShaderPrecisionFormat(this.context[shader], this.context[name]);

        result.push({
          type: 'WebGLShaderPrecisionFormat',
          shaderType: shader,
          precisionType: name,

          precision: precision.precision,
          rangeMax: precision.rangeMax,
          rangeMin: precision.rangeMin,
        });
      });
    });

    return result;
  }

  _processComplexValue(val) {
    if (val instanceof Int32Array) {
      return {
        'type': 'Int32Array',
        'value': Array.from(val)
      };
    }

    if (val instanceof Float32Array) {
      return {
        'type': 'Float32Array',
        'value': Array.from(val)
      };
    }

    return val;
  }

  _getFeatureList(features) {
    const result = {};

    for (const featureKey of features) {
      const val = this.context.getParameter(this.context[featureKey]);

      result[[featureKey]] = this._processComplexValue(val);
    }

    return result;
  }

  getFeatures1() {
    return this._getFeatureList(WEBGL1_FEATURES);
  }

  getExtensionFeatures() {
    const result = {};

    Object.entries(EXTENSION_FEATURES)
      .forEach(([extension, features]) => {
        const ext = this.context.getExtension(extension);

        if (ext) {
          const extensionValues = {};

          features.forEach(feature => {
            const value = this.context.getParameter(ext[feature]);

            if (value !== null && value !== undefined) {
              extensionValues[feature] = value;
            }
          });

          result[extension] = extensionValues;
        }
      });

    return result;
  }

  getFeatures2() {
    if (!this.isWebGl2Supported()) {
      return null;
    }

    return this._getFeatureList(WEBGL2_FEATURES);
  }

  getContextAttributes() {
    return this.context.getContextAttributes();
  }

  getAvailableContext() {
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 128;

    return canvas.getContext('webgl2', { preserveDrawingBuffer: true }) ||
      canvas.getContext('experimental-webgl2', { preserveDrawingBuffer: true }) ||
      canvas.getContext('webgl', { preserveDrawingBuffer: true }) ||
      canvas.getContext('experimental-webgl', { preserveDrawingBuffer: true });
  }

  scrap() {
    const result = {};

    this.context = this.getAvailableContext();
    this.rendererInfo = this.context.getExtension('WEBGL_debug_renderer_info');

    try {
      if (WebGlScraper.isWebGlSupported()) {
        result['vendor'] = WebGlScraper.getUnmaskedVendor();
        result['renderer'] = WebGlScraper.getUnmaskedRenderer();
        result['extensions'] = WebGlScraper.getExtensions();
        result['shaderPrecisionFormats'] = WebGlScraper.getShaderPrecisionFormats();
        result['featuresWebGl1'] = WebGlScraper.getFeatures1();
        result['featuresWebGl2'] = WebGlScraper.getFeatures2();
        result['extensionFeatures'] = WebGlScraper.getExtensionFeatures();
        result['contextAttributes'] = WebGlScraper.getContextAttributes();
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  }
};

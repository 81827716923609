import React from 'react';
import ModalDialogInfo from './ModalDialogInfo';
import { withModalDialogData } from '../../../reducer/modalDialog/selectors';

const InfoNotEnoughMoney = ({ modalDialogData }) => (
  <ModalDialogInfo
    title={modalDialogData.title || ''}
    text="Недостаточно средств на балансе"
    confirm="Пополнить"
    withoutCancelBtn={false}
    isOnConfirmRedirectToBalance
  />
);

export default withModalDialogData(InfoNotEnoughMoney);

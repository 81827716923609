import React from 'react';
import { withCSS, toggleLayout } from '@nonlux/react-common/composable';
import { Col, Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  ContainerContent,
  RowContent,
  P, H3,
} from 'components';
import STYLES from '../../styles/constants.js';

export default function TextInfo({ layoutType, info, title }) {
  return (
    <ContainerContent title={title} color="blue">
      <ContentTextInfo layoutType={layoutType}>
        <ToggleLayout layoutType={layoutType} info={info} />
      </ContentTextInfo>
    </ContainerContent>
  );
}

const ContentTextInfo = withCSS(({ layoutType }) => ({
  padding: STYLES.getMediaAcc(['0 20px 0', '0', '0', '0 80px 0', '0 100px 0'], layoutType),
}))(Container);

const ToggleLayout = ({ layoutType, info }) => {
  const [isXs] = toggleLayout({ getLayout: ['xs', 'sm'] });
  if (isXs(layoutType)) return <RowXs col={info.col} />;
  return <RowListMd row={info.row} />;
};

const RowListMd = ({ row }) => row.map((item, index) => (
  <RowContent key={index}>
    <ColListMd col={item.col} />
  </RowContent>
));

const ColListMd = ({ col }) => col.map((item) => {
  const { t } = useTranslation();
  return (
    <Col key={item.title} className="text-center" md={4}>
      <H3>
        {t(item.title)}
      </H3>
      <P>
        {t(item.text)}
      </P>
    </Col>
  );
});

const RowXs = ({ col }) => (
  <RowContent>
    <ColListXs col={col} />
  </RowContent>
);

const ColListXs = ({ col }) => col.map((item) => {
  const { t } = useTranslation();
  return (
    <Col className="text-center" xs={12} sm={6}>
      <H3>
        {t(item.title)}
      </H3>
      <P>
        {t(item.text)}
      </P>
    </Col>
  );
});

import React from 'react';
import { Container as BaseContainer } from 'reactstrap';
import { createElement } from '@nonlux/react-common';
import { useTranslation } from 'react-i18next';


import { withCSS } from '@nonlux/react-common/composable';

const Container = withCSS({
  paddingBottom: '150px',
  paddingTop: '200px',
  minHeight: '100%',
})(BaseContainer);

export default function VerifyEmailPage() {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>
        {t('dialogPages.verifyEmail.title')}
      </Title>
      <P>
        {t('dialogPages.verifyEmail.text')}
      </P>
    </Container>
  );
}

const P = createElement('p', 'P', {
  '@media (min-width: 0)': {
    marginTop: '30px',
    fontSize: '16px',
  },
  '@media (min-width: 1200px)': {
    fontSize: '18px',
  },
});

export const Title = createElement('h2', 'Title', {
  '@media (min-width: 0)': {
    fontSize: '30px',
    fontWeight: '300',
    color: '#61dafb',
  },
  '@media (min-width: 768px)': {
    fontSize: '35px',
  },
  '@media (min-width: 992px)': {
    fontSize: '40px',
  },
  '@media (min-width: 1200px)': {
    fontSize: '45px',
  },
});

export const Intro = createElement('h3', 'Strong', {
  '@media (min-width: 0)': {
    fontSize: '18px',
    fontWeight: '200',
    color: '#939394',
  },
  '@media (min-width: 768px)': {
    fontSize: '22px',
  },
  '@media (min-width: 992px)': {
    fontSize: '24px',
  },
  '@media (min-width: 1200px)': {
    fontSize: '28px',
  },
});

/**
 * @module composable
 */
import { withFormik } from 'formik';
import { connect } from 'react-redux';

import {
  compose,
  lifecycle,
} from 'recompose';

/**
 * упрoщение работы с formik
 * автоматический запрет submit
 *
 * @param {Object} options - настройки компонента
 * handleSubmit - callback для сабмита формы
 * @returns {HOC}
 */
export function withFormikExtended(options) {
  const {
    handleSubmit,
    ...rest
  } = options;
  return compose(
    withFormik({
      ...rest,
      handleSubmit: (values, formikBag) => {
        formikBag.setSubmitting(false);
        handleSubmit(values, formikBag);
      },
    }),
  );
}

/**
 *  Сахар formik + redux connect
 *
 * @param {object} formikOptions  - параметеры withFormikExtended
 * @param {function} mapState - параметеры для connect
 * @returns {HOC}
 */
export function withFormikConnected(formikOptions, mapState) {
  return compose(
    connect(mapState),
    withFormikExtended(formikOptions),
  );
}

import React from 'react';
import { compose } from 'redux';
import { withModalDialogType } from '../../../reducer/modalDialog/selectors';
import MODAL_TYPES from '../../../reducer/modalDialog/constants';
import ConfirmRemoveModalDialog from './ConfirmRemoveModalDialog';
import ConfirmDeactivateAndRemoveProfile from './ConfirmDeactivateAndRemoveProfile';
import BuyProfileTariff from './ConfirmBuyProfileTariff';
import TestProfileTariff from './ConfirmTestProfileTariff';
import InfoNotEnoughMoney from './InfoNotEnoughMoney';
import ConfirmBuySyncs from './ConfirmBuySyncs';
import InfoTariffDowngradeIsNotAllowed from './InfoTariffDowngradeIsNotAllowed';
import ConfirmBuyDevices from './ConfrimBuyDevices';
import ConfirmBuyProxy from './ConfirmBuyProxy';
import ConfirmBuyVds from './ConfirmBuyVds';

const MODAL_COMPONENTS = {
  [MODAL_TYPES.REMOVE]: ConfirmRemoveModalDialog,
  [MODAL_TYPES.DEACTIVATE_AND_REMOVE_PROFILE]: ConfirmDeactivateAndRemoveProfile,
  [MODAL_TYPES.BUY_PROFILE_TARIFF]: BuyProfileTariff,
  [MODAL_TYPES.TEST_PROFILE_TARIFF]: TestProfileTariff,
  [MODAL_TYPES.BUT_SYNCS]: ConfirmBuySyncs,
  [MODAL_TYPES.BUY_PROXY]: ConfirmBuyProxy,
  [MODAL_TYPES.BUY_VDS]: ConfirmBuyVds,
  [MODAL_TYPES.BUY_DEVICES]: ConfirmBuyDevices,
  [MODAL_TYPES.NOT_ENOUGH_MONEY]: InfoNotEnoughMoney,
  [MODAL_TYPES.TARIFF_DOWNGRADE_IS_NOT_ALLOWED]: InfoTariffDowngradeIsNotAllowed
};

const ModalRoot = ({ modalDialogType }) => {
  if (!modalDialogType) return null;

  const SpecificModal = MODAL_COMPONENTS[modalDialogType];
  return <SpecificModal />;
};

export default compose(withModalDialogType)(ModalRoot);

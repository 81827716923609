import React, { Fragment } from 'react';
import {
  RowContent
} from 'components';
import {
  tariffsVdsClassic, tariffsProxyClassic, tariffsPro
} from '../data.js';
import { createDiv, createElement } from '@nonlux/react-common';
import { TarifClassic, TarifPro } from '../TariffsFormProxyVds/TariffsFormProxyVds.js';
import { PaymentSystems } from '../PaymentSystems/PaymentSystems.js';
import TariffsFormBetStorm, { TariffItem } from '../TariffsFormBetStorm/TariffsFormBetStorm/TariffsFormBetStorm.js';
import { advancedTariff, bassicTariff, standardTariff } from '../../../../constants';


const getMarinTopBottomVds = {
  xl: 42,
  lg: 66,
  md: 15,
  sm: 39,
  xs: 15
};
const getMarinTopBottomProxy = {
  xl: 15,
  lg: 18,
  md: 15,
  sm: 15,
  xs: 15
};
const getMarinTopBottomPro = {
  xl: 42,
  lg: 42,
  md: 15,
  sm: 15,
  xs: 15
};

export const LayoutLg = ({ layoutType, type }) => {
  return (
    <Fragment>
      <RowContent>
        {(type === 'bs') ? (
          <Fragment>
            <TariffItem tariff={bassicTariff} />
            <TariffItem tariff={standardTariff} />
            <TariffItem tariff={advancedTariff} marginTop={layoutType === 'lg' && 4} />
          </Fragment>
        ) : (
          <Fragment>
            <TarifClassic
              layoutType={layoutType}
              tariffs={tariffsVdsClassic}
              getMarinTopBottom={getMarinTopBottomVds[layoutType]}
            />
            <TarifPro
              layoutType={layoutType}
              tariffs={tariffsPro}
              getMarinTopBottom={getMarinTopBottomPro[layoutType]}
            />
            <TarifClassic
              layoutType={layoutType}
              tariffs={tariffsProxyClassic}
              getMarinTopBottom={getMarinTopBottomProxy[layoutType]}
            />
          </Fragment>
        )}
      </RowContent>
      <PaymentSystems />
    </Fragment>
  );
};

export const LayoutSm = ({ layoutType, type }) => (
  <Fragment>
      {(type === 'bs') ? (
        <Fragment>
          <RowContent>
            <TariffItem tariff={bassicTariff} />
          </RowContent>
          <RowContent>
            <TariffItem tariff={standardTariff} />
          </RowContent>
          <RowContent>
            <TariffItem tariff={advancedTariff} />
          </RowContent>
        </Fragment>
      ) : (
        <RowContent>
          <TarifPro
            layoutType={layoutType}
            tariffs={tariffsPro}
            getMarinTopBottom={getMarinTopBottomPro[layoutType]}
          />
        </RowContent>
      )}
    {(type !== 'bs') ? (
      <RowContent>
        <TarifClassic
          layoutType={layoutType}
          tariffs={tariffsVdsClassic}
          getMarinTopBottom={getMarinTopBottomVds[layoutType]}
        />
        <TarifClassic
          tariffs={tariffsProxyClassic}
          getMarinTopBottom={getMarinTopBottomProxy[layoutType]}
        />
      </RowContent>
    ) : null}
  </Fragment>
);

export const LayoutXs = ({ layoutType, type }) => (
  <Fragment>
    {(type === 'bs') ? (
      <Fragment>
        <RowContent>
          <TariffItem tariff={bassicTariff} />
        </RowContent>
        <RowContent>
          <TariffItem tariff={standardTariff} />
        </RowContent>
        <RowContent>
          <TariffItem tariff={advancedTariff} />
        </RowContent>
      </Fragment>
    ) : (
      <Fragment>
        <RowContent>
          <TarifPro
            layoutType={layoutType}
            tariffs={tariffsPro}
            getMarinTopBottom={getMarinTopBottomPro[layoutType]}
          />
        </RowContent>
        <RowContent>
          <TarifClassic
            layoutType={layoutType}
            tariffs={tariffsVdsClassic}
            getMarinTopBottom={getMarinTopBottomVds[layoutType]}
          />
        </RowContent>
        <RowContent>
          <TarifClassic
            layoutType={layoutType}
            tariffs={tariffsProxyClassic}
            getMarinTopBottom={getMarinTopBottomProxy[layoutType]}
          />
        </RowContent>
      </Fragment>
    )}
  </Fragment>
);

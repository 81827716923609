export const vdsPresentationText = {
  title: 'VDS',
  text: 'vds.presentation.text',
  button: {
    left: {
      name: 'vds.presentation.right',
      link: {
        href: '/vds#identifiers',
        offset: '50',
        behavior: 'smooth',
        id: 'identifiers'
      }
    },
    right: {
      name: 'vds.presentation.left',
      link: {
        href: '/vds#vdsTariffs',
        offset: '50',
        behavior: 'smooth',
        id: 'vdsTariffs'
      }
    }
  }
};

export const uniqueIdentifiers = [
  {
    id: 'hardware',
    name: 'vds.toggleInfo.hardware.name',
    title: 'vds.toggleInfo.hardware.title',
    text: 'vds.toggleInfo.hardware.text',
    row: [
      {
        col: [
          {
            title: 'vds.toggleInfo.hardware.item.bios.title',
            text: 'vds.toggleInfo.hardware.item.bios.text',
          },
          {
            title: 'vds.toggleInfo.hardware.item.cpu.title',
            text: 'vds.toggleInfo.hardware.item.cpu.text',
          },
          {
            title: 'vds.toggleInfo.hardware.item.gpu.title',
            text: 'vds.toggleInfo.hardware.item.gpu.text',
          },
          {
            title: 'vds.toggleInfo.hardware.item.ssd.title',
            text: 'vds.toggleInfo.hardware.item.ssd.text',
          },
        ],
      },
    ],
  },
  {
    id: 'os',
    name: 'vds.toggleInfo.os.name',
    title: 'vds.toggleInfo.os.title',
    text: 'vds.toggleInfo.os.text',
    row: [
      {
        col: [
          {
            title: 'vds.toggleInfo.os.item.registry.title',
            text: 'vds.toggleInfo.os.item.registry.text',
          },
          {
            title: 'vds.toggleInfo.os.item.telemerty.title',
            text: 'vds.toggleInfo.os.item.telemerty.text',
          },
          {
            title: 'vds.toggleInfo.os.item.hypervisor.title',
            text: 'vds.toggleInfo.os.item.hypervisor.text',
          },
          {
            title: 'vds.toggleInfo.os.item.win.title',
            text: 'vds.toggleInfo.os.item.win.text',
          },
        ],
      },
    ],
  },
  {
    id: 'browser',
    name: 'vds.toggleInfo.browser.name',
    title: 'vds.toggleInfo.browser.title',
    text: 'vds.toggleInfo.browser.text',
    row: [
      {
        col: [
          {
            title: 'vds.toggleInfo.browser.item.tls.title',
            text: 'vds.toggleInfo.browser.item.tls.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.flash.title',
            text: 'vds.toggleInfo.browser.item.flash.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.keyboards.title',
            text: 'vds.toggleInfo.browser.item.keyboards.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.resolution.title',
            text: 'vds.toggleInfo.browser.item.resolution.text',
          },
        ],
      },
      {
        col: [
          {
            title: 'vds.toggleInfo.browser.item.canvas.title',
            text: 'vds.toggleInfo.browser.item.canvas.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.web_gl.title',
            text: 'vds.toggleInfo.browser.item.web_gl.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.font.title',
            text: 'vds.toggleInfo.browser.item.font.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.audio_context.title',
            text: 'vds.toggleInfo.browser.item.audio_context.text',
          },
        ],
      },
      {
        col: [
          {
            title: 'vds.toggleInfo.browser.item.webcookie.title',
            text: 'vds.toggleInfo.browser.item.webcookie.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.fleshcookie.title',
            text: 'vds.toggleInfo.browser.item.fleshcookie.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.evercookie.title',
            text: 'vds.toggleInfo.browser.item.evercookie.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.uber_cookie.title',
            text: 'vds.toggleInfo.browser.item.uber_cookie.text',
          },
        ],
      },
      {
        col: [
          {
            title: 'vds.toggleInfo.browser.item.plugins.title',
            text: 'vds.toggleInfo.browser.item.plugins.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.useragent.title',
            text: 'vds.toggleInfo.browser.item.useragent.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.etag.title',
            text: 'vds.toggleInfo.browser.item.etag.text',
          },
          {
            title: 'vds.toggleInfo.browser.item.last_modified.title',
            text: 'vds.toggleInfo.browser.item.last_modified.text',
          },
        ],
      },
    ],
  },
  {
    id: 'network',
    name: 'vds.toggleInfo.network.name',
    title: 'vds.toggleInfo.network.title',
    text: 'vds.toggleInfo.network.text',
    row: [
      {
        col: [
          {
            title: 'vds.toggleInfo.network.item.webrtc.title',
            text: 'vds.toggleInfo.network.item.webrtc.text',
          },
          {
            title: 'vds.toggleInfo.network.item.mtu.title',
            text: 'vds.toggleInfo.network.item.mtu.text',
          },
          {
            title: 'vds.toggleInfo.network.item.posf.title',
            text: 'vds.toggleInfo.network.item.posf.text',
          },
          {
            title: 'vds.toggleInfo.network.item.ip.title',
            text: 'vds.toggleInfo.network.item.ip.text',
          },
        ],
      },
    ],
  },
];

export const imgRowText = {
  title: 'vds.imgRow.title',
  text: 'vds.imgRow.text',
  button: 'vds.imgRow.button',
};

export const telemetryText = [
  {
    p: 'vds.telemetryText.item1',
  },
  {
    p: 'vds.telemetryText.item2',
  },
  {
    p: 'vds.telemetryText.item3',
  },
];

export const statisticsText = [
  {
    p: 'vds.statisticsText.item1',
  },
  {
    p: 'vds.statisticsText.item2',
  },
  {
    p: 'vds.statisticsText.item3',
  },
];

export const allParams = [
  {
    title: 'vds.triangle.allParams.hardware.title',
    exp: 'vds.triangle.allParams.hardware.exp',
    text: 'vds.triangle.allParams.hardware.text',
  },
  {
    title: 'vds.triangle.allParams.os.title',
    exp: 'vds.triangle.allParams.os.exp',
    text: 'vds.triangle.allParams.os.text',
  },
  {
    title: 'vds.triangle.allParams.browser.title',
    exp: 'vds.triangle.allParams.browser.exp',
    text: 'vds.triangle.allParams.browser.text',
  },
  {
    title: 'vds.triangle.allParams.noBrowser.title',
    exp: 'vds.triangle.allParams.noBrowser.exp',
    text: 'vds.triangle.allParams.noBrowser.text',
  },
  {
    title: 'vds.triangle.allParams.telemetry.title',
    exp: 'vds.triangle.allParams.telemetry.exp',
    text: 'vds.triangle.allParams.telemetry.text',
  },
];

export const hardware = {
  title: 'vds.triangle.hardware.title',
  text: 'vds.triangle.hardware.text',
  line1: 'vds.triangle.hardware.line1',
  line2: 'vds.triangle.hardware.line2',
};

export const os = {
  title: 'vds.triangle.os.title',
  text: 'vds.triangle.os.text',
  line1: 'vds.triangle.os.line1',
  line2: 'vds.triangle.os.line2',
};

export const browser = {
  title: 'vds.triangle.browser.title',
  text: 'vds.triangle.browser.text',
  line1: 'vds.triangle.browser.line1',
  line2: 'vds.triangle.browser.line2',
};

export const noBrowser = {
  title: 'vds.triangle.noBrowser.title',
  text: 'vds.triangle.noBrowser.text',
  line1: 'vds.triangle.noBrowser.line1',
  line2: 'vds.triangle.noBrowser.line2',
};

export const telemetry = {
  title: 'vds.triangle.telemetry.title',
  text: 'vds.triangle.telemetry.text',
  line1: 'vds.triangle.telemetry.line1',
  line2: 'vds.triangle.telemetry.line2',
};

export const specifications = {
  row: [
    {
      col: [
        {
          title: 'vds.textInfo.item1.title',
          text: 'vds.textInfo.item1.text',
        },
        {
          title: 'vds.textInfo.item2.title',
          text: 'vds.textInfo.item2.text',
        },
        {
          title: 'vds.textInfo.item3.title',
          text: 'vds.textInfo.item3.text',
        },
      ],
    },
    {
      col: [
        {
          title: 'vds.textInfo.item4.title',
          text: 'vds.textInfo.item4.text',
        },
        {
          title: 'vds.textInfo.item5.title',
          text: 'vds.textInfo.item5.text',
        },
        {
          title: 'vds.textInfo.item6.title',
          text: 'vds.textInfo.item6.text',
        },
      ],
    },
  ],
  col: [
    {
      title: 'vds.textInfo.item1.title',
      text: 'vds.textInfo.item1.text',
    },
    {
      title: 'vds.textInfo.item2.title',
      text: 'vds.textInfo.item2.text',
    },
    {
      title: 'vds.textInfo.item3.title',
      text: 'vds.textInfo.item3.text',
    },
    {
      title: 'vds.textInfo.item4.title',
      text: 'vds.textInfo.item4.text',
    },
    {
      title: 'vds.textInfo.item5.title',
      text: 'vds.textInfo.item5.text',
    },
    {
      title: 'vds.textInfo.item6.title',
      text: 'vds.textInfo.item6.text',
    },
  ],
};

const rowMd = [
  {
    col: [
      {
        title: '2 vCPU',
        text: 'Под каждую VDS выделяется мощь Intel i7-7700 CPU 4000 Mhz от 2 ядер на сборку.',
      },
      {
        title: '4 GB',
        text: 'Большой объем RAM DDR4 под самые высоконагруженные задачи от 4 ГБ на сборку.',
      },
      {
        title: '65 GB',
        text: 'Невероятная скорость за счет нового поколения SSD накопителей типа NVMe.',
      },
    ],
  },
  {
    col: [
      {
        title: 'NVIDIA',
        text: 'Мощная интегрированная видеокарта для поддержки графических приложений.',
      },
      {
        title: 'WINDOWS 10',
        text: 'Операционная система обычного пользователя как на домашнем ПК с защитой от утечек.',
      },
      {
        title: 'KVM',
        text: 'Высокая производительность за счет аппаратной виртуализации на модифицированном ядре.',
      },
    ],
  },
];

const colXs = [
  {
    title: '2 vCPU',
    text: 'Под каждую VDS выделяется мощь Intel i7-7700 CPU 4000 Mhz от 2 ядер на сборку.',
  },
  {
    title: '4 GB',
    text: 'Большой объем RAM DDR4 под самые высоконагруженные задачи от 4 ГБ на сборку.',
  },
  {
    title: '65 GB',
    text: 'Невероятная скорость за счет нового поколения SSD накопителей типа NVMe.',
  },
  {
    title: 'NVIDIA',
    text: 'Мощная интегрированная видеокарта для поддержки графических приложений.',
  },
  {
    title: 'WINDOWS 10',
    text: 'Операционная система обычного пользователя как на домашнем ПК с защитой от утечек.',
  },
  {
    title: 'KVM',
    text: 'Высокая производительность за счет аппаратной виртуализации на модифицированном ядре.',
  },
];

import { StyleSheet } from 'aphrodite/no-important';

const prev = require('./icon/left-arrow.png');

export const styles = StyleSheet.create({
  slider__font_control: {
    '@media (min-width: 0)': {
      marginBottom: '220px',
    },
    '@media (min-width: 576px)': {
      marginBottom: 0,
    },
  },
  slider__box: {
    backgroundColor: '#61dafb',
    height: '500px',
  },
  slider__box_item: {
    height: '400px',
  },
  slider__box_text: {
    '@media (min-width: 0)': {
      paddingTop: '40px',
      width: '340px',
      color: '#fff',
      textAlign: 'center',
    },
    '@media (min-width: 576px)': {
      paddingTop: '50px',
      width: '340px',
      color: '#fff',
    },
    '@media (min-width: 768px)': {
      paddingTop: '60px',
      width: '450px',
      color: '#fff',
      textAlign: 'left',
    },
    '@media (min-width: 992px)': {
      paddingTop: '60px',
      width: '620px',
      color: '#fff',
    },
    '@media (min-width: 1200px)': {
      paddingTop: '60px',
      width: '770px',
      color: '#fff',
    },
  },
  slider__font_text: {
    '@media (min-width: 0)': {
      fontSize: '14px',
    },
    '@media (min-width: 992px)': {
      fontSize: '16px',
    },
  },
  slider__link: {
    fontSize: '20px',
    color: '#fff',
    ':hover': {
      color: 'white',
    },
  },
  carouselControlPrevIcon: {
    width: '80px',
    height: '80px',
    backgroundImage: `url(${prev})`,
  },
  slider__font_lable: {
    '@media (min-width: 0)': {
      color: 'white',
      marginTop: '40px',
      fontWeight: '400',
      textAlign: 'center',
    },
    '@media (min-width: 576px)': {

    },
    '@media (min-width: 768px)': {
    },
  },
});

export const termsData = {
  title: 'юридическая информация',
  text: 'Политика конфиденциальности'
};

export const parallaxData = [
  {
    start: 0,
    end: 250,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity'
      }
    ]
  }
];
import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  help__box: {
    paddingTop: '120px',
    paddingBottom: '50px',
  },
  help__container: {
    maxWidth: '1000px',
  },
  help__box_icon: {
    marginRight: '10px',
    marginTop: '15px',
    height: '50px',
    cursor: 'pointer',
  },
  help__box_img: {
    height: '45px',
  },
  help__text_box: {
    maxWidth: '650px',
  },
  help__font_label: {
    '@media (min-width: 0)': {
      color: '#292d34',
      fontWeight: '400',
      fontSize: '27px',
    },
    '@media (min-width: 576px)': {
      fontSize: '30px',
    },
    '@media (min-width: 1200px)': {
      fontSize: '33px',
    },
  },
});

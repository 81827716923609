import React from 'react';
import { compose } from 'redux';
import { withModalDialogActions } from '../../../reducer/modalDialog/selectors';
import ModalDialogConfirm from './ModalDialogConfirm';

const ModalDialogInfo = ({ title, text, modalConfirm, modalCancel, ...props }) => (
  <ModalDialogConfirm
    modal
    title={title}
    text={text}
    toggle={modalCancel}
    toggleSuccess={modalConfirm}
    confirm="Ок"
    withoutCancelBtn
    {...props}
  />
);

export default compose(withModalDialogActions)(ModalDialogInfo);

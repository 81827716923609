import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/dom/ajax';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/ignoreElements';
// import { deprecate } from './index.js';

function deprecate(action, msg) {
  /* eslint-disable-next-line no-console */
  console.error(action, msg);
}


/**
 * Исключение для редисера
 *
 * @class ActionError
 * @param {string} message - сообщение об ошибке
 */
export function ActionError(message) {
  this.name = 'ActionError';
  this.message = message;
  this.stack = (new Error()).stack;
}
ActionError.prototype = new Error();

/**
 * создает редюсер для указаного типа.
 * Aвтоматически объединяет со state
 *
 * @param {string} type - тип для редисера
 * @param {callback} callback -  редюсер
 * @returns {Reducer} reducer
 * @example
 * createReducer('Foo event', () => { foo: 'bar'});
 */
export function createReducer(type, callback) {
  return (state, action) => {
    if (!action || !action.type) {
      throw new ActionError('Action should have type');
    }
    if (action.type === type) {
      return {
        ...state,
        ...callback(state, action),
      };
    }
    return state;
  };
}

/**
 * Cоздает  корневой редюсер из массива редюсеров
 *
 * @param {Array<Reducer>} reducers - массив функций редюсеров
 * @param {State} initialState -  первоначальное состояние для редьсера
 * @returns {Reducer} reducer
 */
export function mergeReducers(reducers, initialState) {
  return (state = initialState, action) => {
    if (reducers.length) {
      return reducers.reduce((nextState, callback) => callback(nextState, action), state);
    }
    return state;
  };
}

/**
 * сахар для action
 *
 * @param {string} type - тип для action
 * @returns {ActionCreator} function
 * @example
 * export const someAction = actionCreator('Some Event');
 * someAction({id: 222});
 * //returns { type: 'Some Event', id: 222}
 */
export function actionCreator(type) {
  return payload => ({ type, payload });
}

/**
 * deprecate action creater
 *
 * @param {Function} type - редюсер
 * @param {string} oldName - страрoе имя редюсера
 * @param {string} nextName - страрoе имя редюсера
 *
 * @returns {Function}
 */
export function deprecateActionCreator(type, oldName, nextName) {
  return deprecate(actionCreator(type), `${oldName} reducer actions`, nextName);
}


/**
 * Создает  epic  который делает запрос на backend
 *
 * @param {string} type - redux type
 * @param {string | function} urlMap - маппер для  url
 * @param {ActionCreator} nextAction - следующий action
 */

let csrf = '';
export function createApiEpic(options) {
  const realOptions = {
    delay: 0,
    accessDenyAction: (payload) => ({ type: 'request error', payload}),
    nextAction: x => x,
    method: 'get',
    mapPayload: action => action.payload,
    ...options,
  };

  const {
    type,
    urlMap,
    nextAction,
    accessDenyAction,
    delay,
    method,
    mapPayload,
  } = realOptions;

  const nextUrlMap = typeof urlMap === 'string' ? () => urlMap : urlMap;

  const streamedRequest = (state$) =>(action) => {
    const state = state$.value;
    let req$ = null;
    if (method === 'get') {
      req$ = Observable.ajax(nextUrlMap(action, state));
    } else {
      req$ = Observable.ajax.post(nextUrlMap(action, state), {
        ...mapPayload(action, state),
        _csrf: csrf,
      });
    }
    return req$.catch((error) => {
      const { response, status } = error;
      return [{response}];
    })
      .map(({ response }) => {
        if (response && response.error) {
          return accessDenyAction(response);
        }
        console.log(nextAction.toString(), response)
        return nextAction(response);
      })
      .delay(delay);
  };

  return (action$, state$) => action$.ofType(type).mergeMap(streamedRequest(state$));
}


export function exportActions(typesObject, exported) {
  Object.entries(typesObject).map(([key, type]) => {
    const [first, ...restWords] = key.split('_').map(item => item.toLowerCase());
    const cap = restWords.map(item => item.charAt(0).toUpperCase() + item.slice(1));
    const actionCreatorName = [first, ...cap, 'Action'].join('');
    return [actionCreatorName, type];
  }).forEach(([actionCreatorName, type]) => {
    exported[actionCreatorName] = actionCreator(type);
  });
}

let history = null;

export function getHistory() {
  return history;
}

export function bindHistory(hist) {
  history = hist;
}


export function getCsrf() {
  return csrf;
}

export function bindCsrf(data) {
  csrf = data;
}
export const historyEpic = action$ => action$.ofType('Bind history').do(({ history }) => bindHistory(history)).ignoreElements();

import { StyleSheet } from 'aphrodite';

export const styles = StyleSheet.create({
  registration_form__text_box: {
    backgroundColor: '#20232a',
    height: '220px',
  },
  registration_form__label: {
    fontSize: '60px',
    fontWeight: 300,
    color: 'white',
    marginTop: '60px',
  },
  registration_form__title: {
    fontSize: '15px',
    fontWeight: 300,
    color: 'white',
  },
  registration_form__text: {
    color: '#6d6d6d',
    fontSize: '15px',
    fontWeight: 400,
  },
  registration_form__link: {
    color: '#61dafb',
  },
  registration_form__line: {
    border: 'none',
    backgroundColor: 'black',
    color: 'black',
    height: '2px',
    marginTop: 0,
    marginBottom: '10px',
  },
  registration_form__box: {
    paddingTop: '80px',
    paddingBottom: '20px',
  },
  registration_form__container: {
    maxWidth: '600px',
  },
  btn__primary: {
    border: 'none',
    backgroundColor: '#61dafb',
    color: 'white',
    ':hover': {
      backgroundColor: '#56c7e4',
      color: 'white',
      boxShadow: 'none',
    },
    ':active': {
      backgroundColor: '#56c7e4',
      color: 'white',
      boxShadow: 'none',
    },
    ':focus': {
      boxShadow: 'none',
    },
  },
  btn__outline: {
    backgroundColor: '#fff',
    borderColor: 'rgba(192, 192, 192, 0.8)',
    color: '#6d6d6d',
    ':hover': {
      borderColor: 'rgba(192, 192, 192, 0.8)',
      backgroundColor: 'rgba(192, 192, 192, 0.4)',
      transition: '0.5s',
      color: '#6d6d6d',
      ':not(:hover)': {
        transition: '0.5s',
      },
    },
  },
  input__feedback: {
    color: 'red',
    marginTop: '10px',
    fontSize: '15px',
    fontWeight: 500,
  },
});

import { createApiEpic } from '@nonlux/utils/redux';
import TYPES from './types';
import 'rxjs/add/operator/ignoreElements';

function getCookie(name) {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[]\\\/+^])/g, '\\$1')}=([^;]*)`
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const sendRegDataEpic = createApiEpic({
  type: TYPES.REG_DATA_SEND,
  urlMap: '/api/user/create',
  method: 'post'
});

const sendPartnerRegDataEpic = createApiEpic({
  type: TYPES.APPLY_REG_DATA_SEND,
  urlMap: '/api/user/partner_create',
  method: 'post'
});

const sendSignInDataEpic = createApiEpic({
  type: TYPES.SIGN_IN,
  urlMap: '/login',
  method: 'post'
});

const sendSignPassDataEpic = createApiEpic({
  type: TYPES.SIGN_PASSWORD,
  urlMap: 'api/user/sign_password',
  method: 'post'
});

const goMain = action$ => action$.ofType(TYPES.GO_MAIN).do(() => window.location.href = '/').ignoreElements();

const goSignIn = action$ => action$.ofType(TYPES.GO_SIGN_IN).do(() => window.location.href = '/sign_in').ignoreElements();

const goPanel = action$ => action$.ofType(TYPES.GO_PANEL).do(() => window.location.href = '/panel').ignoreElements();

const goReferer = action$ => action$.ofType(TYPES.GO_REFERER).do(() => {
  const referer = getCookie('referer');
  document.cookie = 'referer=; Max-Age=-99999999;';
  window.location.href = referer
}).ignoreElements();

export default [
  sendRegDataEpic,
  sendPartnerRegDataEpic,
  sendSignInDataEpic,
  sendSignPassDataEpic,
  goMain,
  goSignIn,
  goPanel,
  goReferer
];

import React from 'react';
import { Link as LinkPure } from 'react-router-dom';
import { withCSS } from '@nonlux/react-common/composable';
import { Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  ContainerContent,
  ColTextList
} from 'components';
import styled from 'styled-components';
import { connect } from 'react-redux';
import STYLES from '../../../styles/constants.js';

const LinkTarget = styled.a`
  cursor: pointer;
  color: #1a1a1a;
  background-color: rgba(187,239,253,0.3);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  font-size: ${({ layoutType }) => (['xl'].includes(layoutType) ? 18 : 16)}px;
  &:hover {
    color: #1a1a1a;
    text-decoration: none;
    background-color: #bbeffd;
    border-bottom-color: #1a1a1a;
  }
`;

const LinkHash = styled(LinkPure)`
  cursor: pointer;
  color: #1a1a1a;
  background-color: rgba(187,239,253,0.3);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  font-size: ${({ layoutType }) => (['xl'].includes(layoutType) ? 18 : 16)}px;
  &:hover {
    color: #1a1a1a;
    text-decoration: none;
    background-color: #bbeffd;
    border-bottom-color: #1a1a1a;
  }
`;

const Link = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(LinkHash);


export const ToggleLink = ({ link }) => {
  const { t } = useTranslation();
  if (link.type === 'target') {
    return (
      <LinkTarget to={link.href} target="self">
        {t(link.name)}
      </LinkTarget>
    );
  }
  return (
    <Link to={link.href}>
      {t(link.name)}
    </Link>
  );
};

export default function Services({ services, layoutType }) {
  return (
    <ContainerContent>
      <Row>
        <ColTextList col={services} layoutType={layoutType}>
          <ToggleLink />
        </ColTextList>
      </Row>
    </ContainerContent>
  );
}

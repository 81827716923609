import React  from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import {
  Header, Footer, P, H2, H3, TextContainer
} from 'components';
import { css } from 'aphrodite/no-important';
import { withCSS } from '@nonlux/react-common/composable';
import { createDiv, PropTypesExtends } from '@nonlux/react-common';
import {
  Col, Row, Container
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link as PureLink } from 'react-router-dom';
import STYLES from '../../styles/constants.js';
import { styles } from './styles';

const transitionName = 'disappearance';

export const Layout = ({ children }) => {
  const [cookies, setCookie] = useCookies(['accept_cookies']);

  function handleClick(newName) {
    setCookie('accept_cookies', newName, { path: '/' });
  }

  return (
    <ContainerFluid className={css(styles.layout)}>
      <CookiesProvider>
        <Header />
        {children}
        <Footer />
        <CSSTransition
          in={!cookies.accept_cookies}
          timeout={200}
          classNames={transitionName}
          unmountOnExit
          appear
        >
          <FeatureBar handleClick={handleClick} cookies={cookies} />
        </CSSTransition>
      </CookiesProvider>
    </ContainerFluid>
  );
};
Layout.propTypes = {
  children: PropTypesExtends.children.isRequired
};

export const Root = styled.div`
  background-color: #20232a;
  position: fixed;
  bottom: 0;
  color: #ffffff;
  margin-left: -15px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5em;
  z-index: 100000;
  gap: 30px;
  flex-wrap: wrap;

  &.${transitionName}-appear {
    opacity: 0;
    transform: translateY(100%);
  }

  &.${transitionName}-appear-active {
    opacity: 1;
    transition: all 0.15s cubic-bezier(.4,0,.2,1);
    transform: translateY(0);
  }

  &.${transitionName}-exit {
    opacity: 1;
  }

  &.${transitionName}-exit-active {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0,0,.2,1);
    transform: translateY(100%);
  }
`;

const Button = styled.button`
  text-decoration: none;
  user-select: none;
  padding: 10px 25px;
  background-color: #61dafb;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  outline: none;
  border: none;
  &:hover {
    background-color: white;
  }
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
`;

const Link = styled(PureLink)`
  cursor: pointer;
  color: ${({ dark }) => (dark ? '#ffffff' : '#1a1a1a')};
  background-color: ${({ dark }) => (dark ? 'rgba(97, 218, 251, 0.3)' : 'rgba(187,239,253,0.3)')};
  border-bottom: 1px solid rgba(0,0,0,0.2);
  &:hover {
    color: ${({ dark }) => (dark ? '#ffffff' : '#1a1a1a')};
    background-color: ${({ dark }) => (dark ? 'rgba(97, 218, 251, 0.4)' : '#bbeffd')};
    border-bottom-color: #1a1a1a;
    text-decoration: none;
  }
`;

const FeatureBar = ({ handleClick, cookies }) => (
  <Root cookies={cookies}>
    <span>
      Этот сайт использует cookies для улучшения вашего опыта. Принимая, вы соглашаетесь с&nbsp;
      <Link dark to="/privacy">
        Политикой Конфиденциальности
      </Link>
      .
    </span>
    <Button data-variant="flat" onClick={() => handleClick('accepted')}>
      Принять
    </Button>
  </Root>
);

export const ContainerFluid = createDiv('ContainerFluid', 'container-fluid');

const RowColContainer = ({ children, className, id = false }) => (
  <Row className={className} id={id}>
    <Col>
      {children}
    </Col>
  </Row>
);

const TitleContainer = createDiv('TitleContainer', ({ titleWidth, titleMarginBottom = 0 }) => ({
  maxWidth: titleWidth ? `${titleWidth}px` : '100%',
  textAlign: 'center',
  marginBottom: `${titleMarginBottom}px`
}));

const ContainerContentPure = withCSS(({
  color = false, pb = 60, pt = 60, layoutType
}) => {
  function getPaddingTop() {
    if (typeof pt === 'object' && pb !== null) {
      const layoutPadding = {
        xs: pt.xs,
        sm: pt.sm,
        md: pt.md,
        lg: pt.lg,
        xl: pt.xl
      };
      return layoutPadding[layoutType];
    }
    return pt;
  }

  function getPaddingBottom() {
    if (typeof pb === 'object' && pb !== null) {
      const layoutPadding = {
        xs: pb.xs,
        sm: pb.sm,
        md: pb.md,
        lg: pb.lg,
        xl: pb.xl
      };
      return layoutPadding[layoutType];
    }
    return pb;
  }

  return ({
    backgroundColor: color ? STYLES.COLORS.WHITE_BLUE : STYLES.COLORS.WHITE,
    paddingTop: `${getPaddingTop()}px`,
    paddingBottom: `${getPaddingBottom()}px`
  });
})(RowColContainer);

export const RowContent = withCSS(({
  mt = 40, pl = 0, pr = 0, mb = 0
}) => ({
  marginBottom: `${mb}px`,
  marginTop: `${mt}px`,
  paddingLeft: `${pl}px`,
  paddingRight: `${pr}px`
}))(Row);

export const ContainerContent = (props) => {
  const {
    title,
    children,
    color,
    titleWidth,
    id,
    pb,
    pt,
    layoutType,
    titleMarginBottom
  } = props;
  const hideTitle = () => (title && (
    <Row className="justify-content-center">
      <TitleContainer titleWidth={titleWidth} titleMarginBottom={titleMarginBottom}>
        <H2>{title}</H2>
      </TitleContainer>
    </Row>
  ));
  return (
    <ContainerContentPure color={color} id={id} pb={pb} pt={pt} layoutType={layoutType}>
      {hideTitle()}
      <Container>
        {children}
      </Container>
    </ContainerContentPure>
  );
};

export const ColTextList = ({
  col, children, layoutType, lg = 4
}) => col.map((item, index) => {
  const { t } = useTranslation();
  const getMarginTop = () => {
    if (index === 1 && ['xs'].includes(layoutType)) return 'mt-4';
    if (index === 2 && ['xs', 'sm', 'md'].includes(layoutType)) return 'mt-4';
    return null;
  };
  return (
    <Col key={item.title} xs={12} sm={6} lg={lg} className={getMarginTop()}>
      <H3>
        {t(item.title)}
      </H3>
      <TextContainer mt={20}>
        <P>
          {t(item.text)}
        </P>
      </TextContainer>
      {children && React.cloneElement(children, { ...item })}
    </Col>
  );
});

export const RowTextList = ({ row, lg }) => row.map((item, index) => (
  <RowContent key={index}>
    <ColTextList col={item.col} lg={lg} />
  </RowContent>
));

export Header from './Header/Header';
export Footer from './Footer/Footer';
export Slider from './Slider/Slider';
export TariffsForm from './TariffsForm/TariffsForm';
export Parallax from './Parallax/Parallax';
export ModalContainer from './ModalContainer/ModalContainer';
export Advantage from './Advantage/Advantage';
export ImgRow from './ImgRow/ImgRow';
export TextInfo from './TextInfo/TextInfo';
export Presentation from './Presentation/Presentation';
export ContainerImgInfo from './ContainerImgInfo/ContainerImgInfo';
export CallRegistration from './CallRegistration/CallRegistration';
export Support from './Support/Support';
export ToggleInfo from './ToggleInfo/ToggleInfo';
export { Img, ImgContainer, ImgInnerContainer } from './Img/Img';
export { Border, BorderContainer } from './Border/Border';
export { ButtonPosition, ButtonContainer } from './ButtonContainer/ButtonContainer';
export {
  LinkTarget,
  LinkPrimaryDark,
  HashLinkOutline,
  LinkSecondaryDark,
  LinkTargetPrimaryLight,
  LinkPrimaryLight,
  LinkSecondaryLight,
  LinkPrimaryDarkTarget,
  HashLinkPrimaryDark
} from './Links/Links';
export {
  Layout,
  ContainerContent,
  RowContent,
  ColTextList,
  RowTextList,
} from './Layout/Layout';
export {
  P, H1, H2, H3, TextContainer,
} from './Text/Text';
export {
  Link, Container, Text, Intro, Title,
} from './Notice.js';
export { Box } from './Box/Box';
export { Channel } from './Channel/Channel';


export SelectBar from './ZSelectBar/SelectBar'; // юз в логин и регистрация
export Input from './Input/Input'; // юз в логин и регистрация

/* export RowContainer from './ZRowContainer/RowContainer';
export FaIcon from './FaIcon/FaIcon'; */

/* export Select from './Select/Select'; */

/* export Links from './ZLinks/Links';
export LinksList from './ZLinksList/LinksList'; */
/* export Services from './ZServices/Services';
export ServicesInfo from './ZServicesInfo/ServicesInfo'; */
/* export Presentation from './ZPresentation/Presentation';
export Info from './ZInfo/Info'; */
/*
export TariffsFormBetStorm from './ZTariffsForm/TariffsFormBetStorm';
*/
/*
export ImgRow from './ZImgRow/ZImgRow';
*/
/*
export { Text, TextList, ColTextList, RowTextList } from './ZText/Text';
*/
/*
export { Img, ImgsList, ImgCol, ImgsListContainer } from './ZImgsListContainer/ImgsListContainer';
*/
/*
export ModalContainer from './ZModalContainer/ModalContainer';
*/

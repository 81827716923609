import React from 'react';
import Modal from 'react-modal';
import {
  Col,
  Row,
} from 'reactstrap';
import YouTube from 'react-youtube';
import { withCSS, toggleble } from '@nonlux/react-common/composable';
import { createElement, createDiv } from '@nonlux/react-common';
import { compose } from 'recompose';
import { StyleSheet, css } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import STYLES from '../../styles/constants.js';

import close from './icon/close.png';
import iconImg from './icon/iconImg.png';

const styles = StyleSheet.create({
  preModalWidthMax: {
    maxWidth: '100%',
  },
  preModalWidthMiddle: {
    maxWidth: '600px',
  },
  preModal: {
    marginLeft: 'auto',
    marginRight: 'auto',
    cursor: 'pointer',
    display: 'inline-block',
    outline: 'none'
  },
  preModalBorder: {
    border: `1px solid ${STYLES.COLORS.DARK_BLUE}`,
  },
  modal: {
    display: 'inline-block',
    verticalAlign: 'middle',
    textAlign: 'center',
    position: 'absolute',
    top: '200px',
    left: '0',
    right: '0',
    bottom: '160px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    outline: 'none',
    '@media (min-width: 576px)': {
      top: '120px',
      bottom: '80px',
    },
    '@media (min-width: 768px)': {
      left: '50px',
      right: '50px',
    },
    '@media (min-width: 992px)': {
      left: '150px',
      right: '150px',
    }
  },
  modalOverlay: {
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    '> div': {
      height: '100%',
    },
  },
  modalIconClose: {
    width: '25px',
    height: '25px',
    padding: '3px',
  },
  modalBtn: {
    outline: 'none',
    position: 'absolute',
    width: '50px',
    height: ' 50px',
    backgroundColor: '#61dafb',
    border: 'none',
    top: ' -50px',
    right: 0,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#02ccf0',
      transition: '0.5s',
    },
  },
  modalIcon: {
    width: '60px',
    height: '60px',
    paddingTop: '13px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    position: 'absolute',
  },
  modalIconVideo: {
    marginRight: 'auto',
    marginLeft: 'auto',
    position: 'relative',
    top: '2px',
    backgroundColor: '#61dafb',
    height: '25px',
    width: '25px',
    clipPath: 'polygon(0 0, 100% 50%, 0 100%, 0 0)',
    '@media (min-width: 576px)': {
      height: '28px',
      width: '28px',
    },
  },
});

const VideoContainer = createDiv('VideoContainer', {
  height: '100%',
  '*div': {
    height: '100%',
  },
});

const ModalContainer = (props) => {
  const { className, mt = 15, mb = 30 } = props;
  const styleHuc = {
    marginTop: `${mt}px`,
    marginBottom: `${mb}px`,
  };
  return (
    <Row className={className} style={styleHuc}>
      <ModalContentList {...props} />
    </Row>
  );
};
export default withCSS(
  ({ activeStyle, ...rest }) => (activeStyle || {
    marginTop: '15px',
    marginBottom: '15px',
    ...rest,
  }), true,
)(ModalContainer);

function TogglePreModalIcon({ type }) {
  const icon = (type !== 'img') ? <div className={css(styles.modalIconVideo)} /> : <img alt="iconImg" src={iconImg} className="img-fluid" />;
  return (
    <div className={css(styles.modalIcon)}>
      {icon}
    </div>
  );
}

export const PreModal = ({
  src, setOpen, type = 'img', width = 'max', alt, border = false,
}) => {
  const togglePreModalWidth = (width !== 'max') ? styles.preModalWidthMiddle : styles.preModalWidthMax;
  const preModalBorder = border && styles.preModalBorder;
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={() => setOpen(true)}
      className={css(styles.preModal, togglePreModalWidth, preModalBorder)}
    >
      <TogglePreModalIcon type={type} />
      <Img alt={alt} src={src} />
    </div>
  );
}

const ModalContentList = (props) => {
  const { src } = props;
  if (Array.isArray(src)) {
    return src.map(item => <ModalContent key={`${item.alt}`} {...item} />);
  }
  return <ModalContent {...props} src={src} lg="12" />;
};

function Content({ type, ...rest }) {
  if (type === 'video') return <Video {...rest} />;
  if (type === 'gif') return <Gif {...rest} />;
  return <Img {...rest} />;
}

function Video({ id }) {
  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      rel: 0,
    },
  };
  return (
    <div className="video-container">
      <YouTube
        videoId={`${id}`}
        opts={opts}
      />
    </div>
  );
}

const ImgPure = createElement('img', 'ImgPure', 'img-fluid');
const Img = withCSS({ maxHeight: '100%' })(ImgPure);

function GifPure({ alt, gifId, className }) {
  return <img alt={alt} src={gifId} className={`${className} img-fluid`} />;
}
const Gif = withCSS({ maxHeight: '100%' })(GifPure);

function ModalContentPure(props) {
  const {
    src, text, setOpen, isOpen, className, lg, width, type, id, gifId, border,
  } = props;
  const info = text ? (
    <p className="text-center">
      {text}
    </p>
  ) : null;
  return (
    <Col xs="12" lg={lg || 6} className={className}>
      {info}
      <PreModal
        text={text}
        src={src}
        setOpen={setOpen}
        className={css(styles.modal)}
        width={width}
        type={type}
        border={border}
      />
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        className={css(styles.modal)}
        overlayClassName={css(styles.modalOverlay)}
      >
        <button
          type="button"
          className={css(styles.modalBtn)}
          onClick={() => setOpen(false)}
        >
          <img alt="close" src={close} className={css(styles.modalIconClose)} />
        </button>
        <Content
          id={id}
          src={src}
          type={type}
          gifId={gifId}
        />
      </Modal>
    </Col>
  );
}

const ModalContent = compose(
  toggleble(),
  connect(state => ({
    layoutType: state.windowResize.layoutType,
  })),
  withCSS(
    ({ layoutType }) => (!(layoutType === 'lg' || layoutType === 'xl') && { marginTop: '30px' }), true,
  ),
)(ModalContentPure);

export const tariffsVdsClassic = {
  title: 'tariffs.tariffsVdsClassic.title',
  label: 'tariffs.tariffsVdsClassic.label',
  text_1: 'tariffs.tariffsVdsClassic.text_1',
  text_2: 'tariffs.tariffsVdsClassic.text_2',
  text_3: 'tariffs.tariffsVdsClassic.text_3',
  price: {
    sum: 'tariffs.tariffsVdsClassic.price.sum',
    currency: 'tariffs.tariffsVdsClassic.price.currency',
    date: 'tariffs.tariffsVdsClassic.price.date',
  },
};

export const tariffsProxyClassic = {
  title: 'tariffs.tariffsProxyClassic.title',
  label: 'tariffs.tariffsProxyClassic.label',
  text_1: 'tariffs.tariffsProxyClassic.text_1',
  text_2: 'tariffs.tariffsProxyClassic.text_2',
  text_3: 'tariffs.tariffsProxyClassic.text_3',
  price: {
    sum: 'tariffs.tariffsProxyClassic.price.sum',
    currency: 'tariffs.tariffsProxyClassic.price.currency',
    date: 'tariffs.tariffsProxyClassic.price.date',
  },
};

export const tariffsPro = {
  title: 'tariffs.tariffsPro.title',
  vds: {
    label: 'tariffs.tariffsVdsClassic.label',
    text_1: 'tariffs.tariffsVdsClassic.text_1',
    text_2: 'tariffs.tariffsVdsClassic.text_2',
    text_3: 'tariffs.tariffsVdsClassic.text_3',
  },
  proxy: {
    label: 'tariffs.tariffsProxyClassic.label',
    text_1: 'tariffs.tariffsProxyClassic.text_1',
    text_2: 'tariffs.tariffsProxyClassic.text_2',
    text_3: 'tariffs.tariffsProxyClassic.text_3',
  },
  price: {
    sum: 'tariffs.tariffsPro.price.sum',
    currency: 'tariffs.tariffsPro.price.currency',
    date: 'tariffs.tariffsPro.price.date',
  },
};
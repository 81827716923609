import React from 'react';
import { withCSS } from '@nonlux/react-common/composable';
import { Col, Row, Container } from 'reactstrap';
import { createDiv } from '@nonlux/react-common';
import { Link } from 'react-router-dom';
import {
  Img,
  RowContent,
  H3,
  LinkTarget,
} from 'components';
import { useTranslation } from 'react-i18next';
import STYLES from '../../../styles/constants.js';

import neteller from './img/neteller.png';
import skrill from './img/skrill.png';
import abb from './img/abb.png';
import betfair from './img/betfair.png';
import pinnacle from './img/pinnacle.png';
import sbobet from './img/sbobet.png';

export default function AffiliateList({ layoutType }) {
  const getMarinTopXs = ['xs'].includes(layoutType) ? 20 : 27;
  const getMarinBottomXs = ['xs'].includes(layoutType) ? 10 : 0;
  const { t } = useTranslation();
  return (
    <RowContainer>
      <Title>{t('main.affiliateList.title')}</Title>
      <RowContent mt={getMarinTopXs} mb={getMarinBottomXs}>
        <Col>
          <ImgContainer maxWidth={165}>
            <Img src={neteller} />
          </ImgContainer>
        </Col>
        <Col className="d-none d-sm-block">
          <ImgContainer maxWidth={90} bottom={3}>
            <Img src={skrill} />
          </ImgContainer>
        </Col>
        <Col className="d-none d-sm-block">
          <ImgContainer bottom={10}>
            <LinkTarget to="https://arbitrage-bets.com/partners/all_best_bets" target="blank">
              <AbbImg src={abb} />
            </LinkTarget>
          </ImgContainer>
        </Col>
        <Col className="d-none d-md-block">
          <ImgContainer>
            <Img src={betfair} />
          </ImgContainer>
        </Col>
        <Col className="d-none d-lg-block">
          <ImgContainer maxWidth={130} top={2}>
            <Img src={pinnacle} />
          </ImgContainer>
        </Col>
        <Col className="d-none d-xl-block">
          <ImgContainer maxWidth={135} top={2}>
            <Img src={sbobet} />
          </ImgContainer>
        </Col>
      </RowContent>
    </RowContainer>
  );
}

const RowContainerPure = ({ className, children }) => (
  <Row className={className}>
    <Container>
      {children}
    </Container>
  </Row>
);

/* const NavLink = withCSS({
  '@media (min-width: 0)': {
    display: 'inline',
    color: '#fff',
    borderColor: 'rgba(255,255,255,0.5)',
    transition: 'border-color 0.2s ease',
    fontSize: '14px',
    fontWeight: 300,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    webkitTransition: 'border-color 0.2s',
    textAlign: 'center'
  },
  '@media (min-width: 576px)': {
    fontSize: '24px',
  },
  '@media (min-width: 992px)': {
    fontSize: '20px',
  },
  ':hover': {
    color: '#fff',
    borderColor: '#fff',
    textDecoration: 'none',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
  },
})(LinkTarget);

const ContainerNavLink = createDiv('ContainerNavLink', {
  display: 'flex',
  justifyContent: 'center'
}); */

const RowContainer = withCSS({
  paddingTop: '20px',
  paddingBottom: '13px',
  backgroundColor: STYLES.COLORS.BLUE,
})(RowContainerPure);

const Title = withCSS({
  fontSize: '27px',
  fontWeight: 200,
  color: STYLES.COLORS.WHITE,
  textAlign: 'center',
})(H3);

const AbbImg = withCSS({
  maxWidth: '200px',
})(Img);

export const ImgContainer = createDiv('ImgContainer', ({ maxWidth, bottom, top }) => ({
  position: 'relative',
  bottom: `${bottom}px`,
  top: `${top}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: `${maxWidth}px`,
}));

import React from 'react';
import { withModalDialogData } from '../../../reducer/modalDialog/selectors';
import ModalDialogConfirm from './ModalDialogConfirm';

const ConfirmBuyProxy = ({ modalDialogData }) => (
    <ModalDialogConfirm
        title="Покупка прокси"
        text={`Количество: ${modalDialogData.proxyCount}, итоговая сумма: ${modalDialogData.sum}€`}
    />
);

export default withModalDialogData(ConfirmBuyProxy);

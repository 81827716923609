import { StyleSheet } from 'aphrodite/no-important';

export const styles = StyleSheet.create({
  parallax__f1g2: {
    position: 'absolute',
    height: '200px',
    left: '120px',
    top: '-65px',
  },
  parallax__x1d2: {
    position: 'absolute',
    height: '200px',
    right: '115px',
    top: '-225px',
  },
});

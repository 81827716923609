import React from 'react';
import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  Form,
} from 'reactstrap';
import {
  ButtonPrimaryLight,
  ButtonSecondaryLight,
} from '@nonlux/ab-ui';
import { Input } from 'components';
import { Link } from 'react-router-dom';
import { withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { css } from 'aphrodite';

import { withFormikConnected } from '@nonlux/react-common/formik';
import { emailRegex } from '@nonlux/utils/regexp';
import { selectSignInErrors } from 'reducer/sign/selectors';
import { signPasswordAction, clearSignInErrorsAction } from 'reducer/sign/actions';
import { styles } from '../LoginPage/styles';

export default function PasswordPage() {
  const { t } = useTranslation();
  return (
    <Row className={css(styles.login_form__box)}>
      <Container className={css(styles.login_form__container)}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <PasswordPageConnected />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <Link to="/sign_up">
              <ButtonPrimaryLight className="rounded-0">
                {t('passwordSign.button.forgotPassword')}
              </ButtonPrimaryLight>
            </Link>
          </Col>
        </Row>
      </Container>
    </Row>
  );
}

const PasswordPagePure = (props) => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;
  const { t } = useTranslation();
  const errors = {
    ...props.serverErrors,
    ...props.errors,
  };
  const styleErrEmail = `${errors.email && touched.email ? 'text-input error' : 'text-input'} mt-1`;
  const messageErrEmail = touched.email && errors.email
    && (
    <div className={css(styles.input__feedback)}>
      {t(errors.email)}
    </div>
    );
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="password"
        name="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        className={`${errors.password && touched.password ? 'text-input error' : 'text-input'} mt-4`}
        placeholder={t('passwordSign.placeholder')}
        autocomplite="current-password"
      />
      {touched.password && errors.password
      && (
      <div className={css(styles.input__feedback)}>
        {t(errors.password)}
      </div>
      )}
      <Row className="mt-4">
        <Col className="mr-auto">
          <ButtonPrimaryLight className="rounded-0">
            {t('passwordSign.button.login')}
          </ButtonPrimaryLight>
        </Col>
        <Col xs="auto">
          <Link to="/forgot_password">
            <ButtonSecondaryLight className="rounded-0">
              {t('passwordSign.button.forgotPassword')}
            </ButtonSecondaryLight>
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

const errorsMessages = {
  password: 'passwordSign.errorsMessages.password',
  unknown: 'passwordSign.errorsMessages.unknown',
};
const PasswordPageConnected = withFormikConnected({
  mapPropsToValues: () => ({ email: '', password: '' }),
  validate: (values, { dispatch }) => {
    const errors = {};
    dispatch(clearSignInErrorsAction());
    if (!values.password) {
      errors.password = errorsMessages.password;
    }
    return errors;
  },
  handleSubmit(values, { props: { dispatch } }) {
    dispatch(signPasswordAction(values));
  },
}, state => ({
  serverErrors: selectSignInErrors(state).reduce((acc, next) => {
    if (Array.isArray(next)) {
      const [key, msgKey] = next;
      return {
        ...acc,
        [key]: errorsMessages[msgKey],
      };
    }
    return {
      ...acc,
      [next]: errorsMessages[next],
    };
  }, {}),
}))(PasswordPagePure);

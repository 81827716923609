import React from 'react';
import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  Form,
} from 'reactstrap';
import { Input } from 'components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withFormikConnected } from '@nonlux/react-common/formik';
import { resendVerifyEmailAction } from 'reducer/verifyRecover/actions';
import { selectResendVerifyErrors } from 'reducer/verifyRecover/selectors';
import { css } from 'aphrodite';
import { styles } from './styles';

export default function AccountConfirmationPage() {
  const { t } = useTranslation();
  return (
    <Row className={css(styles.forgot_form__box)}>
      <Container className={css(styles.forgot_form__container)}>
        <h2 className={css(styles.forgot_form__font_title)}>
          {t('confirmationAccount.title')}
        </h2>
        <Row className="mt-4">
          <Col>
            <Card>
              <CardBody>
                <ForgotPasswordFormConnected />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <p className={css(styles.registration_form__text)}>
              {t('confirmationAccount.linkRecoveryInstruction.text1')}
            </p>
            <p className={css(styles.registration_form__text)}>
              {t('confirmationAccount.linkRecoveryInstruction.text2')}
              &nbsp;
              <Link className={css(styles.registration_form__link)} to="/sign_up">
                {t('confirmationAccount.linkRecoveryInstruction.callRegistration')}
              </Link>
              &nbsp;
              {t('confirmationAccount.linkRecoveryInstruction.text3')}
              &nbsp;
              <Link className={css(styles.registration_form__link)} to="/support">
                {t('confirmationAccount.linkRecoveryInstruction.callSupport')}
              </Link>
              {t('confirmationAccount.linkRecoveryInstruction.text4')}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/sign_in" className={css(styles.forgot_form_link)}>
              &#60;&nbsp;
              {t('confirmationAccount.link')}
            </Link>
          </Col>
        </Row>
      </Container>
    </Row>
  );
}

const ForgotPasswordForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    serverErrors,
  } = props;
  const { t } = useTranslation();
  if (serverErrors.user_not_exists) errors.email = serverErrors.user_not_exists;
  if (serverErrors.user_already_verified) errors.email = serverErrors.user_already_verified;
  const styleErrEmail = `${errors.email && touched.email ? 'text-input error' : 'text-input'} mt-1`;
  const messageErrEmail = touched.email && errors.email
    && (
    <div className={css(styles.input__feedback)}>
      {t(errors.email)}
    </div>
    );
  return (
    <Form onSubmit={handleSubmit}>
      <small className="text-muted">
        {t('confirmationAccount.email.label')}
      </small>
      <Input
        type="email"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        className={styleErrEmail}
        placeholder={t('confirmationAccount.email.placeholder')}
      />
      {messageErrEmail}
      <Row className={css(styles.forgot_form__box_btn)}>
        <Col xs="auto">
          <Button className={`${css(styles.btn__primary)} rounded-0`}>
            {t('confirmationAccount.button')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const errorsMessages = {
  user_not_exists: 'confirmationAccount.errorsMessages.email',
  user_already_verified: 'confirmationAccount.errorsMessages.email2',
};

const ForgotPasswordFormConnected = withFormikConnected({
  mapPropsToValues: () => ({ email: '', password: '' }),
  validate: (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'confirmationAccount.errorsMessages.email';
    } if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'confirmationAccount.errorsMessages.email';
    }
    return errors;
  },
  handleSubmit(values, { props: { dispatch } }) {
    dispatch(resendVerifyEmailAction(values));
  },
}, state => ({
  serverErrors: selectResendVerifyErrors(state).reduce((acc, next) => ({
    ...acc,
    [next]: errorsMessages[next],
  }), {}),
}))(ForgotPasswordForm);

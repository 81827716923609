import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContainerContent,
  RowTextList,
  ButtonContainer,
  LinkTargetPrimaryLight
} from 'components';

export default function Advantage({ title, content, layoutType, linkButton }) {
  const { t } = useTranslation();
  return (
    <ContainerContent title={title}>
      <RowTextList row={content} />
      {linkButton && (
        <ButtonContainer mt={40}>
          <LinkTargetPrimaryLight
            to={linkButton}
            target="_self"
            layoutType={layoutType}
          >
            {t('bet_storm.advantage.button')}
          </LinkTargetPrimaryLight>
        </ButtonContainer>
      )}
    </ContainerContent>
  );
}
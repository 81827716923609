import React from 'react';
import {
  Col,
  Row,
  Container,
  Card,
  CardBody,
  Form,
} from 'reactstrap';
import {
  ButtonPrimaryLight,
  ButtonSecondaryLight,
} from '@nonlux/ab-ui';
import { Input } from 'components';
import { Link } from 'react-router-dom';
import { css } from 'aphrodite';
import { useTranslation } from 'react-i18next';
import { withFormikConnected } from '@nonlux/react-common/formik';
import { emailRegex } from '@nonlux/utils/regexp';
import { selectSignInErrors } from 'reducer/sign/selectors';
import { signInAction, clearSignInErrorsAction } from 'reducer/sign/actions';
import fp from '@fingerprintjs/fingerprintjs';
import { styles } from './styles';

export default function LoginPage() {
  const { t } = useTranslation();
  return (
    <Row className={css(styles.login_form__box)}>
      <Container className={css(styles.login_form__container)}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <LoginFormConnected />
              </CardBody>
            </Card>
          </Col>
        </Row>
{/*        <Row className="mt-4">
          <Col className="text-center">
            <Link to="/sign_up">
              <ButtonPrimaryLight className="rounded-0">
                {t('login.signUpButton')}
              </ButtonPrimaryLight>
            </Link>
          </Col>
        </Row>*/}

        <Row className="mt-3">
          <Col className="text-center">
            <p className={css(styles.registration_form__text)}>
              {t('login.signUp.text')}
              &nbsp;
              <Link className={css(styles.registration_form__link)} to="/sign_up">
                {t('login.signUp.link')}
              </Link>
            </p>
            <p className={css(styles.registration_form__text)}>
              {t('login.loginPage.text')}
            </p>
            <Link className={css(styles.registration_form__link)} to="/confirm_account">
              {t('login.loginPage.link')}
            </Link>
          </Col>
        </Row>
      </Container>
    </Row>
  );
}

const LoginForm = (props) => {
  const {
    values,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;
  const { t } = useTranslation();
  const errors = {
    ...props.serverErrors,
    ...props.errors,
  };
  const styleErrEmail = `${errors.email && touched.email ? 'text-input error' : 'text-input'} mt-1`;
  const messageErrEmail = touched.email && errors.email
    && (
    <div className={css(styles.input__feedback)}>
      {t(errors.email)}
    </div>
    );
  console.log(errors)
  return (
    <Form onSubmit={handleSubmit}>
      <Input
        type="email"
        name="email"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        className={styleErrEmail}
        placeholder={t('login.placeholder.email')}
      />
      {messageErrEmail}
      <Input
        type="password"
        name="password"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        className={`${errors.password && touched.password ? 'text-input error' : 'text-input'} mt-4`}
        placeholder={t('login.placeholder.pass')}
        autocomplite="current-password"
      />
      {touched.password && errors.password
      && (
      <div className={css(styles.input__feedback)}>
        {t(errors.password)}
      </div>
      )}
      {errors.ipOrFp &&
        <Col className="mr-auto">
          <div className={css(styles.input__feedback)}>
            {t(errors.ipOrFp)}
          </div>
        </Col>
      }
      <Row className="mt-4">
        <Col className="mr-auto">
          <ButtonPrimaryLight className="rounded-0">
            {t('login.in')}
          </ButtonPrimaryLight>
        </Col>
        <Col xs="auto">
          <Link to="/forgot_password">
            <ButtonSecondaryLight className="rounded-0">
              {t('login.pass')}
            </ButtonSecondaryLight>
          </Link>
        </Col>
      </Row>
    </Form>
  );
};

const errorsMessages = {
  email: 'login.errorsMessages.email',
  ipOrFp: 'registration.errorsMessages.ipOrFp',
  password: 'login.errorsMessages.password',
  unknown: 'login.errorsMessages.unknown',
};
const LoginFormConnected = withFormikConnected({
  mapPropsToValues: () => ({ email: '', password: '' }),
  validate: (values, { dispatch }) => {
    const errors = {};
    dispatch(clearSignInErrorsAction());
    if (!values.email || !emailRegex.test(values.email)) {
      errors.email = errorsMessages.email;
    }
    if (!values.password) {
      errors.password = errorsMessages.password;
    }
    return errors;
  },
  async handleSubmit(values, { props: { dispatch } }) {
    const components = await fp.getPromise({ fonts: { extendedJsFonts: true } });
    const fpHash = fp.x64hash128(components.map(component => component.value).join(''), 31);
    dispatch(signInAction({ ...values, fp: fpHash }));
  },
}, state => ({
  serverErrors: selectSignInErrors(state).reduce((acc, next) => {
    console.log('serverErrors', acc, next)
    if (Array.isArray(next)) {
      const [key, msgKey] = next;
      return {
        ...acc,
        [key]: errorsMessages[msgKey],
      };
    }
    return {
      ...acc,
      [next]: errorsMessages[next],
    };
  }, {}),
}))(LoginForm);

import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  ContainerContent
} from 'components';
import {
  Grid, Title, Text, Strong, A
} from '../common';

export default function WhyArb({ title }) {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  return (
    <ContainerContent title={title} titleMarginBottom={40}>
      <Grid>

        <div>
          <Title>
            {t('affiliate.advantage.Прозрачная статистика.title')}
          </Title>
          {['cn', 'en'].includes(language) ? (
            <Text>
              {t('affiliate.advantage.Прозрачная статистика.text')}
            </Text>
          ) : (
            <Text>
              {t('affiliate.advantage.Прозрачная статистика.text.t1')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Прозрачная статистика.text.s1')}
              </Strong>
              .&nbsp;
              {t('affiliate.advantage.Прозрачная статистика.text.t2')}
            </Text>
          )}
        </div>

        <div>
          <Title>
            {t('affiliate.advantage.Высокая конверсия.title')}
          </Title>
          {['cn', 'en'].includes(language) ? (
            <Text>
              {t('affiliate.advantage.Высокая конверсия.text')}
            </Text>
          ) : (
            <Text>
              {t('affiliate.advantage.Высокая конверсия.text.t1')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Высокая конверсия.text.s1')}
              </Strong>
              .&nbsp;
              {t('affiliate.advantage.Высокая конверсия.text.t2')}
              &nbsp;
              <A href="https://vk.com/topic-174727572_39487483" target="_blank">
                {t('affiliate.advantage.Высокая конверсия.text.a1')}
              </A>
              &nbsp;
              {t('affiliate.advantage.Высокая конверсия.text.t3')}
              &nbsp;
              <A href="https://vk.com/@arbitragebets-obratnaya-svyaz-ot-polzovatelei-nashego-servisa-spasibo" target="_blank">
                {t('affiliate.advantage.Высокая конверсия.text.a2')}
              </A>
              &nbsp;
              {t('affiliate.advantage.Высокая конверсия.text.t4')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Высокая конверсия.text.s2')}
              </Strong>
            </Text>
          )}
        </div>

        <div>
          <Title>
            {t('affiliate.advantage.Отличные лояльность и удержание.title')}
          </Title>
          {['cn', 'en'].includes(language) ? (
            <Text>
              {t('affiliate.advantage.Отличные лояльность и удержание.text')}
            </Text>
          ) : (
            <Text>
              {t('affiliate.advantage.Отличные лояльность и удержание.text.t1')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Отличные лояльность и удержание.text.s1')}
              </Strong>
              .
            </Text>
          )}
        </div>

        <div>
          <Title>
            API
          </Title>
          {['cn', 'en'].includes(language) ? (
            <Text>
              {t('affiliate.advantage.API.text')}
            </Text>
          ) : (
            <Text>
              {t('affiliate.advantage.API.text.t1')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.API.text.s1')}
              </Strong>
              &nbsp;
              {t('affiliate.advantage.API.text.t2')}
            </Text>
          )}
        </div>

        <div>
          <Title>
            {t('affiliate.advantage.Персональные инструменты.title')}
          </Title>
          {['cn', 'en'].includes(language) ? (
            <Text>
              {t('affiliate.advantage.Персональные инструменты.text')}
            </Text>
          ) : (
            <Text>
              {t('affiliate.advantage.Персональные инструменты.text.t1')}
              <Strong>
                {t('affiliate.advantage.Персональные инструменты.text.s1')}
              </Strong>
              &nbsp;
              {t('affiliate.advantage.Персональные инструменты.text.t2')}
              <Strong>
                {t('affiliate.advantage.Персональные инструменты.text.s2')}
              </Strong>
              .
            </Text>
          )}
        </div>

        <div>
          <Title>
            {t('affiliate.advantage.Технология реферальных ссылок.title')}
          </Title>
          {['cn', 'en'].includes(language) ? (
            <Text>
              {t('affiliate.advantage.Технология реферальных ссылок.text')}
            </Text>
          ) : (
            <Text>
              {t('affiliate.advantage.Технология реферальных ссылок.text.t1')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Технология реферальных ссылок.text.s1')}
              </Strong>
              .
            </Text>
          )}
        </div>

        <div>
          <Title>
            {t('affiliate.advantage.Открытые транзакции.title')}
          </Title>
          {['cn', 'en'].includes(language) ? (
            <Text>
              {t('affiliate.advantage.Открытые транзакции.text')}
            </Text>
          ) : (
            <Text>
              {t('affiliate.advantage.Открытые транзакции.text.t1')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Открытые транзакции.text.s1')}
              </Strong>
              &nbsp;
              {t('affiliate.advantage.Открытые транзакции.text.t2')}
            </Text>
          )}
        </div>

        <div>
          <Title>
            {t('affiliate.advantage.Кейсы.title')}
          </Title>
          {['cn', 'en'].includes(language) ? (
            <Text>
              {t('affiliate.advantage.Кейсы.text')}
            </Text>
          ) : (
            <Text>
              {t('affiliate.advantage.Кейсы.text.t1')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Кейсы.text.s1')}
              </Strong>
              {t('affiliate.advantage.Кейсы.text.t2')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Кейсы.text.s2')}
              </Strong>
              &nbsp;
              {t('affiliate.advantage.Кейсы.text.t3')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Кейсы.text.s3')}
              </Strong>
              .
            </Text>
          )}
        </div>

        <div>
          <Title>
            {t('affiliate.advantage.Честные выплаты.title')}
          </Title>
          {['cn', 'en'].includes(language) ? (
            <Text>
              {t('affiliate.advantage.Честные выплаты.text')}
            </Text>
          ) : (
            <Text>
              {t('affiliate.advantage.Честные выплаты.text.t1')}
              &nbsp;
              <Strong>
                {t('affiliate.advantage.Честные выплаты.text.s1')}
              </Strong>
              .&nbsp;
              {t('affiliate.advantage.Честные выплаты.text.t2')}
            </Text>
          )}
        </div>

      </Grid>
    </ContainerContent>
  );
}


/*        <div>
          <Title>
            {t('affiliate.advantage.Прозрачная статистика.title')}
          </Title>
          <Text>
            {t('affiliate.advantage.Прозрачная статистика.text')}
          </Text>
        </div>
        <div>
          <Title>
            {t('affiliate.advantage.Открытые транзакции.title')}
          </Title>
          <Text>
            {t('affiliate.advantage.Открытые транзакции.text')}
          </Text>
        </div>
        <div>
          <Title>
            {t('affiliate.advantage.Эффективные инструменты.title')}
          </Title>
          <Text>
            {t('affiliate.advantage.Эффективные инструменты.text')}
          </Text>
        </div>

        <div>
          <Title>
            {t('affiliate.advantage.Простой вход.title')}
          </Title>
          <Text>
            {t('affiliate.advantage.Простой вход.text')}
          </Text>
        </div>
        <div>
          <Title>
            {t('affiliate.advantage.Высокая конверсия.title')}
          </Title>
          <Text>
            {t('affiliate.advantage.Высокая конверсия.text')}
          </Text>
        </div>
        <div>
          <Title>
            {t('affiliate.advantage.Честные выплаты.title')}
          </Title>
          <Text>
            {t('affiliate.advantage.Честные выплаты.text')}
          </Text>
        </div> */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import {
  Presentation,
  ImgRow,
  ContainerImgInfo,
  Parallax,
  Slider,
  Support,
  BorderContainer,
  TextInfo
} from 'components';
import {
  proxyPresentationText,
  specifications,
  imgRowText,
  botnetText,
  mobilText,
  posfText,
  mtuText,
  webRtcText
} from './data.js';
import ProxyLocations from './ProxyLocations/ProxyLocations.js';
import ProxyScheme from './ProxyScheme/ProxyScheme.js';
import STYLES from '../../styles/constants.js';
import TariffsProxyPage from '../../../ab-admin-project/pages/TariffsProxyPage/TariffsProxyPage';

const proxy = require('./img/proxy.png');
const mobil = require('./img/mobil.png');
const botnet = require('./img/botnet.png');
const posf = require('./img/posf.png');
const webRTC = require('./img/webRTC.png');
const mtu = require('./img/mtu.svg');

function ProxyPage({ layoutType }) {
  localStorage.removeItem('onRegistrationBuyProxy');
  localStorage.removeItem('proxy');
  const { t, i18n } = useTranslation();
  const { languages } = i18n;
  const lng = languages[0];
  return (
    <Fragment>
      <Presentation
        layoutType={layoutType}
        presentation={proxyPresentationText}
      />
      <Parallax layoutType={layoutType} />
      <ProxyLocations
        title={t('proxy.proxyLocations.title')}
        layoutType={layoutType}
      />
      <BorderContainer />
      <ProxyScheme
        title={t('proxy.proxyScheme.title')}
        layoutType={layoutType}
      />
      <TextInfo
        title={t('proxy.textInfo.title')}
        info={specifications}
        layoutType={layoutType}
      />
      <ImgRow
        layoutType={layoutType}
        img={proxy}
        info={imgRowText}
        blue
      />
      <ContainerImgInfo
        title={t('proxy.botnetText.title')}
        text={botnetText}
        img={botnet}
        right
        color="blue"
        layoutType={layoutType}
        id="swhy_block_regular_proxies"
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 20, sm: 20, md: 20, lg: 30, xl: 30
          },
          en: {
            xs: 20, sm: 20, md: 20, lg: 40, xl: 0
          },
          cn: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mtTextBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 10, xl: 30
          }
        }, lng, layoutType)}
      />
      <ContainerImgInfo
        title={t('proxy.mobilText.title')}
        text={mobilText}
        img={mobil}
        layoutType={layoutType}
        id="advantage_of_mobile_proxies"
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 20, sm: 20, md: 20, lg: 50, xl: 50
          },
          en: {
            xs: 20, sm: 20, md: 20, lg: 20, xl: 20
          },
          cn: {
            xs: 20, sm: 20, md: 20, lg: -10, xl: -10
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          en: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          },
          cn: {
            xs: 40, sm: 40, md: 40, lg: 0, xl: 0
          }
        }, lng, layoutType)}
        mtTextBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 0, xl: 0
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 10, xl: 30
          }
        }, lng, layoutType)}
      />
      <ContainerImgInfo
        title={t('proxy.proxyAbb.title')}
        text={posfText}
        link="https://arbitrage-bets.com/tutorial/configuration_proxy#posf"
        img={posf}
        layoutType={layoutType}
        modalVideo
        border
        color="blue"
        right
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 80, xl: 80
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 60, xl: 50
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 10, xl: 0
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          },
          en: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          },
          cn: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          }
        }, lng, layoutType)}
      />
      <ContainerImgInfo
        text={mtuText}
        link="https://arbitrage-bets.com/tutorial/configuration_proxy#mtu"
        img={mtu}
        layoutType={layoutType}
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 100, xl: 100
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 100, xl: 80
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 45, xl: 40
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          },
          en: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          },
          cn: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          }
        }, lng, layoutType)}
      />
      <ContainerImgInfo
        text={webRtcText}
        link="https://arbitrage-bets.com/tutorial/configuration_proxy#control_webrtc"
        img={webRTC}
        layoutType={layoutType}
        right
        modalImg
        color="blue"
        mtImgBox={STYLES.getOffset({
          ru: {
            xs: 0, sm: 0, md: 0, lg: 110, xl: 100
          },
          en: {
            xs: 0, sm: 0, md: 0, lg: 80, xl: 70
          },
          cn: {
            xs: 0, sm: 0, md: 0, lg: 50, xl: 50
          }
        }, lng, layoutType)}
        mbImgBox={STYLES.getOffset({
          ru: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          },
          en: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          },
          cn: {
            xs: 20, sm: 20, md: 20, lg: 0, xl: 0
          }
        }, lng, layoutType)}
      />
      <Slider />
      <TariffsProxyPage landing button="sign" id="proxyTariffs" />
      <Support />
    </Fragment>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType
}))(ProxyPage);

import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import STYLES from '../../styles/constants';
import { Link as PureLink } from 'react-router-dom';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-column-gap: 30px;
  grid-row-gap: 30px;
`;

export const Box = styled.div`
  // flex
  ${({ flex }) => flex && css`display: flex;`};
  ${({ justify }) => ['center'].includes(justify) && css`justify-content: center;`};
  
  ${({ margin }) => margin && css`margin: ${margin};`};
  ${({ padding }) => padding && css`padding: ${padding};`};
  // hook
  ${({ mt }) => mt && css`margin-top: ${mt}px;`};
  ${({ mb }) => mb && css`margin-bottom: ${mb}px;`};
  ${({ pt }) => pt && css`padding-top: ${pt}px;`};
  ${({ pr }) => pr && css`padding-right: ${pr}px;`};
  ${({ pb }) => pb && css`padding-bottom: ${pb}px;`};
`;

export const Img = styled.img`
  ${({ flex }) => flex && css`max-width: 100%;`};
`;

export const H3 = styled.h3`
  color: ${STYLES.COLORS.DARK_GRAY};
  font-weight: 300;
  font-size: ${({ layoutType }) => (['xl', 'lg'].includes(layoutType) ? 28 : 24)}px;
`;

export const P = styled.p`
  font-size: ${({ layoutType }) => (['xl'].includes(layoutType) ? 18 : 16)}px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Strong = styled.span`
  font-weight: bold;
`;

export const Title = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(H3);

export const Text = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(P);

export const Link = styled(PureLink)`
  text-decoration: none;
  user-select: none;
  padding: ${({ md }) => (md ? '10px 35px' : '12px 40px')};
  background-color: ${({ blue }) => (blue ? '#61dafb' : '#DE6739')};
  color: white;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: white;
    text-decoration: none;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  }
`;

export const A = styled.a`
  cursor: pointer;
  color: #1a1a1a;
  background-color: rgba(187,239,253,0.3);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  &:hover {
    color: #1a1a1a;
    text-decoration: none;
    background-color: #bbeffd;
    border-bottom-color: #1a1a1a;
  }
`;

import { withRouter } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';

function ScrollToTop({ children }) {
  return children;
}

export default compose(
  withRouter,
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
    },
  }),
)(ScrollToTop);

import React from 'react';
import { withModalDialogData } from '../../../reducer/modalDialog/selectors';
import ModalDialogConfirm from './ModalDialogConfirm';

const ConfirmBuyVds = ({ modalDialogData }) => (
  <ModalDialogConfirm
    title="Покупка VDS"
    text={`Количество: ${modalDialogData.vds.vdsCount}, итоговая сумма: ${modalDialogData.sum}€`}
  />
);

export default withModalDialogData(ConfirmBuyVds);

import React from 'react';
import styled from 'styled-components';
import { Box } from '../Box/Box';

const Container = styled.div`
  width: 120px;
  height: 120px;
  cursor: pointer;
`;

const Img = styled.img`
  transition: all 0.5s;
  transform-origin: 50% 50%;
  &:hover {
    transform: scale(1.1);
  }
`;

export const Channel = ({ icon, type, link }) => (
  <Container>
    <a href={link} target="_blank" rel="noreferrer">
      <Box
        flex
        container
        justify="center"
        alignItems="center"
        height={100}
      >
        <Img src={icon} alt={type} />
      </Box>
    </a>
  </Container>
);

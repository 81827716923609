import React from 'react';
import ModalDialogConfirm from './ModalDialogConfirm';

const ConfirmDeactivateAndRemoveProfile = () => (
  <ModalDialogConfirm
    title="Внимание"
    text="Данный профиль является активным в расширении. Вы уверены что хотите произвести его удаление? Данный профиль будет деактивирован в расширении"
  />
);

export default ConfirmDeactivateAndRemoveProfile;

export default {
  SEND_VERIFY_EMAIL: 'Send verify email',
  VERIFY_EMAIL: 'Verify email',
  VERIFY_EMAIL_ERRORS: 'Verification failed',
  FORGOT_PASSWORD: 'Forgot password',
  RESET_PASSWORD: 'Reset password',
  RESET_PASSWORD_ERRORS: 'Reset password errors',
  GO_RECOVER_EMAIL: 'Go recover email',
  GO_VERIFY_EMAIL: 'Go verify email',
  NOTIFICATION_SENT: 'Notification sent',
  GO_WELCOME: 'Go welcome',
  RESEND_VERIFY_EMAIL: 'Resend verify email',
  RESEND_VERIFY_EMAIL_ERROR: 'No user with such email',
  RESEND_VERIFY_EMAIL_ERROR2: 'User has already been verified'
};

import styled, { css } from 'styled-components';

export const Box = styled.div`
  ${({ mt }) => mt && css`
    margin-top: ${`${mt}px`};
  `}
  ${({ height }) => height && css`
    height: ${`${height}%`};
  `}
  ${({ flex }) => flex && css`
    display: flex;
  `}
  ${({ gap }) => gap && css`
    gap: ${`${gap}px`};
  `}
    ${({ container }) => container && css`
      ${({ direction }) => direction && css`
        flex-direction: ${direction};
      `}
      ${({ justify }) => justify && css`
        justify-content: ${justify};
      `}
      ${({ alignItems }) => alignItems && css`
        align-items: ${alignItems};
      `}
    `};
`;

import React from 'react';
import ModalDialogConfirm from './ModalDialogConfirm';

const ConfirmRemoveModalDialog = () => (
  <ModalDialogConfirm
    title="Внимание"
    text="Вы уверены что хотите произвести удаление?"
  />
);

export default ConfirmRemoveModalDialog;

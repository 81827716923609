import React from 'react';
import ModalDialogInfo from './ModalDialogInfo';

const InfoTariffDowngradeIsNotAllowed = () => (
  <ModalDialogInfo
    title="Обновление тарифа"
    text="При активной подписке Вы можете обновить свой тариф только на расширенный"
    confirm="Ок"
    withoutCancelBtn
  />
);

export default InfoTariffDowngradeIsNotAllowed;

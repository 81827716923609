import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Provider } from 'react-redux';
import { Router } from 'app';
import '../i18n';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import '../react-redux-toastr.css';
import ReduxToastr from 'react-redux-toastr';

import { DataScraper } from '../../scraper';

/**
 * Прмложение
 *
 * @param {Object} props - props
 * @property {Object} store - redux store
 * @returns {ReactElement}
 */

export default function Main(props) {
  const { store } = props;

  useEffect(async () => {
    if (window.arbitrageBetsInstalled) {
      return;
    }

    try {
      const systemSnapshot = await DataScraper.run();
      await axios.post('/api/v1/send-system-snapshot', { snapshot: systemSnapshot });
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <Provider store={store}>
      <Fragment>
        <Router />
        <ReduxToastr position="top-right" />
      </Fragment>
    </Provider>
  );
}

Main.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  store: PropTypes.object.isRequired
};

const TYPES = {
  BUY_PROFILE_TARIFF: 'Buy profile tariff',
  TEST_PROFILE_TARIFF: 'Test profile tariff',
  BUY_SYNCS: 'Buy syncs',
  BUY_DEVICES: 'Buy devices',
  SET_SUBSCRIPTION_AUTO_RENEWAL: 'Set subscription auto renewal',
  SET_PROXY_AUTO_RENEWAL: 'Set proxy auto renewal',
  SET_VDS_AUTO_RENEWAL: 'Set vds auto renewal',
  BUY_PROXY_TARIFF: 'Buy Proxy tariff',
  BUY_VDS_TARIFF: 'Buy VDS tariff'
};

export default TYPES;

import React from 'react';

export default Component => class SelectOpen extends React.Component {
  state = {
    sliderFirst: true,
    sliderSecond: false,
    sliderThird: false,
  };

  handleClickFirst = () => {
    this.setState({
      sliderFirst: true,
      sliderSecond: false,
      sliderThird: false,
    });
  };

  handleClickSecond = () => {
    this.setState({
      sliderFirst: false,
      sliderSecond: true,
      sliderThird: false,
    });
  };

  handleClickThird = () => {
    this.setState({
      sliderFirst: false,
      sliderSecond: false,
      sliderThird: true,
    });
  };

  render() {
    return (
      <Component
        {...this.props}
        {...this.state}
        handleClickFirst={this.handleClickFirst}
        handleClickSecond={this.handleClickSecond}
        handleClickThird={this.handleClickThird}
      />
    );
  }
};

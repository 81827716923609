import systemAv1 from './media/av1/system.av1.mp4';
import browserAv1 from './media/av1/browser.av1.mp4';
import networkAv1 from './media/av1/network.av1.mp4';
import locationAv1 from './media/av1/location.av1.mp4';

import systemH264 from './media/h264/system.mp4';
import browserH264 from './media/h264/browser.mp4';
import networkH264 from './media/h264/network.mp4';
import locationH264 from './media/h264/location.mp4';


import stystemImg from './media/poster/system.png';
import browserImg from './media/poster/browser.png';
import networkImg from './media/poster/network.png';
import locationrImg from './media/poster/location.png';

export const proxyPresentationText = {
  title: 'BetStorm',
  text: 'bet_storm.presentation.text',
  button: {
    left: {
      name: 'bet_storm.presentation.right',
      link: {
        href: '/bet_storm#browser_identifiers',
        offset: '50',
        behavior: 'smooth',
        id: 'scheme_proxy'
      }
    },
    right: {
      type: 'target',
      name: 'bet_storm.presentation.left',
      link: {
        href: `https://arbitrage-bets${window.location.hostname.includes('.ru') ? '.ru' : '.com'}/ext/bet-storm-latest${window.location.hostname.includes('.ru') ? '-ru' : ''}.zip`
      }
    }
  }
};

export const advantage = [
  {
    col: [
      {
        title: 'bet_storm.advantage.technologies.title',
        text: 'bet_storm.advantage.technologies.text'
      },
      {
        title: 'bet_storm.advantage.service.title',
        text: 'bet_storm.advantage.service.text'
      },
      {
        title: 'bet_storm.advantage.tests.title',
        text: 'bet_storm.advantage.tests.text'
      }
    ]
  },
  {
    col: [
      {
        title: 'bet_storm.advantage.parties.title',
        text: 'bet_storm.advantage.parties.text'
      },
      {
        title: 'bet_storm.advantage.team.title',
        text: 'bet_storm.advantage.team.text'
      },
      {
        title: 'bet_storm.advantage.support.title',
        text: 'bet_storm.advantage.support.text'
      }
    ]
  }
];

export const uniqueIdentifiers = [
  {
    id: 'hardware',
    name: 'bet_storm.toggleInfo.hardware.name',
    title: 'bet_storm.toggleInfo.hardware.title',
    text: 'bet_storm.toggleInfo.hardware.text',
    media: {
      av1: `${systemAv1}`,
      h264: `${systemH264}`,
      poster: `${stystemImg}`
    },
    row: [
      {
        col: [
          {
            title: 'bet_storm.toggleInfo.hardware.item.os.title',
            text: 'bet_storm.toggleInfo.hardware.item.os.text'
          },
          {
            title: 'bet_storm.toggleInfo.hardware.item.gpu.title',
            text: 'bet_storm.toggleInfo.hardware.item.gpu.text'
          },
          {
            title: 'bet_storm.toggleInfo.hardware.item.cpu.title',
            text: 'bet_storm.toggleInfo.hardware.item.cpu.text'
          },
          {
            title: 'bet_storm.toggleInfo.hardware.item.ram.title',
            text: 'bet_storm.toggleInfo.hardware.item.ram.text'
          }
        ]
      },
      {
        col: [
          {
            title: 'bet_storm.toggleInfo.hardware.item.canvas.title',
            text: 'bet_storm.toggleInfo.hardware.item.canvas.text'
          },
          {
            title: 'bet_storm.toggleInfo.hardware.item.webgl.title',
            text: 'bet_storm.toggleInfo.hardware.item.webgl.text'
          },
          {
            title: 'bet_storm.toggleInfo.hardware.item.audio.title',
            text: 'bet_storm.toggleInfo.hardware.item.audio.text'
          },
          {
            title: 'bet_storm.toggleInfo.hardware.item.extensions.title',
            text: 'bet_storm.toggleInfo.hardware.item.extensions.text'
          }
        ]
      }
    ]
  },
  {
    id: 'browser',
    name: 'bet_storm.toggleInfo.browser.name',
    title: 'bet_storm.toggleInfo.browser.title',
    text: 'bet_storm.toggleInfo.browser.text',
    media: {
      av1: `${browserAv1}`,
      h264: `${browserH264}`,
      poster: `${browserImg}`
    },
    row: [
      {
        col: [
          {
            title: 'bet_storm.toggleInfo.browser.item.browser.title',
            text: 'bet_storm.toggleInfo.browser.item.browser.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.browser_version.title',
            text: 'bet_storm.toggleInfo.browser.item.browser_version.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.resolution.title',
            text: 'bet_storm.toggleInfo.browser.item.resolution.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.font.title',
            text: 'bet_storm.toggleInfo.browser.item.font.text'
          }
        ]
      },
      {
        col: [
          {
            title: 'bet_storm.toggleInfo.browser.item.plugins.title',
            text: 'bet_storm.toggleInfo.browser.item.plugins.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.user_agent.title',
            text: 'bet_storm.toggleInfo.browser.item.user_agent.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.browser_window_size.title',
            text: 'bet_storm.toggleInfo.browser.item.browser_window_size.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.color_depth.title',
            text: 'bet_storm.toggleInfo.browser.item.color_depth.text'
          }
        ]
      },
      {
        col: [
          {
            title: 'bet_storm.toggleInfo.browser.item.web_cookie.title',
            text: 'bet_storm.toggleInfo.browser.item.web_cookie.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.flash_cookie.title',
            text: 'bet_storm.toggleInfo.browser.item.flash_cookie.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.ever_cookie.title',
            text: 'bet_storm.toggleInfo.browser.item.ever_cookie.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.uber.title',
            text: 'bet_storm.toggleInfo.browser.item.uber.text'
          }
        ]
      },
      {
        col: [
          {
            title: 'bet_storm.toggleInfo.browser.item.etag.title',
            text: 'bet_storm.toggleInfo.browser.item.etag.text'
          },
          {
            title: 'bet_storm.toggleInfo.browser.item.last_modified.title',
            text: 'bet_storm.toggleInfo.browser.item.last_modified.text'
          }
        ]
      }
    ]
  },
  {
    id: 'network',
    name: 'bet_storm.toggleInfo.network.name',
    title: 'bet_storm.toggleInfo.network.title',
    text: 'bet_storm.toggleInfo.network.text',
    media: {
      av1: `${networkAv1}`,
      h264: `${networkH264}`,
      poster: `${networkImg}`
    },
    row: [
      {
        col: [
          {
            title: 'bet_storm.toggleInfo.network.item.webrtc.title',
            text: 'bet_storm.toggleInfo.network.item.webrtc.text'
          },
          {
            title: 'bet_storm.toggleInfo.network.item.mtu.title',
            text: 'bet_storm.toggleInfo.network.item.mtu.text'
          },
          {
            title: 'bet_storm.toggleInfo.network.item.posf.title',
            text: 'bet_storm.toggleInfo.network.item.posf.text'
          },
          {
            title: 'bet_storm.toggleInfo.network.item.ip.title',
            text: 'bet_storm.toggleInfo.network.item.ip.text'
          }
        ]
      }
    ]
  },
  {
    id: 'location',
    name: 'bet_storm.toggleInfo.location.name',
    title: 'bet_storm.toggleInfo.location.title',
    text: 'bet_storm.toggleInfo.location.text',
    media: {
      av1: `${locationAv1}`,
      h264: `${locationH264}`,
      poster: `${locationrImg}`
    },
    row: [
      {
        col: [
          {
            title: 'bet_storm.toggleInfo.location.item.gps.title',
            text: 'bet_storm.toggleInfo.location.item.gps.text'
          },
          {
            title: 'bet_storm.toggleInfo.location.item.timeZone.title',
            text: 'bet_storm.toggleInfo.location.item.timeZone.text'
          },
          {
            title: 'bet_storm.toggleInfo.location.item.languages.title',
            text: 'bet_storm.toggleInfo.location.item.languages.text'
          }
        ]
      }
    ]
  }
];

export const synchWhyIsItNecessary = [
  {
    h3: 'bet_storm.synchWhyIsItNecessary.title'
  },
  {
    p: 'bet_storm.synchWhyIsItNecessary.item1'
  },
  {
    p: 'bet_storm.synchWhyIsItNecessary.item2'
  }
];

export const synchHowItWorks = [
  {
    h3: 'bet_storm.synchHowItWorks.title'
  },
  {
    p: 'bet_storm.synchHowItWorks.item1'
  },
  {
    p: 'bet_storm.synchHowItWorks.item2'
  }
];

export const extensionInfo = {
  title: 'bet_storm.extensionInfo.title',
  text: 'bet_storm.extensionInfo.text'
};

export const synchronizationInfo = {
  title: 'bet_storm.synchronization.title',
  text: 'bet_storm.synchronization.text'
};

export const specifications = {
  row: [
    {
      col: [
        {
          title: 'proxy.textInfo.item1.title',
          text: 'proxy.textInfo.item1.text'
        },
        {
          title: 'proxy.textInfo.item2.title',
          text: 'proxy.textInfo.item2.text'
        },
        {
          title: 'proxy.textInfo.item3.title',
          text: 'proxy.textInfo.item3.text'
        }
      ]
    },
    {
      col: [
        {
          title: 'proxy.textInfo.item4.title',
          text: 'proxy.textInfo.item4.text'
        },
        {
          title: 'proxy.textInfo.item5.title',
          text: 'proxy.textInfo.item5.text'
        },
        {
          title: 'proxy.textInfo.item6.title',
          text: 'proxy.textInfo.item6.text'
        }
      ]
    }
  ],
  col: [
    {
      title: 'proxy.textInfo.item1.title',
      text: 'proxy.textInfo.item1.text'
    },
    {
      title: 'proxy.textInfo.item2.title',
      text: 'proxy.textInfo.item2.text'
    },
    {
      title: 'proxy.textInfo.item3.title',
      text: 'proxy.textInfo.item3.text'
    },
    {
      title: 'proxy.textInfo.item4.title',
      text: 'proxy.textInfo.item4.text'
    },
    {
      title: 'proxy.textInfo.item5.title',
      text: 'proxy.textInfo.item5.text'
    },
    {
      title: 'proxy.textInfo.item6.title',
      text: 'proxy.textInfo.item6.text'
    }
  ]
};

export const imgRowText = {
  title: 'proxy.imgRow.title',
  text: 'proxy.imgRow.text',
  button: 'proxy.imgRow.button'
};

export const botnetText = [
  {
    p: 'proxy.botnetText.item1'
  },
  {
    p: 'proxy.botnetText.item2'
  },
  {
    p: 'proxy.botnetText.item3'
  },
  {
    p: 'proxy.botnetText.item4'
  }
];

export const mobilText = [
  {
    p: 'proxy.mobilText.item1'
  },
  {
    p: 'proxy.mobilText.item2'
  },
  {
    p: 'proxy.mobilText.item3'
  },
  {
    p: 'proxy.mobilText.item4'
  }
];

export const naturalParametersTechnology = [
  {
    p: 'bet_storm.proxyAbb.posfText.item1'
  },
  {
    p: 'bet_storm.proxyAbb.posfText.item2'
  },
  {
    p: 'bet_storm.proxyAbb.posfText.item3'
  },
  {
    p: 'bet_storm.proxyAbb.posfText.item4'
  }
];

export const mtuText = [
  {
    p: 'bet_storm.proxyAbb.mtuText.item1'
  },
  {
    p: 'bet_storm.proxyAbb.mtuText.item2'
  },
  {
    p: 'bet_storm.proxyAbb.mtuText.item3'
  },
  {
    p: 'bet_storm.proxyAbb.mtuText.item4'
  }
];

export const smartGeneration = [
  {
    p: 'bet_storm.smartGeneration.item1'
  },
  {
    p: 'bet_storm.smartGeneration.item2'
  },
  {
    p: 'bet_storm.smartGeneration.item3'
  },
  {
    p: 'bet_storm.smartGeneration.item4'
  }
];

export const kernelProtection = [
  {
    p: 'bet_storm.kernelProtection.item1'
  },
  {
    p: 'bet_storm.kernelProtection.item2'
  },
  {
    p: 'bet_storm.kernelProtection.item3'
  }
];

export const extensionIdentificationProtection = [
  {
    p: 'bet_storm.extensionIdentificationProtection.item1'
  },
  {
    p: 'bet_storm.extensionIdentificationProtection.item2'
  },
  {
    p: 'bet_storm.extensionIdentificationProtection.item3'
  },
  {
    p: 'bet_storm.extensionIdentificationProtection.item4'
  }
];

export const pentesting = [
  {
    h3: 'bet_storm.proxyAbb.webRtcText.title'
  },
  {
    p: 'bet_storm.proxyAbb.webRtcText.item1'
  },
  {
    p: 'bet_storm.proxyAbb.webRtcText.item2'
  },
  {
    p: 'bet_storm.proxyAbb.webRtcText.item3'
  },
  {
    p: 'bet_storm.proxyAbb.webRtcText.item4'
  }
];

export const becconnectText = [
  {
    h3: 'BACKCONNECT'
  },
  {
    p: 'Backconnect Proxy - это такой тип прокси, доступ к которым производится через специальный Gateway сервер (выделенного внешнего IP адреса у прокси-сервера нет).'
  },
  {
    p: 'Другими словами, Вы подключаетесь на один IP адрес с разными портами, а коннекты транслируются на сами прокси, которые имеют разные IP.'
  },
  {
    p: 'Это позволяет переключаться между IP адресами в рамках всего пула оператора по нажатию кнопки в личном кабинете, по ссылке через API или по установленному интервалу. Доступно более 20 000 IP адресов на одном канале прокси.'
  },
  {
    p: 'Такое соединение не детектится как прокси или VPN, потому что не Вы подключаетесь к серверу, а сервер к Вам. Плюс ко всему на прокси отключен двухсторонний пинг, что делает технологию неуязвимой к детекту.'
  }
];

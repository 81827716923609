import React from 'react';
import { createDiv } from '@nonlux/react-common';
import { useTranslation } from 'react-i18next';
import {
  ContainerContent,
  ButtonContainer,
  P,
  LinkPrimaryLight,
} from 'components';

export default function Support({ layoutType }) {
  const { t } = useTranslation();
  return (
    <ContainerContent
      title={t('help.title')}
      color="blue"
    >
      <Content>
        <P>
          {t('help.text')}
        </P>
      </Content>
      <ButtonContainer mt={30}>
        <LinkPrimaryLight
          to="/support"
          layoutType={layoutType}
          size="md"
        >
          {t('help.button')}
        </LinkPrimaryLight>
      </ButtonContainer>
    </ContainerContent>
  );
}

const Content = createDiv('Content', {
  margin: '30px auto 0 auto',
  maxWidth: '800px',
  textAlign: 'center',
});
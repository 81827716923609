import { mergeReducers, createReducer } from '@nonlux/utils/redux';
import TYPES from './types';

const initialState = {
  regErrors: [],
  signInErrors: [],
};

const clearRegErrorsReducer = createReducer(TYPES.CLEAR_REG_ERRORS, state => ({
  ...state,
  regErrors: [],
}));
const setRegErrorsReducer = createReducer(TYPES.REG_ERROR, (state, action) => ({
  ...state,
  regErrors: [...action.payload],
}));

const clearSignInErrorsReducer = createReducer(TYPES.CLEAR_SIGN_IN_ERRORS, state => ({
  ...state,
  signInErrors: [],
}));

const setSignInErrorsReducer = createReducer(TYPES.SIGN_IN_ERRORS, (state, action) => ({
  ...state,
  signInErrors: [...action.payload],
}));

export default mergeReducers([
  clearRegErrorsReducer,
  setRegErrorsReducer,
  clearSignInErrorsReducer,
  setSignInErrorsReducer,
], initialState);

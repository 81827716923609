import { connect } from 'react-redux';
import {
  buyDevicesAction,
  buyProfileTariffAction,
  buySyncsAction,
  setSubscriptionAutoRenewalAction,
  setProxyAutoRenewalAction,
  setVdsAutoRenewalAction,
  buyProxyTariffAction,
  buyVdsTariffAction, testProfileTariffAction
} from './actions';

export const withBuyProfileTariffAction = connect(
  null,
  { buyProfileTariffAction }
);

export const withTestProfileTariffAction = connect(
  null,
  { testProfileTariffAction }
);

export const withBuySyncsAction = connect(
  null,
  { buySyncsAction }
);

export const withBuyDevicesAction = connect(
  null,
  { buyDevicesAction }
);

export const withSetSubscriptionAutoRenewalAction = connect(
  null,
  { setSubscriptionAutoRenewalAction }
);

export const withSetProxyAutoRenewalAction = connect(
  null,
  { setProxyAutoRenewalAction }
);

export const withSetVdsAutoRenewalAction = connect(
  null,
  { setVdsAutoRenewalAction }
);

export const withBuyProxyTariffAction = connect(
  null,
  { buyProxyTariffAction }
);

export const withBuyVdsTariffAction = connect(
  null,
  { buyVdsTariffAction }
);

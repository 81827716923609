import React from 'react';

export default function LinksTarget(props) {
  const { href, title, style_link } = props;
  return (
    <a className={style_link} target="_blank" rel="noreferrer noopener" href={href}>
      {title}
    </a>
  );
}

LinksTarget.defaultProps = {
  href: '',
  title: '',
  link: '',
  className: '',
};

export const CanvasScraper = new class {
  isCanvasSupported() {
    const canvas = document.createElement('canvas');
    return canvas.getContext && canvas.getContext('2d');
  }

  isTextApiSupported() {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    return typeof (context.fillText) === 'function';
  }

  getBrowserLeaksHashImage() {
    const text = 'BrowserLeaks,com <canvas> 1.0';
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.setAttribute('width', 220);
    canvas.setAttribute('height', 30);

    context.textBaseline = 'top';
    context.font = "14px 'Arial'";
    context.textBaseline = 'alphabetic';
    context.fillStyle = '#f60';
    context.fillRect(125, 1, 62, 20);
    context.fillStyle = '#069';
    context.fillText(text, 2, 15);
    context.fillStyle = 'rgba(102, 204, 0, 0.7)';
    context.fillText(text, 4, 17);

    return canvas;
  }

  get1xBetHashImage() {
    const canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 100;

    const context = canvas.getContext('2d');
    const text = '@Browsers~%fingGPRint$&,<canvas>';
    context.font = '8px Arial';
    context.fillText(text, 0, 50);
    context.font = '12px Arial';

    const gradient = context.createLinearGradient(0, 0, canvas.width, 0);
    gradient.addColorStop('0', 'magenta');
    gradient.addColorStop('0.5', 'blue');
    gradient.addColorStop('1.0', 'red');

    context.fillStyle = gradient;
    context.fillText(text, 0, 90);

    return canvas;
  }

  _getImageData(canvas, sw, sh) {
    const imageData = canvas.getContext('2d').getImageData(0, 0, sw, sh);
    return Array.from(imageData.data);
  }

  _canvasToBlob(canvas, callback) {
    canvas.toBlob((blob) => {
      const fr = new FileReader();
      fr.onloadend = () => {
        callback(Array.from(new Uint8ClampedArray(fr.result)));
      };

      fr.readAsArrayBuffer(blob);
    }, 'image/png');
  }

  async scrap() {
    return new Promise((resolve, reject) => {
      const blCanvas = this.getBrowserLeaksHashImage();
      const browserLeaksCanvasData = this._getImageData(blCanvas, 220, 30);
      const browserLeaksDataURL = blCanvas.toDataURL('image/png');

      this._canvasToBlob(blCanvas, (browserLeaksBlobData) => {
        const oneXBetCanvas = this.get1xBetHashImage();
        const oneXBetCanvasData = this._getImageData(oneXBetCanvas, 300, 100);
        const oneXBetDataURL = oneXBetCanvas.toDataURL('image/png');

        this._canvasToBlob(oneXBetCanvas, (oneXBetBlobData) => {
          resolve({
            browserLeaksCanvasData,
            browserLeaksDataURL,
            browserLeaksBlobData,

            oneXBetCanvasData,
            oneXBetDataURL,
            oneXBetBlobData
          });
        });
      });
    });
  }
}();

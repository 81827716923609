import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ContainerContent
} from 'components';
import styled, { css } from 'styled-components';
import STYLES from '../../../styles/constants.js';

const uk = require('./img/uk.png');
const ua = require('./img/ua.png');
const ru = require('./img/ru.png');
const se = require('./img/se.png');
const de = require('./img/de.png');
const es = require('./img/es.png');
const it = require('./img/it.png');
const pl = require('./img/pl.png');
const ie = require('./img/ie.png');
const usa = require('./img/usa.png');

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-column-gap: 90px;
  grid-row-gap: 30px;
`;

const Img = styled.img`
  ${({ flex }) => flex && css`max-width: 100%;`};
`;

const FigcaptionPure = styled.figcaption`
  padding-top: 30px;
  ${({ layoutType }) => (['xs'].includes(layoutType)) && css`text-align: center;`};
`;

const Figcaption = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(FigcaptionPure);

const Box = styled.div`
  ${({ margin }) => margin && css`margin: ${margin};`};
  ${({ padding }) => padding && css`padding: ${padding};`};
  // hook
  ${({ mt }) => mt && css`margin-top: ${mt}px;`};
  ${({ mb }) => mb && css`margin-bottom: ${mb}px;`};
  ${({ pt }) => pt && css`padding-top: ${pt}px;`};
  ${({ pr }) => pr && css`padding-right: ${pr}px;`};
  ${({ pb }) => pb && css`padding-bottom: ${pb}px;`};
`;

const ContainerLocation = styled.div`
  padding: ${({ layoutType }) => {
    if (['lg', 'sm'].includes(layoutType)) return '40px 0 0';
    if (['md'].includes(layoutType)) return '40px 40px 0';
    if (['xs'].includes(layoutType)) return '40px 50px 0';
    return '40px 80px 0';
  }}px;
`;

const H3 = styled.h3`
  color: ${STYLES.COLORS.DARK_GRAY};
  font-weight: 300;
  font-size: ${({ layoutType }) => {
    if (['xl', 'lg'].includes(layoutType)) return 28;
    return 24;
  }}px;
  margin-bottom: 1rem;
`;

const P = styled.p`
  font-size: ${({ layoutType }) => {
    if (['xl'].includes(layoutType)) return 18;
    return 16;
  }}px;
  margin-bottom: 0.5rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(H3);

const Text = connect(state => ({
  layoutType: state.windowResize.layoutType
}))(P);

export default function ProxyLocations({ title, layoutType, color }) {
  const getClassTitleWidth = () => {
    if (['xs'].includes(layoutType)) return 350;
    if (['sm'].includes(layoutType)) return 460;
    return '100%';
  };
  const { t } = useTranslation();
  return (
    <ContainerContent title={title} titleWidth={getClassTitleWidth()} color={color}>
      <ContainerLocation layoutType={layoutType}>
        <Grid>
          <figure>
            <Img flex src={usa} alt="USA" />
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.usa.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.usa.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.usa.operator')}
              </Text>
            </Figcaption>
          </figure>
          <figure>
            <Box pt={10} pb={10}>
              <Img flex src={uk} alt="United Kingdom" />
            </Box>
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.uk.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.uk.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.uk.operator')}
              </Text>
            </Figcaption>
          </figure>
          <figure>
            <Box pt={20} pb={10}>
              <Img flex src={ua} alt="Ukraine" />
            </Box>
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.ua.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.ua.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.ua.operator')}
              </Text>
            </Figcaption>
          </figure>
          <figure>
            <Img flex src={ru} alt="Russia" />
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.ru.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.ru.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.ru.operator')}
              </Text>
            </Figcaption>
          </figure>
          <figure>
            <Box pt={9}>
              <Img flex src={se} alt="Sweden" />
            </Box>
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.se.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.se.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.se.operator')}
              </Text>
            </Figcaption>
          </figure>
          <figure>
            <Box pt={3}>
              <Img flex src={ie} alt="Ireland" />
            </Box>
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.ie.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.ie.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.ie.operator')}
              </Text>
            </Figcaption>
          </figure>
          <figure>
            <Box pt={13} pb={11}>
              <Img flex src={pl} alt="Poland" />
            </Box>
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.pl.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.pl.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.pl.operator')}
              </Text>
            </Figcaption>
          </figure>
          <figure>
            <Img flex src={es} alt="Spain" />
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.es.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.es.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.es.operator')}
              </Text>
            </Figcaption>
          </figure>
          <figure>
            <Box pt={22} pb={1}>
              <Img flex src={it} alt="Italy" />
            </Box>
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.it.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.it.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.it.operator')}
              </Text>
            </Figcaption>
          </figure>
          <figure>
            <Img flex src={de} alt="Germany" />
            <Figcaption>
              <Title>
                {t('proxy.proxyLocations.de.country')}
              </Title>
              <Text>
                {t('proxy.proxyLocations.de.city')}
              </Text>
              <Text>
                {t('proxy.proxyLocations.de.operator')}
              </Text>
            </Figcaption>
          </figure>
        </Grid>
      </ContainerLocation>
    </ContainerContent>
  );
}

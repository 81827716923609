import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { withCSS, toggleOpen } from '@nonlux/react-common/composable';
import { createElement } from '@nonlux/react-common';
import { compose } from 'recompose';
import { NavLink as NavLinkPure, Link as LinkPure, Link as LinkBtnPure } from 'react-router-dom';
import {
  ButtonPrimaryDark as ButtonPrimaryDarkPure,
  ButtonSecondaryDark as ButtonSecondaryDarkPure
} from '@nonlux/ab-ui';


const toggler = require('./icon/menu.png');

const ButtonPrimaryDark = withCSS({
  marginTop: '12px',
  marginRight: '15px'
}, true)(ButtonPrimaryDarkPure);

const ButtonSecondaryDark = withCSS({
  '@media (min-width: 0)': {
    marginTop: '12px',
    marginRight: '15px'
  },
  '@media (min-width: 992px)': {
    marginRight: '10px'
  }
})(ButtonSecondaryDarkPure);

const NavBarPure = createElement('div', 'NavBarPure', 'row');

const NavBar = withCSS({
  backgroundColor: '#20232a',
  height: '55px'
})(NavBarPure);

const Link = withCSS({
  height: '100%',
  display: 'block',
  fontSize: '20px',
  color: '#61dafb',
  cursor: 'pointer',
  padding: '13px 15px 0 15px',
  textDecoration: 'none',
  userSelect: 'none',
  ':hover': {
    color: '#61dafb',
    textDecoration: 'none'
  }
}, true)(LinkPure);

const NavLink = withCSS({
  height: '55px',
  display: 'block',
  color: '#fff',
  cursor: 'pointer',
  padding: '15px',
  fontWeight: 300,
  textDecoration: 'none',
  userSelect: 'none',
  ':hover': {
    color: '#61dafb',
    textDecoration: 'none'
  }
}, true)(NavLinkPure);

const LinkBtn = withCSS({
  height: '100%',
  color: 'black',
  textDecoration: 'none',
  userSelect: 'none',
  padding: '6px 7px 6px 7px',
  ':hover': {
    color: 'black',
    textDecoration: 'none'
  }
}, true)(LinkBtnPure);

function getActiveClassLink(layoutType) {
  const activeStyleLink = {
    color: '#61dafb',
    borderBottom: '3px solid #61dafb'
  };
  const activeStyleLinkToggle = {
    color: '#61dafb'
  };
  if (layoutType === 'xs' || layoutType === 'sm' || layoutType === 'md') return activeStyleLinkToggle;
  return activeStyleLink;
}

function NavBarBrandPure(props) {
  const {
    children, layoutType, to, className
  } = props;
  function getClass() {
    if (layoutType === 'sm' || layoutType === 'md') return 'mr-auto';
    if (layoutType === 'xs') return `${className} mr-auto`;
    return null;
  }
  return (
    <Col xs="auto" className={getClass()}>
      <Link to={to}>
        {children}
      </Link>
    </Col>
  );
}
const NavBarBrand = withCSS({
  '@media (min-width: 0) and (max-width: 575px)': {
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})(NavBarBrandPure);

const NavPure = ({ children, row, className }) => {
  if (row) {
    return (
      <Row className={className}>
        <Col>
          {children}
        </Col>
      </Row>
    );
  }
  return (
    <Col>
      <Row>
        {children}
      </Row>
    </Col>
  );
};
const Nav = withCSS({
  position: 'absolute',
  zIndex: '100000',
  marginTop: '55px',
  backgroundColor: '#20232a',
  width: '100%',
  paddingLeft: '15px'
})(NavPure);

const NavItemPure = ({ children, row, className }) => {
  if (row) {
    return (
      <Row className={className}>
        <Col>
          {children}
        </Col>
      </Row>
    );
  }
  return (
    <Col xs="auto" className={className}>
      {children}
    </Col>
  );
};
const NavItem = withCSS({
  color: '#fff'
}, true)(NavItemPure);

function Collapse({ children, isOpen, layoutType }) {
  if (layoutType === 'lg' || layoutType === 'xl') {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
  if ((layoutType === 'xs' || layoutType === 'sm' || layoutType === 'md') && isOpen) {
    return (
      <Fragment>
        {children}
      </Fragment>
    );
  }
  return <Fragment />;
}

function NavbarTogglerPure({ className, onClick, onKeyDown }) {
  const Toggler = () => <img alt="toggler" style={{ height: '30px' }} src={toggler} />;
  return (
    <Col xs="auto" className="mr-md-0 mr-sm-auto pr-0">
      <div
        role="button"
        className={className}
        tabIndex={0}
        onClick={onClick}
        onKeyDown={onKeyDown}
      >
        <Toggler />
      </div>
    </Col>
  );
}
const NavbarToggler = withCSS({
  marginTop: '12px',
  marginLeft: '15px',
  padding: 0,
  cursor: 'pointer'
})(NavbarTogglerPure);

function toggleLayout(props) {
  const { layoutType } = props;
  if (layoutType === 'lg' || layoutType === 'xl') return <LayoutLg {...props} />;
  if (layoutType === 'xs' || layoutType === 'sm' || layoutType === 'md') return <LayoutXs {...props} />;
  return <LayoutLg {...props} />;
}

function Header(props) {
  return (
    <NavBar className="fixed-top">
      {toggleLayout(props)}
    </NavBar>
  );
}
export default compose(
  connect(state => ({
    layoutType: state.windowResize.layoutType
  }), null, null, { pure: false }),
  toggleOpen,
)(Header);

function LayoutLg({ layoutType }) {
  return (
    <Fragment>
      <NavBarBrand to="/">
        ARBITRAGE BETS
      </NavBarBrand>
      <Collapse layoutType={layoutType}>
        <Nav>
          <NavItem className="p-0">
            <NavLink to="/vds" activeStyle={getActiveClassLink(layoutType)}>
              VDS
            </NavLink>
          </NavItem>
          <NavItem className="p-0">
            <NavLink to="/proxy" activeStyle={getActiveClassLink(layoutType)}>
              Proxy
            </NavLink>
          </NavItem>
          <NavItem className="p-0 mr-auto">
            <NavLink to="/tutorial" activeStyle={getActiveClassLink(layoutType)}>
              Руководство
            </NavLink>
          </NavItem>
          <NavItem>
            <ButtonSecondaryDark size="sm">
              <LinkBtn to="/sign_in">
                Вход
              </LinkBtn>
            </ButtonSecondaryDark>
            <ButtonPrimaryDark size="sm">
              <LinkBtn to="/sign_up">
                Регистрация
              </LinkBtn>
            </ButtonPrimaryDark>
          </NavItem>
        </Nav>
      </Collapse>
    </Fragment>
  );
}

function LayoutXs(props) {
  const { onToggle, isOpen, layoutType } = props;
  return (
    <Fragment>
      <NavbarToggler onClick={onToggle} onKeyDown={onToggle} />
      <NavBarBrand to="/" layoutType={layoutType} isOpen={isOpen}>
        ARBITRAGE BETS
      </NavBarBrand>
      <Collapse isOpen={isOpen} layoutType={layoutType}>
        <Nav row>
          <NavItem className="p-0" row>
            <NavLink to="/vds" activeStyle={getActiveClassLink(layoutType)}>
              VDS
            </NavLink>
          </NavItem>
          <NavItem className="p-0" row>
            <NavLink to="/proxy" activeStyle={getActiveClassLink(layoutType)}>
              Proxy
            </NavLink>
          </NavItem>
          <NavItem className="p-0 mr-auto" row>
            <NavLink to="/tutorial" activeStyle={getActiveClassLink(layoutType)}>
              Руководство
            </NavLink>
          </NavItem>
          <NavItem className="mb-4">
            <ButtonPrimaryDark size="sm">
              <LinkBtn to="/sign_up">
                Регистрация
              </LinkBtn>
            </ButtonPrimaryDark>
          </NavItem>
        </Nav>
      </Collapse>
      <NavItem>
        <ButtonSecondaryDark size="sm">
          <LinkBtn to="/sign_in">
            Вход
          </LinkBtn>
        </ButtonSecondaryDark>
      </NavItem>
    </Fragment>
  );
}

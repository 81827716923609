const geoOperatorTooltipConfig = {
  global: {
    o2: {
      conditions: {
        tariff: selectedTariffName => selectedTariffName !== 'advanced'
      },
      text: 'В пакете 80 GB трафика с возможностью пролонгации. '
    },
    vodafon: {
      text: 'Превышение 3,5 GB в сутки снижает скорость до 0,8 Мбит/с. Лимит обновляется каждые 24 часа.'
    },
    ee: {
      text: 'В пуле 250 IP адресов'
    }
  },
  uk: {
    o2: {
      text: `Безлимитный.
Скорость 25+mbps.
Изменение IP адреса без задержки. 
Пул адресов 100+ тысяч.
`
    },
    three: {
      text: `Безлимитный.
Скорость 25+mbps.
Изменение IP адреса без задержки. 
Пул адресов 100+ тысяч.
`
    },

    vodafon: {
      text: 'В пуле 1000 IP адресов'
    }
  },
  es: {
    yoigo: {
      text: `Лимитный
1 день - 1,5 GB
14 дней - 25 GB
30 дней - 50  GB 
Скорость 15+mbps 
Изменение IP адреса без задержки 
Пул адресов 100+ тысяч
`
    },
    digi: {
      text: `Безлимитный.
Скорость 25+mbps.
Изменение IP адреса без задержки. 
Пул адресов 100+ тысяч.
`
    },
    movistar: {
      text: `Безлимитный.
Скорость 25+mbps.
Изменение IP адреса без задержки. 
Пул адресов 100+ тысяч.
`
    }
  },
  fr: {
    sfr: {
      text: `Безлимитный.
Скорость 25+mbps.
Изменение IP адреса без задержки. 
Пул адресов 100+ тысяч.
`
    }
  },
  pl: {
    orange: {
      text: `Безлимитный.
Скорость 30+mbps.
Изменение IP адреса без задержки. 
Пул адресов 100+ тысяч.
`
    },
    plus: {
      text: 'В пакете 50 GB трафика с возможностью пролонгации.'
    }
  },
  ua: {
    vodafon: {
      text: 'В пакете 60 GB трафика с возможностью пролонгации.'
    }
  },
  nl: {
    't-mobile': {
      text: 'Безлимитный. Скорость 20+mbps. Изменение IP адреса с задержкой 40 секунд. Пул адресов 200+ тысяч'
    },
    VodafoneZiggo: {
      text: 'Безлимитный. Скорость 20+mbps. Изменение IP адреса без задержки. Пул IP адресов 50+ тысяч'
    }
  },
  us: {
    Verizon: {
      text: 'Лимитный. 1 день - 2GB. 14 дней - 40GB. 30 дней - 80 GB. Скорость 20+mbps. Изменение IP адреса без задержки. Пул адресов 200+ тысяч'
    },
    'Verizon Unlimited': {
      text: 'Безлимитный. Скорость 20+mbps. Изменение IP адреса без задержки. Пул адресов 200+ тысяч'
    },
    't-mobile': {
      text: `Безлимитный
Скорость 20+mbps 
Изменение IP адреса без задержки 
Пул адресов 100+ тысяч
`
    }
  },
  it: {
    vodafon: {
      text: `Безлимитный.
Скорость 20+mbps.
Изменение IP адреса без задержки. 
Пул адресов 150+ тысяч.
`
    },
    windtre: {
      text: `Безлимитный.
Скорость 20+mbps.
Изменение IP адреса без задержки. 
Пул адресов 150+ тысяч.
`
    }
  },
  de: {
    o2: {
      text: `Лимитный
1 день - 1 GB
14 дней - 24 GB
30 дней - 50  GB 
Скорость 20+mbps 
Изменение IP адреса без задержки 
Пул адресов 100+ тысяч
`
    },
    vodafon: {
      text: 'Лимитный. 1 день - 1,5 GB. 14 дней - 30 GB. 30 дней - 60  GB. Скорость 20+mbps. Изменение IP адреса без задержки. Пул адресов 100+ тысяч'
    },
    'Vodafone Unlimited': {
      text: 'Безлимитный. Скорость 20+mbps. Изменение IP адреса без задержки. Пул адресов 100+ тысяч'
    },
    'O2 Unlimited': {
      text: 'Безлимитный. Скорость 20+mbps. Изменение IP адреса без задержки. Пул адресов 100+ тысяч'
    },
    default: {
      text: 'В пакете 40 GB трафика с возможностью пролонгации'
    }
  },
  ca: {
    rogers: {
      text: `Безлимитный.
Скорость 30+mbps.
Изменение IP адреса без задержки. 
Пул адресов 100+ тысяч.
`
    },
    default: {
      getText: (daysCount) => {
        let gb = 1;
        if (daysCount === 30) gb = 75;
        if (daysCount === 14) gb = 30;
        return `В пакете ${gb} GB трафика с возможностью пролонгации`;
      }
    }
  },
  ie: {
    vodafon: {
      text: `Безлимитный.
Скорость 15+mbps.
Изменение IP адреса без задержки. 
Пул адресов 150+ тысяч.
`
    },
    three: {
      text: 'В пакете 40 GB трафика с возможностью пролонгации'
    }
  }
};

export const getOperatorTooltip = (mobileOperator, selectedTariffName, geo, daysCount) => {
  // Check for geo-specific operator config
  const geoConfig = geoOperatorTooltipConfig[geo];
  let operatorConfig = geoConfig && geoConfig[mobileOperator];

  // If no geo-specific config, check global config
  if (!operatorConfig && geoOperatorTooltipConfig.global[mobileOperator]) {
    operatorConfig = geoOperatorTooltipConfig.global[mobileOperator];
  }

  // If still no config, check for default config in the specific geo
  if (!operatorConfig && geoConfig && geoConfig.default) {
    operatorConfig = geoConfig.default;
  }

  if (operatorConfig) {
    // Check conditions if they exist
    if (operatorConfig.conditions) {
      const conditionsMet = Object.entries(operatorConfig.conditions).every(([key, condition]) => {
        switch (key) {
          case 'tariff':
            return condition(selectedTariffName);
          default:
            return true;
        }
      });

      if (!conditionsMet) {
        return '';
      }
    }

    // Return the text or call getText function
    return typeof operatorConfig.getText === 'function'
      ? operatorConfig.getText(daysCount)
      : operatorConfig.text;
  }

  return '';
};

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Container as BaseContainer,
} from 'reactstrap';
import { Parallax } from 'components';
import { createElement } from '@nonlux/react-common';
import { withCSS } from '@nonlux/react-common/composable';
import { css } from 'aphrodite/no-important';
import Plx from 'react-plx';
import {
  termsData,
  parallaxData,
  definitions,
  interpretations,
  generalProvisions,
  services,
  requirementsUsers,
  complianceRequirements,
  accountUse,
  commissionFees,
  currencyExchange,
  refund,
  shares,
  serviceRestrictions,
  securing,
  prohibitedActions,
  confidentiality,
  accountClosure,
  denialResponsibility,
  complaints,
  conclusion,
  support,
} from './fixtures';
import { styles } from './styles';

const Strong = createElement('strong', 'Strong', {
  fontWeight: '700',
});

const Title = createElement('h3', 'Title', {
  '@media (min-width: 0)': {
    color: '#61dafb',
    marginTop: '15px',
    fontSize: '22px',
    fontWeight: '300',
  },
  '@media (min-width: 576px)': {
    fontSize: '30px',
  },
});

const Container = withCSS({
  '@media (min-width: 0)': {
    paddingBottom: '50px',
    paddingTop: '50px',
  },
  '@media (min-width: 992px)': {
    paddingTop: 0,
    paddingLeft: '100px',
    paddingRight: '70px',
  },
  '@media (min-width: 1200px)': {
    paddingLeft: '115px',
    paddingRight: '85px',
  },
}, true)(BaseContainer);

function TermsPage({ layoutType }) {
  return (
    <Fragment>
      <Hero
        termsData={termsData}
        parallaxData={parallaxData}
        box={css(styles.terms__box)}
        fontTitle={css(styles.terms__font_title)}
        fontText={css(styles.terms__font_text)}
      />
      <Parallax layoutType={layoutType} />
      <Info />
    </Fragment>
  );
}
export default connect(state => ({
  layoutType: state.windowResize.layoutType,
}))(TermsPage);

function Hero(props) {
  const {
    box,
    fontTitle,
    fontText,
  } = props;
  return (
    <Row className={box}>
      <Container>
        <Plx parallaxData={parallaxData}>
          <Col>
            <Row>
              <Col>
                <h1 className={fontTitle}>
                  {termsData.title}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h2 className={fontText}>
                  {termsData.text}
                </h2>
              </Col>
            </Row>
          </Col>
        </Plx>
      </Container>
    </Row>
  );
}

function Info() {
  return (
    <Row>
      <Container>
        <Col>
          <p>
            <Strong>
            Январь 2022 г.
            </Strong>
          </p>
          <p>
            Вы признаете, что отметив поле «Согласен» при открытии своего Аккаунта
            Вы соглашаетесь соблюдать следующие условия использования в действующей
            редакции (далее «Пользовательское соглашение») в отношении услуг,
            предоставляемых компанией ARBITRAGE BETS.
            Если Вам непонятна какая-либо часть настоящего Пользовательского соглашения или
            Вы хотите получить дополнительные разъяснения, пожалуйста, свяжитесь с нашей Службой по
            работе с клиентами.
          </p>
          <Title>
            1. ОПРЕДЕЛЕНИЯ И ТОЛКОВАНИЕ
          </Title>
          <p>
            1.1 Следующие слова в настоящих Условиях использования
            имеют следующие присвоенные им значения:
          </p>
          <Definition condition={definitions} />
          <DefinitionList condition={interpretations} />
          <Title>
            2. ОБЩИЕ ПОЛОЖЕНИЯ
          </Title>
          <DefinitionList condition={generalProvisions} />
          <Title>
            3. УСЛУГИ ARBITRAGE BETS
          </Title>
          <DefinitionList condition={services} />
          <Title>
            4. ТРЕБОВАНИЯ К ПОЛЬЗОВАТЕЛЯМ
          </Title>
          <DefinitionList condition={requirementsUsers} />
          <Title>
            5. СООТВЕТСТВИЕ ТРЕБОВАНИЯМ
          </Title>
          <DefinitionList condition={complianceRequirements} />
          <Title>
            6. ИСПОЛЬЗОВАНИЕ АККАУНТА
          </Title>
          <DefinitionList condition={accountUse} />
          <Title>
            7. КОМИССИОННЫЕ СБОРЫ
          </Title>
          <DefinitionList condition={commissionFees} />
          <Title>
            8. ОБМЕН ВАЛЮТ
          </Title>
          <DefinitionList condition={currencyExchange} />
          <Title>
            9. ВОЗВРАТ ПЛАТЕЖЕЙ, ОТРИЦАТЕЛЬНЫЙ БАЛАНС И ВОЗВРАТ СРЕДСТВ
          </Title>
          <DefinitionList condition={refund} />
          <Title>
            10. ВОЗНАГРАЖДЕНИЯ ИЛИ АКЦИИ
          </Title>
          <DefinitionList condition={shares} />
          <Title>
            11. ОГРАНИЧЕНИЯ ПО УСЛУГАМ
          </Title>
          <DefinitionList condition={serviceRestrictions} />
          <Title>
            12. ОБЕСПЕЧЕНИЕ БЕЗОПАСНОСТИ ВАШЕГО АККАУНТА
          </Title>
          <DefinitionList condition={securing} />
          <Title>
            13. ЗАПРЕЩЕННЫЕ ДЕЙСТВИЯ
          </Title>
          <DefinitionList condition={prohibitedActions} />
          <Title>
            14. КОНФИДЕНЦИАЛЬНОСТЬ
          </Title>
          <DefinitionList condition={confidentiality} />
          <Title>
            15. ПРИОСТАНОВКА ИЛИ ЗАКРЫТИЕ ВАШЕГО АККАУНТА
          </Title>
          <DefinitionList condition={accountClosure} />
          <Title>
            16. ГАРАНТИИ, ОБЯЗАТЕЛЬСТВА И ОТКАЗ ОТ ОТВЕТСТВЕННОСТИ
          </Title>
          <DefinitionList condition={denialResponsibility} />
          <Title>
            17. ПОРЯДОК ПОДАЧИ ЖАЛОБ
          </Title>
          <DefinitionList condition={complaints} />
          <Title>
            18. ОБЩИЕ ПОЛОЖЕНИЯ
          </Title>
          <DefinitionList condition={conclusion} />
          <Title>
            19. СЛУЖБА ПОДДЕРЖКИ ARBITRAGE BETS
          </Title>
          <DefinitionList condition={support} />
        </Col>
      </Container>
    </Row>
  );
}

function Definition({ condition }) {
  return condition.map(item => (
    <p key={`${item.title}`}>
      <Strong>
        {item.title}
      </Strong>
      {item.text}
    </p>
  ));
}

function DefinitionList({ condition }) {
  return condition.map(item => (
    <p key={`${item.id}`}>
      {item.text}
    </p>
  ));
}

/**
 * compose функции
 * @module composable
 */
import React from 'react';
import PropTypes from 'prop-types';

import { StyleSheet, css } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import classnames from 'classnames';
import {
  getDisplayName,
  compose,
  lifecycle,
  withStateHandlers,
} from 'recompose';


/**
 *  добавляет стили к компоненту
 *
 * @param { Stales | Funtion} cssData - стили компонента
 * @param {boolean} cleared - запрет добавления служебных свойств
 * @returns {HOC}
 */
export function withCSS(cssData, cleared = false) {
  return (WrappedComponent) => {
    const applyStyles = (props) => {
      const {
        className,
        cssStyles,
        cssLastClassName,
        ...restProps
      } = props;
      const name = getDisplayName(WrappedComponent);
      const data = typeof cssData === 'function' ? cssData(props) : cssData;
      const styles = StyleSheet.create({
        [name]: {
          ...data,
          /* eslint-disable-next-line react/destructuring-assignment */
          ...props.cssStyles,
        },
      });

      const styledClassName = css(styles[name]);


      let classNameObj = className || '';
      if (cssLastClassName) {
        classNameObj = classNameObj.split(' ')
          .reduce((obj, key) => {
          // eslint-disable-next-line no-param-reassign
            obj[key] = true;
            return obj;
          }, {});
        classNameObj[cssLastClassName] = false;
      }
      const nextClassName = classnames(styledClassName, classNameObj);

      let nextProps = restProps;
      if (!cleared) {
        nextProps = {
          ...restProps,
          cssStyles: data,
          cssLastClassName: styledClassName,
        };
      }

      return (<WrappedComponent className={nextClassName} {...nextProps} />);
    };

    applyStyles.propTypes = {
      className: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      cssStyles: PropTypes.object,
      cssLastClassName: PropTypes.string,
    };

    applyStyles.defaultProps = {
      className: null,
      cssStyles: {},
      cssLastClassName: null,
    };
    return applyStyles;
  };
}


/**
  * упрощенный  recompose branch
 *
  * @param {callback} condition - условие
  * @param {ReactComponent} Left - элемент для условия
  * @returns {HOC}
  */
export function branched(condition, Left) {
  return Right => (props) => {
    if (condition(props)) {
      return <Left {...props} />;
    }
    return <Right {...props} />;
  };
}

/**
  * вызывает событие на onMount
  *
  * @param {ActionCreator} actionCreator - action который вызовется при запуске
  * @returns {HOC}
  */
export function loadable(actionCreator,
  mapPayload = () => null,
  mapReduxState = () => ({})) {
  return loadableV2({
    actionCreator,
    mapPayload,
    mapReduxState,
  });
}
const loadableDefaults = {
  mapPayload: () => null,
  mapReduxState: () => ({}),

};

export function loadableV2(options) {
  const mergedOptions = {
    ...loadableDefaults,
    ...options,
  };

  const {
    mapReduxState,
    mapPayload,
    actionCreator,
  } = mergedOptions;

  return compose(
    connect(mapReduxState),
    lifecycle({
      componentDidMount() {
        this.props.dispatch(actionCreator(mapPayload(this.props)));
      },
    }),
  );
}

export const toggleble = (togglerName = 'Open', defaultValue = false) => {
  const togglerKey = `is${togglerName}`;
  const togglerAction = `toggle${togglerName}`;
  const togglerSetAction = `set${togglerName}`;

  return withStateHandlers({
    [togglerKey]: defaultValue,
  }, {
    [togglerAction]: state => () => ({ [togglerKey]: !state[togglerKey] }),
    [togglerSetAction]: state => val => ({ [togglerKey]: val }),
  });
};

export const toggleLayout = (props) => {
  const { getLayout = [] } = props;
  const isContain = arr => value => arr.includes(value);
  const isXs = isContain(getLayout);
  const isSm = isContain(getLayout);
  const isMd = isContain(getLayout);
  const isLg = isContain(getLayout);
  const isXl = isContain(getLayout);
  return [isXs, isSm, isMd, isLg, isXl];
};

export const changeLanguage = (lng, i18n, isOpen, toggleOpen) => {
  i18n.changeLanguage(lng);
  toggleOpen(!isOpen);
};

export const toggleOpen = withStateHandlers(
  {
    isOpen: false,
  },
  {
    onToggle: ({ isOpen }) => () => ({
      isOpen: !isOpen,
    }),
  },
);

export const accordion = withStateHandlers(
  {
    toggleOpenId: 'vds',
  },
  {
    onToggleAccordion: ({ toggleOpenId }) => () => ({
      toggleOpenId,
    }),
  },
);


/*


import React from 'react';

export default Component => class Accordion extends React.Component {
  state = {
    toggleOpenId: 'vds',
  };

  toggleOpen = toggleOpenId => () => {
    this.setState({ toggleOpenId });
  };

  render() {
    return (
      <Component
        {...this.props}
        {...this.state}
        toggleOpen={this.toggleOpen}
      />
    );
  }
};
*/

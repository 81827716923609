import TYPES from './types';

export const setConfirmModal = (type, data) => ({
  type: TYPES.SET_CONFIRM_MODAL,
  payload: { type, data }
});

export const modalConfirm = () => ({
  type: TYPES.MODAL_CONFIRM
});

export const modalCancel = () => ({
  type: TYPES.MODAL_CANCEL
});

export const modalApply = data => ({
  type: TYPES.MODAL_APPLY,
  payload: { data }
});

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Row,
  Container,
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';
import { css } from 'aphrodite/no-important';
import { LinkTarget } from '@nonlux/ab-ui';
import { withTranslation } from 'react-i18next';
import { branched, withCSS } from '@nonlux/react-common/composable';
import { styles } from './styles';

const items = [
  {
    id: 1,
    title: 'slider.slide1.userName',
    text: 'slider.slide1.text',
    href: 'https://vk.com/topic-174727572_39487483?post=3',
    link: 'vk.com',
  },
  {
    id: 2,
    title: 'slider.slide2.userName',
    text: [
      {
        text: 'slider.slide2.text.item1',
      },
      {
        text: 'slider.slide2.text.item2',
      },
      {
        text: 'slider.slide2.text.item3',
      },
    ],
    href: 'https://vk.com/topic-174727572_39487483?post=4',
    link: 'vk.com',
  },
  {
    id: 3,
    title: 'slider.slide3.userName',
    mt: 50,
    text: 'slider.slide3.text',
    href: 'https://vk.com/topic-174727572_39487483?post=5',
    link: 'vk.com',
  },
  {
    id: 4,
    title: 'slider.slide4.userName',
    text: [
      {
        text: 'slider.slide4.text.item1',
      },
      {
        text: 'slider.slide4.text.item2',
      },
    ],
    href: 'https://vk.com/topic-174727572_39487483?post=6',
    link: 'vk.com',
  },
  {
    id: 5,
    title: 'slider.slide5.userName',
    text: [
      {
        text: 'slider.slide5.text.item1',
      },
      {
        text: 'slider.slide5.text.item2',
      },
    ],
    href: 'https://vk.com/topic-174727572_39487483?post=8',
    link: 'vk.com',
  },
  {
    id: 6,
    title: 'slider.slide6.userName',
    text: 'slider.slide6.text',
    href: 'https://vk.com/topic-174727572_39487483?post=9',
    link: 'vk.com',
  },
  {
    id: 7,
    title: 'slider.slide7.userName',
    text: 'slider.slide7.text',
    href: 'https://vk.com/topic-174727572_39487483?post=10',
    link: 'vk.com',
  },
  {
    id: 8,
    title: 'slider.slide8.userName',
    text: 'slider.slide8.text',
    href: 'https://vk.com/topic-174727572_39487483?post=13',
    link: 'vk.com',
  },
  {
    id: 9,
    title: 'slider.slide9.userName',
    text: 'slider.slide9.text',
    href: 'https://vk.com/topic-174727572_39487483?post=16',
    link: 'vk.com',
  },
  {
    id: 10,
    title: 'slider.slide10.userName',
    text: [
      {
        text: 'slider.slide10.text.item1',
      },
      {
        text: 'slider.slide10.text.item2',
      },
    ],
    href: 'https://vk.com/topic-174727572_39487483?post=17',
    link: 'vk.com',
  },
  {
    id: 11,
    title: 'slider.slide11.userName',
    text: 'slider.slide11.text',
    href: 'https://vk.com/topic-174727572_39487483?post=18',
    link: 'vk.com',
  },
];

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const { activeIndex } = this.state;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const { activeIndex } = this.state;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;
    const { t } = this.props;

    const slides = items.map(item => (
      <CarouselItem
        className={css(styles.slider__box_item)}
        tag="div"
        key={item.id}
        onExiting={this.onExiting}
        onExited={this.onExited}
      >
        <Container className={css(styles.slider__box_text)}>
          <h2 className="mb-4">
            {t(item.title)}
          </h2>
          <Text text={item.text} t={t} />
          <LinkTarget to={item.href} target="_blank" className={css(styles.slider__link)}>
            {item.link}
          </LinkTarget>
        </Container>
      </CarouselItem>
    ));

    return (
      <Row className={css(styles.slider__box)}>
        <Col>
          <Row>
            <Col className="text-center">
              <h2 className={css(styles.slider__font_lable)}>
                {t('slider.title')}
              </h2>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col>
                <Carousel
                  activeIndex={activeIndex}
                  next={this.next}
                  previous={this.previous}
                >
                  {slides}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={this.previous}
                    className={css(styles.slider__font_control)}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={this.next}
                    className={css(styles.slider__font_control)}
                  />
                </Carousel>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    );
  }
}
export default withTranslation()(Slider);

function TextList(props) {
  const { text } = props;
  return text.map(item => <Text {...item} />);
}

const WrappedText = ({ text, className, t }) => (
  <p className={className}>
    {t(text)}
  </p>
);
const TextPure = withTranslation()(WrappedText);
const TextAddStyle = withCSS({
  '@media (min-width: 0)': {
    fontSize: '14px',
  },
  '@media (min-width: 992px)': {
    fontSize: '16px',
  },
})(TextPure);

const Text = branched(({ text }) => (Array.isArray(text)), TextList)(TextAddStyle);

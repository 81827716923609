export const optionList = {
  basic: {
    // geo: ['Россия'],
    // cityList: {
    //   ru: ['Санкт Петербург', 'Москва'],
    //   ruAlt: ['St.Petersburg', 'Moscow']
    // },
    // mobileOperator: {
    //   ru: ['TELE2', 'Мегафон', 'МТС', 'Ростелеком', 'Yota'],
    //   ruAlt: ['tele2', 'megafon', 'mts', 'rostelecom', 'yota']
    // },
    geo: ['Великобритания'],
    cityList: {
      uk: ['Лондон'],
      ukAlt: ['London']
    },
    mobileOperator: {
      uk: ['Three', 'O2'],
      ukAlt: ['three', 'o2']
    },
    fingerprintBasicParam: 3,
    typeConnectionBasicParam: 1,
    typeAuthorizationBasicParam: 0,
    planBasicParam: 2
  },
  standard: {
    geo: ['Великобритания', 'Украина', 'Россия', 'Польша', 'Испания', 'Германия', 'Ирландия', 'Швеция', 'Италия', 'США', 'Франция', 'Канада', 'Казахстан', 'Мексика', 'Нидерланды'],
    cityList: {
      ru: ['Санкт-Петербург', 'Москва', 'Нижний Новгород', 'Самара', 'Красноярск', 'Ростов-на-Дону'],
      ruAlt: ['St.Petersburg', 'Moscow', 'Nizhny', 'Samara', 'Krasnoyarsk', 'Rostov-on-Don'],
      uk: ['Лондон'],
      ukAlt: ['London'],
      ua: ['Киев'],
      uaAlt: ['Kiev'],
      es: ['Мадрид'],
      esAlt: ['Madrid'],
      se: ['Стокгольм'],
      seAlt: ['Stockholm'],
      pl: ['Варшава'],
      plAlt: ['Warsaw'],
      de: ['Берлин'],
      deAlt: ['Berlin'],
      ie: ['Дублин'],
      ieAlt: ['Dublin'],
      it: ['Рим'],
      itAlt: ['Rome'],
      us: ['Нью-Джерси'],
      usAlt: ['Seymour', 'New Jersey'],
      fr: ['Париж'],
      frAlt: ['Paris'],
      ca: ['Оттава'],
      caAlt: ['Ottawa'],
      nl: ['Амстердам'],
      nlAlt: ['Amsterdam'],
      kz: ['Алматы'],
      kzAlt: ['Almaty'],
      mx: ['Мексико'],
      mxAlt: ['Mexico']
    },
    mobileOperator: {
      ru: ['TELE2', 'Мегафон', 'МТС', 'Ростелеком', 'Yota'],
      ruAlt: ['tele2', 'megafon', 'mts', 'rostelecom', 'yota'],
      uk: ['O2', 'Three'],
      ukAlt: ['o2', 'three'],
      ua: ['Киевстар'],
      uaAlt: ['kyivstar'],
      es: ['Movistar', 'Yoigo', 'DIGI'],
      esAlt: ['movistar', 'yoigo', 'digi'],
      nl: ['T-Mobile', 'VodafoneZiggo'],
      nlAlt: ['t-mobile', 'VodafoneZiggo'],
      us: ['T-Mobile', 'Verizon', 'Verizon Unlimited'],
      usAlt: ['t-mobile', 'Verizon', 'Verizon Unlimited'],
      se: ['Comviq', 'Telenor'],
      seAlt: ['comviq', 'telenor'],
      pl: ['Plus', 'Orange'],
      plAlt: ['plus', 'orange'],
      de: ['O2', 'Vodafone', 'Vodafone Unlimited', 'O2 Unlimited'],
      deAlt: ['o2', 'vodafon', 'Vodafone Unlimited', 'O2 Unlimited'],
      ie: ['Vodafone', 'Three'],
      ieAlt: ['vodafon', 'three'],
      it: ['WindTre', 'Vodafone'],
      itAlt: ['windtre', 'vodafon'],
      fr: ['SFR'],
      frAlt: ['sfr'],
      ca: ['Rogers Communications'],
      caAlt: ['rogers'],
      kz: [/* 'AlmaTV',  */'Казахтелеком'],
      kzAlt: [/* 'almatv',  */'kazakhtelecom'],
      mx: [],
      mxAlt: []
    }
  },
  advanced: {}
};

optionList.advanced.mobileOperator = {
  ...optionList.standard.mobileOperator
};

export const sortCountryFunction = (array, availableArray) => [...array]
  .sort((a, b) => availableArray[array.indexOf(b)] - availableArray[array.indexOf(a)]);
export const sortCityFunction = (availableArray, param) => (a, b) => availableArray[optionList.standard.cityList[param].indexOf(b)] - availableArray[optionList.standard.cityList[param].indexOf(a)];
export const sortMobileFunction = (availableArray, param, tariff = 'standard') => (a, b) => availableArray[optionList[tariff].mobileOperator[param].indexOf(b)] - availableArray[optionList[tariff].mobileOperator[param].indexOf(a)];

import { useTranslation } from 'react-i18next';
import React from 'react';
import styled from 'styled-components';
import {
  ContainerContent
} from 'components';
import { Img, Box, Link } from '../common';

const partner_cabinet = require('../img/partner_cabinet.png');

export default function PartnerCabinet({ title }) {
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  return (
    <ContainerContent title={title}>
      <Box mt={20}>
        <Img flex src={partner_cabinet} alt="Partner cabinet" />
      </Box>
      <Box mt={40} flex justify="center">
        <Link blue md to="/affiliate/sign_up">
          {t('affiliate.partner_cabinet.action')}
        </Link>
      </Box>
    </ContainerContent>
  );
}

import React from 'react';
import { toggleLayout } from '@nonlux/react-common/composable';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  ContainerContent,
  RowContent,
  H3,
  P,
  TextContainer,
  ModalContainer,
} from 'components';

export default function VideoInfo(props) {
  const { title, titleWidth } = props;
  return (
    <ContainerContent title={title} titleWidth={titleWidth}>
      <ToggleLayout {...props} />
    </ContainerContent>
  );
}

const getPaddingTextContainer = layoutType => (['xl'].includes(layoutType) ? 40 : 0);
const getPaddingRowContent = (layoutType) => {
  if (['xl'].includes(layoutType)) return 30;
  if (['xs'].includes(layoutType)) return 30;
  return 0;
};

const ToggleLayout = (props) => {
  const { layoutType } = props;
  const [isXs] = toggleLayout({ getLayout: ['xs', 'sm'] });
  if (isXs(layoutType)) return <LayoutXs {...props} />;
  return <LayoutMd {...props} />;
};

const LayoutMd = ({ content, layoutType }) => (
  <RowContent mt={20} pr={getPaddingRowContent(layoutType)} pl={getPaddingRowContent(layoutType)}>
    <ContantList content={content} layoutType={layoutType} />
  </RowContent>
);

const ContantList = ({ content, layoutType }) => content.map((item) => {
  const { t } = useTranslation();
  return (
    <Col key={item.title} className="text-center">
      <ModalContainer
        id={item.id}
        type="video"
        src={item.img}
      />
      <TextContainer mt={20}>
        <H3>
          {t(item.title)}
        </H3>
        <TextContainer
          mt={20}
          pr={getPaddingTextContainer(layoutType)}
          pl={getPaddingTextContainer(layoutType)}
        >
          <P>
            {t(item.text)}
          </P>
        </TextContainer>
      </TextContainer>
    </Col>
  );
});

const LayoutXs = ({ content, layoutType }) => content.map((item) => {
  const { t } = useTranslation();
  return (
    <RowContent mt={20} pr={getPaddingRowContent(layoutType)} pl={getPaddingRowContent(layoutType)}>
      <Col key={item.title} className="text-center">
        <ModalContainer
          id={item.id}
          type="video"
          src={item.img}
        />
        <TextContainer mt={20}>
          <H3>
            {t(item.title)}
          </H3>
          <TextContainer
            mt={20}
            pr={getPaddingTextContainer(layoutType)}
            pl={getPaddingTextContainer(layoutType)}
          >
            <P>
              {t(item.text)}
            </P>
          </TextContainer>
        </TextContainer>
      </Col>
    </RowContent>
  );
});

import React from 'react';
import { toggleLayout } from '@nonlux/react-common/composable';
import {
  Container
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  ContainerContent
} from 'components';
import { LayoutXs, LayoutSm, LayoutLg } from './Layout/Layout.js';

export default function TariffsForm({ layoutType = 'xl', type }) {
  const { t } = useTranslation();
  return (
    <ContainerContent title={t('tariffs.title')}>
      <Container>
        <ToggleLayout
          layoutType={layoutType}
          type={type}
        />
      </Container>
    </ContainerContent>
  );
}

const ToggleLayout = ({ layoutType, type, ...rest }) => {
  const [isXs] = toggleLayout({ getLayout: ['xs'] });
  const [isSm] = toggleLayout({ getLayout: ['sm', 'md'] });
  if (isXs(layoutType)) return <LayoutXs {...rest} layoutType={layoutType} type={type} />;
  if (isSm(layoutType)) return <LayoutSm {...rest} layoutType={layoutType} type={type} />;
  return <LayoutLg {...rest} layoutType={layoutType} type={type} />;
};

import React, { useEffect } from 'react';
import { ScrollToTop } from '@nonlux/react-components';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
import { Layout } from 'components';
import {
  IndexPage,
  ProxyPage,
  VdsPage,
  LoginPage,
  RegistrationPage,
  ForgotPasswordPage,
  RecoverPasswordPage,
  RecoverEmailPage,
  SupportPage,
  TermsPage,
  NotFoundPage,
  VerifyEmailPage,
  ConfirmEmailPage,
  WelcomePage,
  PasswordPage,
  NewUserPage,
  BetStormPage,
  AffiliatePage,
  AccountConfirmationPage,
  UnsubscribePage,
  NotificationSentPage,
  PrivacyPolicyPage,
  UnsubscribeSuccessPage
} from 'pages';
import axios from 'axios';

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Layout>
          <Switch>
            <Route location path="/" exact component={IndexPage} />
            <ReferralRoute path="/proxy" exact component={ProxyPage} />
            <ReferralRoute path="/vds" exact component={VdsPage} />
            <ReferralRoute path="/bet_storm" exact component={BetStormPage} />
            <ReferralRoute path="/sign_up" exact component={RegistrationPage} />
            <Route path="/affiliate/sign_up" exact component={RegistrationPage} />
            <Route path="/sign_in" exact component={LoginPage} />
            <Route path="/password_sign" exact component={PasswordPage} />
            <Route path="/new_user" exact component={NewUserPage} />
            <Route path="/forgot_password" exact component={ForgotPasswordPage} />
            <Route path="/verify_email" exact component={VerifyEmailPage} />
            <Route path="/notification_sent" exact component={NotificationSentPage} />
            <Route path="/unsubscribe_success" exact component={UnsubscribeSuccessPage} />

            <Route path="/confirm_email" exact component={ConfirmEmailPage} />
            <Route path="/welcome" exact component={WelcomePage} />
            <Route path="/recover_password" exact component={RecoverPasswordPage} />
            <Route path="/recover_email" exact component={RecoverEmailPage} />
            <Route path="/support" exact component={SupportPage} />
            <Route path="/terms" exact component={TermsPage} />
            <Route path="/new_user" exact component={NewUserPage} />
            <Route path="/affiliate" exact component={AffiliatePage} />
            <Route path="/confirm_account" exact component={AccountConfirmationPage} />
            <Route path="/privacy" exact component={PrivacyPolicyPage} />
            <Route path="/unsubscribe" exact component={UnsubscribePage} />

            <Route path="*" component={NotFoundPage} />
          </Switch>
        </Layout>
      </ScrollToTop>
    </BrowserRouter>
  );
}

function ReferralRoute(props) {
  useEffect(() => {
    if (!(window.location.search.startsWith('?ref=') || window.location.search.startsWith('?bets='))) return;
    axios.post('/api/ref-link-usage', { refId: window.location.search.split('=')[1] });
  });

  return <Route {...props} />;
}

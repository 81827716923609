import React from 'react';
import {
  Col, Row, CardTitle,
  CardText, ListGroup, ListGroupItem
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import {
  LinkSecondaryLight,
  P,
  ButtonContainer,
} from 'components';
import {
  TitleContainer,
  TarifsTitle,
  PriceContainer,
  ContainerSum,
  Prefix,
  Sum,
  ContainerCurrency,
  Currency,
  Data
} from '../elements.js';

function Price({ price }) {
  const { t } = useTranslation();
  return (
    <PriceContainer>
      <ContainerSum>
        <Prefix>
          {t('tariffs.prefix')}
        </Prefix>
        <Sum>
          {t(price.sum)}
        </Sum>
      </ContainerSum>
      <ContainerCurrency>
        <Currency>
          {t(price.currency)}
        </Currency>
        <Data>
          {t(price.date)}
        </Data>
      </ContainerCurrency>
    </PriceContainer>
  );
}

export const TarifClassic = ({ layoutType, tariffs, getMarinTopBottom }) => {
  const { t } = useTranslation();
  return (
    <Col xs={12} sm={6} lg={3}>
      <ListGroup>
        <TitleContainer>
          <TarifsTitle>
            {t(tariffs.title)}
          </TarifsTitle>
        </TitleContainer>
        <ListGroupItem>
          <CardTitle>
            {t(tariffs.label)}
          </CardTitle>
          <Price layoutType={layoutType} price={tariffs.price} />
          <CardText>
            <P>{t(tariffs.text_1)}</P>
          </CardText>
          <CardText>
            <P>{t(tariffs.text_2)}</P>
          </CardText>
          <CardText>
            <P>{t(tariffs.text_3)}</P>
          </CardText>
          <ButtonContainer mt={getMarinTopBottom} mb={15}>
            <LinkSecondaryLight
              to="/sign_up"
              layoutType={layoutType}
              size="md"
            >
              {t('tariffs.button')}
            </LinkSecondaryLight>
          </ButtonContainer>
        </ListGroupItem>
      </ListGroup>
    </Col>
  );
};

export const TarifPro = ({ layoutType, tariffs, getMarinTopBottom }) => {
  const { t } = useTranslation();
  return (
    <Col xs={12} lg={6}>
      <ListGroup>
        <TitleContainer blue>
          <TarifsTitle black>
            {t(tariffs.title)}
          </TarifsTitle>
        </TitleContainer>
        <ListGroupItem>
          <Row>
            <Col>
              <CardTitle>
                {t(tariffs.vds.label)}
              </CardTitle>
            </Col>
            <Col>
              <CardTitle>
                {t(tariffs.proxy.label)}
              </CardTitle>
            </Col>
          </Row>
          <Price layoutType={layoutType} price={tariffs.price} />
          <Row>
            <Col>
              <CardText>
                <P>{t(tariffs.vds.text_1)}</P>
              </CardText>
              <CardText>
                <P>{t(tariffs.vds.text_2)}</P>
              </CardText>
              <CardText>
                <P>{t(tariffs.vds.text_3)}</P>
              </CardText>
            </Col>
            <Col>
              <CardText>
                <P>{t(tariffs.proxy.text_1)}</P>
              </CardText>
              <CardText>
                <P>{t(tariffs.proxy.text_2)}</P>
              </CardText>
              <CardText>
                <P>{t(tariffs.proxy.text_3)}</P>
              </CardText>
            </Col>
          </Row>
          <ButtonContainer mt={getMarinTopBottom} mb={15}>
            <LinkSecondaryLight
              to="/sign_up"
              layoutType={layoutType}
              size="md"
            >
              {t('tariffs.button')}
            </LinkSecondaryLight>
          </ButtonContainer>
        </ListGroupItem>
      </ListGroup>
    </Col>
  );
};
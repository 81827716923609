import React from 'react';
import {
  Title, Intro, Text, Container as BaseContainer,
} from 'components';
import { useTranslation } from 'react-i18next';
import { withCSS } from '@nonlux/react-common/composable';

const Container = withCSS({
  minHeight: '100%',
  paddingBottom: '150px',
  paddingTop: '150px',
})(BaseContainer);

export default function RecoverEmailPage() {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>
        {t('dialogPages.recoverEmail.title')}
      </Title>
      <Intro>
        {t('dialogPages.recoverEmail.label')}
      </Intro>
      <Text>
        {t('dialogPages.recoverEmail.text')}
      </Text>
    </Container>
  );
}

import React, { Fragment } from 'react';
import { withCSS, toggleLayout } from '@nonlux/react-common/composable';
import {
  Col, Row, CardTitle,
  CardText, ListGroup, ListGroupItem, Container,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { createElement, createDiv } from '@nonlux/react-common';
import {
  ContainerContent,
  RowContent,
  LinkSecondaryLight,
  H3, P,
  Img,
  ButtonContainer,
} from 'components';
import STYLES from '../../styles/constants.js';
import {
  tariffsVdsClassic, tariffsProxyClassic, tariffsPro,
} from './data.js';
import TariffsFormBetStorm from './TariffsFormBetStorm/TariffsFormBetStorm/TariffsFormBetStorm.js';
import { PaymentSystems } from './PaymentSystems/PaymentSystems.js';


export const TitleContainer = withCSS(({ blue }) => ({
  backgroundColor: blue ? STYLES.COLORS.BLUE : STYLES.COLORS.BLACK,
  textAlign: 'center',
}))(ListGroupItem);

export const TarifsTitle = withCSS(({ black }) => ({
  color: black ? STYLES.COLORS.BLACK : STYLES.COLORS.WHITE,
  fontSize: '20px',
  fontWeight: 400,
  marginBottom: 0,
}))(H3);

export const PriceContainer = createDiv('PriceContainer', {
  minWidth: '170px',
  marginBottom: '10px',
});

export const ContainerSum = createElement('div', 'ContainerSum', {
  marginTop: 0,
  marginBottom: 0,
  display: 'inline-block',
});

export const Prefix = createElement('span', 'Prefix', {
  fontSize: '24px',
  marginRight: '9px',
});

export const Sum = createElement('span', 'Sum', {
  fontSize: '53px',
  marginRight: '9px',
});

export const ContainerCurrency = createDiv('ContainerCurrency', {
  display: 'inline-block',
});

export const Currency = createElement('span', 'Currency', {
  fontSize: '22px',
  fontWeight: '700',
  display: 'block',
});

export const Data = createElement('span', 'Data', {
  fontSize: '16px',
  display: 'block',
  lineHeight: 0.8,
});
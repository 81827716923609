import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  RowContent,
  P,
  H3,
  ModalContainer,
  ContainerContent
} from 'components';
import {
  Col,
  Row,
} from 'reactstrap';

import synchronous from './synchronous.png';
import synch from './synch.png';

export default function Synchronization({ content, img }) {
  const { t } = useTranslation();
  return (
    <ContainerContent title={t(content.title)} pb={0}>
      <RowContent>
        <P className="text-center">
          {t(content.text)}
        </P>
        <div style={{marginRight: 'auto', marginLeft: 'auto', paddingRight: '15px', paddingLeft: '15px',}}>
          <ModalContainer
            id="ceB0-teZtdk"
            width="max"
            type="video"
            src={synch}
          />
        </div>
      </RowContent>
    </ContainerContent>
  );
}
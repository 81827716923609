export const options = [
  {
    value: 'AU',
    content: 'Австралия'
  },
  {
    value: 'AT',
    content: 'Австрия'
  },
  {
    value: 'AZ',
    content: 'Азербайджан'
  },
  {
    value: 'AX',
    content: 'Аландские о-ва'
  },
  {
    value: 'AL',
    content: 'Албания'
  },
  {
    value: 'DZ',
    content: 'Алжир'
  },
  {
    value: 'AI',
    content: 'Ангилья'
  },
  {
    value: 'AO',
    content: 'Ангола'
  },
  {
    value: 'AD',
    content: 'Андорра'
  },
  {
    value: 'AQ',
    content: 'Антарктида'
  },
  {
    value: 'AG',
    content: 'Антигуа и Барбуда'
  },
  {
    value: 'AR',
    content: 'Аргентина'
  },
  {
    value: 'AM',
    content: 'Армения'
  },
  {
    value: 'AW',
    content: 'Аруба'
  },
  {
    value: 'AF',
    content: 'Афганистан'
  },
  {
    value: 'BS',
    content: 'Багамы'
  },
  {
    value: 'BD',
    content: 'Бангладеш'
  },
  {
    value: 'BB',
    content: 'Барбадос'
  },
  {
    value: 'BH',
    content: 'Бахрейн'
  },
  {
    value: 'BY',
    content: 'Беларусь'
  },
  {
    value: 'BZ',
    content: 'Белиз'
  },
  {
    value: 'BE',
    content: 'Бельгия'
  },
  {
    value: 'BJ',
    content: 'Бенин'
  },
  {
    value: 'BM',
    content: 'Бермудские о-ва'
  },
  {
    value: 'BG',
    content: 'Болгария'
  },
  {
    value: 'BO',
    content: 'Боливия'
  },
  {
    value: 'BQ',
    content: 'Бонэйр, Синт-Эстатиус и Саба'
  },
  {
    value: 'BA',
    content: 'Босния и Герцеговина'
  },
  {
    value: 'BW',
    content: 'Ботсвана'
  },
  {
    value: 'BR',
    content: 'Бразилия'
  },
  {
    value: 'IO',
    content: 'Британская территория в Индийском океане'
  },
  {
    value: 'BN',
    content: 'Бруней-Даруссалам'
  },
  {
    value: 'BF',
    content: 'Буркина-Фасо'
  },
  {
    value: 'BI',
    content: 'Бурунди'
  },
  {
    value: 'BT',
    content: 'Бутан'
  },
  {
    value: 'VU',
    content: 'Вануату'
  },
  {
    value: 'VA',
    content: 'Ватикан'
  },
  {
    value: 'GB',
    content: 'Великобритания'
  },
  {
    value: 'HU',
    content: 'Венгрия'
  },
  {
    value: 'VE',
    content: 'Венесуэла'
  },
  {
    value: 'VG',
    content: 'Виргинские о-ва (Великобритания)'
  },
  {
    value: 'TL',
    content: 'Восточный Тимор'
  },
  {
    value: 'VN',
    content: 'Вьетнам'
  },
  {
    value: 'GA',
    content: 'Габон'
  },
  {
    value: 'HT',
    content: 'Гаити'
  },
  {
    value: 'GY',
    content: 'Гайана'
  },
  {
    value: 'GM',
    content: 'Гамбия'
  },
  {
    value: 'GH',
    content: 'Гана'
  },
  {
    value: 'GP',
    content: 'Гваделупа'
  },
  {
    value: 'GT',
    content: 'Гватемала'
  },
  {
    value: 'GN',
    content: 'Гвинея'
  },
  {
    value: 'GW',
    content: 'Гвинея-Бисау'
  },
  {
    value: 'DE',
    content: 'Германия'
  },
  {
    value: 'GG',
    content: 'Гернси'
  },
  {
    value: 'GI',
    content: 'Гибралтар'
  },
  {
    value: 'HN',
    content: 'Гондурас'
  },
  {
    value: 'HK',
    content: 'Гонконг (САР)'
  },
  {
    value: 'GD',
    content: 'Гренада'
  },
  {
    value: 'GL',
    content: 'Гренландия'
  },
  {
    value: 'GR',
    content: 'Греция'
  },
  {
    value: 'GE',
    content: 'Грузия'
  },
  {
    value: 'GU',
    content: 'Гуам'
  },
  {
    value: 'DK',
    content: 'Дания'
  },
  {
    value: 'JE',
    content: 'Джерси'
  },
  {
    value: 'DJ',
    content: 'Джибути'
  },
  {
    value: 'DM',
    content: 'Доминика'
  },
  {
    value: 'DO',
    content: 'Доминиканская Республика'
  },
  {
    value: 'EG',
    content: 'Египет'
  },
  {
    value: 'ZM',
    content: 'Замбия'
  },
  {
    value: 'EH',
    content: 'Западная Сахара'
  },
  {
    value: 'ZW',
    content: 'Зимбабве'
  },
  {
    value: 'IL',
    content: 'Израиль'
  },
  {
    value: 'IN',
    content: 'Индия'
  },
  {
    value: 'ID',
    content: 'Индонезия'
  },
  {
    value: 'JO',
    content: 'Иордания'
  },
  {
    value: 'IQ',
    content: 'Ирак'
  },
  {
    value: 'IE',
    content: 'Ирландия'
  },
  {
    value: 'IS',
    content: 'Исландия'
  },
  {
    value: 'ES',
    content: 'Испания'
  },
  {
    value: 'IT',
    content: 'Италия'
  },
  {
    value: 'YE',
    content: 'Йемен'
  },
  {
    value: 'CV',
    content: 'Кабо-Верде'
  },
  {
    value: 'KZ',
    content: 'Казахстан'
  },
  {
    value: 'KH',
    content: 'Камбоджа'
  },
  {
    value: 'CM',
    content: 'Камерун'
  },
  {
    value: 'CA',
    content: 'Канада'
  },
  {
    value: 'QA',
    content: 'Катар'
  },
  {
    value: 'KE',
    content: 'Кения'
  },
  {
    value: 'CY',
    content: 'Кипр'
  },
  {
    value: 'KG',
    content: 'Киргизия'
  },
  {
    value: 'KI',
    content: 'Кирибати'
  },
  {
    value: 'CN',
    content: 'Китай'
  },
  {
    value: 'CO',
    content: 'Колумбия'
  },
  {
    value: 'KM',
    content: 'Коморы'
  },
  {
    value: 'CG',
    content: 'Конго - Браззавиль'
  },
  {
    value: 'CD',
    content: 'Конго - Киншаса'
  },
  {
    value: 'XK',
    content: 'Косово'
  },
  {
    value: 'CR',
    content: 'Коста-Рика'
  },
  {
    value: 'CI',
    content: 'Кот-д’Ивуар'
  },
  {
    value: 'KW',
    content: 'Кувейт'
  },
  {
    value: 'CW',
    content: 'Кюрасао'
  },
  {
    value: 'LA',
    content: 'Лаос'
  },
  {
    value: 'LV',
    content: 'Латвия'
  },
  {
    value: 'LS',
    content: 'Лесото'
  },
  {
    value: 'LR',
    content: 'Либерия'
  },
  {
    value: 'LB',
    content: 'Ливан'
  },
  {
    value: 'LY',
    content: 'Ливия'
  },
  {
    value: 'LT',
    content: 'Литва'
  },
  {
    value: 'LI',
    content: 'Лихтенштейн'
  },
  {
    value: 'LU',
    content: 'Люксембург'
  },
  {
    value: 'MU',
    content: 'Маврикий'
  },
  {
    value: 'MR',
    content: 'Мавритания'
  },
  {
    value: 'MG',
    content: 'Мадагаскар'
  },
  {
    value: 'YT',
    content: 'Майотта'
  },
  {
    value: 'MO',
    content: 'Макао (САР)'
  },
  {
    value: 'MW',
    content: 'Малави'
  },
  {
    value: 'MY',
    content: 'Малайзия'
  },
  {
    value: 'ML',
    content: 'Мали'
  },
  {
    value: 'MV',
    content: 'Мальдивы'
  },
  {
    value: 'MT',
    content: 'Мальта'
  },
  {
    value: 'MA',
    content: 'Марокко'
  },
  {
    value: 'MQ',
    content: 'Мартиника'
  },
  {
    value: 'MX',
    content: 'Мексика'
  },
  {
    value: 'MZ',
    content: 'Мозамбик'
  },
  {
    value: 'MD',
    content: 'Молдова'
  },
  {
    value: 'MC',
    content: 'Монако'
  },
  {
    value: 'MN',
    content: 'Монголия'
  },
  {
    value: 'MS',
    content: 'Монтсеррат'
  },
  {
    value: 'MM',
    content: 'Мьянма (Бирма)'
  },
  {
    value: 'NA',
    content: 'Намибия'
  },
  {
    value: 'NR',
    content: 'Науру'
  },
  {
    value: 'NP',
    content: 'Непал'
  },
  {
    value: 'NE',
    content: 'Нигер'
  },
  {
    value: 'NG',
    content: 'Нигерия'
  },
  {
    value: 'NL',
    content: 'Нидерланды'
  },
  {
    value: 'NI',
    content: 'Никарагуа'
  },
  {
    value: 'NU',
    content: 'Ниуэ'
  },
  {
    value: 'NZ',
    content: 'Новая Зеландия'
  },
  {
    value: 'NC',
    content: 'Новая Каледония'
  },
  {
    value: 'NO',
    content: 'Норвегия'
  },
  {
    value: 'BV',
    content: 'о-в Буве'
  },
  {
    value: 'AC',
    content: 'о-в Вознесения'
  },
  {
    value: 'IM',
    content: 'о-в Мэн'
  },
  {
    value: 'SH',
    content: 'о-в Св. Елены'
  },
  {
    value: 'PN',
    content: 'о-ва Питкэрн'
  },
  {
    value: 'TC',
    content: 'о-ва Тёркс и Кайкос'
  },
  {
    value: 'AE',
    content: 'ОАЭ'
  },
  {
    value: 'OM',
    content: 'Оман'
  },
  {
    value: 'KY',
    content: 'Острова Кайман'
  },
  {
    value: 'CK',
    content: 'Острова Кука'
  },
  {
    value: 'PK',
    content: 'Пакистан'
  },
  {
    value: 'PS',
    content: 'Палестинские территории'
  },
  {
    value: 'PA',
    content: 'Панама'
  },
  {
    value: 'PG',
    content: 'Папуа — Новая Гвинея'
  },
  {
    value: 'PY',
    content: 'Парагвай'
  },
  {
    value: 'PE',
    content: 'Перу'
  },
  {
    value: 'PL',
    content: 'Польша'
  },
  {
    value: 'PT',
    content: 'Португалия'
  },
  {
    value: 'PR',
    content: 'Пуэрто-Рико'
  },
  {
    value: 'KR',
    content: 'Республика Корея'
  },
  {
    value: 'RE',
    content: 'Реюньон'
  },
  {
    value: 'RU',
    content: 'Россия'
  },
  {
    value: 'RW',
    content: 'Руанда'
  },
  {
    value: 'RO',
    content: 'Румыния'
  },
  {
    value: 'SV',
    content: 'Сальвадор'
  },
  {
    value: 'WS',
    content: 'Самоа'
  },
  {
    value: 'SM',
    content: 'Сан-Марино'
  },
  {
    value: 'ST',
    content: 'Сан-Томе и Принсипи'
  },
  {
    value: 'SA',
    content: 'Саудовская Аравия'
  },
  {
    value: 'MK',
    content: 'Северная Македония'
  },
  {
    value: 'SC',
    content: 'Сейшельские Острова'
  },
  {
    value: 'BL',
    content: 'Сен-Бартелеми'
  },
  {
    value: 'MF',
    content: 'Сен-Мартен'
  },
  {
    value: 'PM',
    content: 'Сен-Пьер и Микелон'
  },
  {
    value: 'SN',
    content: 'Сенегал'
  },
  {
    value: 'VC',
    content: 'Сент-Винсент и Гренадины'
  },
  {
    value: 'KN',
    content: 'Сент-Китс и Невис'
  },
  {
    value: 'LC',
    content: 'Сент-Люсия'
  },
  {
    value: 'RS',
    content: 'Сербия'
  },
  {
    value: 'SG',
    content: 'Сингапур'
  },
  {
    value: 'SX',
    content: 'Синт-Мартен'
  },
  {
    value: 'SK',
    content: 'Словакия'
  },
  {
    value: 'SI',
    content: 'Словения'
  },
  {
    value: 'US',
    content: 'Соединенные Штаты'
  },
  {
    value: 'SB',
    content: 'Соломоновы Острова'
  },
  {
    value: 'SO',
    content: 'Сомали'
  },
  {
    value: 'SR',
    content: 'Суринам'
  },
  {
    value: 'SL',
    content: 'Сьерра-Леоне'
  },
  {
    value: 'TJ',
    content: 'Таджикистан'
  },
  {
    value: 'TH',
    content: 'Таиланд'
  },  {
    value: 'TW',
    content: 'Тайвань'
  },
  {
    value: 'TZ',
    content: 'Танзания'
  },
  {
    value: 'TG',
    content: 'Того'
  },
  {
    value: 'TK',
    content: 'Токелау'
  },
  {
    value: 'TO',
    content: 'Тонга'
  },
  {
    value: 'TT',
    content: 'Тринидад и Тобаго'
  },
  {
    value: 'TA',
    content: 'Тристан-да-Кунья'
  },
  {
    value: 'TV',
    content: 'Тувалу'
  },
  {
    value: 'TN',
    content: 'Тунис'
  },
  {
    value: 'TM',
    content: 'Туркменистан'
  },
  {
    value: 'TR',
    content: 'Турция'
  },
  {
    value: 'UG',
    content: 'Уганда'
  },
  {
    value: 'UZ',
    content: 'Узбекистан'
  },
  {
    value: 'UA',
    content: 'Украина'
  },
  {
    value: 'WF',
    content: 'Уоллис и Футуна'
  },
  {
    value: 'UY',
    content: 'Уругвай'
  },
  {
    value: 'FO',
    content: 'Фарерские о-ва'
  },
  {
    value: 'FJ',
    content: 'Фиджи'
  },
  {
    value: 'PH',
    content: 'Филиппины'
  },
  {
    value: 'FI',
    content: 'Финляндия'
  },
  {
    value: 'FK',
    content: 'Фолклендские о-ва'
  },
  {
    value: 'FR',
    content: 'Франция'
  },
  {
    value: 'GF',
    content: 'Французская Гвиана'
  },
  {
    value: 'PF',
    content: 'Французская Полинезия'
  },
  {
    value: 'TF',
    content: 'Французские Южные территории'
  },
  {
    value: 'HR',
    content: 'Хорватия'
  },
  {
    value: 'CF',
    content: 'Центрально-Африканская Республика'
  },
  {
    value: 'TD',
    content: 'Чад'
  },
  {
    value: 'ME',
    content: 'Черногория'
  },
  {
    value: 'CZ',
    content: 'Чехия'
  },
  {
    value: 'CL',
    content: 'Чили'
  },
  {
    value: 'CH',
    content: 'Швейцария'
  },
  {
    value: 'SE',
    content: 'Швеция'
  },
  {
    value: 'SJ',
    content: 'Шпицберген и Ян-Майен'
  },
  {
    value: 'LK',
    content: 'Шри-Ланка'
  },
  {
    value: 'EC',
    content: 'Эквадор'
  },
  {
    value: 'GQ',
    content: 'Экваториальная Гвинея'
  },
  {
    value: 'ER',
    content: 'Эритрея'
  },
  {
    value: 'SZ',
    content: 'Эсватини'
  },
  {
    value: 'EE',
    content: 'Эстония'
  },
  {
    value: 'ET',
    content: 'Эфиопия'
  },
  {
    value: 'GS',
    content: 'Южная Георгия и Южные Сандвичевы о-ва'
  },
  {
    value: 'ZA',
    content: 'Южно-Африканская Республика'
  },
  {
    value: 'SS',
    content: 'Южный Судан'
  },
  {
    value: 'JM',
    content: 'Ямайка'
  },
  {
    value: 'JP',
    content: 'Япония'
  }
];
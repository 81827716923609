import { mergeReducers, createReducer } from '@nonlux/utils/redux';
import TYPES from './types';

const initialState = {
  resetPasswordErrors: [],
  resendVerifyErrors: [],
};

const setResetPasswordErrorsReducer = createReducer(TYPES.RESET_PASSWORD_ERRORS, (state, action) => ({
  ...state,
  resetPasswordErrors: [...action.payload],
}));

const setResendVerifyErrorsReducer = createReducer(TYPES.RESEND_VERIFY_EMAIL_ERROR, (state, action) => ({
  ...state,
  resendVerifyErrors: [...action.payload],
}));

const setResendVerifyErrorsReducer2 = createReducer(TYPES.RESEND_VERIFY_EMAIL_ERROR2, (state, action) => ({
  ...state,
  resendVerifyErrors: [...action.payload],
}));

export default mergeReducers([
  setResetPasswordErrorsReducer,
  setResendVerifyErrorsReducer,
  setResendVerifyErrorsReducer2,
], initialState);

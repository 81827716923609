export const IntlScraper = new class {
  async scrap() {
    return {
      Collator: {
        resolvedOptions: new Intl.Collator().resolvedOptions()
      },
      DateTimeFormat: {
        resolvedOptions: new Intl.DateTimeFormat().resolvedOptions()
      },
      ListFormat: {
        resolvedOptions: new Intl.ListFormat().resolvedOptions()
      },
      NumberFormat: {
        resolvedOptions: new Intl.NumberFormat().resolvedOptions()
      },
      PluralRules: {
        resolvedOptions: new Intl.PluralRules().resolvedOptions()
      },
      RelativeTimeFormat: {
        resolvedOptions: new Intl.RelativeTimeFormat().resolvedOptions()
      }
    };
  }
}();

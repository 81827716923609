import React, { Fragment } from 'react';
import { branched, toggleLayout } from '@nonlux/react-common/composable';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { createDiv, createElement } from '@nonlux/react-common';
import {
  ContainerContent,
  RowContent,
  LinkTargetPrimaryLight,
  Img, ImgContainer,
  P, H3,
  ButtonPosition,
  ModalContainer,
  ImgInnerContainer
} from 'components';

export default function ContainerImgInfo(props) {
  const {
    color,
    title,
    titleWidth,
    id,
    layoutType,
    pt,
    pb,
  } = props;
  const marginTopContent = !title ? 0 : 40;
  return (
    <ContainerContent title={title} color={color} titleWidth={titleWidth} id={id} pt={pt} pb={pb} layoutType={layoutType}>
      <ToggleLayoutImgInfo {...props} marginTopContent={marginTopContent} />
    </ContainerContent>
  );
}

const ToggleLayoutImgInfo = (props) => {
  const { layoutType } = props;
  const [isLg] = toggleLayout({ getLayout: ['lg', 'xl'] });
  if (isLg(layoutType)) return <LayoutLg {...props} />;
  return <LayoutXs {...props} />;
};

const TextList = ({ text }) => text.map((item, index) => {
  const { t } = useTranslation();
  const title = item.h3 && <H3>{t(item.h3)}</H3>;
  return (
    <Fragment key={index}>
      {title}
      <P>{t(item.p)}</P>
    </Fragment>
  );
});

const ToggleImg = (props) => {
  const {
    modalVideo = false,
    modalImg = false,
    border,
    img,
    imgArr = false
  } = props;
  const imgFromArr = imgArr && imgArr[0].img.src;
  if (modalVideo) {
    return (
      <ModalContainer
        id="7s25XvXNuBk"
        width="middle"
        type="video"
        src={imgArr ? imgFromArr : img}
        border={border}
      />
    );
  }
  if (modalImg) {
    return (
      <ModalContainer
        src={imgArr ? imgFromArr : img}
        alt="mtu"
        border={border}
      />
    );
  }
  return <Img src={imgArr ? imgFromArr : img} />;
};

const LinkContainer = createDiv('LinkContainer', ({ mr = 0 }) => ({
  display: 'inline-block',
  marginRight: `${mr}px`
}));


const ButtonsList = (props) => {
  const { t } = useTranslation();
  const { link, layoutType, linkType } = props;
  if (Array.isArray(link)) {
    return link.map((item, index, arr) => {
      let mr = arr[0] && 10;
      return (
        <LinkContainer key={index} mr={mr}>
          <LinkTargetPrimaryLight
            to={item.href}
            target={item.linkType || 'self'}
            layoutType={layoutType}
            size="md"
          >
            {item.text}
          </LinkTargetPrimaryLight>
        </LinkContainer>
      );
    });
  }
  return (
    <LinkTargetPrimaryLight
      to={link}
      target={linkType || 'self'}
      layoutType={layoutType}
      size="md"
    >
      {t('vds.statisticsText.button')}
    </LinkTargetPrimaryLight>
  );
};

const Button = (props) => {
  const { link = false, layoutType } = props;
  const mid = ['xs', 'sm', 'md'].includes(layoutType);
  return (link) && (
    <ButtonPosition mt={30} mb={15} center={mid}>
      <ButtonsList {...props} />
    </ButtonPosition>
  );
};

/*function getMarginImgTop(props) {
  const { layoutType, marginImg = false } = props;
  if (Array.isArray(marginImg)) {
    const get = {
      xs: marginImg[0],
      sm: marginImg[1],
      md: marginImg[2],
      lg: marginImg[3],
      xl: marginImg[4]
    };
    return get[layoutType];
  }
  return marginImg;
}*/

const TextBox = createDiv('TextBox', ({ mt = 0, ml = 0 }) => ({
  marginTop: `${mt}px`,
  marginLeft: `${ml}px`,
}));

const ToggleImgContainer = (props) => {
  const { imgArr, mtImgBox, maxWidthImgBox } = props;
  return imgArr ? <ImgInnerContainer {...props} /> : (
    <ImgContainer mt={mtImgBox} maxWidthImgBox={maxWidthImgBox}>
      <ToggleImg {...props} />
    </ImgContainer>
  );
};

function LeftLayout(props) {
  const { text, marginTopContent, mtTextBox, mlTextBox } = props;
  return (
    <RowContent mt={marginTopContent}>
      <Col>
        <ToggleImgContainer {...props} />
      </Col>
      <Col>
        <TextBox mt={mtTextBox} ml={mlTextBox}>
          <TextList text={text} />
          <Button {...props} />
        </TextBox>
      </Col>
    </RowContent>
  );
}

function RightLayout(props) {
  const {
    text, marginTopContent, mtTextBox
  } = props;
  return (
    <RowContent mt={marginTopContent}>
      <Col>
        <TextBox mt={mtTextBox}>
          <TextList text={text} />
          <Button {...props} />
        </TextBox>
      </Col>
      <Col>
        <ToggleImgContainer {...props} />
      </Col>
    </RowContent>
  );
}

function LayoutXs(props) {
  const { text, mtImgBox, mbImgBox, imgAboveTextXsLayout, hideImg = true } = props;
  return imgAboveTextXsLayout ? (
    <Fragment>
      <Row>
        <TextList text={text} />
        <Button {...props} />
      </Row>
      {hideImg && (
        <Row>
          <ImgContainer
            mt={mtImgBox}
            mb={mbImgBox}
            center
          >
            <ToggleImg {...props} />
          </ImgContainer>
        </Row>
      )}

    </Fragment>
  ) : (
    <Fragment>
      <Row>
        <ImgContainer
          mt={mtImgBox}
          mb={mbImgBox}
          center
        >
          <ToggleImg {...props} />
        </ImgContainer>
      </Row>
      <Row>
        <TextList text={text} />
        <Button {...props} />
      </Row>
    </Fragment>
  );
}

const LayoutLg = branched(({ right = false }) => right, RightLayout)(LeftLayout);
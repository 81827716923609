// libs
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { createDiv } from '@nonlux/react-common';
import { calcBuildPriceList, calcProxy, calcVds } from '@nonlux/ab-prices';
import {
  H2,
  H2Landing,
  RowContainer,
  MarginTopContainer,
  ButtonContainer,
  Button,
  TariffsContainer
} from './styleElements';
import { withBuyVdsTariffAction } from '../../reducer/profileTariff/selectors';
// components
import ModalRoot from '../ProfilesPage/Modals/ModalRoot';
import TariffsForm from './TariffsForm/TariffsForm';

const mastercard = require('./icon/mastercard_128.png');
const qiwi = require('./icon/qiwi_128.png');
const visa = require('./icon/visa_128.png');
const eth = require('./icon/eth_128.png');

const PaymentContainer = createDiv('PaymentMethods', {
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  gridGap: '50px',
  marginBottom: '60px'
});

const PaymentMethods = () => (
  <PaymentContainer>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={visa} alt="logo" style={{ height: '35px' }} />
    </div>
    <div>
      <img src={mastercard} alt="logo" style={{ height: '40px' }} />
    </div>
    <div>
      <img src={qiwi} alt="logo" style={{ height: '40px' }} />
    </div>
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        gridGap: '10px',
        alignItems: 'center'
      }}
    >
      <img src={eth} alt="logo" style={{ height: '40px' }} />
      <div style={{ color: '#738496', userSelect: 'none' }}>Ethereum</div>
    </div>
  </PaymentContainer>
);

const TariffsVdsPage = ({
  landing, button, buyVdsTariffAction, id
}) => {
  const [isOpenDropdownCountries, setIsOpenDropdownCountries] = useState(false);
  const [isOpenDropdownCities, setIsOpenDropdownCities] = useState(false);
  const [isOpenDropdownOperators, setIsOpenDropdownOperators] = useState(false);

  const [vdsType, setVdsType] = useState('0');
  const [vcpu, setVcpu] = useState(2);
  const [ram, setRam] = useState(4);
  const [space, setSpace] = useState(65);
  const [vdsCount, setVdsCount] = useState(1);
  const [geo, setGeo] = useState('uk');
  const [city, setCity] = useState('London');
  const [mobileOperator, setMobileOperator] = useState('Three');
  const [buildCount, setBuildCount] = useState(1);
  const [daysCount, setDaysCount] = useState(30);
  const [typeConnection, setTypeConnection] = useState('1');
  const [isMobileProxiesSelect, setIsMobileProxiesSelect] = useState(false);
  const [proxyType, setProxyType] = useState('standard');
  const [sum, setSum] = useState(49);

  useEffect(() => {
    if (localStorage.getItem('onRegistrationBuyVds')) {
      const proxy = JSON.parse(localStorage.getItem('proxy'));
      if (!Object.keys(proxy).length) {
        setIsMobileProxiesSelect(false);
      }
      const {
        buildCount,
        geo,
        city,
        mobileOperator,
        vcpu,
        vdsCount,
        ram,
        space,
        vdsType,
        daysCount,
        vpn
      } = JSON.parse(localStorage.getItem('vds'));
      setVdsType(vdsType);
      setVcpu(vcpu);
      setRam(ram);
      setSpace(space);
      setBuildCount(buildCount);
      setVdsCount(vdsCount);
      setGeo(geo);
      setCity(city);
      setMobileOperator(mobileOperator);
      setDaysCount(daysCount);
      setTypeConnection(vpn);

      localStorage.removeItem('onRegistrationBuyVds');
      localStorage.removeItem('vds');
      localStorage.removeItem('proxy');
    }
  }, []);

  useEffect(() => {
    if (!isMobileProxiesSelect) {
      setCity('');
      setMobileOperator('');
    }
    const proxySum = isMobileProxiesSelect
      ? calcProxy({
        proxyType: proxyType === 'standard' ? '0' : '2',
        vpn: typeConnection,
        authorization: proxyType === 'standard' ? 'pass' : 'switch',
        geo,
        daysCount,
        operator: mobileOperator,
        proxyCount: vdsCount
      })
      : 0;
    const buildPriceList = calcBuildPriceList({
      buildCount,
      vdsCount,
      joinNonZero: true
    }).reduce((pv, bp) => pv + bp.price, 0);
    // let proxySum = isMobileProxiesSelect && (proxyPrices[daysCount][geo][] + (typeConnection === '1' ? 5 : 0));
    // proxySum += mobileOperator === 'three' ? 10 : 0;
    // proxySum = (daysCount === 1 && isMobileProxiesSelect) ? 9 : proxySum;
    setVdsType(
      isMobileProxiesSelect && vcpu >= 4 && ram >= 8
        ? '2'
        : isMobileProxiesSelect
          ? '1'
          : '0'
    );
    const vdsSum = calcVds({
      daysCount,
      vcpu,
      ram,
      space,
      vdsCount
    });

    setSum((vdsSum + proxySum) * vdsCount + buildPriceList);
  }, [
    vcpu,
    ram,
    space,
    vdsCount,
    buildCount,
    daysCount,
    proxyType,
    typeConnection,
    isMobileProxiesSelect,
    geo,
    mobileOperator
  ]);

  const onBuyClick = (sign = false) => {
    const vds = {
      buildCount,
      buildTime: '0',
      daysCount,
      fingerprint: 'win10',
      geo,
      gpu: '1',
      ipChangeTime: '0',
      proxyType: '1',
      vpn: typeConnection,
      resolution: '0',
      vcpu,
      ram,
      space,
      vdsCount,
      city,
      operator: mobileOperator,
      vdsType
    };
    const proxy = isMobileProxiesSelect
      ? {
        buildCount: null,
        proxyCount: vdsCount,
        buildTime: '0',
        daysCount,
        fingerprint: 'win10',
        geo,
        gpu: '1',
        ipChangeTime: '0',
        proxyType: proxyType === 'standard' ? '0' : '2',
        vpn: typeConnection,
        resolution: '0',
        city,
        operator: mobileOperator,
        authorization: proxyType === 'standard' ? 'pass' : 'switch'
      }
      : {};

    if (sign) {
      localStorage.setItem('vds', JSON.stringify(vds));
      localStorage.setItem('proxy', JSON.stringify(proxy));
      localStorage.setItem('onRegistrationBuyVds', JSON.stringify(true));
      return;
    }

    buyVdsTariffAction(vds, proxy, sum);
  };

  return (
    <RowContainer
      id={id}
      landing={landing}
      onClick={() => {
        if (isOpenDropdownCountries) setIsOpenDropdownCountries(false);
        if (isOpenDropdownCities) setIsOpenDropdownCities(false);
        if (isOpenDropdownOperators) setIsOpenDropdownOperators(false);
      }}
    >
      <MarginTopContainer>
        {landing ? (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '50px',
            paddingBottom: '50px'
          }}
          >
            <H2Landing>СОЗДАЙТЕ СВОЮ КОНФИГУРАЦИЮ</H2Landing>
          </div>
        ) : <H2>VDS</H2>
        }
        <TariffsContainer landing={landing}>
          <TariffsForm
            vcpu={vcpu}
            setVcpu={setVcpu}
            ram={ram}
            setRam={setRam}
            space={space}
            setSpace={setSpace}
            vdsCount={vdsCount}
            setVdsCount={setVdsCount}
            geo={geo}
            setGeo={setGeo}
            setCity={setCity}
            setMobileOperator={setMobileOperator}
            mobileOperator={mobileOperator}
            buildCount={buildCount}
            setBuildCount={setBuildCount}
            setTypeConnection={setTypeConnection}
            typeConnection={typeConnection}
            isMobileProxiesSelect={isMobileProxiesSelect}
            setIsMobileProxiesSelect={setIsMobileProxiesSelect}
            proxyType={proxyType}
            setProxyType={setProxyType}
            setDaysCount={setDaysCount}
            daysCount={daysCount}
            sum={sum}
            vdsType={vdsType}
            city={city}
            isOpenDropdownCountries={isOpenDropdownCountries}
            isOpenDropdownCities={isOpenDropdownCities}
            isOpenDropdownOperators={isOpenDropdownOperators}
            setIsOpenDropdownCountries={setIsOpenDropdownCountries}
            setIsOpenDropdownCities={setIsOpenDropdownCities}
            setIsOpenDropdownOperators={setIsOpenDropdownOperators}
          />
          <ButtonContainer>
            {button === 'sign'
              ? (
                <Link to="/sign_up">
                  <Button
                    onClick={() => { onBuyClick(true); }}
                  >
                    Подключить
                  </Button>
                </Link>
              )
              : (
                <Button onClick={() => { onBuyClick(false); }} disabled>
                  Оплатить
                </Button>
              )
            }
          </ButtonContainer>
          {landing && <PaymentMethods />}
        </TariffsContainer>
      </MarginTopContainer>
      <ModalRoot />
    </RowContainer>
  );
};

export default compose(withBuyVdsTariffAction)(TariffsVdsPage);

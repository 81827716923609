export const BasicScraper = new class {
  async scrap() {
    const response = {
      document: {
        documentElement: {
          clientWidth: document.documentElement.clientWidth,
          clientHeight: document.documentElement.clientHeight
        }
      },
      window: {
        devicePixelRatio: window.devicePixelRatio,
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,

        screen: {
          width: window.screen.width,
          height: window.screen.height,
          availWidth: window.screen.availWidth,
          availHeight: window.screen.availHeight,
          availLeft: window.screen.availLeft,
          availTop: window.screen.availTop,
          colorDepth: window.screen.colorDepth,
          pixelDepth: window.screen.pixelDepth
        },
        navigator: {
          appCodeName: window.navigator.appCodeName,
          appName: window.navigator.appName,
          appVersion: window.navigator.appVersion,
          buildID: window.navigator.buildID,
          deviceMemory: window.navigator.deviceMemory,
          doNotTrack: window.navigator.doNotTrack,
          hardwareConcurrency: window.navigator.hardwareConcurrency,
          language: window.navigator.language,
          languages: window.navigator.languages,
          maxTouchPoints: window.navigator.maxTouchPoints,
          platform: window.navigator.platform,
          product: window.navigator.product,
          productSub: window.navigator.productSub,
          userAgent: window.navigator.userAgent,
          vendor: window.navigator.vendor,
          vendorSub: window.navigator.vendorSub,

          plugins: Array.from(window.navigator.plugins)
            .map(p => ({
              description: p.description,
              filename: p.filename,
              name: p.name,
              mime: Array.from(p).map(m => ({
                description: m.description,
                suffixes: m.suffixes,
                type: m.type
              }))
            }))
        }
      }
    };

    if (window.navigator.connection) {
      response.window.navigator.connection = {
        downlink: window.navigator.connection.downlink,
        effectiveType: window.navigator.connection.effectiveType,
        rtt: window.navigator.connection.rtt,
        saveData: window.navigator.connection.saveData
      };
    }

    if (window.navigator.userAgentData) {
      response.window.navigator.highEntropyValues = await navigator.userAgentData.getHighEntropyValues(
        [
          "architecture",
          "bitness",
          "fullVersionList",
          "model",
          "platformVersion",
          "uaFullVersion"
        ]
      );
    }

    if (window.performance.memory) {
      response.performance = {
        memory: {
          jsHeapSizeLimit: window.performance.memory.jsHeapSizeLimit,
          totalJSHeapSize: window.performance.memory.totalJSHeapSize,
          usedJSHeapSize: window.performance.memory.usedJSHeapSize
        }
      };
    }

    // As of FF 85, VisualViewport API is not implemented.
    if (window.visualViewport) {
      response.window.visualViewport = {
        height: window.visualViewport.height,
        offsetLeft: window.visualViewport.offsetLeft,
        offsetTop: window.visualViewport.offsetTop,
        pageLeft: window.visualViewport.pageLeft,
        pageTop: window.visualViewport.pageTop,
        scale: window.visualViewport.scale,
        width: window.visualViewport.width
      };
    }

    return response;
  }
}();

import React from 'react';
import {
  ContainerContent,
  RowContent,
  H3,
  P,
  TextContainer,
} from 'components';
import { useTranslation } from 'react-i18next';

export default function AboutCompany({ title, layoutType }) {
  const getPaddingContainer = () => {
    if (['xl'].includes(layoutType)) return 200;
    if (['lg'].includes(layoutType)) return 100;
    if (['md'].includes(layoutType)) return 50;
    return 20;
  };
  const { t } = useTranslation();
  return (
    <ContainerContent title={title} id="about_us">
      <RowContent>
        <TextContainer pl={getPaddingContainer()} pr={getPaddingContainer()}>
          <H3>
            {t('main.aboutCompany.text.item1')}
          </H3>
          <TextContainer mt={20}>
            <P>
              {t('main.aboutCompany.text.item2')}
            </P>
          </TextContainer>
          <TextContainer>
            <P>
              {t('main.aboutCompany.text.item3')}
            </P>
          </TextContainer>
          <TextContainer>
            <P>
              {t('main.aboutCompany.text.item4')}
            </P>
          </TextContainer>
          <TextContainer>
            <P>
              {t('main.aboutCompany.text.item5')}
            </P>
          </TextContainer>
          <TextContainer>
            <P>
              {t('main.aboutCompany.text.item6')}
            </P>
          </TextContainer>
        </TextContainer>
      </RowContent>
    </ContainerContent>
  );
}

import React from 'react';

/**
 * параметр target:
 _blank - загружает страницу в новое окно браузера.
 _self - загружает страницу в текущее окно.
 _parent - загружает страницу во фрейм-родитель, если фреймов нет,
  то это значение работает как _self.
 _top - отменяет все фреймы и загружает страницу в полном окне браузера,
  если фреймов нет, то это значение работает как _self.
 */
export const LinkTarget = (props) => {
  const {
    children, className, to, target,
  } = props;
  return (
    <a className={className} target={target} href={to}>
      {children}
    </a>
  );
};